import { PageRouteProps } from "../../types";

export const serviceRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/service/list",
        "module": ["Ecommerce"],
        "component": "ServiceList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/service/add",
        "module": ["Ecommerce"],
        "component": "AddService",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/service/category",
        "module": ["Ecommerce"],
        "component": "ServiceCategory",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/service/edit",
        "module": ["Ecommerce"],
        "component": "AddService",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/service/list",
        "module": ["Ecommerce"],
        "component": "ServiceList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/service/freshly-brewed",
        "module": ["Ecommerce"],
        "component": "FreshlyBrewedServices",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/trash/service/premium",
        "module": ["Ecommerce"],
        "component": "OurPremiumServices",
        "type": "admin",
        "mode": "static"
    }
]