import Select from 'react-select'
import { useManageAddress } from './hooks';
import { useElement, useService } from '../../../hooks';

export const ManageAddress = () => {
    const {parseAddress} = useService('Misc')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const {
        trashLocation,
        user,
        activeBranches,
        selectCompany,
        branches,
        addressList,
    } = useManageAddress()

    const renderAddressList = addressFiles => {
        return addressFiles.map(( item, index ) => {
            return <div className="form-box" key={index}>
                <div className="account-detail service-single-item locationlistdetail">
                    <div className="service-content">
                        <div className="item-number">{index>9?(index+1): '0'+(index+1)}</div>
                    </div>
                    <div className="form-group col">
                        <label className="control-label initial">Address</label>
                        <p>{parseAddress(item)}</p>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label className="control-label initial">Landmark </label>
                            <p>{item.landmark}</p>
                        </div>
                        <div className="form-group col">
                            <label className="control-label initial">Alternate Phone No. </label>
                            <p>{item.alternateNo}</p>
                        </div>
                    </div>
                    { !['company', 'representative'].includes(user.role) && <div className="flex">
                        <Link className="btn btn-outline btn-box" href={"/address/edit/"+item.uid}>Edit</Link>
                        <Button className="btn btn-primary btn-box ml20" onClick={() => trashLocation(item)}>Remove</Button>
                    </div>}
                </div>
            </div>
        })
    }

    return <div className="right-side-profile-content">
        <div className='flex overflow-visible'>
            <h1 className="nowrap">
                <span className='mr20'>Manage Address</span>
                {
                    !['company', 'representative'].includes(user.role) && ((addressList.length < 1 && ['manager', 'branch'].includes(user?.role)) || !['manager', 'branch'].includes(user?.role)) && <Link className="btn btn-primary btn-small locationbtn" href="/address/add">Add</Link>
                }
            </h1>
            {
                ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                <Select
                    isClearable
                    closeMenuOnSelect={false}
                    placeholder='Select Company'
                    defaultValue={activeBranches}
                    onChange={selectCompany}
                    options={branches}
                    isMulti
                />
            }
        </div>
        <div className="profile-sec wrapper">
            <div className="form-editable-section">
                { renderAddressList(addressList || []) }
            </div>
        </div>
    </div>
}
