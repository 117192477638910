import { brandInfo } from '../../../constant'
import { useFeedback } from './hooks'
import { useElement, useWidget } from '../../../hooks'

export const Feedback = () => {
    const Button = useElement('Button')
    const Banner = useWidget('Banner')
    const {
        feedBackSubmit,
        rating,
        setrating,
        comments,
        setcomments,
    } = useFeedback()

    return <>
        <Banner title="Feedback" image="blue-shutter-1.jpg" />
        <div className="feedback-page mt40 mb40">
            <div className="jumbo-container">
                <div className="heading-box flex mb20">
                    <p className="star-icon"><i className="hi-star-o"></i> </p>
                    <h1>Feedback</h1>
                </div>
                <div className="title"><b>Service: </b> Pick & Drop</div>
                <div className="body-feedback mb20">
                    <p className="mb20">How likely are you to recommend {brandInfo.name.capital} to your friends and colleagues ?</p>
                    <form onSubmit={feedBackSubmit}>
                        <div className="rating-grid-box mb20">
                            <span className={rating === 1?'rate1 active': 'rate1'} onClick={() => setrating(1)}>1</span>
                            <span className={rating === 2?'rate2 active': 'rate2'} onClick={() => setrating(2)}>2</span>
                            <span className={rating === 3?'rate3 active': 'rate3'} onClick={() => setrating(3)}>3</span>
                            <span className={rating === 4?'rate4 active': 'rate4'} onClick={() => setrating(4)}>4</span>
                            <span className={rating === 5?'rate5 active': 'rate5'} onClick={() => setrating(5)}>5</span>
                        </div>
                        <p>Any Other Feedback ?</p>
                        <textarea className="textarea-feedback" placeholder="Type something.." value={comments} onChange={e => setcomments(e.target.value)}></textarea>
                        <Button type="submit" className="btn btn-primary">Submit</Button>
                    </form>
                </div>
            </div>
        </div>
    </>
}
