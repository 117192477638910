import { PageRouteProps } from "../../types";

export const projectRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/project/list",
        "module": ["Project"],
        "component": "AdminProjectList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/project/add",
        "module": ["Project"],
        "component": "AddProject",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/project/edit",
        "module": ["Project"],
        "component": "AddProject",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/project/list",
        "module": ["Blog"],
        "component": "PostList",
        "type": "admin",
        "mode": "static"
    }
]