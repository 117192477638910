import { useContext, useEffect, useState } from "react"
import { userRole, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext, NavigatorContext } from "../../../../context"

export const useUsers = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const filterTags = [{label:'All', value: 'all'}, ...userRole]
    const {save} = useService('Data', 'log')
    const {
        getLoggedInUserData,
        getUsers,
        deleteUser: removeUser,
        updateUser,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        generateUID,
        isBottom,
    } = useService('Misc')
    const {
        getLocationData,
        urlToSplitData,
    } = useService('Router')
    const {commonPageSize} = config
    const user = getLoggedInUserData()
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [exportedData, setexportedData] = useState({})
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState<string>('')
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])
    const urlHash = getLocationData().hash.replace('#', '')
    const [pagination, setpagination] = useState({ size: commonPageSize, number: 0 })
    const [tableContent, settableContent] = useState({
        headers: [
            {
                template: <span className="check-all" onClick={() => {
                    let newCheckedList = []
                    if (checkedAll) {
                        setcheckedAll(!checkedAll)
                        setcheckedList([])
                    } else {
                        filteredList.forEach(e => newCheckedList.push(e.uid))
                        setcheckedAll(!checkedAll)
                        setcheckedList(newCheckedList)
                    }
                }}>
                    <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                </span>
            },
            {
                label: 'Name',
                key: 'name'
            },
            {
                label: 'Email',
                key: 'email'
            },
            {
                label: 'UID',
                key: 'uid'
            },
            {
                label: 'Role',
                key: 'role'
            },
            {
                label: 'Mobile',
                key: 'mobile'
            },
            {
                label: 'Image',
                key: 'image'
            },
            {
                label: 'Actions',
                key: 'actions'
            }
        ],
        content: null,
    })

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length) showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getUsersData = async () => {
        const urlData = urlToSplitData(getLocationData().pathname), trashAction = urlData[2] === 'trash'
        const result = await getUsers()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if ((user.role === 'supervisor' && e.supervisor && JSON.parse(e.supervisor).value === user.uid) || user.role !== 'supervisor') {
                    if (!trashAction) {
                        if (!e.trash)
                            tableData.push(e)
                    } else {
                        if (e.trash)
                            tableData.push(e)
                    }
                }
            })
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                settableData(tableData)
                const activefilterValue = filterTags.find(e => Number(e.id) === activeFilterIndex+1)?.value ?? urlHash !== '' ? urlHash : 'all'
                setactiveFilterIndex(filterTags.findIndex(e => e.value === activefilterValue))
                filterItems( activefilterValue, activeFilterIndex)
            }
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectUser = () => {
        deleteUser(activeItem.uid)
    }
    
    const deleteUser = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await removeUser({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'User deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getUsersData()
            } else showAlert({ type: 'error', msg: 'Unable to delete order!' })
        } else {
            let userObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    userObject = e
            })
            userObject.trash = task === 'trash'
            delete userObject._id
            let data = {
                query: JSON.stringify({uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await updateUser(data)
            if (result.status) {
                let logData = {
                    log: userObject.name+' updated by admin with website',
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: userObject.uid,
                    uid: generateUID()
                }
                save(logData)
                showAlert({ type: 'success', msg: 'User '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getUsersData()
            } else showAlert({ type: 'error', msg: 'Unable to update user!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteUser(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const editUser = userData => {
        navigateTo({route: '/admin/user/edit/'+userData.uid})
    }
    
    const filterItems = ( key, activeFilterIndex ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.role === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.name.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1 || e.mobile.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const tempLogin = tempUserData => {
        showPreloader()
        localStorage.setItem('tempLogin', 'true')
        localStorage.setItem('tempUserData', JSON.stringify(tempUserData))
        setTimeout(() => {
            hidePreloader()
            navigateTo({route: '/user/profile'})
        }, 2000)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                name: item.name,
                email: item.email,
                uid: item.uid,
                role: item.role,
                mobile: item.mobile,
                image: item.image?
                    <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                    <p>No Image</p>,
                action: user.role !== "supervisor" ?
                    <>
                        {
                            trashAction ?
                            <div className="action-bar">
                                <Actor type='trash' onClick={() => {
                                    setactiveItem(item)
                                    setshowTrashConfirm(true)
                                }} />
                                <Actor type='undo' onClick={() => deleteUser(item.uid, 'restore')} />
                            </div>:
                            <div className="action-bar">
                                <Actor type='trash' onClick={() => {
                                    setactiveItem(item)
                                    setshowTrashConfirm(true)
                                }} />
                                <Actor type='edit' onClick={() => navigateTo({route: '/admin/user/edit/'+item.uid, newTab: true})} />
                                {
                                    item.role !== 'admin' &&
                                    <Actor type='login' onClick={() => tempLogin(item)} title={"Login As "+item.name} />
                                }
                            </div>
                        }
                    </>:
                    <div className="action-bar">
                        <Actor type='car' onClick={() => navigateTo({route: '/admin/car/list/'+item.uid, newTab: true})} title="View Cars" />
                        <Actor type='work' onClick={() => navigateTo({route: '/admin/work/list/'+item.uid, newTab: true})} title="View Work Details" />
                    </div>
            }
            data.push(e)
        })
        return data
    }

    setTitle('Users List', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getUsersData()
    }, [])

    useEffect(() => {
        setfilteredList(tableData)
    }, [tableData])

    useEffect(() => {
        let exportedData = []
        filteredList.forEach(e => {
            let ex = {
                email: e.email,
                name: e.name,
                role: e.role,
                gstin: e.gstin,
                mobile: e.mobile,
                uid: e.uid,
            }
            exportedData.push(ex)
        })
        setexportedData(exportedData)
        settableContent({
            ...tableContent,
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        urlHash,
        user,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectUser,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
        filteredList,
        exportedData,
    }
}