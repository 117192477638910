import {brandInfo, headerNav} from '../../../constant'
import {SideCart} from '../SideCart'
import { useHeader } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'
import './style.scss'
export const Header = () => {
    const {
        isUserLoggedIn,
        isAllowedAdmin,
    } = useService('User')
    const {
        isModuleEnabled,
        isEmpty,
        isDesktop,
        toggleBodyClass,
        preventFormSubmit,
    } = useService('Misc')
    const Input = useElement('Input')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const CarSelector = useComponent('CarSelector')
    const GuestCarQueryModal = useComponent('GuestCarQueryModal')
    const TopNavbarBanner = useComponent('TopNavbarBanner')
    const {
        showCarSelector,
        getSelectedCar,
        toggleTopNavBar,
        activeTopBar,
        cityDetail,
        cityDescription,
        cartCount,
        toggleUserNav,
        userProfileImage,
        showUserNav,
        sendBackToAdmin,
        logout,
        productSearch,
        resetProductSearch,
        searchResultBackGround,
        user,
        showSideCart,
        navBackGround,
        toggleCart,
        searchKey,
        searchProduct,
        filteredList,
        settemporaryUser,
        temporaryUser,
        activeCar,
        currentUrl,
        navigateToLink,
    } = useHeader()
    const renderCarSelector = () => {
        return showCarSelector && <section className="car-select-form">
            <CarSelector getSelectedCar={getSelectedCar} getNewCars={false} />
        </section>
    }

    const renderPublicMenu = () => {
        return headerNav.map(( item, index ) => {
            if(isModuleEnabled(item.module) && !item.disabled) {
                if (isUserLoggedIn()) {
                    if (!item.rejectRoles || !item.rejectRoles.includes(user?.role)) {
                        return <li title={item.title} key={index}>
                            <Link onClick={() => navigateToLink(item.link)} href={item.link}>{item.icon ? <i className={item.icon}></i> : item.title}</Link>
                        </li>
                    }
                } else {
                    return <li title={item.title} key={index}><Link onClick={() => navigateToLink(item.link)} href={item.link}>{item.icon ? <i className={item.icon}></i> : item.title}</Link></li>
                }
            }
        })
    }
    const renderFilteredList = filteredList => {
        return filteredList.map(( item, index ) => {
            let productimg = JSON.parse(item.image)
            return <li key={index} className="blog-product-search-box">
                <Link href={"/"+item.type+"/" + item.slug} title={item.title} className="flex">
                    <Img className="blog-product-search-img"
                        src={isEmpty(item.image) ? 'product-placeholder.png':productimg[0]}
                        alt={item.title}
                    />
                    <div className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                        {
                            item.type === 'product' && <>
                                <span className="black"><b>Item Code: </b><span className="gray">{item.sku}</span> </span> <span className="black ml10"> <b>Stock: </b> <span className="gray">{item.stock} </span></span>
                            </>
                        }
                    </div>
                </Link>
            </li>
        })
    }
    return <>
        {
            activeTopBar && (localStorage.getItem('topBarBanner') !== 'no') &&
            <>
                {isModuleEnabled(["TopNavbarBanner"]) && <TopNavbarBanner activeTopBar={true} onClick={toggleTopNavBar} />}
                <div className="close-top-ads-box" onClick={toggleTopNavBar}>
                    <i className="close-top-ads hi-cross-circle"></i>
                </div>
            </>
        }
        <div className= {activeTopBar && (localStorage.getItem('topBarBanner') !== 'no') && isModuleEnabled(["TopNavbarBanner"]) ? "header-container-active-top-bar": "header-container"}>
            <div className="header-background">
                <div className="main-header jumbo-container">
                    <div className="left-header">
                        <Link href="/" title={brandInfo.name.capital}>
                            <Img source='internal' alt={brandInfo.name.capital} src={brandInfo.logo} className="logo" />
                        </Link>
                        {isModuleEnabled(['CitySwitch']) && (!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <p className="city-name" onClick={() => cityDescription() }>Jaipur/Mohali <i className="hi-arrow_drop_down"></i></p>}
                    </div>
                        {
                        cityDetail &&
                            <>
                                <div onClick={cityDescription} className="backgroundFixedLocation"></div>
                                <div className="dropDown-City">
                                    <p className='mb0'>We are servicing in Jaipur and Mohali only, upcoming cities will be updated here</p>
                                </div>
                            </>
                        }
                        { isModuleEnabled(["Ecommerce"]) && (isUserLoggedIn() ? !['manager', 'company', 'branch', 'representative'].includes(user?.role) : true) &&  <span className="mobile-cart-header-top">
                        {
                            !currentUrl.includes('cart') && !currentUrl.includes('checkout') &&
                            <li className="navbarMobile-cart"><span onClick={toggleCart}><i className="hi-cart1"></i><p className="cartCount"><span>{cartCount}</span></p></span></li>
                        }
                        </span>}
                    <i className="hi-menu1 btn-menu" onClick={() => toggleBodyClass('nav-open')}></i>

                    {isModuleEnabled(["SiteWideSearch"]) && <div className="for-mobile-search">
                        <form className="search-bar" noValidate onSubmit={preventFormSubmit}>
                            <Input required value={searchKey || ''} placeholder="Search Products & Services for your car" onChange={searchProduct} />
                            {
                                searchKey === '' ? <Button className="btn btn-primary" onClick={productSearch}><i className="ml0 hi-search1"></i></Button>
                                :<Button onClick={resetProductSearch}><i className="hi-close"></i></Button>
                            }
                        </form>
                        {
                            searchKey !== '' &&
                            <div className="search-result for-mobile-search-result">
                                <ul>
                                    {renderFilteredList(filteredList || [] )}
                                </ul>
                            </div>
                        }
                        <div onClick={searchResultBackGround} className="background-fixed-search-result"></div>
                    </div>}
                    <div className="right-header">
                        <i className="hi-arrow-circle-right btn-menu white" onClick={() => toggleBodyClass('nav-open')}></i>
                        <ul>
                            {
                                isModuleEnabled(['Ecommerce']) && (!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && !currentUrl.includes('cart') && !currentUrl.includes('checkout') &&
                                <li><span onClick={toggleCart}><i className="hi-cart1"></i><p className="cartCount"><span>{cartCount}</span></p></span></li>
                            }
                            {
                                !isDesktop() && renderPublicMenu()
                            }
                            {isModuleEnabled(["User"]) && <>{
                                isUserLoggedIn() ?
                                <li>
                                    <nav className="profile-setup">
                                        {isUserLoggedIn() && <div className="profile-box" onClick={toggleUserNav}>
                                            {
                                                userProfileImage ?
                                                <i className="hi-user1"></i> :
                                                <Img className="user-profile-picture" src={JSON.parse(user.image)[0]} alt={user.name} />
                                            }
                                            <span title={user.name}>{user.name}</span>
                                        </div>}
                                        {
                                            showUserNav &&
                                            <ul>
                                                {
                                                    ['manager','representative'].includes(user.role) &&
                                                    <li>
                                                        <Link href="/company/profile">
                                                            <i className="hi-user1"></i>
                                                            <span>Company Profile</span>
                                                        </Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link href="/user/profile">
                                                        <i className="hi-user1"></i>
                                                        <span>Profile</span>
                                                    </Link>
                                                </li>
                                                {
                                                    (localStorage.getItem('tempLogin') === 'true' || isAllowedAdmin()) &&
                                                    <li>
                                                        <Link href="/admin/dashboard" onClick={sendBackToAdmin}>
                                                            <i className="hi-presentation"></i>
                                                            <span>Admin Panel</span>
                                                        </Link>
                                                    </li>
                                                }
                                                {!temporaryUser && <li>
                                                    <i onClick={logout}>
                                                        <i className="hi-logout"></i>
                                                        <span>Logout</span>
                                                    </i>
                                                </li>}
                                            </ul>
                                        }
                                    </nav>
                                </li>:
                                <li><Link href="/login"><i className='hi-user1 mr10'></i>Login / Register</Link></li>
                            }</>}
                        </ul>
                    </div>
                </div>
                {isDesktop() ? <div className='sub-header'>
                    <div className='jumbo-container flex p0'>
                        <ul>
                            { renderPublicMenu() }
                        </ul>
                        {renderCarSelector()}
                    </div>
                </div> :
                renderCarSelector()}
            </div>
            {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <div id="menu-cart" className="menu-cart">
                {
                    isModuleEnabled(['Ecommerce']) && showSideCart && <SideCart toggleCart={toggleCart} />
                }
            </div>}
            <div onClick={navBackGround} className="backgroundFixedNavBar"></div>
            <div onClick={toggleCart} className="backgroundFixed"></div>
            {
                localStorage.getItem('tempLogin') === 'true' &&
                <>
                    {
                        temporaryUser &&
                        <div className="temporary-login">
                            <span>Temporary Login</span>
                        </div>
                    }
                    <span className={temporaryUser ? "top-to-bottom-icon ": "top-to-bottom-icon bottom-to-top"} onClick={() => settemporaryUser(!temporaryUser)}>
                        <i className="hi-arrow_downward"></i>
                    </span>
                </>
            }
            {
                isModuleEnabled(["GuestModal"]) && !isUserLoggedIn() && activeCar === null && !currentUrl.includes('franchise') && !currentUrl.includes('accessories') && !currentUrl.includes('product/') && <GuestCarQueryModal />
            }
        </div>
    </>
}
