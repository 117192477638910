import { memo } from "react"
import { TableContent, TableFooter, TableHeader } from "./components"
import { TableProps } from "./types"

export const Table = memo(({data, pageSize, tableClass = '', total, showMeta, showTotal = true}: TableProps) => {
    return <div className="table-wrapper overflow-auto">
        {showMeta && <div className="table-metadata">
            <span className="widget">Total: <b>{total}</b></span>
         </div>}
        {showTotal && <p>Total: <b>{data?.content?.length ?? 0}</b></p>}
        <table id="list-wrapper" className={tableClass}>
            <TableHeader data={data?.headers} />
            <TableContent data={{content: data?.content, addonBodyContent: data?.addonBodyContent}} pageSize={pageSize} />
            <TableFooter data={data?.footers} />
        </table>
    </div>
})