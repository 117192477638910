import { useContext, useEffect, useState } from "react";
import {utils, read} from 'xlsx'
import { config, createColumns } from "../../../../constant";
import { useService } from "../../../../hooks";
import { AppDataContext } from "../../../../context";
export const useImportProducts = () => {
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        slugify,
        getFormattedDate,
        generateUID,
        showAlert,
        openModal,
        isBottom,
        parseToHtml,
    } = useService('Misc')
    const {
        getProducts,
        addProduct: publishProduct,
        updateProduct,
    } = useService('Product')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [file, setfile] = useState({})
    const [data, setdata] = useState([])
    const [cols, setcols] = useState([])
    const [excelProductList, setexcelProductList] = useState([])
    const [fileImported, setfileImported] = useState<boolean>(false)
    const [fileSelected, setfileSelected] = useState<boolean>(false)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [importProductData, setimportProductData] = useState([])
    const [tableData, settableData] = useState([])
    const [status, setstatus] = useState('publish')
    const [publishDate, setpublishDate] = useState(getFormattedDate(new Date()))
    const [showTable, setshowTable] = useState<boolean>(false)
    const [importDone, setimportDone] = useState<boolean>(false)

    setTitle('Import Products', 'admin')
    useEffect(() => {
        getProductListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            setfile(files[0])
            setfileSelected(true)
        }
    }
    
    const handleFile = () => {
        showPreloader()
        setshowTable(true)
        let excelProductList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = (e) => {
            let bstr = e.target.result,
            wb = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates:true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = utils.sheet_to_json(ws)
            setdata(data)
            setcols(createColumns(ws['!ref']))
            data.forEach(e => excelProductList.push(e))
            setexcelProductList(excelProductList)
            setfileImported(true)
            hidePreloader()
        }
        if (rABS) {
            //@ts-ignore
            reader.readAsBinaryString(file)
        } else {
            //@ts-ignore
            reader.readAsArrayBuffer(file)
        }
    }
    
    const processProducts = () => {
        let mode = 'add', activeItem = null
        excelProductList.forEach(e => {
            tableData.forEach(item => {
                if (e.Item_Code === item.sku) {
                    mode = 'edit'
                    activeItem = item;
                }
            })
            addProduct(e, mode, activeItem)
        })
    }
    
    const getProductListData = async () => {
        const result = await getProducts()
        if (result.status) {
            settableData(result.data.reverse())
        }
    }
    
    const addProduct = async (item, mode, oldProduct = null) => {
        let error = false,
        errorMsg = '',
        tempTitle = item.Title, tempSlug = slugify(tempTitle),
        tempCategory = JSON.parse(item.Category), tempSubCategory = JSON.parse(item.Sub_Category), tc = []
        tempCategory.forEach(e => {
            tc.push({ value: slugify(e), label: e })
        })
        tempSubCategory.forEach(e => {
            tc.push({ value: slugify(e), label: e })
        })
        item.Category = JSON.stringify(tc)
        item.Brand = JSON.stringify({ value: slugify(item.Brand), label: item.Brand })
        let productObject = {
            title: item.Title,
            slug: tempSlug,
            content: parseToHtml(item.Description),
            excerpt: parseToHtml(item.Excerpt),
            status: 'publish',
            category: item.Category,
            brand: item.Brand,
            price: item.MRP,
            publishDate: getFormattedDate(new Date()),
            sku: item.Item_Code,
            hsn: item.HS_Code,
            stock: item.Stock_Availability,
            isFeatured: item.IsFeatured,
            inclusiveTax: true,
            tax: item.IGST_Rate,
            saleprice: item.Sale_Price,
            image: item.Image_Path,
            metaDescription: mode === 'add' ? '' : oldProduct.metaDescription,
            canonical: mode === 'add' ? '' : oldProduct.canonical,
            css: mode === 'add' ? '' : oldProduct.css,
            js: mode === 'add' ? '' : oldProduct.js,
            robots: mode === 'add' ? '' : oldProduct.robots,
            metaKeyword: mode === 'add' ? '' : oldProduct.metaKeyword,
            tag: mode === 'add' ? '' : oldProduct.tag,
            uid: mode === 'add' ? generateUID() : oldProduct.uid,
        }
        if (!error) {
            if (mode === 'add') {
                const result = await publishProduct(productObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Product added successfully!' })
                    setimportProductData(result)
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add product!' })
                }
                if (item.Item_Code === excelProductList[excelProductList.length-1].Item_Code) {
                    setshowTable(false)
                    setimportDone(true)
                }
            } else {
                let data = {
                    query: JSON.stringify({uid: oldProduct.uid}),
                    productData: JSON.stringify(productObject)
                }
                const result = await updateProduct(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Product updated successfully!' })
                    setimportProductData(result)
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update product!' })
                }
                if (item.Item_Code === excelProductList[excelProductList.length-1].Item_Code) {
                    setshowTable(false)
                    setimportDone(true)
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                Item_Code: item.Item_Code,
                HS_Code: item.HS_Code,
                Title: <span onClick={() => openModal('get-packages-modal')} className="package-name">{item.Title}</span>,
                Description: parseToHtml(item.Description),
                IGST_Rate: item.IGST_Rate,
                Stock_Availability: item.Stock_Availability,
                MRP: item.MRP,
                Sale_Price: item.Sale_Price,
                Image_Path: item.Image_Path,
                Excerpt: parseToHtml(item.Excerpt),
                Category: item.Category,
                Sub_Category: item.Sub_Category,
                Brand: item.Brand,
                IsFeatured: String(item.IsFeatured),
                publishDate: publishDate,
                status: status
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'SKU',
                    key: 'sku'
                },
                {
                    label: 'HSN Code',
                    key: 'HSNCode'
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Description',
                    key: 'description'
                },
                {
                    label: 'IGST Rate',
                    key: 'IGSTRate'
                },
                {
                    label: 'Stock Availability',
                    key: 'stockAvailability'
                },
                {
                    label: 'Price',
                    key: 'price'
                },
                {
                    label: 'Sale Price',
                    key: 'salePrice'
                },
                {
                    label: 'Images',
                    key: 'images'
                },
                {
                    label: 'Excerpt',
                    key: 'excerpt'
                },
                {
                    label: 'Category',
                    key: 'category'
                },
                {
                    label: 'Sub Category',
                    key: 'subCategory'
                },
                {
                    label: 'Brand',
                    key: 'brand'
                },
                {
                    label: 'IsFeatured',
                    key: 'isFeatured'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                }
            ],
            content: getTableData(excelProductList)
        })
    }, [excelProductList])
    return {
        trackScrolling,
        fileSelected,
        fileImported,
        processProducts,
        handleFile,
        handleChange,
        showTable,
        tableContent,
        activeShownTotal,
        importDone,
    }
}