import { useContext } from "react"
import { NavigatorContext } from "../../../../context"

export const useTopNavbarBanner = ({
    onClick
}) => {
    const {navigateTo} = useContext(NavigatorContext)

    const ctaClick = () => {
        onClick()
        navigateTo({route: '/services/detailing'})
    }
    return {
        ctaClick
    }
}