import Select from 'react-select'
import {base, orderOption} from '../../../constant'
import { useOrderDetail } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const OrderDetail = () => {
    const {isValidVehicleRegistrationNumber} = useService('Validation')
    const {isDeveloper} = useService('User')
    const {
        getFormattedDate,
        showAlert,
        toIndianCurrency,
        parseAddress,
        isModuleEnabled,
    } = useService('Misc')
    const Button = useElement('Button')
    const Badge = useElement('Badge')
    const BackIcon = useElement('BackIcon')
    const Link = useElement('Link')
    const Rating = useElement('Rating')
    const Input = useElement('Input')
    const DatePicker = useElement('DatePicker')
    const Img = useElement('Img')
    const Box = useComponent('Box')
    const CarSelector = useComponent('CarSelector')
    const {
        setactiveSociety,
        setfullAddress,
        updateCar,
        setregistrationNo,
        registrationNo,
        assignCar,
        datepicker,
        setstartDate,
        updateStartDate,
        startDate,
        setdatepicker,
        invoiceNo,
        generateInvoice,
        user,
        editAddress,
        addLocation,
        setshowSocietyListBox,
        fullAddress,
        showSocietyListBox,
        societyData,
        activeSociety,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setlocality,
        pinCode,
        setpinCode,
        city,
        setcity,
        state,
        setstate,
        landmark,
        setlandmark,
        alternateNo,
        setalternateNo,
        seteditAddress,
        orderData,
        addr,
        assignWorker,
        setassignWorker,
        assignWorkers,
        assignAWorkers,
        assignCustomer,
        setassignCustomer,
        assignCustomers,
        assignACustomer,
        feedbackContent,
        orderContent,
        addNote,
        note,
        setnote,
        cartDataType,
        setstatus,
        setactiveProductTempUid,
        order,
    } = useOrderDetail()
    const renderSocietyList = carList => {
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null) {
                activeClass = activeSociety.title === item.title? 'active': ''
            }
            return <li key={index} className={activeClass} onClick={() => {
                setactiveSociety(item)
                setshowSocietyListBox(false)
                let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                activeaddress.address_components.forEach(e => {
                    if (e.types[0]==="postal_code")
                        pinCode = e.long_name
                    if (e.types[0]==="administrative_area_level_1")
                        state = e.long_name
                    if (e.types[0]==="administrative_area_level_2")
                        city = e.long_name
                    if (e.types[0]==="locality")
                        locality = e.long_name
                })
                setlocality(locality)
                setpinCode(pinCode)
                setcity(city)
                setstate(state)
                setfullAddress(activeaddress.formattedaddress)
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    
    const renderNotes = orderNotes => {
        return orderNotes.map(( item, index ) => {
            let activeLabel = '', orderData = null
            if (item.tempUid) {
                if (orderContent !== null) {
                    orderData = JSON.parse(orderContent.orderData)
                }
                if (orderData !== null) {
                    orderData?.cartData?.forEach(e => {
                        if (e.tempUid === item.tempUid)
                            activeLabel = e.title
                    })
                }
            }
            return <li rel="102" className="note system-note" key={index}>
                <div className="note-content">
                    {
                        activeLabel !=='' && <label>{activeLabel}</label>
                    }
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">
                    <Badge item={item.status} />
                </p>
            </li>
        })
    }
    const renderCartData = cartData => {
        return cartData.map(( item, index ) => {
            let activeStatus = '', noCar = false, activeCar = item.activeCar
            if (orderContent.orderNotes) {
                orderContent.orderNotes.forEach(e => {
                    if (e.tempUid === item.tempUid) {
                        orderOption.forEach(el => {
                            if (el.value === e.status)
                                activeStatus = el.label
                        })
                    }
                })
            }
            if (['servicePackage', 'service'].includes(item.type))
            if (typeof item.activeCar.registrationNo === "undefined")
            noCar = true
            return <tr className="cart-form-cart-item cart_item priceProductPage" key={index}>
                <td className="product-thumbnail">
                {
                    item.type === 'servicePackage'?
                    <i className="hi-dropbox service-package-icon"></i>:
                    item.type === 'subscription'?
                    <i className="hi-block service-package-icon"></i>:
                    <Img src={item.image} alt={item.title} />
                }
                </td>
                <td className="product-name" colSpan={3} data-title="Product">
                    {item.title}
                    {
                        activeCar && <>
                            <CarSelector getSelectedCar={(c) => updateCar(item.tempUid, c)} getNewCars={true} activeCar={activeCar} />
                        </>
                    }
                    {
                        item.type === 'service' &&
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    }
                    {
                        noCar &&
                        <div className="guest-car-box">
                            <div className="form-group">
                                <div className="col-9 controls mt20">
                                    <Input className="input uppercase" placeholder="Registration No" required name="registrationNo" value={registrationNo || ''} onChange={e => setregistrationNo(e.target.value)} />
                                    <small className="help-text ashgray">e.g. RJ14 AB 1234 --OR-- RNX AB 1234</small>
                                </div>
                                <Button className="btn btn-primary btn-small" onClick={() => {
                                    if (!isValidVehicleRegistrationNumber(registrationNo)) {
                                        showAlert({ type: 'error', msg: 'Please enter a valid car Registration Number!' })
                                    } else {
                                        assignCar(item.tempUid)
                                    }
                                }}>Submit</Button>
                            </div>
                        </div>
                    }
                </td>
                {
                    item.type === "servicePackage" ?
                    <>
                        <td>
                            <p className="nowrap">{getFormattedDate(new Date(item.startDate))}</p>
                            {
                                datepicker ?
                                <div>
                                    <DatePicker
                                        placeholderText="New Start Date"
                                        className="mt10"
                                        dateFormat="d, MMMM, yyyy"
                                        onChange={startDate => {
                                            setstartDate(startDate)
                                            updateStartDate(item.tempUid, startDate)
                                        }}
                                        selected={startDate}
                                    />
                                </div>:
                                <Button onClick={() => setdatepicker(true)} className="btn btn-primary">Edit</Button>
                            }
                        </td>
                        <td>
                            <p className="nowrap">{getFormattedDate(new Date(item.endDate))}</p>
                        </td>
                    </>:''
                }
                <td className="product-price" data-title="Price">
                    {
                        typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                        <div className="mrp">
                            <p className="black m0">{toIndianCurrency(item.price)} </p>
                            <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                        </div>:
                        <>
                            <div className="mrp">
                                <p className="black m0">{toIndianCurrency(item.price)} </p>
                                <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                            </div>
                            <div className="sale-price">
                                <p className="black stroke">{toIndianCurrency(item.mrp)} </p>
                            </div>
                            <div className="per-off m0">{((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % Off
                            </div>
                        </>
                    }
                </td>
                <td className="product-quantity" data-title="Quantity">
                    <div className="quantity">
                        {item.purchaseQuantity}
                    </div>
                </td>
                <td className="product-subtotal" data-title="Subtotal">
                    {toIndianCurrency((item.taxAmount+item.price) * item.purchaseQuantity)}
                    {
                        activeStatus !== '' && <p className="status-box"><label>{activeStatus}</label></p>
                    }
                </td>
            </tr>
        })
    }
    
    return <div id="content-box" className="content">
        {
            orderContent && orderContent !== null &&
                <>
                <h1 className="flex">
                    <BackIcon backLink='/admin/order/list' />
                    <span className="ml10">Order #{orderContent.uid} </span>
                    {
                        (orderContent.status === 'paid'
                        || orderContent.status === 'processing'
                        || orderContent.status === 'shipped'
                        || orderContent.status === 'delivered'
                        || orderContent.status === 'completed') && invoiceNo !== '' &&
                        <Link href={base.invoiceBaseUrl+"/invoice_"+invoiceNo+".pdf"} download={"invoice_"+invoiceNo+".pdf"} target="_blank" className="btn btn-primary mlauto" rel="noreferrer">
                            <i className="hi-download-cloud"></i>
                        Download Invoice</Link>
                    }
                    {
                        // ((invoiceNo === '' && (orderContent.status === 'paid'
                        // || orderContent.status === 'processing'
                        // || orderContent.status === 'shipped'
                        // || orderContent.status === 'delivered'
                        // || orderContent.status === 'completed')) || (user.role === 'developer')) &&
                        <Button className="btn btn-primary  mlauto " onClick={generateInvoice}>Generate Invoice</Button>
                    }
                </h1>
                <div className="columns">
                    <div className="left">
                        <Box title='User Details'>
                            <div className="form-group">
                                <label>Name</label>
                                <p>{user.name}</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <p>{user.email}</p>
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <p>{user.mobile}</p>
                            </div>
                            <div className="form-group">
                                <label>Alternate Number</label>
                                <p>{addr?.alternateNo}</p>
                            </div>
                    </Box>
                        <Box title='Delivery Address'>
                            {parseAddress(addr)}
                            {
                                !editAddress && <Button onClick={() => seteditAddress(true)} className="btn btn-small btn-primary mlauto">Edit</Button>
                            }
                            {
                                editAddress &&
                                <form className="col-9" noValidate onSubmit={addLocation}>
                                    <h1>
                                        Edit Address
                                    </h1>
                                    {
                                        isModuleEnabled(["Society"]) && fromSociety ?
                                        <div className="relative">
                                            <div className="form-group">
                                                <label className="col-3 control-label">Select your society</label>
                                                <div className="col-9 controls relative">
                                                    {
                                                        activeSociety === null ?
                                                        <Button className="btn btn-primary" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>Show Societies List</Button>
                                                        :<>
                                                            <div className="selected-society-type" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>
                                                                <p>{activeSociety.title}</p>
                                                            </div>
                                                            <small className="ashgray">({ fullAddress })</small>
                                                        </>
                                                    }
                                                    {
                                                        showSocietyListBox &&
                                                        <ul className="scroll-box mt-10">
                                                            { renderSocietyList(societyData || []) }
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                activeSociety !== null &&
                                                <div className="filled-society-data">
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="Locality (Street/Area)" name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="City/District/Town" disabled name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">State <sup>*</sup></label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="State" disabled name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="form-group">
                                                        <label className="col-3 control-label">Alternate Phone No. </label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className="link mt20" onClick={() => setfromSociety(!fromSociety)}>Not living in above listed society? Click Here</div>
                                        </div>:
                                        <>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                                <div className="col-9 controls">
                                                    <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Locality (Street/Area)" required name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="City/District/Town" required name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">State <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="State" required name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-group">
                                                <label className="col-3 control-label">Landmark </label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Landmark" required name="landmark" value={landmark||''} onChange={e => setlandmark(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Alternate Phone No. </label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="flex">
                                        <Button className="btn btn-outline btn-small addlocationbtn mr20" onClick={() => seteditAddress(false)}>Cancel</Button>
                                        <Button className="btn btn-primary btn-small" type="submit">Submit</Button>
                                    </div>
                                </form>
                            }
                        </Box>
                        <Box title='Items'>
                            <table className="shop-table overflown shop-table_responsive cart cart-form__contents mb0 br0">
                                <thead>
                                    <tr>
                                        <th className="product-thumbnail">Preview</th>
                                        <th className="product-name" colSpan={cartDataType?.includes('servicePackage') ? 2: 3}>Product</th>
                                        {
                                            cartDataType?.includes('servicePackage') &&
                                            <>
                                                <th className="product-subtotal">Start Date</th>
                                                <th className="product-subtotal">End Date</th>
                                            </>
                                        }
                                        <th className="product-price">Price</th>
                                        <th className="product-quantity">Quantity</th>
                                        <th className="product-subtotal">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { renderCartData(orderData?.cartData || []) }
                                    <tr>
                                        <td className="small-col" colSpan={6}>Total</td>
                                        <td className="small-col"><b>{toIndianCurrency(orderData?.cartTotal)}</b></td>
                                    </tr>
                                    {
                                        !isNaN(orderData?.discount) && orderData?.discount && orderData?.discount !== "" && orderData?.discount !== 0 && orderData?.discount !== '0' ?
                                        <>
                                            <tr>
                                                <td className="small-col" colSpan={6}>Discount</td>
                                                <td className="small-col">{toIndianCurrency(orderData?.discount)}</td>
                                            </tr>
                                            <tr>
                                                <td className="small-col" colSpan={6}>Net Payable</td>
                                                <td className="small-col"><b>{toIndianCurrency(orderData?.cartTotal - orderData?.discount)}</b></td>
                                            </tr>
                                        </>:<></>
                                    }
                                </tbody>
                            </table>
                        </Box>
                    </div>
                    <div className="right">
                        <Box title='Active Status'>
                            <Badge item={orderContent.status} />
                        </Box>
                        {
                            (cartDataType?.includes('servicePackage') || cartDataType?.includes('product') || cartDataType?.includes('service')) && orderContent.status !== 'initialised' && orderContent.status !== 'payment-fail' && orderContent.status !== 'completed' && (typeof addr?.activeSociety === "undefined" || addr?.activeSociety === "null" || addr?.activeSociety === null || addr?.activeSociety === "undefined") &&
                            <Box title='Assign Workers'>
                                <Select
                                    value={assignWorker}
                                    defaultValue={assignWorker}
                                    onChange={e => setassignWorker(e)}
                                    options={assignWorkers}
                                    isMulti
                                />
                                <Button className="btn btn-primary mt20" onClick={assignAWorkers}>Assign Workers</Button>
                            </Box>
                        }
                        {
                            isDeveloper() &&
                            <Box title='Assign Customer'>
                                <Select
                                    value={assignCustomer}
                                    defaultValue={assignCustomer}
                                    onChange={e => setassignCustomer(e)}
                                    options={assignCustomers}
                                />
                                <Button className="btn btn-primary mt20" onClick={assignACustomer}>Assign Customer</Button>
                            </Box>
                        }
                        {
                            orderContent.status === 'completed' &&
                            <Box title='Feedback & Review'>
                                {
                                    feedbackContent !== null &&
                                    <div className="rating-order-detail">
                                        {feedbackContent.rating && <Rating rating={feedbackContent.rating} />}
                                        <p>{feedbackContent.comments}</p>
                                    </div>
                                }
                            </Box>
                        }
                        <Box title='Notes'>
                            <ul className="notes">
                                { renderNotes( orderContent.orderNotes||[] ) }
                            </ul>
                        </Box>
                        <Box title='Add Notes'>
                            <form onSubmit={addNote}>
                                <textarea className="mb20" placeholder="Type the note here" required value={note} name="content" onChange={e => setnote(e.target.value)}></textarea>
                                <p className='mb0'>Status</p>
                                <Select
                                    onChange={(e: {value: string, label: string}) => setstatus(e.value)}
                                    options={orderOption}
                                />
                                <p className='mb0'>Products</p>
                                <Select
                                    onChange={e => setactiveProductTempUid(e.value)}
                                    options={order}
                                />
                                <Button type="submit" className="btn btn-primary mt20">Add</Button>
                            </form>
                        </Box>
                    </div>
                </div>
            </>
        }
    </div>
}
