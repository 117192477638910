import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const usePackagesData = ({
    startDate,
    endDate,
    price,
    billing,
    activeCar,
    partialPackage,
}) => {
    const {
        getActiveCar,
        closeModal,
        openCarSelectModal,
        generateUID,
        showAlert,
        proceedToCheckOut,
        getDateDifference,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart} = useService('Order')
    const {getVehicleClassList} = useService('Car')
    const [vehicleClassList, setvehicleClassList] = useState(null)
    const [billedQuarterly, setbilledQuarterly] = useState<boolean>(false)
    const [bestFriendPackagePrice, setbestFriendPackagePrice] = useState<string>('')
    const [friendPackagePrice, setfriendPackagePrice] = useState<string>('')
    const [buddyPackagePrice, setbuddyPackagePrice] = useState<string>('')

    const getVehicleClasses = async () => {
        const result = await getVehicleClassList()
        if (result.status) {
            setvehicleClassList(result.data)
            result.data.forEach(c => {
                if (activeCar !== null) {
                    if (activeCar?.Segment.toLowerCase().includes(c.priceGroup.toLowerCase())) {
                        c.pricing.forEach(e => {
                            if (e.type === 'BASIC') {
                                let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                                setbuddyPackagePrice(price)
                            } else if (e.type === 'ADVANCE') {
                                let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                                setfriendPackagePrice(price)
                            } else if (e.type === 'PREMIUM') {
                                let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                                setbestFriendPackagePrice(price)
                            }
                        })
                    }
                }
            })
        }
    }
    
    const purchase = (purchaseType, packageName) => {
        let activeCar = getActiveCar(),
        oldPrice = price,
        todayDate = new Date(),
        remainingDays = 0,
        perDayOldPrice = 0,
        totalDays = 0,
        urlData = urlToSplitData(getLocationData().pathname),
        found = false
        if (partialPackage !== "") {
            totalDays = billing === 'monthly' ? 30 : 90
            perDayOldPrice = Number(oldPrice)/totalDays
            let spentTime = Math.abs(getDateDifference(todayDate, new Date(startDate))),
            spentDays = Math.ceil(spentTime / (1000 * 60 * 60 * 24))
            remainingDays = totalDays - spentDays
            closeModal('package-upgrade-modal')
            setbilledQuarterly(billing !== 'monthly')
        } else {
            startDate.setDate(new Date().getDate() + 1)
            endDate.setDate(endDate.getDate() + billedQuarterly ? 90 : 30)
        }
        if (activeCar === null)
            openCarSelectModal()
        else {
            vehicleClassList.forEach(c => {
                if (activeCar?.Segment.toLowerCase().includes(c.priceGroup) && !found) {
                    found = true
                    c.pricing.forEach(e => {
                        if (e.type === purchaseType) {
                            let price = !billedQuarterly? e.price : (e.price * 3 * c.quarterlyDiscount).toFixed(2) // 10% off on 3 months payment
                            if (partialPackage !== "") {
                                let perDayNewPrice = Number(price)/totalDays,
                                newTotalPrice = remainingDays * perDayNewPrice - remainingDays * perDayOldPrice
                                price = newTotalPrice
                            }
                            let mrp = price, saleprice = price
                            const taxAmount = Number((price * 18/100).toFixed(2))
                            const orderData = partialPackage !== "" ? urlToSplitData(atob(urlData[3])): undefined
                            const servicePackage = {
                                uid: 'PACKAGE__00' + e.id,
                                title: activeCar.registrationNo ? packageName+ ' Package ['+activeCar.registrationNo+']' : packageName+ ' Package',
                                billing: !billedQuarterly?'monthly': 'quarterly',
                                purchaseQuantity: 1,
                                type: 'servicePackage',
                                tempUid: generateUID(),
                                mrp,
                                saleprice,
                                tax: 18,
                                hsn: '',
                                sku: '',
                                startDate,
                                endDate,
                                activeCar,
                                taxAmount,
                                price: price - taxAmount,
                                displayPrice: price,
                                previousOrderId: partialPackage !== "" ? orderData?.[0]: undefined,
                                previousPackageId: partialPackage !== "" ? orderData?.[1]: undefined,
                                purchaseDate: partialPackage !== "" ? new Date() : undefined,
                            }
                            addToCart(servicePackage)
                            showAlert({ type: 'success', msg: packageName+ ' Package added to cart successfully' })
                            if (partialPackage !== "") {
                                setTimeout(() => {
                                    proceedToCheckOut()
                                }, 2000)
                            }
                        }
                    })
                }
            })
        }
    }

    const toggleBillingPeriod = () => setbilledQuarterly(!billedQuarterly)

    useEffect(() => {
        getVehicleClasses()
    }, [])
    return {
        bestFriendPackagePrice,
        friendPackagePrice,
        buddyPackagePrice,
        purchase,
        toggleBillingPeriod,
        billedQuarterly,
    }
}