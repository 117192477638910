import { memo } from "react"
import { TableFooterProps } from "../../types"

export const TableFooter = memo(({data}: TableFooterProps) => {
    return <tfoot>
        {data && <tr> {
            data?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>}
    </tfoot>
})