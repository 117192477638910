import {base, brandInfo} from '../../../constant'
import { useLogin } from './hooks'
import { useElement, useService, useWidget } from '../../../hooks'

export const Login = () => {
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const SocialButton = useWidget('SocialButton')
    const {
        sendOtp,
        handleSocialLogin,
        handleSocialLoginFailure,
        navigateToHome,
        setMobileNumber,
        mobileNumber,
    } = useLogin()
    const {isModuleEnabled} = useService('Misc')

    return <div className="login-page">
        <div className="left-box-login left-box-login-login"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox tac">
                <Link href="/" className="logo"><Img source='internal' alt={brandInfo.name.capital} className="logo-right-banner" src={brandInfo.logo} /></Link>
                <p className="welcomText">Login to Continue</p>
                <div className="loginIcon">
                    <i className="hi-call"></i>
                </div>
                <h1 className="form-title tac">Please enter your mobile number</h1>
                <form onSubmit={sendOtp} noValidate>
                    <div className="form-group">
                        <Input autoFocus value={mobileNumber} onChange={setMobileNumber} className="input" type="number" name="mobile" placeholder="Enter Mobile Number" maxLength={10} minLength={10} max={9999999999} min={1000000000} />
                    </div>
                    <Button className="btn btn-primary width-full" type="submit">Submit</Button>
                </form>
                {isModuleEnabled(["SocialLogin"]) && <div className="social-media-box mv20">
                    <p>Or</p>
                    <ul>
                        <li className="fb-logo">
                            <SocialButton provider='facebook' appId={base.fbAppId} onLoginSuccess={handleSocialLogin} onLoginFailure={handleSocialLoginFailure}>
                                <Img source='internal' src={require('../../../../assets/images/btn_fb.png')} alt="Login with facebook" />
                            </SocialButton>
                            <SocialButton provider='google' appId={base.googleSignInCleintId} onLoginSuccess={handleSocialLogin} onLoginFailure={handleSocialLoginFailure}>
                                <Img source='internal' src={require('../../../../assets/images/btn_google.png')} alt="Sign with google" />
                            </SocialButton>
                        </li>
                    </ul>
                </div>}
            </div>
            <i className="hi-arrow-circle-left footer-back-btn" onClick={navigateToHome}></i>
        </div>
    </div>
}