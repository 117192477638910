import { PageRouteProps } from "../../types";

export const companyRoutes: PageRouteProps[] = [
    {
        "pathName": "/company/profile",
        "module": ["User"],
        "component": "CompanyProfile",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/company/dashboard",
        "module": ["User"],
        "component": "CompanyDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/company/dashboard/work",
        "module": ["User"],
        "component": "CompanyWorkDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/company/dashboard/work/detail",
        "module": ["User"],
        "component": "CompanyWorkDetailDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "dynamic"
    },
    {
        "pathName": "/company/dashboard/stock/detail",
        "module": ["User"],
        "component": "CompanyStockDetailDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "dynamic"
    },
    {
        "pathName": "/company/dashboard/work/detail",
        "module": ["User"],
        "component": "CompanyWorkDetailDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    },
    {
        "pathName": "/company/dashboard/stock/detail",
        "module": ["User"],
        "component": "CompanyStockDetailDashboard",
        "type": "postLogin",
        "accessRoles": ["manager", "company", "branch", "representative"],
        "mode": "static"
    }
]