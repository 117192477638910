import {brandInfo, mainUrl} from '../../constant'
import { useProject } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../hooks'

export const Project = () => {
    const {getFormattedDate} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Banner = useWidget('Banner')
    const Share = useComponent('Share')
    const PostMeta = useComponent('PostMeta')
    const {
        title,
        releaseDate,
        image,
        slug,
        description,
        category,
    } = useProject()
    return <>
        <Banner title={title} image="white-car-banner.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt120 pb100">
                <div className="blog-details-wrap">
                    <div className="blog-details-items">
                        <div className="content bg-none">
                            <h3 className="title">{title}</h3>
                            <PostMeta categoryList={category} publishDate={releaseDate} />
                        </div>
                        <div className="thumb mt20">
                            <Img src={JSON.parse(image)[0]} alt={title} />
                        </div>
                        <div className="blog-details-inner">
                            <Html content={description} />
                        </div>
                    </div>
                    <Share shareUrl={mainUrl+"/project/"+slug} />
                </div>
            </div>
        </div>
    </>
}
