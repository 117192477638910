import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {robotsOption,statusOption, mainUrl} from '../../../constant'
import { useAddMicroService } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const AddMicroService = () => {
    const {
        toIndianCurrency,
        generateUID,
    } = useService('Misc')
    const DatePicker = useElement('DatePicker')
    const Img = useElement('Img')
    const Editor = useElement('Editor')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        activeCar,
        companies,
        tag,
        settag,
        tagOptions,
        isAutoApproved,
        setstatus,
        status,
        activePrice,
        setactiveCar,
        setselectedCarList,
        setshowCarListBox,
        removePrice,
        mode,
        prices,
        activeRobotsIndex,
        activeStatusIndex,
        robots,
        canonical,
        setcanonical,
        urlData,
        slug,
        taxClassData,
        tax,
        addService,
        title,
        addTitle,
        setslug,
        sku,
        setsku,
        hsn,
        sethsn,
        content,
        setcontent,
        userNote,
        setuserNote,
        excerpt,
        setexcerpt,
        showCarList,
        showCarListBox,
        vehicleClassList,
        setactivePrice,
        saleprice,
        setsaleprice,
        setprices,
        fuelPrices,
        setfuelPrices,
        css,
        setcss,
        js,
        setjs,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        settax,
        serviceTime,
        setserviceTime,
        setpublishDate,
        publishDate,
        setinclusiveTax,
        inclusiveTax,
        setisFeatured,
        isFeatured,
        setisAutoApproved,
        company,
        setcompany,
        category,
        setcategory,
        categoryOptions,
        image,
        seticon,
    } = useAddMicroService()
    
    const renderCarList = carList => {
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeCar !== null)
                activeClass = activeCar.slug === item.slug? 'active': ''
            if (item.slug === item.priceGroup)
                return <li key={index} className={activeClass} onClick={() => {
                    item.price = activePrice
                    setactiveCar(item)
                    setselectedCarList(false)
                    setshowCarListBox(false)
                }}>
                    <Img alt={item.name} source='carCDN' src={item.image} />
                    <p>{item.name}</p>
                </li>
            else return false
        })
    }
    
    const renderPriceList = priceList => {
        return priceList.map(( item, index ) => {
            return <li key={index}>
                <Img alt={item.name} source='carCDN' src={item.image} />
                <p className="car-name">{item.name}</p>
                <p className="service-price">{toIndianCurrency(item.price)}</p>
                <p className="service-price">{toIndianCurrency(item.saleprice)}</p>
                <i className="hi-trash remove" onClick={() => removePrice(item)}></i>
            </li>
        })
    }

    const serviceTitle = mode + ' MicroService'
    let derivedPrices = JSON.parse(prices)
    let activeRobots = robotsOption[activeRobotsIndex],
    activeStatus = statusOption[activeStatusIndex]
    statusOption.forEach(e => {
        if (e.value === status) {
            activeStatus = e
        }
    })
    robotsOption.forEach(e => {
        if (e.value === robots) {
            activeRobots = e
        }
    })
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let selectedTaxClass = null
    if (taxClassData.length>0 && tax === '') {
        selectedTaxClass = taxClassData[0]
    }
    taxClassData.forEach(e => {
        if (e.value === tax) {
            selectedTaxClass = e
        }
    })
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/microservice/list' />
            {serviceTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addService}>
            <div className="left">
                <Box title="Title" required>
                    <Input autoFocus required name="title" value={title} onChange={addTitle} />
                </Box>
                <Box title="Slug" required>
                    <Input required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                </Box>
                <Box title="SKU">
                    <Input name="sku" value={sku} onChange={e => setsku(e.target.value)} />
                </Box>
                <Box title="HSN Code">
                    <Input name="hsn" value={hsn} onChange={e => sethsn(e.target.value)} />
                </Box>
                <Box title="Content" required>
                    <Editor
                        value={content}
                        onEditorChange={(content) => setcontent(content)}
                    />
                </Box>
                <Box title="User Note">
                    <Editor
                        initialValue={userNote}
                        value={userNote}
                        onEditorChange={(userNote) => setuserNote(userNote)}
                    />
                </Box>
                <Box title="Excerpt" required>
                    <Editor
                        value={excerpt}
                        onEditorChange={(excerpt) => setexcerpt(excerpt)}
                    />
                </Box>
                {
                !sku.includes("fuel-refill") ?
                <Box title="Prices" required>
                    <div className="form-inline">
                        {
                            activeCar === null ?
                            <Button className="showCarList" onClick={showCarList}>Show Car List</Button>
                            :
                            <div className="selected-car-type" onClick={showCarList}>
                                <Img alt={activeCar.name} source='carCDN' src={activeCar.image} />
                                <p>{activeCar.name}</p>
                            </div>
                        }
                        {
                            showCarListBox &&
                            <ul className="scroll-box">
                                { renderCarList(vehicleClassList || []) }
                            </ul>
                        }
                        <Input className="input" type="number" required name="activePrice" value={activePrice||''} placeholder="Enter MRP" onChange={e => setactivePrice(e.target.value)} />
                        <Input className="input" type="number" required name="saleprice" value={saleprice||''} placeholder="Enter Sale Price" onChange={e => setsaleprice(e.target.value)} />
                        <span className="btn btn-icon" onClick={() => {
                            let car = activeCar
                            if (activePrice !== '' && activeCar !== null) {
                                car.price = activePrice;
                                car.saleprice = saleprice;
                                car.uid = generateUID()
                                derivedPrices.push(car)
                                setprices(JSON.stringify(derivedPrices))
                                setactivePrice('')
                                setsaleprice('')
                                setactiveCar(null)
                            }
                        }}><i className="hi-add-circle"></i></span>
                    </div>
                    {
                        derivedPrices.length !== 0 &&
                        <ul className="prices-list">
                            { renderPriceList(derivedPrices || []) }
                        </ul>
                    }
                    <Input className="hidden" required name="prices" value={JSON.stringify(derivedPrices)||'[]'} onChange={e => setprices(e.target.value)} />
                </Box>:
                <Box title="Prices (Per lt.)">
                    <div className='form-group'>
                        <label>Petrol</label>
                        <Input name="petrol" value={fuelPrices.petrol || ''} onChange={e => setfuelPrices({...fuelPrices, petrol: e.target.value})} />
                    </div>
                    <div className='form-group'>
                        <label>Diesel</label>
                        <Input name="diesel" value={fuelPrices.diesel || ''} onChange={e => setfuelPrices({...fuelPrices, diesel: e.target.value})} />
                    </div>
                    <div className='form-group'>
                        <label>CNG</label>
                        <Input name="cng" value={fuelPrices.cng || ''} onChange={e => setfuelPrices({...fuelPrices, cng: e.target.value})} />
                    </div>
                    <div className='form-group'>
                        <label>Electric</label>
                        <Input name="electric" value={fuelPrices.electric || ''} onChange={e => setfuelPrices({...fuelPrices, electric: e.target.value})} />
                    </div>
                </Box>
                }
                {status?.value === 'private' && <>
                <Box title="CSS">
                    <textarea className="description" placeholder=".selector {property:value}" required name="css" value={css} onChange={e => setcss(e.target.value)}></textarea>
                </Box>
                <Box title="JS">
                    <textarea className="description" placeholder="alert('Type your script here')" required name="js" value={js} onChange={e => setjs(e.target.value)}></textarea>
                </Box>
                <Box title="Canonical URL">
                    <Input required name="canonical" value={canonical} onChange={e => setcanonical(e.target.value)} />
                </Box>
                <Box title="Robots">
                    <Select
                        value={activeRobots}
                        defaultValue={activeRobots}
                        onChange={e => setrobots(e.value)}
                        options={robotsOption}
                    />
                </Box>
                <Box title="Meta Description">
                    <textarea placeholder="Enter meta description here" required name="meta descrition" value={metaDescription} onChange={e => setmetaDescription(e.target.value)}></textarea>
                </Box>
                <Box title="Meta Keywords">
                    <CreatableSelect
                        isMulti
                        onChange={e => setmetaKeyword(e)}
                        options={keywordOptions}
                        value={metaKeyword}
                    />
                </Box>
                </>}
            </div>
            <div className="right">
                <Box title="Meta">
                    <p>
                        Render Slug:
                        <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={toggleRenderSlug}></span>
                    </p>
                    <Button className="btn btn-primary has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                {
                    taxClassData.length > 0 &&
                    <Box title="Tax % (Tax Class)" required>
                        <Select
                            value={selectedTaxClass}
                            defaultValue={selectedTaxClass}
                            onChange={e => settax(e.value)}
                            options={taxClassData}
                        />
                    </Box>
                }
                <Box title="Service Time">
                    <Input required name="serviceTime" min={0} value={serviceTime||''} onChange={e => setserviceTime(e.target.value)} />
                </Box>
                <Box title="Publish Date">
                    <DatePicker
                        onChange={publishDate => setpublishDate(publishDate)}
                        selected={publishDate}
                    />
                </Box>
                <Box title="Price Meta" required>
                    <span className="pointer isFeatured" onClick={() => setinclusiveTax(!inclusiveTax)}>
                        {
                            inclusiveTax ?
                            <i className="icon-check orange hi-check-circle"></i>:
                            <i className="hi-circle icon-check"></i> 
                        }
                        Inclusive of all taxes </span> 
                </Box>
                <Box title="Featured">
                    <span className="pointer isFeatured" onClick={() => setisFeatured(!isFeatured)}>
                        {
                            isFeatured ?
                            <i className="icon-check orange hi-check-circle"></i>:
                            <i className="hi-circle icon-check"></i> 
                        }
                        Is Featured </span>
                </Box>
                <Box title="Auto Approved">
                    <span className="pointer isAutoApproved" onClick={() => setisAutoApproved(!isAutoApproved)}>
                        {
                            isAutoApproved ?
                            <i className="icon-check orange hi-check-circle"></i>:
                            <i className="hi-circle icon-check"></i> 
                        }
                        Is Auto Approved </span>
                </Box>
                <Box title="Status">
                    <Select
                        value={activeStatus}
                        defaultValue={activeStatus}
                        onChange={e => setstatus(e.value)}
                        options={statusOption}
                    />
                </Box>
                {status === 'private' &&
                <Box title="Company" required>
                    <Select
                        value={company}
                        defaultValue={company}
                        onChange={e => setcompany(e)}
                        options={companies}
                    />
                </Box>}
                <Box title="Category">
                    <Select
                        value={category}
                        onChange={e => setcategory(e)}
                        options={categoryOptions}
                        isMulti
                    />
                </Box>
                <Box title="Tags">
                    <CreatableSelect
                        isMulti
                        value={tag}
                        onChange={e => settag(e)}
                        options={tagOptions}
                    />
                </Box>
                <MediaBox image={image} setimage={icon => seticon(icon)} label='Add Icon' usage={['service']} />
            </div>
        </form>
    </div>
}
