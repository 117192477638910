import { useContext, useEffect, useState } from 'react'
import { base, config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext, NavigatorContext } from '../../../../context'

export const useOrders = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const CheckBox = useElement('CheckBox')
    const {save} = useService('Data', 'log')
    const {isEmpty} = useService('Misc')
    const {
        getLoggedInUserData,
        getUsers,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {getSocities} = useService('Location')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        getFranchiseData,
        generateUID,
        toIndianCurrency,
        parseAddress,
        getFormattedDate,
        isBottom,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getInvoices,
        getOrder,
        getOrders,
        deleteOrder: removeOrder,
        updateOrder,
    } = useService('Order')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const user = getLoggedInUserData()
    const [exportedData, setexportedData] = useState([])
    const [tableContent, settableContent] = useState({})
    const [society, setsociety] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [workers, setworkers] = useState([])
    const [worker, setworker] = useState(null)
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [startDate, setstartDate] = useState<string>('')
    const [endDate, setendDate] = useState<string>('')
    const [checkedList, setcheckedList] = useState([])
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [searchKey, setsearchKey] = useState<string>('')
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [filteredList, setfilteredList] = useState([])
    const [activeFilter, setactiveFilter] = useState(null)

    setTitle('Orders List', 'admin')
    useEffect(() => {
        getWorkersData()
        getSocietiesListData()
    }, [])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList?.length > commonPageSize && activeShownTotal < filteredList?.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const urlData = urlToSplitData(getLocationData().pathname)
    const getSocietiesListData = async () => {
        const result = await getSocities()
        if (result.status) {
            let tableData = result.data.reverse()
            setsociety(tableData)
            if (urlData[4])
                getOrdersData(urlData[4])
            else
                getOrdersData()
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No society added yet!' })
        } else showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    
    const getWorkersData = async () => {
        let workers = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker')
                        workers.push({label: result.name, value: result.uid})
                })
                setworkers(workers)
            }
        }
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const getOrdersData = async (hash?: string) => {
        let activefilterValue = 'all'
        if (!isEmpty(hash))
            activefilterValue = hash
        setactiveFilterIndex(filterTags.findIndex(e => e.value === activefilterValue))
        activefilterValue = filterTags.find(e => Number(e.id) === activeFilterIndex+1).value
        const trashAction = urlData[2] === 'trash'
        settrashAction(trashAction)
        let invoiceList = await getInvoices(), newtableData = [], result = null
        if(['franchiseOwner', 'franchiseManager'].includes(user?.role)) {
            let query = {
                source: 'franchise',
                franchiseId: getFranchiseData().uid
            }
            result = await getOrder(query)
        } else {
            result = await getOrders()
        }
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                invoiceList.data.forEach(el => {
                    if (e.uid === el.orderId) {
                        e.invoice = el.invoiceNo
                    }
                })
                if (!trashAction) {
                    if (typeof e.trash === "undefined") {
                        tableData.push(e)
                    } else if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            tableData.forEach(e => {
                e.workerdIds = ''
                e.workerNames = ''
                if (e.status !== 'initialised' && e.status !== 'payment-fail') {
                    if (typeof e.assignWorker === "undefined") { // Not Direclty assigned
                        let activeSociety = null
                        try {
                            activeSociety = JSON.parse(e.activeAddress).activeSociety
                        } catch (e) {
                            console.log('error', e)
                            activeSociety = ''
                        }
                        if (['null', null, 'undefined',''].includes(activeSociety) || typeof activeSociety === 'undefined') {
                            e.workerNames = 'Not Assigned'
                        } else {
                            society.forEach(s => {
                                activeSociety = isValidJson(activeSociety) ? JSON.parse(activeSociety): activeSociety;
                                if (s.uid === activeSociety.uid)
                                    e.assignWorker = s.assignWorker // old worker === activeSociety.assignWorker
                            })
                        }
                    }
                    if (e.assignWorker) {
                        let workers = JSON.parse(e.assignWorker)
                        if (Array.isArray(workers)) {
                            workers.forEach((w, l) => {
                                e.workerNames += w.label
                                e.workerdIds += w.value
                                if (l !== workers.length - 1) {
                                    e.workerdIds += ', '
                                    e.workerNames += ', '
                                }
                            })
                        }
                    } else {
                        e.workerNames = 'Not Assigned'
                    }
                }
                newtableData.push(e)
            })
            settableData(newtableData)
            filterItems( activefilterValue, activeFilterIndex, newtableData )
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No order placed yet!' })
        } else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectOrder = () => {
        deleteOrder(activeItem.uid)
    }
    
    const deleteOrder = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await removeOrder({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Order deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getOrdersData()
            } else showAlert({ type: 'error', msg: 'Unable to delete order!' })
        } else {
            let orderContent = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    orderContent = e
            })
            orderContent.trash = task === 'trash'
            delete orderContent._id
            let data = {
                query: JSON.stringify({uid: orderContent.uid}),
                orderData: JSON.stringify(orderContent)
            }
            const result = await updateOrder(data)
            if (result.status) {
                let logData = {
                    log: user.name+' updated by the order status to '+task+' with website for order Id #'+orderContent.uid,
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: user.uid,
                    uid: generateUID()
                }
                save(logData)
                showAlert({ type: 'success', msg: 'Order '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getOrdersData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' Order!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const getTableData = filterData => {
        let data = [], allOrder = []
        filterData?.forEach(item => {
            let orderType = []
            allOrder = JSON.parse(item.orderData).cartData
            allOrder.forEach(e => {
                if (orderType.includes(e.type))
                    orderType.push(e.type)
            })
            let orderTime = item.timing,
            startTime = startDate === ''?'':new Date(startDate),
            endTime = endDate === ''?new Date():new Date(endDate);
            if (orderTime >= startTime && orderTime <= endTime &&
                (
                    (['settled', 'paidAtFranchise', 'paid', 'processing', 'shipped', 'delivered', 'completed'].includes(item.status) || ['cancelled', 'deactivate', 'refund', 'initialised','payment-fail'].includes(item.status)) && (worker === null || (worker !== null && item.workerNames.includes(worker.label)))
                )
            ) {
                let e = {
                    checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <i className="orange hi-check-circle"></i>
                            :<i className="hi-circle"></i>
                        }
                    </span>,
                    orderId: <>
                        #{item.uid}
                        {item.source === 'franchise' && !['franchiseOwner', 'franchiseManager'].includes(user?.role) && <p className='order-label'>Franchise Order</p>}
                        <span className="order-type">
                        {
                            orderType.includes('service') &&
                            <span className="hammer-outline">
                                <i className="hi-hammer"></i> 
                                <span className="hover-action">Service</span>
                            </span>
                        }
                        {
                            orderType.includes('servicePackage') &&
                            <span className="cube-outline">
                                <i className="hi-dropbox"></i>
                                <span className="hover-action">Service Package</span>
                            </span>
                        }
                        {
                            orderType.includes('subscription') &&
                            <span className="ribbon-outline">
                                <i className="hi-block"></i>
                                <span className="hover-action">Subscription</span>
                            </span>
                        }
                        {
                            orderType.includes('product') &&
                            <span className="list-circle-outline">
                                <i className="hi-list1"></i>
                                <span className="hover-action">Product</span>
                            </span>
                        }</span>
                    </>,
                    name: JSON.parse(item.userData).name,
                    timing: getFormattedDate(item.timing),
                    status: <Badge item={item.status} />,
                    total: JSON.parse(item.orderData).discount ?
                        <>{toIndianCurrency(JSON.parse(item.orderData).cartTotal - JSON.parse(item.orderData).discount)}</>:
                        <>{toIndianCurrency(JSON.parse(item.orderData).cartTotal)}</>,
                    workerNames:item.workerNames === 'Not Assigned'?<p className="error">{item.workerNames}</p>:item.workerNames,
                    action: trashAction ?
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => {
                                setactiveItem(item)
                                setshowTrashConfirm(true)
                            }} />
                            <Actor type='undo' onClick={() => deleteOrder(item.uid, 'restore')} />
                        </div>:
                        <div className="action-bar">
                            <Actor type='trash' onClick={() => {
                                setactiveItem(item)
                                setshowTrashConfirm(true)
                            }} />
                            <Actor type='view' onClick={() => navigateTo({route: '/admin/order/view/' + item.uid, newTab: true})} />
                            {item.invoice ? <Actor type='download' onClick={() => navigateTo({route: base.invoiceBaseUrl+"/invoice_"+item.invoice+".pdf", newTab: true})} /> : ''}
                        </div>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let filteredList = [], tempTitle = ''
                tableData.forEach(e => {
                tempTitle = (JSON.parse(e.userData).name).toLowerCase()
                if (e.uid.search(searchKey.toLowerCase()) !== -1 || tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    let orderTime = e.timing,
                    startTime = startDate === ''?'':new Date(startDate),
                    endTime = endDate === ''?new Date():new Date(endDate)
                    if (orderTime >= startTime && orderTime <= endTime)
                    filteredList?.push(e)
                }
            })
            setfilteredList(filteredList)
            setactiveFilterIndex(0)
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteOrder(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList?.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList?.length && filteredList?.length !== 0) } />
                    </span>
                },
                {
                    label: 'Order',
                    key: 'order'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Assigned Worker',
                    key: 'assignedWorker'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
        let exportedData = [], cartTotalTax = 0
        filteredList?.forEach((e, j) => {
            let orderData = JSON.parse(e.orderData)
            if (!orderData.discount) {
                orderData.discount = 0
            }
            if (typeof orderData.cartTotal !== "number")
                orderData.cartTotal = Number(orderData.cartTotal)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
            cartTotalTax = 0
            orderData.cartData.forEach(( c, i ) => {
                c.tax = Number(c.tax);
                c.price = Number(c.price).toFixed(2)
                c.taxAmount = (c.tax * c.price/(100+c.tax)).toFixed(2)
                cartTotalTax += c.taxAmount
                let ex = {
                    Customer_Id: (i === 0) ? JSON.parse(e.userData).uid: '',
                    Order_Id: (i === 0) ? e.uid: '',
                    Customer_Name: (i === 0) ? JSON.parse(e.userData).name: '',
                    Mobile_No: (i === 0) ? JSON.parse(e.userData).mobile: '',
                    Address: ( e.activeAddress && e.activeAddress !== "undefined") ?((i === 0) ? parseAddress(JSON.parse(e.activeAddress)): ''): '',
                    Pin_Code: ( e.activeAddress && e.activeAddress !== "undefined")? ((i === 0) ? JSON.parse(e.activeAddress).pinCode: ''): '',
                    Status: (i === 0) ? e.status: '',
                    Vehicle_Details: c.activeCar ? c.activeCar.name + ' ' + c.activeCar?.Segment: '',
                    Vehicle_No: c.activeCar ? c.activeCar.registrationNo ?c.activeCar.registrationNo: '': '',
                    Package: c.type === 'servicePackage'?c.title: '',
                    Package_Frequency: c.billing ? c.billing: '',
                    Service: c.type === 'service'?c.title: '',
                    Product: c.type === 'product'?c.title: '',
                    Subscription: c.type === 'subscription'?c.title: '',
                    Total: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal).toFixed(2): ''): '',
                    Discount: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?Number((orderData.discount).toFixed(2)): ''): '',
                    Net_Payment: (i === 0) ? (['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?(orderData.cartTotal - Number((orderData.discount).toFixed(2))).toFixed(2): ''): '',
                    GST: ( e.status === "initialised") ? '': (i === 0) ? cartTotalTax: '',
                    Payment_Date: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?getFormattedDate(e.timing): ''): '',
                    TransactionId: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?e.paymentData.TXNID: ''): '',
                    Payment_Mode: (i === 0) ?( e.status === "initialised") ? '': (e.paymentData && ['paid', 'processing', 'shipped', 'delivered', 'completed'].includes(e.status)?'Payment Gateway': 'Manual'): '',
                    Invoice: (i === 0) ? "invoice_"+e.invoice+".pdf": '',
                }
                exportedData.push(ex)
            })
        })
        setexportedData(exportedData)
    }, [filteredList, checkedList, worker, startDate, endDate])
    
    return {
        trackScrolling,
        trashAction,
        filteredList,
        exportedData,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        setstartDate,
        startDate,
        endDate,
        setendDate,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        worker,
        setworker,
        workers,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectOrder,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}