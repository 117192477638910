import { PageRouteProps } from "../../types";

export const managerRoutes: PageRouteProps[] = [
    {
        "pathName": "/manager/list",
        "module": ["User"],
        "component": "CompanyManagers",
        "type": "postLogin",
        "mode": "static",
        "accessRoles": ["company", "branch"]
    },
    {
        "pathName": "/manager/add",
        "module": ["User"],
        "component": "AddManager",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/manager/edit",
        "module": ["User"],
        "component": "AddManager",
        "type": "postLogin",
        "mode": "dynamic"
    }
]