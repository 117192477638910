import { memo } from "react"
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps"
import { base } from "../../../constant"

interface Place {
    lat: number
    lng: number
}
interface MapWithMarkerProps {
    places: Place[]
    zoom: number
    center: Place
}
interface MarkerProps {
    places: Place[]
}

export const MapWithMarker = memo(({places = [], zoom = 14, center}: MapWithMarkerProps) => {
    const Markers = ({ places }: MarkerProps) => {
        return places.map((place, index ) => {
            return <Marker key={index} position={{ lat: place.lat, lng: place.lng }} />
        })
    }

    return <APIProvider apiKey={base.googleApiKey}>
        <Map zoom={zoom} center={center}>
            <Markers places={places} />
        </Map>
    </APIProvider>
})