import { useContext } from 'react';
import { useElement } from '../../../hooks';
import { useRecentServices } from './hooks';
import { NavigatorContext } from '../../../context';
export const RecentServices = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Html = useElement('Html')
    const Button = useElement('Button')
    const {navigateTo} = useContext(NavigatorContext)
    const {serviceList } = useRecentServices()
    const renderServiceList = serviceFiles => {
        return serviceFiles.map(( item, index ) => {
            return <div className='service-item' key={index}>
                <Link href={"/service/"+item.slug}>
                    {item.image && <Img src={JSON.parse(item.image)[0]} alt={item.title} />}
                </Link>
                <div className='p20'>
                    <h4>{item.title}</h4>
                    <div className='clipped'>
                        <Html content={item.content} />
                    </div>
                    <Button onClick={() => navigateTo({route: '/service/'+item.slug})} className="btn btn-primary btn-block btn-small">Book Now<i className='hi-cart'></i></Button>
                </div>
            </div>
        })
    }

    return <div className="mb60-mbs40">
        <div className="jumbo-container">
            <h2 className="heading-02 ">Freshly Brewed Services</h2>
            <div className="featured-group-sidebar">
            { renderServiceList(serviceList) }
            </div>
        </div>
    </div>
}