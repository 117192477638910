import Select from 'react-select'
import PlacesAutocomplete from 'react-places-autocomplete'
import {statusOption, base} from '../../../constant'
import { useAddFranchise } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddFranchise = () => {
    const Editor = useElement('Editor')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const MapWithMarker = useComponent('MapWithMarker')
    const {
        mode,
        status,
        image,
        setimage,
        setstatus,
        socialLinks,
        setsocialLinks,
        renderSlug,
        toggleRenderSlug,
        city,
        state,
        updateAddressDetail,
        pinCode,
        addFranchise,
        title,
        addTitle,
        slug,
        setslug,
        companyName,
        setcompanyName,
        mobile,
        setmobile,
        email,
        setemail,
        content,
        setcontent,
        address,
        addressDetail,
        setaddressDetail,
        owner,
        setowner,
        places,
        handleChange,
        handleSelect,
        showSuggestions,
        saveSuggestion,
        locality,
        franchiseOwners,
        contactPerson,
        setcontactPerson,
        franchiseManagers,
    } = useAddFranchise()
    const pageTitle = mode + ' Franchise'
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/franchise/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addFranchise}>
            <div className="left">
                <Box title='Title' required>
                    <Input autoFocus required name="title" value={title} onChange={addTitle} />
                </Box>
                <Box title='Slug' required>
                    <Input required name="slug" value={slug} onChange={e => setslug(e.target.value)} />
                </Box>
                <Box title='Company Name' required>
                    <Input required name="companyName" value={companyName} onChange={e => setcompanyName(e.target.value)} />
                </Box>
                <Box title='Mobile' required>
                    <Input required name="mobile" value={mobile} onChange={e => setmobile(e.target.value)} />
                </Box>
                <Box title='Email' required>
                    <Input type="email" required name="email" value={email} onChange={e => setemail(e.target.value)} />
                </Box>
                <Box title='Content' required>
                    <Editor
                        value={content}
                        onEditorChange={(content) => setcontent(content)}
                    />
                </Box>
                <Box title='Address' required>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                                <Input
                                    {...getInputProps({
                                        placeholder: 'Search Places . . .',
                                        className: 'location-search-input input',
                                    })}
                                />
                                {
                                    showSuggestions &&
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion, index ) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
                                            return <div
                                                {...getSuggestionItemProps(suggestion, { className })}
                                                key={index} onClick={() => saveSuggestion(suggestion)}>{suggestion.description}
                                            </div>
                                        })}
                                    </div>
                                }
                            </>
                        )}
                    </PlacesAutocomplete>
                </Box>
                {
                    (addressDetail.address_components?.length || places.length !== 0) && <Box title='Address Details' required overflown={false}>
                        <MapWithMarker
                            center={places[0]}
                            places={places}
                        />
                        <Box title='Building / Flat' required>
                            <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={addressDetail.building||''} onChange={e => setaddressDetail({...addressDetail, building: e.target.value})} />
                        </Box>
                        <Box title='Locality (Street/Area)' required>
                            <Input className="input" placeholder="Locality (Street/Area)" name="locality" value={locality||''} onChange={e => updateAddressDetail('locality', e.target.value)} />
                        </Box>
                        <Box title='Pincode' required>
                            <Input className="input" placeholder="Pincode" type="number" name="pinCode" value={pinCode||''} onChange={e => updateAddressDetail('pinCode', e.target.value)} />
                        </Box>
                        <Box title='City/District/Town' required>
                            <Input className="input" placeholder="City/District/Town" name="city" value={city||''} onChange={e => updateAddressDetail('city', e.target.value)} />
                        </Box>
                        <Box title='State' required>
                            <Input className="input" placeholder="State" name="state" value={state||''} onChange={e => updateAddressDetail('state', e.target.value)} />
                        </Box>
                        <hr />
                        <Box title='Alternate Phone No.'>
                            <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={addressDetail.alternateNo||''} onChange={e => setaddressDetail({...addressDetail, alternateNo: e.target.value})} />
                        </Box>
                        <Box title='Landmark'>
                            <Input className="input" placeholder="Landmark" required name="landmark" value={addressDetail.landmark||''} onChange={e => setaddressDetail({...addressDetail, landmark: e.target.value})} />
                        </Box>
                    </Box>
                }
                <Box title='Social Links'>
                    <Box title='Facebook'>
                        <Input name="facebook" value={socialLinks.facebook} onChange={e => setsocialLinks({...socialLinks, facebook: e.target.value})} />
                    </Box>
                    <Box title='X'>
                        <Input name="twitter" value={socialLinks.twitter} onChange={e => setsocialLinks({...socialLinks, twitter: e.target.value})} />
                    </Box>
                    <Box title='Instragram'>
                        <Input name="instragram" value={socialLinks.instragram} onChange={e => setsocialLinks({...socialLinks, instragram: e.target.value})} />
                    </Box>
                    <Box title='Google Map'>
                        <Input name="googleMap" value={socialLinks.googleMap} onChange={e => setsocialLinks({...socialLinks, googleMap: e.target.value})} />
                    </Box>
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <p>
                        Render Slug:
                        <span className={renderSlug?"switch on":"switch off"} title="Click to Enable/Disable auto slug rendering" onClick={toggleRenderSlug}></span>
                    </p>
                    <Button className="btn btn-primary has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                <Box title='Owner' required>
                    <Select
                        value={owner}
                        defaultValue={owner}
                        onChange={e => setowner(e)}
                        options={franchiseOwners}
                    />
                </Box>
                <Box title='Contact Person' required>
                    <Select
                        value={contactPerson}
                        defaultValue={contactPerson}
                        onChange={e => setcontactPerson(e)}
                        options={franchiseManagers}
                    />
                </Box>
                <Box title='Status'>
                    <Select
                        value={status}
                        defaultValue={status}
                        onChange={e => setstatus(e)}
                        options={statusOption}
                    />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['franchise']} />
            </div>
        </form>
    </div>
}
