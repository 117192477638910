import { useStoreLocatorSideBar } from './hooks'
import { useElement, useService } from '../../../hooks'

export const StoreLocatorSideBar = () => {
    const Input = useElement('Input')
    const Link = useElement('Link')
    const {
        isModuleEnabled,
        preventFormSubmit,
    } = useService('Misc')
    const {
        fetchFranchiseData,
        filteredFranchiseList,
        searchFranchiseKey,
        searchFranchise
    } = useStoreLocatorSideBar()
    const renderFilteredList = (filteredList, urlKey) => {
        return filteredList.map(( item, index ) => {
            return <li key={index} className="storeLocator-product-search-box">
                <Link href={"/"+urlKey+"/" + item.slug} title={item.title} className="flex">
                    <p className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                    </p>
                </Link>
            </li>
        })
    }

    return <div className="widget-area">
        {isModuleEnabled(["Franchise"]) && <div className="widget storeLocator-bg">
            <form className="search-form" noValidate onSubmit={preventFormSubmit}>
                <h5 className="widget-title border-bottom">Search For Franchises</h5>
                <div className="form-group">
                    <Input onFocus={fetchFranchiseData} required name="searchFranchiseKey" value={searchFranchiseKey || ''} placeholder="Search..." onChange={searchFranchise} className="form-control" />
                </div>
                <div className="search-result-storeLocator">
                    <ul>
                        {
                            searchFranchiseKey !== ''?
                            renderFilteredList(filteredFranchiseList || [], 'franchise') : null
                        }
                    </ul>
                </div>
            </form>
        </div>}
    </div>
}
