import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext, NavigatorContext } from '../../../../context'

export const useServiceList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const {setTitle} = useService('Meta')
    const {
        addService,
        getServices,
        deleteService: removeService,
        updateService,
    } = useService('Service')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        getFormattedDate,
        isBottom,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [tableContent, settableContent] = useState({})
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [filteredList, setfilteredList] = useState([])
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [checkedList, setcheckedList] = useState([])
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [searchKey, setsearchKey] = useState<string>('')
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    setTitle('Services List', 'admin')
    useEffect(() => {
        getServiceListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const cloneService = async item => {
        let serviceObject = {
            ...item,
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await addService(serviceObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Service clone successfully!' })
            getServiceListData()
        } else showAlert({ type: 'error', msg: 'Unable to clone service!' })
    }
    
    const getServiceListData = async () => {
        const urlData = urlToSplitData(getLocationData().pathname),
        trashAction = urlData[2] === 'trash'
        settrashAction(trashAction)
        const result = await getServices()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash) tableData.push(e)
                } else {
                    if (e.trash) tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1)
                    activefilterValue = e.value
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No service added yet!' })
        } else showAlert({ type: 'error', msg: 'No service added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectService = () => {
        deleteService(activeItem.uid)
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const deleteService = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await removeService({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Service deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
            } else showAlert({ type: 'error', msg: 'Unable to delete service!' })
        } else {
            let serviceObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    serviceObject = e
            })
            serviceObject.trash = task === 'trash'
            delete serviceObject._id
            let data = {
                query: JSON.stringify({uid}),
                serviceData: JSON.stringify(serviceObject)
            }
            const result = await updateService(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Service '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' service!' })
        }
        getServiceListData()
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                {
                    checkedAll || checkedList.includes(item.uid) ?
                    <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                }
                </span>,
                title: item.title,
                image: item.image?
                    <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                publishDate: getFormattedDate(new Date(item.publishDate)),
                status: item.status,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='undo' onClick={() => deleteService(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    {item.status === "publish" && <Actor type='view' onClick={() => navigateTo({route: '/service/'+item.slug, newTab: true})} />}
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/service/edit/'+item.uid, newTab: true})} />
                    <Actor type='copy' onClick={() => cloneService(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteService(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    checkAction: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'Image',
                    key: 'image'
                },
                {
                    label: 'Publish Date',
                    key: 'publishDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        trashAction,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectService,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}