import { PageRouteProps } from "../../types";

export const jobRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/job/list",
        "module": ["Job"],
        "component": "JobsList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/job/add",
        "module": ["Job"],
        "component": "AddJob",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/job/edit",
        "module": ["Job"],
        "component": "AddJob",
        "type": "admin",
        "mode": "dynamic"
    }
]