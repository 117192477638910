import { PageRouteProps } from "../../types";

export const serviceRoutes: PageRouteProps[] = [
    {
        "pathName": "/services",
        "module": ["Ecommerce"],
        "component": "Services",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/services",
        "module": ["Ecommerce"],
        "component": "Services",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/service",
        "module": ["Ecommerce"],
        "component": "Service",
        "type": "public",
        "mode": "dynamic"
    }
]