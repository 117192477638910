import { useContext, useEffect, useState } from "react"
import { statusOption, base } from "../../../constant"
import { useService } from "../../../hooks"
import { AppDataContext } from "../../../context"

export const useProduct = () => {
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        isEmpty,
        showAlert,
        toIndianCurrency,
        openModal,
        generateUID,
        proceedToCheckOut,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart: pushToCart} = useService('Order')
    const {
        getProduct,
        updateProduct,
    } = useService('Product')
    const user = getLoggedInUserData()
    const [averageRating, setaverageRating] = useState<number>(0)
    const [imagesList, setimagesList] = useState([])
    const [tableContent, settableContent] = useState<any>({})
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [background, setbackground] = useState<string>('')
    const [feedback, setfeedback] = useState([])
    const [stock, setstock] = useState<number | undefined>()
    const [price, setprice] = useState<number>(0)
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [purchaseQuantity, setpurchaseQuantity] = useState<number>(1)
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [excerpt, setexcerpt] = useState<string>('')
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [isFeatured, setisFeatured] = useState<boolean>(false)
    const [isAssured, setisAssured] = useState<boolean>(false)
    const [inclusiveTax, setinclusiveTax] = useState<boolean>(false)
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState(null)
    const [brand, setbrand] = useState<any>()
    const [tag, settag] = useState(null)
    const [saleprice, setsaleprice] = useState<number>()
    const [sku, setsku] = useState<string>('')
    const [tax, settax] = useState<string>('')
    const [hsn, sethsn] = useState<string>('')
    const [activeImage, setactiveImage] = useState<string>('')
    const [showDropdwon, setshowDropdwon] = useState<boolean>(false)
    const [activeCarType, setactiveCarType] = useState(null)
    const [buyType, setbuyType] = useState<string>('')
    const [rating, setrating] = useState<number>(0)
    const [comments, setcomments] = useState<string>('')

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getProductData({slug: urlData[2]})
    }, [urlData, slug])

    const getProductData = async (query) => {
        const result = await getProduct(query)
        if (result.status && result.data?.length > 0) {
            const productData = result.data[0]
            setactiveStatusIndex(statusOption.findIndex(e => e.value === productData.status))
            setmetaKeyword(isEmpty(productData.metaKeyword) ? [] : JSON.parse(productData.metaKeyword))
            settag(isEmpty(productData.tag) ? [] : JSON.parse(productData.tag))
            setcategory(isEmpty(productData.category) ? [] : JSON.parse(productData.category))
            setTitle(productData.title)
            settitle(productData.title)
            setbrand(JSON.parse(productData.brand))
            setslug(productData.slug)
            setcontent(productData.content)
            setexcerpt(productData.excerpt)
            setcss(productData.css)
            setjs(productData.js)
            setcanonical(productData.canonical)
            setrobots(productData.robots)
            setmetaDescription(productData.metaDescription)
            setpublishDate(new Date(productData.publishDate))
            setstatus(productData.status)
            setisFeatured(productData.isFeatured)
            setisAssured(productData.isAssured)
            setinclusiveTax(productData.inclusiveTax)
            setsku(productData.sku)
            sethsn(productData.hsn)
            setstock(productData.stock)
            settax(productData.tax)
            setprice(productData.price)
            setsaleprice(productData.saleprice)
            setbackground(productData.background)
            setuid(productData.uid)
            setimage(productData.image)
            setactiveImage(JSON.parse(productData.image)[0])
            setfeedback(productData.feedback ? productData.feedback : [])
        } else showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    
    const productAddedTemplate = () => {
        let derivedPrice = price
        if ( !saleprice )
            derivedPrice = saleprice
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(image)[0]+'" alt="'+title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+title+'</p>'+
        '<p class="product-price">'+toIndianCurrency(derivedPrice)+'</p>'+
        '</div>'+
        '</div>'
        showAlert({
            type: 'custom',
            template: prodcutAddedTemplate
        })
        setTimeout(() => {
            setactiveCarType(null)
        }, 2000)
    }
    
    const toggleSubscribeDropDown = () => {
        setshowDropdwon(!showDropdwon)
    }
    
    const subscribe = time => {
        toggleSubscribeDropDown()
    }
    
    const buyItem = buyType => {
        openModal("add-to-cart-modal")
        setbuyType(buyType)
    }
    
    const addToCart = async (method='simple') => {
        showPreloader()
        let mrp:number = price, derivedPrice: number = price
        if (!saleprice)
            derivedPrice = saleprice
        const taxAmount = Number((derivedPrice * Number(tax)/(100+Number(tax))).toFixed(2))
        let product = {
            uid,
            title,
            image: JSON.parse(image)[0],
            purchaseQuantity: purchaseQuantity,
            type: 'product',
            tempUid: generateUID(),
            mrp: Number(mrp),
            saleprice,
            tax,
            hsn,
            sku,
            taxAmount,
            price: derivedPrice - taxAmount,
            displayPrice: derivedPrice,
        };
        await pushToCart(product)
        productAddedTemplate()
        hidePreloader()
        if (method === 'quick')
            proceedToCheckOut()
    }
    
    const feedBackSubmit = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        feedbackObject = {
            rating,
            submitDate: new Date(),
            comments,
            userId: user.uid,
            name: user.name,
            image: user.image,
            uid: generateUID()
        }
        if (rating === 0) {
            errorMsg = 'Please give a rating'
            error = true
        }
        if (!error) {
            feedback.push(feedbackObject)
            let productObject = {
                feedback: feedback
            }
            let data = {
                query: JSON.stringify({uid}),
                productData: JSON.stringify(productObject)
            }
            const result = await updateProduct(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Your review has been submited successfully.' })
                getLocationData().reload()
            } else showAlert({ type: 'error', msg: 'Unable to submit your review' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const renderPrice = (saleprice, price) => {
        return saleprice ?
        <div className="mrp">
            <span className="black">{toIndianCurrency(price)}</span>
        </div>:
        <>
        <div className="mrp">
            <span className="black">{toIndianCurrency(saleprice)}</span>
        </div>
        <div className="sale-price">
            <span className="black">{toIndianCurrency(price)}</span>
        </div>
            <div className="per-off-product">
            <span className="green">{((price - saleprice)/price * 100).toFixed(0)} % off</span>
            </div>
        </>
    }

    useEffect(() => {
        setimagesList(JSON.parse(image))
        let today = new Date(),
        tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        let averageRating = 0
        if (feedback.length>0) {
            feedback.forEach(e => {
                averageRating += e.rating
            })
            setaverageRating(averageRating/feedback.length)
        }
        settableContent({
            addonBodyContent: <tr className="backgroud-change">
                <td className="nowrap"><b>Price:</b></td>
                <td className="white priceProductPage">
                    {renderPrice(saleprice, price)}
                    <small> (Inclusive of all taxes)</small>
                </td>
            </tr>
        })
    }, [price])
    return {
        activeImage,
        rating,
        setrating,
        stock,
        excerpt,
        content,
        feedback,
        feedBackSubmit,
        title,
        addToCart,
        brand,
        comments,
        setcomments,
        setactiveImage,
        imagesList,
        isAssured,
        user,
        averageRating,
        tableContent,
    }
}