import { useContext, useEffect, useState } from "react"
import { config, activeDateFilter as ADF, searchRegex} from '../../../../constant'
import { useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"
export const useCompanyWorkDetailDashboard = () => {
    const {commonPageSize} = config
    const {fetchByAttr} = useService('Data', 'b2bDailyWorkData')
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        getPreviousDate,
        isBottom,
    } = useService('Misc')
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [exportedData, setexportedData] = useState({})
    const [searchPostKey, setsearchPostKey] = useState<string>('')
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [b2bDailyWorkData, setb2bDailyWorkData] = useState([])
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[4])
    const [branches, setbranches] = useState([])
    const [fromFilterDate, setfromFilterDate] = useState(getPreviousDate(2))
    const [toFilterDate, settoFilterDate] = useState(getPreviousDate(1))
    const [filteredList, setfilteredList] = useState([])
    const [activeBranches, setactiveBranches] = useState([])
    
    setTitle('Daily Work Detail Report')
    useEffect(() => {
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getB2bDailyWorkData()
        }
        document.getElementsByClassName('table-wrapper')[0].addEventListener('scroll', trackScrolling)
        return () => {
            document.getElementsByClassName('table-wrapper')[0].removeEventListener('scroll', trackScrolling)
        }
    }, [])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementsByClassName('table-wrapper')[0]
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const searchPost = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchPostKey(e.target.value)
            let tempTitle = '', filteredList = []
            b2bDailyWorkData.forEach(e => {
                tempTitle = e.registrationNo.toLowerCase()
                if (tempTitle.search(searchPostKey.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }
    
    const getBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getB2bDailyWorkData(activeBranchIds)
    }
    
    const getB2bDailyWorkData = async (branches?: string[]) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setb2bDailyWorkData([])
            } else {
                setb2bDailyWorkData(result.data.reverse())
                setfilteredList(result.data.reverse())
            }
        }
    }
    
    const selectCompany = (e) => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    const getTableData = filterData => {
        let data = [], i = 0
        filterData.forEach(e => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                i++;
                let yardName = activeBranches.length === 0 ? user.name : ''
                activeBranches.forEach(a => {
                    if (a.value === e.ownerId) {
                        yardName = a.label
                    }
                })
                let el = {
                    'Sr. No': i,
                    'Date': e['Date'] ?? '',
                    'Vehicle No (In Capital Letters)': e['Vehicle No (In Capital Letters)'] ?? '',
                    'Yard Name': yardName ?? '',
                    'Cleaning Work Done': e['Cleaning Work Done'] ?? '',
                    'Vehicle Inspection Done': e['Vehicle Inspection Done'] ?? '',
                    'Engine Starting': e['Engine Starting'] ?? '',
                    'Status [Switches Working]': e['Status ']['Switches Working'] ?? '',
                    'Status [AC Working]': e['Status ']['AC Working'] ?? '',
                    'Status [Lights Working]': e['Status ']['Lights Working'] ?? '',
                    'Battery Voltage': e['Battery Voltage'] ?? '',
                    'Vehicle Tyre Status': e['Vehicle Tyre Status'] ?? '',
                    'Service Done [Puncture Repair]': e['Service Done ']['Puncture Repair'] ?? '',
                    'Service Done [Battery Charging]': e['Service Done ']['Battery Charging'] ?? '',
                    'Vehicle Running Condition': e['Vehicle Running Condition'] ?? ''
                }
                data.push(el)
            }
        })
        return data
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Sr. No',
                    key: 'Sr. No'
                },
                {
                    label: 'Date',
                    key: 'Date'
                },
                {
                    label: 'Vehicle No (In Capital Letters)',
                    key: 'Vehicle No (In Capital Letters)'
                },
                {
                    label: 'Yard Name',
                    key: 'Yard Name'
                },
                {
                    label: 'Cleaning Work Done',
                    key: 'Cleaning Work Done'
                },
                {
                    label: 'Vehicle Inspection Done',
                    key: 'Vehicle Inspection Done'
                },
                {
                    label: 'Engine Starting',
                    key: 'Engine Starting'
                },
                {
                    label: 'Status [Switches Working]',
                    key: 'Status [Switches Working]'
                },
                {
                    label: 'Status [AC Working]',
                    key: 'Status [AC Working]'
                },
                {
                    label: 'Status [Lights Working]',
                    key: 'Status [Lights Working]'
                },
                {
                    label: 'Battery Voltage',
                    key: 'Battery Voltage'
                },
                {
                    label: 'Vehicle Tyre Status',
                    key: 'Vehicle Tyre Status'
                },
                {
                    label: 'Service Done [Puncture Repair]',
                    key: 'Service Done [Puncture Repair]'
                },
                {
                    label: 'Service Done [Battery Charging]',
                    key: 'Service Done [Battery Charging]'
                },
                {
                    label: 'Vehicle Running Condition',
                    key: 'Vehicle Running Condition'
                }
            ],
            content: getTableData(filteredList)
        })
        let exportedData = []
        filteredList.forEach((e, i) => {
            if(new Date(e['Date']).getTime() >= fromFilterDate && new Date(e['Date']).getTime() <= toFilterDate) {
                i++;
                let yardName = activeBranches.length === 0 ? user.name : ''
                activeBranches.forEach(a => {
                    if (a.value === e.ownerId) {
                        yardName = a.label
                    }
                })
                let el = {
                    'Sr. No': i,
                    'Date': e['Date'] ?? '',
                    'Vehicle No (In Capital Letters)': e['Vehicle No (In Capital Letters)'] ?? '',
                    'Yard Name': yardName ?? '',
                    'Cleaning Work Done': e['Cleaning Work Done'] ?? '',
                    'Vehicle Inspection Done': e['Vehicle Inspection Done'] ?? '',
                    'Engine Starting': e['Engine Starting'] ?? '',
                    'Status [Switches Working]': e['Status ']['Switches Working'] ?? '',
                    'Status [AC Working]': e['Status ']['AC Working'] ?? '',
                    'Status [Lights Working]': e['Status ']['Lights Working'] ?? '',
                    'Battery Voltage': e['Battery Voltage'] ?? '',
                    'Vehicle Tyre Status': e['Vehicle Tyre Status'] ?? '',
                    'Service Done [Puncture Repair]': e['Service Done ']['Puncture Repair'] ?? '',
                    'Service Done [Battery Charging]': e['Service Done ']['Battery Charging'] ?? '',
                    'Vehicle Running Condition': e['Vehicle Running Condition'] ?? ''
                }
                exportedData.push(el)
            }
        })
        setexportedData(exportedData)
    }, [filteredList])

    return {
        user,
        activeShownTotal,
        tableContent,
        searchPost,
        searchPostKey,
        toFilterDate,
        settoFilterDate,
        fromFilterDate,
        setfromFilterDate,
        branches,
        exportedData,
        activeBranches,
        selectCompany,
    }
}