import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useEvent = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'event')
    const {setTitle} = useService('Meta')
    const {isEmpty} = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [image, setimage] = useState([])
    const [uid, setuid] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [excerpt, setexcerpt] = useState<string>('')
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState([])
    const [tag, settag] = useState(null)

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getEventData({slug: urlData[2]})
    }, [urlData, slug])

    const getEventData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'publish')
                navigateTo({route: '/error404'})
            else {
                const eventData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === eventData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === eventData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === eventData.status))
                settag(isEmpty(eventData.tag) ? [] : JSON.parse(eventData.tag))
                setcategory(isEmpty(eventData.category) ? [] : JSON.parse(eventData.category))
                setmetaKeyword(isEmpty(eventData.metaKeyword) ? [] : JSON.parse(eventData.metaKeyword))
                setTitle(eventData.title)
                settitle(eventData.title)
                setslug(eventData.slug)
                setcontent(eventData.content)
                setexcerpt(eventData.excerpt)
                setcss(eventData.css)
                setjs(eventData.js)
                setcanonical(eventData.canonical)
                setrobots(eventData.robots)
                setmetaDescription(eventData.metaDescription)
                setauthor(eventData.author)
                setpublishDate(new Date(eventData.publishDate))
                setstatus(eventData.status)
                setuid(eventData.uid)
                setimage(JSON.parse(eventData.image))
            }
        }
    }
    return {
        category: category.join(', '),
        title,
        publishDate,
        image,
        content,
        slug,
    }
}