import { useContext, useEffect, useState } from "react"
import { userRole } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddUser = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'log')
    const {
        getUser,
        getUsers,
        addUser: publishUser,
        updateUser,
        getLoggedInUserData,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [branchOptions, setbranchOptions] = useState([])
    const [activeRoleIndex, setactiveRoleIndex] = useState<number>(1)
    const [supervisor, setsupervisor] = useState<string>('')
    const [company, setcompany] = useState<string>('')
    const [supervisorOptions, setsupervisorOptions] = useState([])
    const [email, setemail] = useState<string>('')
    const [name, setname] = useState<string>('')
    const [role, setrole] = useState<string>('')
    const [parent, setparent] = useState(null)
    const [gstin, setgstin] = useState<string>('')
    const [companies, setcompanies] = useState([])
    const [mobile, setmobile] = useState<string>('')
    const [uid, setuid] = useState<string>('')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [image, setimage] = useState<string>('[]')
    
    const getUserData = async (query) => {
        const result = await getUser(query)
        if (result.status) {
            const userData = result.data[0]
            setactiveRoleIndex(userRole.findIndex(e => e.value === userData.role))
            setemail(userData.email)
            setname(userData.name)
            setrole(userData.role)
            setgstin(userData.gstin||'')
            setmobile(userData.mobile)
            setmode('edit')
            setuid(userData.uid)
            if(userData.image)
                setimage(userData.image)
            if (['branch', 'representative', 'builder'].includes(userData.role)) {
                setparent(userData.parent)
                setsupervisor(userData.supervisor !== 'undefined' && typeof userData.supervisor !== 'undefined' && userData.supervisor !== ''? JSON.parse(userData.supervisor): '')
            }
            else if (['manager', 'representative'].includes(userData.role))
                setparent(userData.parent)
        } else showAlert({ type: 'error', msg: 'No user added yet!' })
    }
    
    const getAllUsersData = async () => {
        let companies = [], supervisorOptions = [], branchOptions = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(e => {
                    let el = { label: e.name, value: e.uid }
                    if (e.role === 'company')
                        companies.push(el)
                    else if (e.role === 'supervisor')
                        supervisorOptions.push(el)
                    else if (e.role === 'branch')
                        branchOptions.push(el)
                })
                setcompanies(companies)
                setsupervisorOptions(supervisorOptions)
                setbranchOptions(branchOptions)
            }
        }
    }
    
    const addUser = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = {
            email,
            name,
            role,
            gstin,
            mobile,
            image,
            supervisor: role === 'branch' ? JSON.stringify(supervisor) : undefined,
            company: ['branch', 'representative', 'builder'].includes(role) ? JSON.stringify(company) : undefined,
            uid: mode === 'add' ? generateUID() : uid,
            parent,
        }
        if (email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        } else if (role === '') {
            errorMsg = 'Please select the Role'
            error = true
        } else if (mobile === '') {
            errorMsg = 'Please enter Mobile Number'
            error = true
        } else if (gstin === '' && ['company', 'branch'].includes(role)) {
            errorMsg = 'Please enter GSTIN'
            error = true
        }
        if (['branch', 'manager', 'representative', 'builder'].includes(role) && parent === null) {
            errorMsg = 'Please select a Parent Company'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await publishUser(userObject)
                if (result.status) {
                    let logData = {
                        log: userObject.name+' added by admin with website',
                        timing: new Date().getTime(),
                        type: 'info',
                        userId: userObject.uid,
                        uid: generateUID()
                    }
                    save(logData)
                    showAlert({ type: 'success', msg: 'User added successfully!' })
                    navigateTo({route: '/admin/user/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    userData: JSON.stringify(userObject)
                }
                const result = await updateUser(data)
                if (result.status) {
                    let logData = {
                        log: userObject.name+' updated by admin with website',
                        timing: new Date().getTime(),
                        type: 'info',
                        userId: userObject.uid,
                        uid: generateUID()
                    }
                    save(logData)
                    showAlert({ type: 'success', msg: 'User updated successfully!' })
                    navigateTo({route: '/admin/user/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update user!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const urlData = urlToSplitData(getLocationData().pathname)
    setTitle('Add User', 'admin')
    useEffect(() => {
        getAllUsersData()
        if (urlData[2] === 'account') {
            let userData = getLoggedInUserData(),
            activeRoleIndex = 0
            userRole.forEach(( e, i ) => {
                if (e.value === userData.role)
                    activeRoleIndex = i
            })
            setemail(userData.email)
            setname(userData.name)
            setrole(userData.role)
            setgstin(userData.gstin||'')
            setmobile(userData.mobile)
            setmode('edit')
            setuid(userData.uid)
            setactiveRoleIndex(activeRoleIndex)
            setimage(typeof userData.image === 'undefined'? '[]' : userData.image)
        } else if (urlData[3] === 'edit') {
            getUserData({uid: urlData[4]})
        }
    }, [])

    let activeRole = userRole[activeRoleIndex],
    title = (urlData[2] === 'account') ? 'Profile' : mode + ' User'
    userRole.forEach(e => {
        if (e.value === role) {
            activeRole = e
        }
    })
    return {
        title,
        addUser,
        name,
        setname,
        urlData,
        activeRole,
        setrole,
        branchOptions,
        parent,
        setparent,
        companies,
        email,
        setemail,
        gstin,
        setgstin,
        mobile,
        setmobile,
        image,
        setimage,
        role,
        supervisor,
        setsupervisor,
        supervisorOptions,
    }
}