import { useImportB2BStockData } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'
import { SheetJSFT } from '../../../constant'

export const ImportB2BStockData = () => {
    const {getLocationData} = useService('Router')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const ImportResult = useComponent('ImportResult')
    const {
        trackScrolling,
        fileSelected,
        fileImported,
        processExcel,
        excelWorkList,
        handleFile,
        handleChange,
        showTable,
        tableContent,
        activeShownTotal,
        importDone,
        resultWorkList,
    } = useImportB2BStockData()
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        {
            fileSelected?
            <>
                {
                    fileImported?
                    !importDone &&
                    <div className='flex'>
                        <Button type="submit" className="btn btn-primary mv20" onClick={processExcel}>Import</Button>
                        <span className='mlauto'>Rows Attempted: <b>{excelWorkList.length}</b></span>
                    </div>:
                    <Button type="submit" className="btn btn-primary mv20" onClick={handleFile}>Parse</Button>
                }
            </>:
            <Box title="Import Daily B2b Stock Data">
                <Input type="file" className="hidden form-control inputfile-1" id="file" accept={SheetJSFT} onChange={handleChange} />
                <label className="btn btn-primary" htmlFor="file">
                    <i className="hi-upload-cloud"></i>
                    <span>Choose a file…</span>
                </label>
            </Box>
        }
        {
            showTable?
            <Table
                className="import-table"
                data={tableContent}
                pageSize={activeShownTotal}
            />
            :importDone &&
            <>
                <p>All Cars imported successfully</p>
                <div className="flex mb20">
                    <Button className="btn btn-outline" onClick={() => getLocationData().reload()}>Import More</Button>
                </div>
            </>
        }
        <ImportResult resultList={resultWorkList} excelList={excelWorkList} />
    </div>
}
