import {base} from '../../constant'
import { useComponent, useElement, useService } from '../../hooks'
import { useServiceTemplate } from './hooks'
import './style.scss'
export const Service = () => {
    const TimeSlots = useComponent('TimeSlots')
    const {
        getLoggedInUserData,
        isUserLoggedIn,
        getActiveSubscription,
    } = useService('User')
    const {
        toIndianCurrency,
        openCarSelectModal,
        isModuleEnabled,
        openModal,
        closeModal,
    } = useService('Misc')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const RelatedProductSlider = useComponent('RelatedProductSlider')
    const {
        selectSubscription,
        setactiveSubscription,
        activeSubscription,
        activeTimeSlot,
        setactiveTimeSlot,
        title,
        image,
        serviceTime,
        activePrice,
        activeCar,
        buyItem,
        excerpt,
        usernote,
        content,
        setorderDate,
        setchooseServiceTime,
        orderDate,
        chooseServiceTime,
        buyType,
        chooseActiveCar,
        subscriptionData,
    } = useServiceTemplate()
    const user = getLoggedInUserData()

    const renderCarSubscription = carData => {
        return carData.map(( item, index ) => {
            return <div className="car-list" onClick={() => {
                selectSubscription(index)
                setactiveSubscription(item)
            }} key={index}>
                <div className="car-list-block">
                    <span className="carCheckbox">
                        {(activeSubscription === index) ?<i className="hi-checkbox orange"></i> : <i className="hi-checkbox-unchecked1"></i>}
                    </span>
                    <span className="car-name">{item.title}</span>
                </div>
                <div className="price">{item.discount}%</div>
            </div>
        })
    }

    const renderImages = imageList => {
        return imageList.slice(1).map(( item, index ) => {
            return <div className="img-item" key={index}>
                <Img hasZoom src={item} alt={title} />
            </div>
        })
    }

    const imagesList = JSON.parse(image),
    today = new Date(),
    tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return <>
        <div className="service-banner" style={{backgroundImage: 'url("'+base.imageBaseUrl+JSON.parse(image)[0]+'")'}}>
            <div className='jumbo-container'>
                <h1 className="title">{title}</h1>
                <div className="product-info">
                    {serviceTime && <div className="hrsTaken mv20"><i className="hi-clock1 primary"></i> Service Time {serviceTime} Hrs</div>}
                    <div className="addToCartbtn-service-page">
                        {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <Button className="btn btn-primary mrauto btn-large" onClick= {() => buyItem('add-to-cart')}>Add to Cart <i className="hi-cart"></i></Button>}
                        {
                            activePrice?
                            <>
                                {
                                    typeof activePrice.saleprice === "undefined" || activePrice.saleprice === 0 || activePrice.saleprice === '' || activePrice.saleprice === activePrice.price ?
                                    <div className="mrp">
                                        <span className="white">{toIndianCurrency(activePrice.price)} <small>+18% GST</small></span>
                                    </div>:
                                    <div className="flex">
                                        <div className="mrp mrauto">
                                            <span className="white">{toIndianCurrency(activePrice.saleprice)}</span>
                                        </div>
                                        <div className="sale-price mlauto">
                                            <span className="white">{toIndianCurrency(activePrice.price)}</span>
                                        </div>
                                        <div className="per-off">{((activePrice.price - activePrice.saleprice)/activePrice.price * 100).toFixed(0)} % Off
                                        </div>
                                    </div>
                                }
                            </>:<>
                            {
                                activeCar === null &&
                                <Button className="btn get-price btn-outline btn-large ml30" onClick={openCarSelectModal}>Get Price <i className="hi-directions_car"></i></Button>
                            }
                            </>
                        }
                        {
                            isModuleEnabled(['Subscription']) && getActiveSubscription() === null &&
                            <p className="get-off" onClick={() => openModal("get-subscription-modal")}>Get 10% off <i className="hi-info"></i> </p>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="servicedetail-page">
            <div className="jumbo-container">
                <h2 className="heading-02 mt40">Summary</h2>
                <div className='summary-card'>
                    <div className='summary-content'>
                        <Html className="product-detail-description points" content={excerpt} />
                    </div>
                    <div className='summary-image' style={{backgroundImage: `url('${base.imageBaseUrl}${imagesList[0]}')`}}></div>
                </div>
                {
                    usernote && <>
                        <div className="clearfix pb40"></div>
                        <h2 className="heading-02 mt40">User Notes</h2>
                        <Html className="content-detail points" content={usernote} />
                    </>
                }
                <div className="clearfix pb40"></div>
                {imagesList.length > 1 && <section className="main-product-detail-page mb60">
                    <h2 className="heading-02">Gallery</h2>
                    <div className="left-col">
                        <div className="gallery">
                            <div className="gallery-items">
                                {renderImages(imagesList || [] )}
                            </div>
                        </div>
                    </div>
                </section>}
                <h2 className="heading-02">Description</h2>
                <Html className="content-detail points" content={content} />
                <div className="clearfix pb40"></div>
            </div>
        </div>
        <RelatedProductSlider />
        <div className="modal add-to-cart-modal" id="add-to-cart-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small overflown">
                <Button className="close" onClick={() => closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="add-to-cart-modal-header">
                        <div className="modal-header">
                            <div className="heading">Select Service Date</div>
                        </div>
                    </div>
                    <div className="select-date">
                        <div className="datebox">
                            <label className="date-label">Select Service Date</label>
                            <DatePicker
                                onChange={orderDate => {
                                    setorderDate(orderDate)
                                    setchooseServiceTime(true)
                                }}
                                minDate={tomorrow}
                                startDate={tomorrow}
                                selected={orderDate}
                            />
                        </div>
                        {
                            chooseServiceTime && buyType === 'add-to-cart' &&
                            <div className="datebox">
                                <label className="date-label">Select ServiceTime Slot</label>
                                <TimeSlots activeTimeSlot={activeTimeSlot} setactiveTimeSlot={setactiveTimeSlot} />
                                <Button className="btn btn-primary mt20" onClick={chooseActiveCar}>Proceed</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="modal add-to-cart-modal" id="subscribe-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => closeModal("subscribe-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="add-to-cart-modal-header">
                        <div className="modal-header">
                            <h2 className="heading">Select Time</h2>
                        </div>
                    </div>
                    {renderCarSubscription(subscriptionData || [] )}
                </div>
            </div>
        </div>
    </>
}
