import { useContext, useEffect, useState } from "react"
import { config, stateTypes } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useAdminState = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchAll,
        remove,
        save,
        update,
    } = useService('Data', 'state')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        closeModal,
        openModal,
        isBottom,
    } = useService('Misc')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [stateList, setstateList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [filteredList, setfilteredList] = useState([])

    setTitle('State', 'admin')
    useEffect(() => {
        getStatesData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getStatesData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No State added yet!' })
            else
                setstateList(result.data)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashState = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getStatesData()
            showAlert({ type: 'success', msg: 'State deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete state!' })
    }
    
    const addState = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        stateObject = {
            ...activeItem,
            type: activeItem.type.value ?? activeItem.type.label ?? activeItem.type
        }
        if (stateObject.code === '') {
            errorMsg = 'Please enter Code'
            error = true
        } else if (stateObject.name === '') {
            errorMsg = 'Please enter State'
            error = true
        } else if (stateObject.type === '') {
            errorMsg = 'Please select Type'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                stateObject.uid = generateUID()
                const result = await save(stateObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'State added successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add state!' })
                }
                setactiveItem(null)
                closeModal("state-modal")
                getStatesData()
            } else {
                delete stateObject._id
                let data = {
                    query: JSON.stringify({uid: stateObject.uid}),
                    stateData: JSON.stringify(stateObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'State updated successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update state!' })
                }
                setactiveItem(null)
                closeModal("state-modal")
                getStatesData()
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                code: item.code,
                name: item.name,
                type: item.type,
                status: <Badge item={item.status ? 'active' : 'inactive'} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        setmode('edit')
                        setactiveItem({...item, type: stateTypes.find(e => e.value === item.type)})
                        openModal("state-modal")
                    }} />
                    <Actor type='trash' onClick={() => {
                        setactiveItem({...item, type: stateTypes.find(e => e.value === item.type)})
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Type',
                    key: 'type'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(stateList)
        })
    }, [stateList])

    const assignStateType = (e) => {
        setactiveItem({...activeItem, type: e})
    }

    const openAddModal = () => {
        setmode('add')
        setactiveItem({
            type: '',
            code: '',
            status: false,
            name: ''
        })
        openModal("state-modal")
    }
    return {
        trackScrolling,
        openAddModal,
        tableContent,
        activeShownTotal,
        trashState,
        trashCancel,
        showTrashConfirm,
        mode,
        addState,
        setactiveItem,
        activeItem,
        assignStateType,
        closeModal,
        stateTypes,
    }
}