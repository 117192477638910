import { useState } from "react"
import { useService } from "../../../../hooks"

export const useMobileApp = () => {
    const {
        sendAppShare,
        showAlert,
    } = useService('Misc')
    const {
        isValidMobile,
        isValidEmail,
    } = useService('Validation')
    const [activeDownloadState, setactiveDownloadState] = useState('mobile')
    const [receiver, setreceiver] = useState<string>('')
    
    const shareApp = async () => {
        let error = false, errorMsg = '',
        shareObject = {
            to: receiver,
            type: activeDownloadState === 'mobile' ? 'sms' : 'email'
        }
        if (activeDownloadState === 'mobile') {
            if (!isValidMobile(receiver)) {
                error = true
                errorMsg = 'Please enter a valid mobile number'
            }
        } else if (!isValidEmail(receiver)) {
            error = true
            errorMsg = 'Please enter a valid email appdress'
        }
        if (!error) {
            const result = await sendAppShare(shareObject)
            if (result.status) {
                setreceiver('')
                showAlert({ type: 'info', msg: shareObject.type === 'email' ? 'App download link has been sent on your Email address!' : 'App download link has been sent to your mobile number in SMS!' })
            } else showAlert({ type: 'error', msg: 'Unable to send app link, try after some time.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const setReceiverData = e => setreceiver(e.target.value)

    const selectSharingMedium = (e) => {
        setactiveDownloadState(e)
        setreceiver('')
    }
    return {
        selectSharingMedium,
        activeDownloadState,
        receiver,
        setReceiverData,
        shareApp,
    }
}