import { useContext, useEffect, useState } from "react"
import { robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddProject = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'project')
    const {setTitle} = useService('Meta')
    const {
        isEmpty,
        showAlert,
        generateUID,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const keywordOptions =  [],
    categoryOptions = [],
    tagOptions = []
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [renderSlug, setrenderSlug] = useState<boolean>(true)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [description, setdescription] = useState("")
    const [url, seturl] = useState("")
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [releaseDate, setreleaseDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState(null)
    const [tag, settag] = useState(null)
    
    const getProjectData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const projectData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === projectData.robots))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === projectData.status))
            settag(isEmpty(projectData.tag) ? [] : JSON.parse(projectData.tag))
            setcategory(isEmpty(projectData.category) ? [] : JSON.parse(projectData.category))
            setmetaKeyword(isEmpty(projectData.metaKeyword) ? [] : JSON.parse(projectData.metaKeyword))
            settitle(projectData.title)
            setmode('edit')
            setslug(projectData.slug)
            setdescription(projectData.description)
            seturl(projectData.url)
            setcanonical(projectData.canonical)
            setrobots(projectData.robots)
            setmetaDescription(projectData.metaDescription)
            setreleaseDate(new Date(projectData.releaseDate))
            setstatus(projectData.status)
            setuid(projectData.uid)
            setimage(projectData.image)
        } else showAlert({ type: 'error', msg: 'No project added yet!' })
    }
    
    const addProject = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        projectObject = {
            title,
            slug,
            description,
            url,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            releaseDate,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image,
            uid: mode === 'add' ? generateUID() : uid,
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (description === '') {
            errorMsg = 'Please enter the description'
            error = true
        } else if (url === '') {
            errorMsg = 'Please enter the url'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(projectObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Project added successfully!' })
                    navigateTo({route: '/admin/project/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add project!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    projectData: JSON.stringify(projectObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Project updated successfully!' })
                    navigateTo({route: '/admin/project/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update project!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const urlData = urlToSplitData(getLocationData().pathname)
    
    setTitle('Add Project', 'admin')
    useEffect(() => {
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getProjectData({uid: urlData[4]})
        }
    }, [])

    const addTitle = (e) => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    const pageTitle = mode + ' Project'
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let activeRobots = robotsOption[activeRobotsIndex],
    activeStatus = statusOption[activeStatusIndex]
    if (mode === 'edit') {
        statusOption.forEach(e => {
            if (e.value === status)
                activeStatus = e
        })
        robotsOption.forEach(e => {
            if (e.value === robots)
                activeRobots = e
        })
    }
    return {
        pageTitle,
        addProject,
        title,
        addTitle,
        slug,
        setslug,
        description,
        setdescription,
        url,
        seturl,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        setreleaseDate,
        releaseDate,
        status,
        setstatus,
        category,
        setcategory,
        categoryOptions,
        tag,
        settag,
        tagOptions,
        image,
        setimage,
    }
}