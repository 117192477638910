import { PageRouteProps } from "../../types";

export const staticRoutes: PageRouteProps[] = [
    {
        "pathName": "/contact",
        "component": "Contact",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/about",
        "component": "About",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/complaint",
        "module": ["Complaint"],
        "component": "Complaint",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/faq",
        "module": ["Faq"],
        "component": "Faq",
        "type": "public",
        "mode": "static"
    }
]