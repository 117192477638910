import ReactImageZoom from 'react-image-zoom'
import {base} from '../../constant'
import { useProduct } from './hooks'
import { useComponent, useElement, useService } from '../../hooks'
import './style.scss'
export const Product = () => {
    const {isUserLoggedIn} = useService('User')
    const {getFormattedDate, isEmpty} = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Html = useElement('Html')
    const RelatedProductSlider = useComponent('RelatedProductSlider')
    const Table = useComponent('Table')
    const Rating = useElement('Rating')
    const {
        activeImage,
        rating,
        setrating,
        stock,
        excerpt,
        content,
        feedback,
        feedBackSubmit,
        title,
        addToCart,
        brand,
        comments,
        setcomments,
        setactiveImage,
        imagesList,
        isAssured,
        user,
        averageRating,
        tableContent,
    } = useProduct()
    
    const renderImages = () => {
        return imagesList.map(( item, index ) => {
            return <div className="img-item" key={index} onClick={() => setactiveImage(item)}>
                <Img src={item} alt={title} />
            </div>
        })
    }
    
    const renderReviews = () => {
        return feedback?.map(( item, index ) => {
            return <div className="review" key={index}>
                <div className="review-user-box">
                    {
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        <Img src={'no-image-found.png'} alt={item.title} />:
                        <Img src={JSON.parse(item.image)} alt={item.title} />
                    }
                    <p>{item.name}</p>
                </div>
                {item.rating && <div className="rating">
                    <Rating rating={item.rating} />
                </div>}
                <div className="reviwed-date">Reviewed on {getFormattedDate(new Date(item.submitDate))}</div>
                <div className="review-content">
                    {item.comments}
                </div>
            </div>
        })
    }

    return <>
        <div className="productdetail-page mb60-mbs40 pt40">
            <section className="main-product-detail">
                <div className="left-col-box">
                    <div className="left-col pt30">
                        <div className="gallery">
                            <div className="images-section">
                                {renderImages()}
                            </div>
                        </div>
                        <div className="image-section">
                            <div className="image-box">
                                <ReactImageZoom {...{zoomWidth: 600, img: base.imageBaseUrl+activeImage}} />
                            </div>
                            { isAssured && <Img className="assuredImg" src={'assured.png'} alt={title} /> }
                        </div>
                    </div>
                    {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <div className="addtocart-btn-box flex overflow-hidden">
                        {
                            isEmpty(stock)?
                            <Button disabled className="btn btn-gray btn add-to-cart" title="Out Of Stock">Out Of Stock</Button>:
                            <> 
                                <Button onClick={() => addToCart('quick')} className="btn btn-outline btn-box btn add-to-cart">Buy Now</Button>
                                <Button onClick={addToCart} className="btn btn-primary btn-box btn add-to-cart">Add to Cart<i className='hi-cart'></i></Button>
                            </>
                        }
                    </div>}
                </div>
                <div className="right-col">
                    <div className="product-details">
                        <h1 className="title">{title}</h1>
                        {
                            brand ?
                            <div className="brand">
                                <span className="customer-counting">
                                    by {brand.label}
                                </span>
                            </div>: null
                        }
                        {
                            feedback.length !== 0 &&
                            <div className="starer">
                                {averageRating && <span className="common-rating-style">
                                    <Rating rating={averageRating} />
                                </span>}
                                <span className="customer-counting link">
                                    {feedback.length} ratings
                                </span>
                            </div>
                        }
                        <hr className="mh0" />
                        <div id="price" className="a-section a-spacing-small">
                            <Table
                                data={tableContent}
                                showTotal={false}
                            />
                        </div>
                        <div className="product-trust">
                            <div className="product-trust-item">
                                <i className="hi-account_balance_wallet"></i>
                                <span>Online Payment options</span>
                            </div>
                            <div className="product-trust-item">
                                <i className="hi-replay_10"></i>
                                <span>10 Days Replacement</span>
                            </div>
                            <div className="product-trust-item">
                                <i className="hi-ticket"></i>
                                <span>Genuine product</span>
                            </div>
                            <div className="product-trust-item">
                                <i className="hi-delivery_dining"></i>
                                <span>Free Shipping</span>
                            </div>
                        </div>
                        {
                            isEmpty(stock)?
                            <p className="a-size-medium error">Out Of Stock</p>:
                            <p className="a-size-medium success">In Stock</p>
                        }
                        <Html content={excerpt} />
                    </div>
                    <h2 className="title borderbottom">Description</h2>
                    <Html className="content-detail" content={content} />
                </div>
            </section>
            <div className="review-rating-box-background">
                {
                !isEmpty(feedback) &&
                <div className="reviews-box pt50">
                    <div className="jumbo-container">
                        <h2 className="heading-02 ">Reviews</h2>
                        {renderReviews()}
                    </div>
                </div>
                }
                {
                    isUserLoggedIn() &&
                    <div className="feedback-page product-review-box pb50 pt40">
                        <div className="jumbo-container">
                            <h2 className="heading-02 ">Write a Review</h2>
                            <div className="body-feedback mb20">
                                <form onSubmit={feedBackSubmit}>
                                    <div className="rating-product">
                                        <span className={rating === 5?'active': ''} onClick={() => setrating(5)}>☆</span>
                                        <span className={rating === 4?'active': ''} onClick={() => setrating(4)}>☆</span>
                                        <span className={rating === 3?'active': ''} onClick={() => setrating(3)}>☆</span>
                                        <span className={rating === 2?'active': ''} onClick={() => setrating(2)}>☆</span>
                                        <span className={rating === 1?'active': ''} onClick={() => setrating(1)}>☆</span>
                                    </div>
                                    <textarea className="textarea-feedback" placeholder="Type something.." value={comments} onChange={e => setcomments(e.target.value)}></textarea>
                                    <Button type="submit" className="btn btn-primary">Submit</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <RelatedProductSlider />
        </div>
    </>
}
