import { brandInfo } from '../../../constant'
import { useElement } from '../../../hooks'

export const OurWorkingProcess = () => {
    const Img = useElement('Img')
    return <section className="our-process-section mb60-mbs40">
        <div className="jumbo-container">
            <div className="wrapper">
                <div className="section-title-wrap tac">
                    <div className="section-title">
                        <h2 className="heading-02 tal heading-02">Working Process</h2>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <div className="col-2">
                    <div className="our-process-section__work-items">
                        <div className="items-number">01</div>
                        <div className="work-times-inner">
                            <div>
                                <h5 className="items-title">Download {brandInfo.name.capital}</h5>
                            </div>
                            <p>Download The {brandInfo.name.capital} app and login by using your mobile number. </p>
                            <Img source="internal" src={require('../../../../assets/images/7-character-design.jpg')} alt={`Download ${brandInfo.name.capital}`} />
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="our-process-section__work-items">
                        <div className="items-number">02</div>
                        <div className="work-times-inner">
                            <div>
                                <h5 className="items-title">Upload Your Details</h5>
                            </div>
                            <p>Upload your personal & car details. All information will be safe.</p>
                            <Img source="internal" src={require('../../../../assets/images/1-character-design.jpg')} alt="Upload Your Details" />
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="our-process-section__work-items">
                        <div className="items-number">03</div>
                        <div className="work-times-inner">
                            <div>
                                <h5 className="items-title">Select The Service</h5>
                            </div>
                            <p>Select the package/service that suits your requirements. </p>
                            <Img source="internal" src={require('../../../../assets/images/5-character-design.jpg')} alt="Select The Service" />
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="our-process-section__work-items">
                        <div className="items-number">04</div>
                        <div className="work-times-inner">
                            <div>
                                <h5 className="items-title">Secure Checkout</h5>
                            </div>
                            <p>Make payment for the package/service availed.</p>
                            <Img source="internal" src={require('../../../../assets/images/2-character-design.jpg')} alt="Secure Checkout" />
                        </div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="our-process-section__work-items">
                        <div className="items-number">05</div>
                        <div className="work-times-inner">
                            <div>
                                <h5 className="items-title">Get Status & Give Feedback</h5>
                            </div>
                            <p>You can check the service status online and give feedback along with recommendation. </p>
                            <Img source="internal" src={require('../../../../assets/images/6-character-design.jpg')} alt="Get Status & Give Feedback" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}