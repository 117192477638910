import { useCallback, useState } from "react"
import { useService } from "../../../../hooks"

export const useMediaBox = ({
    usage,
    image,
    setimage,
    id,
}) => {
    const {
        showAlert,
        openModal,
        closeModal,
    } = useService('Misc')
    const {
        fetchByAttr,
        fetchAll,
    } = useService('Data', 'media')
    const [searchKey, setsearchKey] = useState<string>('')
    const [mediaFiles, setmediaFiles] = useState([])
    const [tableData, settableData] = useState([])
    const inputId = 'image-input'+id
    const getMedias = useCallback(async () => {
        let result
        if (usage) {
            result = await fetchByAttr({usage: {$in: usage}})
        } else {
            result = await fetchAll()
        }
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No media added yet!' })
            } else {
                let media = result.data.reverse().filter(e=>{
                    return (e.fileName.includes('.'))
                })
                setmediaFiles(media)
                settableData(media)
            }
        }
    }, [])
    
    const resetSearch = useCallback(() => {
        setmediaFiles(tableData)
        setsearchKey('')
    }, [])

    const selectImage = useCallback(file => {
        let imagesArray = []
        let images = (document.getElementById('image-input') as HTMLInputElement).value === '' ? JSON.stringify([]) : (document.getElementById('image-input') as HTMLInputElement).value
        if (!images.includes(file)) {
            imagesArray = JSON.parse(images)
            imagesArray.push(file)
        } else {
            imagesArray = JSON.parse(images)
            let imageIndex = imagesArray.indexOf(file);
            if (imageIndex > -1) {
                imagesArray.splice(imageIndex, 1);
              }
        }
        setimage(JSON.stringify(imagesArray))
    }, [])

    const trashSelectedImage = useCallback(item => {
        let newImages = []
        JSON.parse(image).forEach(i => {
            if (i !== item) {
                newImages.push(i)
            }
        })
        setimage(JSON.stringify(newImages))
    }, [])

    const setImageInput = useCallback(e => setimage(e.target.value), [])

    const showImages = useCallback(() => {
        getMedias()
        openModal("media-modal"+id)
    }, [])

    const closeMediaModel = useCallback(() => closeModal("media-modal"+id), [])

    const search = useCallback(e => {
        setsearchKey(e.target.value)
        let tempTitle = '', filteredList = []
        tableData.forEach(e => {
            tempTitle = e.fileName.toLowerCase()
            if (tempTitle.search(searchKey) !== -1)
                filteredList.push(e)
        })
        setmediaFiles(filteredList)
    }, [])

    return {
        search,
        searchKey,
        mediaFiles,
        closeMediaModel,
        setImageInput,
        showImages,
        resetSearch,
        selectImage,
        trashSelectedImage,
        inputId,
    }
}