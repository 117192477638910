import { memo } from "react"
import Select from "react-select"
import { statusOption } from "../../../../constant"
import { usePostTotal } from "./hooks"
import { useElement } from "../../../../hooks"
import { DashboardWidgetProps } from "../types"
export const PostTotal = memo(({
    data,
    action,
}: DashboardWidgetProps) => {
    const Input = useElement('Input')
    const Button = useElement('Button')
    const {
        applyFilters,
        setWidgetTitle,
        title,
        status,
        changeStatus,
        count,
    } = usePostTotal({
        data,
        action,
    })

    const renderFilters = () => {
        return <form onSubmit={applyFilters}>
            <Input onChange={setWidgetTitle} value={title} placeholder="Widget Title" />
            <Select
                defaultValue={status}
                onChange={changeStatus}
                options={statusOption}
                placeholder='Select Status'
            />
            <Button type="submit" className="btn btn-primary hi-check-circle"></Button>
        </form>
    }

    return <>
        { data.configure ? renderFilters():
            <>
                <i className="widget-icon hi-archive"></i>
                {count}
            </>
        }
    </>
})
