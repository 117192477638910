import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useAdminHeader = () => {
    const {cacheClean} = useService('Router')
    const {
        getLoggedInUserData,
        logout: logoutUser,
    } = useService('User')
    const {
        getThemeMode,
        setThemeMode,
        toggleBodyClass,
        getFranchiseData: getFranchiseInfo,
        isEmpty,
        setFranchiseData,
    } = useService('Misc')
    const {fetchByAttr} = useService('Data', 'franchise')
    const user = getLoggedInUserData()
    const [showUserNav, setshowUserNav] = useState<boolean>(false)
    const [franchiseData, setfranchiseData] = useState(null)

    const toggleThemeMode = () => {
        if (getThemeMode() === "theme-dark") {
            setThemeMode("theme-light")
        } else setThemeMode("theme-dark")
    }
    
    const toggleNav = () => {
        toggleBodyClass('', '', 'closed')
    }
    
    const toggleUserNav = () => {
        setshowUserNav(!showUserNav)
    }

    const logout = () => {
        setshowUserNav(false)
        logoutUser('/login')
    }

    const getFranchiseData = async () => {
        let franchiseData = getFranchiseInfo()
        if(isEmpty(franchiseData)) {
            let managerData = JSON.stringify({
                label: user.name,
                value: user.uid
            })
            const result = await fetchByAttr({
                $or: [ { contactPerson: managerData }, { owner: managerData } ]
            })
            if(result.status) {
                franchiseData = result.data[0]
                setFranchiseData(franchiseData)
            }
            setfranchiseData(franchiseData)
        }
    }

    useEffect(() => {
        if (['franchiseManager', 'franchiseOwner'].includes(user?.role)) {
            getFranchiseData()
        }
    }, [])

    const headerNav = [
        {
            onClick: toggleNav,
            className: "nav-btn",
            title: "Toggle Nav",
            icon: "hi-nav",
        },
        {
            onClick: toggleThemeMode,
            className: "theme-btn",
            title: "Toggle Theme",
            icon: "hi-bulb",
        },
        {
            onClick: cacheClean,
            className: "cache-btn",
            title: "Clear cache & Reload",
            icon: "hi-refresh",
        },
    ]

    const userNav = [
        {
            title: "Profile",
            link: "/admin/account",
            icon: 'hi-user1',
        },
        {
            title: "Visit Site",
            link: "/",
            icon: 'hi-network',
        },
        {
            title: "Logout",
            onClick: logout,
            icon: 'hi-logout',
        },
    ]

    return {
        toggleNav,
        toggleThemeMode,
        franchiseData,
        user,
        toggleUserNav,
        showUserNav,
        logout,
        headerNav,
        userNav,
    }
}