import { useContext, useEffect, useState } from "react"
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from "react-places-autocomplete"
import { statusOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddFranchise = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'franchise')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [address, setaddress] = useState<string>('')
    const [companyName, setcompanyName] = useState<string>('')
    const [mobile, setmobile] = useState<string>('')
    const [email, setemail] = useState<string>('')
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [renderSlug, setrenderSlug] = useState<boolean>(true)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState("")
    const [owner, setowner] = useState(null)
    const [contactPerson, setcontactPerson] = useState(null)
    const [status, setstatus] = useState(null)
    const [franchiseOwners, setfranchiseOwners] = useState([])
    const [franchiseManagers, setfranchiseManagers] = useState([])
    const [places, setplaces] = useState([])
    const [showSuggestions, setshowSuggestions] = useState<boolean>(true)
    const [addressDetail, setaddressDetail] = useState(null)
    const [locality, setlocality] = useState<string>('')
    const [pinCode, setpinCode] = useState<string>('')
    const [city, setcity] = useState<string>('')
    const [state, setstate] = useState<string>('')
    const [socialLinks, setsocialLinks] = useState({
        facebook: '',
        twitter: '',
        instragram: '',
        googleMap: ''
    })
    const urlData = urlToSplitData(getLocationData().pathname)
    
    const handleChange = address => {
        setaddress(address)
        setplaces([])
        setshowSuggestions(true)
    }
    
    const parseAddress = (address_components) => {
        let pinCode ='', locality= '', city= '', state = ''
        address_components?.forEach(e => {
            if (e.types?.includes("postal_code"))
                pinCode = e.long_name
            if (e.types?.includes("administrative_area_level_1"))
                state = e.long_name
            if (e.types?.includes("administrative_area_level_2"))
                city = e.long_name
            if (e.types?.includes("locality"))
                locality = e.long_name
        })
        setlocality(locality)
        setpinCode(pinCode)
        setcity(city)
        setstate(state)
    }
    
    const saveSuggestion = async addressSuggestion => {
        let results = await geocodeByPlaceId(addressSuggestion.placeId)
        setaddress(results[0].formatted_address)
        setaddressDetail(results[0])
        handleChange(results[0].formatted_address)
        handleSelect(results[0].formatted_address)
        parseAddress(results[0].address_components)
    }
    
    const handleSelect = async address => {
        geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
            let places = []
            places.push(latLng)
            if (latLng !== null) {
                setplaces(places)
                setshowSuggestions(false)
            }
        })
        .catch(e => console.log('error', e))
    }
    const getUsersData = async () => {
        let franchiseOwners = [], franchiseManagers = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(e => {
                    if (e?.role === 'franchiseManager') {
                        franchiseManagers.push({label: e.name, value: e.uid})
                    } else if (e?.role === 'franchiseOwner') {
                        franchiseOwners.push({label: e.name, value: e.uid})
                        franchiseManagers.push({label: e.name, value: e.uid})
                    }
                })
                setfranchiseOwners(franchiseOwners)
                setfranchiseManagers(franchiseManagers)
            }
        }
    }
    
    const getFranchiseData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const franchiseData = result.data[0],
            addressDetail = JSON.parse(franchiseData.addressDetail)
            addressDetail.address_components?.forEach(e => {
                if (e.types?.includes("postal_code"))
                    setpinCode(e.long_name)
                if (e.types?.includes("administrative_area_level_1"))
                    setstate(e.long_name)
                if (e.types?.includes("administrative_area_level_2"))
                    setcity(e.long_name)
                if (e.types?.includes("locality"))
                    setlocality(e.long_name)
            })
            setmode('edit')
            setuid(franchiseData.uid)
            settitle(franchiseData.title)
            setcompanyName(franchiseData.companyName)
            setcontactPerson(JSON.parse(franchiseData.contactPerson))
            setowner(JSON.parse(franchiseData.owner))
            setmobile(franchiseData.mobile)
            setemail(franchiseData.email)
            setcontent(franchiseData.content)
            setimage(franchiseData.image)
            setsocialLinks(JSON.parse(franchiseData.socialLinks))
            setslug(franchiseData.slug)
            statusOption.forEach(e => {
                if (e.value === franchiseData.status) {
                    setstatus(e)
                }
            })
            setaddress(franchiseData.address)
            setaddressDetail(addressDetail)
        } else showAlert({ type: 'error', msg: 'No franchise found with given Id!' })
    }
    
    const updateAddressDetail = (key, value) => {
        const typeIndex = {
            'locality': {
                label: 'locality',
                action: setlocality
            },
            'pinCode': {
                label: 'postal_code',
                action: setpinCode
            },
            'city': {
                label: 'administrative_area_level_2',
                action: setcity
            },
            'state': {
                label: 'administrative_area_level_1',
                action: setstate
            }
        }
        addressDetail.address_components?.forEach(e => {
            if (e.types?.includes(typeIndex[key]['label'])) {
                e.long_name = value
                typeIndex[key]['action'](value)
            }
        })
        setaddressDetail(addressDetail)
    }

    const addFranchise = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        franchiseObject = {
            title,
            companyName,
            contactPerson: JSON.stringify(contactPerson),
            owner: JSON.stringify(owner),
            mobile,
            email,
            content,
            image,
            address,
            socialLinks: JSON.stringify(socialLinks),
            slug,
            status: status.value,
            addressDetail: JSON.stringify(addressDetail),
            uid: mode === 'add' ? generateUID(): uid
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (address === '') {
            errorMsg = 'Please enter the address'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(franchiseObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Franchise added successfully!' })
                    navigateTo({route: '/admin/franchise/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add franchise!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    franchiseData: JSON.stringify(franchiseObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Franchise updated successfully!' })
                    navigateTo({route: '/admin/franchise/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update franchise!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    setTitle('Add Franchise', 'admin')
    useEffect(() => {
        if (urlData[3] === 'edit') {
            getFranchiseData({uid: urlData[4]})
        }
        getUsersData()
    }, [])

    const addTitle = (e) => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }
    return {
        mode,
        status,
        image,
        setimage,
        setstatus,
        socialLinks,
        setsocialLinks,
        renderSlug,
        toggleRenderSlug,
        city,
        state,
        updateAddressDetail,
        pinCode,
        addFranchise,
        title,
        addTitle,
        slug,
        setslug,
        companyName,
        setcompanyName,
        mobile,
        setmobile,
        email,
        setemail,
        content,
        setcontent,
        address,
        addressDetail,
        setaddressDetail,
        owner,
        setowner,
        places,
        handleChange,
        handleSelect,
        showSuggestions,
        saveSuggestion,
        locality,
        franchiseOwners,
        contactPerson,
        setcontactPerson,
        franchiseManagers,
    }
}