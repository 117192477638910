import Select from 'react-select'
import { useAddOffer } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddOffer = () => {
    const DatePicker = useElement('DatePicker')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        mode,
        applicableOptions,
        activeStatusIndex,
        discountOptions,
        activeDiscountIndex,
        offerRestrictionOptions,
        setimage,
        image,
        statusOption,
        setstatus,
        expiryDate,
        setexpiryDate,
        offerForSociety,
        offerRestrictionFor,
        discountType,
        offerApplicable,
        offerRestriction,
        status,
        addOffer,
        code,
        setcode,
        discount,
        setdiscount,
        setdiscountType,
        setofferApplicable,
        content,
        setcontent,
        setofferRestriction,
        setofferRestrictionFor,
        offerForUser,
        selectUsersToOffers,
        userRestrictionOptions,
        selectSocietiesToOffer,
        societyRestrictionOptions,
    } = useAddOffer()

    let pageTitle = mode + ' Offer',
    activeOfferAplicable = applicableOptions[activeStatusIndex],
    activeDiscount = discountOptions[activeDiscountIndex],
    activeOfferRestrictions = offerRestrictionOptions[activeStatusIndex],
    activeStatus = statusOption[activeStatusIndex]
    discountOptions.forEach(e => {
        if (e.value === discountType)
            activeDiscount = e
    })
    applicableOptions.forEach(e => {
        if (e.value === offerApplicable)
            activeOfferAplicable = e
    })
    offerRestrictionOptions.forEach(e => {
        if (e.value === offerRestriction)
            activeOfferRestrictions = e
    })
    statusOption.forEach(e => {
        if (e.value === status)
            activeStatus = e
    })
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/offer/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={addOffer}>
            <div className="left">
                <Box title='Coupon Code' required>
                    <Input autoFocus required name="code" value={code} onChange={e => setcode(e.target.value)} />
                </Box>
                <Box title='Discount' required>
                    <Input type="number" required name="discount" value={discount||''} onChange={e => setdiscount(e.target.value)} min={0} max={100} minLength={1} maxLength={3} />
                </Box>
                <Box title='Discount Type' required>
                    <Select
                        value={activeDiscount}
                        defaultValue={activeDiscount}
                        onChange={e => setdiscountType(e.value)}
                        options={discountOptions}
                    />
                </Box>
                <Box title='Offer Applicable' required>
                    <Select
                        value={activeOfferAplicable}
                        defaultValue={activeOfferAplicable}
                        onChange={e => setofferApplicable(e.value)}
                        options={applicableOptions}
                    />
                </Box>
                <Box title='Description'>
                    <textarea className="description" placeholder="Enter coupon description text" required name="content" value={content} onChange={e => setcontent(e.target.value)}></textarea>
                </Box>
                <Box title='Offer Restriction'>
                    <Select
                        value={activeOfferRestrictions}
                        defaultValue={activeOfferRestrictions}
                        onChange={e => setofferRestriction(e.value)}
                        options={offerRestrictionOptions}
                    />
                    {
                        offerRestriction === 'private' &&
                        <>
                            <div className="body overflown">
                                <span className="pointer" onClick={() => setofferRestrictionFor('user')}>
                                    {
                                        offerRestrictionFor === 'user' ?
                                        <i className="icon-check orange hi-check-circle"></i>:
                                        <i className="hi-circle icon-check"></i> 
                                    }
                                    For User </span>
                            </div>
                            <div className="body overflown">
                                <span className="pointer" onClick={() => setofferRestrictionFor('society')}>
                                    {
                                        offerRestrictionFor === 'society' ?
                                        <i className="icon-check orange hi-check-circle"></i>:
                                        <i className="hi-circle icon-check"></i> 
                                    }
                                    For Society </span>
                            </div>
                            {
                                offerRestrictionFor === 'user' ?
                                <Select
                                    placeholder="Select Users"
                                    value={offerForUser}
                                    defaultValue={offerForUser}
                                    onChange={selectUsersToOffers}
                                    options={userRestrictionOptions}
                                    isMulti
                                />:
                                offerRestrictionFor === 'society' &&
                                <Select
                                    placeholder="Select Societies"
                                    value={offerForSociety}
                                    defaultValue={offerForSociety}
                                    onChange={selectSocietiesToOffer}
                                    options={societyRestrictionOptions}
                                    isMulti
                                />
                            }
                        </>
                    }
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn btn-primary has-icon" type="submit" code="Click here to save">Save</Button>
                </Box>
                <Box title='Expiry Date' required>
                    <DatePicker
                        onChange={expiryDate => setexpiryDate(expiryDate)}
                        selected={expiryDate}
                    />
                </Box>
                <Box title='Status'>
                    <Select
                        value={activeStatus}
                        defaultValue={activeStatus}
                        onChange={e => setstatus(e.value)}
                        options={statusOption}
                    />
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['offer']} />
            </div>
        </form>
    </div>
}
