import { PageRouteProps } from "../../types";

export const eventRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/trash/event/list",
        "module": ["Event"],
        "component": "EventsList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/event/list",
        "module": ["Event"],
        "component": "EventsList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/event/add",
        "module": ["Event"],
        "component": "AddEvent",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/event/edit",
        "module": ["Event"],
        "component": "AddEvent",
        "type": "admin",
        "mode": "dynamic"
    }
]