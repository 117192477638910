import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useSendMail = () => {
    const {setTitle} = useService('Meta')
    const {
        sendMail: sendEmail,
        showAlert,
    } = useService('Misc')
    const [toEmail, settoEmail] = useState<string>('')
    const [subject, setsubject] = useState<string>('')
    const [mailContent, setmailContent] = useState<string>('')
    const [selectAll, setselectAll] = useState<boolean>(false)

    setTitle('Send Mail', 'admin')

    const sendMail = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = ''
        if (toEmail === '' && !selectAll) {
            errorMsg = 'Please enter receiver\'s email!'
            error = true
        } else if (subject === '') {
            errorMsg = 'Please enter the email subject'
            error = true
        } else if (mailContent === '') {
            errorMsg = 'Email content cannot be blank'
            error = true
        }
        if (!error) {
            const result = await sendEmail(toEmail, subject, mailContent, selectAll)
            if (result.status) {
                settoEmail('')
                setsubject('')
                setmailContent('')
                showAlert({ type: 'success', msg: 'Email is sent successfully!' })
            } else showAlert({ type: 'error', msg: 'Unable to send email!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        sendMail,
        setselectAll,
        selectAll,
        toEmail,
        settoEmail,
        subject,
        setsubject,
        mailContent,
        setmailContent,
    }
}