import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddOffer = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const statusOption = [
        { value: 'expired', label: 'Expired' },
        { value: 'publish', label: 'Publish' }
    ]
    const offerRestrictionOptions = [
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Private' }
    ]
    const discountOptions = [
        { value: 'percent', label: 'Percentage' },
        { value: 'fixedprice', label: 'Fixed Price' }
    ]
    const applicableOptions = [
        { value: 'service', label: 'Service' },
        { value: 'product', label: 'Product' },
        { value: 'both', label: 'All' }
    ]
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'offer')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {getSocities} = useService('Location')
    const {generateUID,showAlert, isEmpty} = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeDiscountIndex, setactiveDiscountIndex] = useState<number>(0)
    const [activeOfferSocietyIndex, setactiveOfferSocietyIndex] = useState<number>(0)
    const [activeOfferUserIndex, setactiveOfferUserIndex] = useState<number>(0)
    const [discount, setdiscount] = useState<number>(0)
    const [discountType, setdiscountType] = useState<string>('')
    const [offerApplicable, setofferApplicable] = useState<string>('')
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [code, setcode] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [expiryDate, setexpiryDate] = useState(new Date())
    const [offerRestriction, setofferRestriction] = useState(offerRestrictionOptions[0].value)
    const [status, setstatus] = useState(null)
    const [userRestrictionOptions, setuserRestrictionOptions] = useState([])
    const [societyRestrictionOptions, setsocietyRestrictionOptions] = useState([])
    const [offerRestrictionFor, setofferRestrictionFor] = useState<string>('')
    const [offerForSociety, setofferForSociety] = useState(null)
    const [offerForUser, setofferForUser] = useState(null)
    
    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                setuserRestrictionOptions(tableData)
            }
        }
    }
    
    const getSocietiesListData = async () => {
        let tableDataSociety = []
        const result = await getSocities()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableDataSociety.push({label: result.title, value: result.uid})
                })
                setsocietyRestrictionOptions(tableDataSociety)
            }
        }
    }
    
    const getOfferData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const offerData = result.data[0]
            setactiveStatusIndex(statusOption.findIndex(e => e.value === offerData.status))
            setactiveDiscountIndex(discountOptions.findIndex(e => e.value === offerData.discountOptions))
            setactiveOfferUserIndex(userRestrictionOptions.findIndex(e => e.value === offerData.userRestrictionOptions))
            setactiveOfferSocietyIndex(societyRestrictionOptions.findIndex(e => e.value === offerData.societyRestrictionOptions))
            setcode(offerData.code)
            setmode('edit')
            setdiscount(offerData.discount)
            setdiscountType(offerData.discountType)
            setofferApplicable(offerData.offerApplicable)
            setcontent(offerData.content)
            setexpiryDate(new Date(offerData.expiryDate))
            setstatus(offerData.status)
            setofferRestrictionFor(offerData.offerRestrictionFor?offerData.offerRestrictionFor: '')
            setuid(offerData.uid)
            setimage(offerData.image)
            setofferRestriction(offerData.offerRestriction)
            setuserRestrictionOptions(offerData.userRestrictionOptions)
            setsocietyRestrictionOptions(offerData.societyRestrictionOptions)
            setofferForSociety(offerData.offerForSociety && isValidJson(offerData.offerForSociety)?JSON.parse(offerData.offerForSociety): null)
            setofferForUser(offerData.offerForUser && isValidJson(offerData.offerForUser) ?JSON.parse(offerData.offerForUser): null)
        } else showAlert({ type: 'error', msg: 'No offer added yet!' })
    }
    
    const addOffer = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        offerObject = {
            code,
            content,
            discount,
            discountType,
            offerApplicable,
            expiryDate,
            status,
            image,
            offerRestriction,
            offerRestrictionFor,
            offerForSociety: JSON.stringify(offerForSociety),
            offerForUser: JSON.stringify(offerForUser),
            uid: mode === 'add' ? generateUID() : uid,
        }
        if (code === '') {
            errorMsg = 'Please enter the code'
            error = true
        } else if (isEmpty(discount)) {
            errorMsg = 'Please enter valid discount'
            error = true
        } else if (discountType === '') {
            errorMsg = 'Please select valid discount Type'
            error = true
        } else if (offerApplicable === '') {
            errorMsg = 'Please select applicable discount'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the offer description'
            error = true
        } else if (status === '') {
            errorMsg = 'Please select the status'
            error = true
        } else if (isEmpty(expiryDate)) {
            errorMsg = 'Please select the expiry Date'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(offerObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Offer added successfully!' })
                    navigateTo({route: '/admin/offer/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add offer!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    offerData: JSON.stringify(offerObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Offer updated successfully!' })
                    navigateTo({route: '/admin/offer/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update offer!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    setTitle('Add Offer', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getOfferData({uid: urlData[4]})
        }
        getUsersData()
        getSocietiesListData()
    }, [])

    const selectUsersToOffers = (e) => {
        setofferForUser(e)
        setofferForSociety(null)
    }

    const selectSocietiesToOffer = (e) => {
        setofferForSociety(e)
        setofferForUser(null)
    }
    return {
        mode,
        applicableOptions,
        activeStatusIndex,
        discountOptions,
        activeDiscountIndex,
        offerRestrictionOptions,
        setimage,
        image,
        statusOption,
        setstatus,
        expiryDate,
        setexpiryDate,
        offerForSociety,
        offerRestrictionFor,
        discountType,
        offerApplicable,
        offerRestriction,
        status,
        addOffer,
        code,
        setcode,
        discount,
        setdiscount,
        setdiscountType,
        setofferApplicable,
        content,
        setcontent,
        setofferRestriction,
        setofferRestrictionFor,
        offerForUser,
        selectUsersToOffers,
        userRestrictionOptions,
        selectSocietiesToOffer,
        societyRestrictionOptions,
    }
}