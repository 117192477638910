import { useEffect, useRef, useState } from "react"
import { useService } from "../../../../hooks"
// import io from "socket.io-client";

export const useChat = () => {
    const {
        getLoggedInUserData,
        getUsers,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        save,
        fetchByAttr,
    } = useService('Data', 'msg')
    const {generateUID} = useService('Misc')
    // const socket = io.connect("https://chat.programmerindia.org");
    const sender = getLoggedInUserData()
    const chatContentRef = useRef()
    const tabs = [
        {icon: 'hi-user1', activeIcon: 'people', label: 'Users', value: 'contacts'},
        {icon: 'hi-chat', activeIcon: 'chatbubbles', label: 'Chat', value: 'chat'},
        {icon: 'hi-fire', activeIcon: 'footsteps', label: 'What\'s Hot', value: 'steps'},
        {icon: 'hi-bell1', activeIcon: 'notification', label: 'Notifications', value: 'notification'}
    ]
    const [rightClass, setrightClass] = useState('right')
    const [chatPanelClass, setchatPanelClass] = useState<string>('')
    const [activeRoom, setactiveRoom] = useState<string>('')
    const [showContactFilter, setshowContactFilter] = useState<boolean>(false)
    const [showSmileyBox, setshowSmileyBox] = useState<boolean>(false)
    const [showMsgFilter, setshowMsgFilter] = useState<boolean>(false)
    const [activeTab, setactiveTab] = useState(tabs[0].value)
    const [user, setuser] = useState([])
    const [msg, setmsg] = useState([])
    const [activeMsg, setactiveMsg] = useState<string>('')
    const [receiver, setreceiver] = useState(null)
    const [msgfilter, setmsgfilter] = useState<string>('')
    const [contactfilter, setcontactfilter] = useState<string>('')

    setTitle('Chat')
    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if(activeRoom !== '') {
                fetchMessages(activeRoom)
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [activeRoom]);

    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         setmsg((list) => [...list, data]);
    //     })
    // }, [socket]);

    const addMsg = async (e) => {
        e.preventDefault()
        if (activeMsg !== "") {
            const messageData = {
                uid: generateUID(),
                sender: sender.uid,
                receiver: receiver.uid,
                message: activeMsg,
                time: new Date().getTime(),
                room: activeRoom,
                type: 'text'
            };
            let result = await save(messageData)
            // await socket.emit("send_message", messageData);
            setmsg((msg) => [...msg, messageData]);
            setactiveMsg("");
            scrollToBottom()
        }
    }

    const compareByUid = ( a, b ) => {
        if ( Number(a.uid) < Number(b.uid) )
            return -1
        if ( Number(a.uid) > Number(b.uid) )
            return 1
        return 0
    }

    const initiateChat = (receiver) => {
        let chatParties = [ sender, receiver ]
        chatParties.sort( compareByUid )
        let newRoom = chatParties[0].uid+'_'+chatParties[1].uid
        fetchMessages(newRoom)
        setreceiver(receiver)
        setrightClass('right active')
        setactiveRoom(newRoom)
        // socket.emit("join_room", newRoom);
    }

    const fetchUsers = async () => {
        const result = await getUsers()
        if (result.status) setuser(result.data)
    }

    const fetchMessages = async room => {
        const result = await fetchByAttr({ room })
        if (result.status) {
            setmsg(result.data)
        }
        scrollToBottom()
    }

    const filterMsg = item => {
        return item.message.includes(msgfilter) || msgfilter === ''
    }

    const filterContact = item => {
        return item.name.includes(contactfilter) || item.mobile.includes(contactfilter) || contactfilter === ''
    }

    const scrollToBottom = () => {
        const chatBox: any = chatContentRef.current
        if(chatBox) {
            const oneScrollSize = (chatBox.scrollHeight - chatBox.scrollTop)/100
            for (let i = 0; i < 100; i++) {
                setTimeout(() => {
                    chatBox.scrollTop += oneScrollSize
                }, i);
            }
        }
    }

    return {
        tabs,
        activeTab,
        setactiveTab,
        msg,
        sender,
        filterMsg,
        user,
        filterContact,
        initiateChat,
        chatPanelClass,
        setshowContactFilter,
        showContactFilter,
        contactfilter,
        setcontactfilter,
        rightClass,
        receiver,
        setrightClass,
        setreceiver,
        setshowMsgFilter,
        showMsgFilter,
        chatContentRef,
        setmsgfilter,
        msgfilter,
        scrollToBottom,
        setshowSmileyBox,
        showSmileyBox,
        addMsg,
        setactiveMsg,
        activeMsg,
    }
}