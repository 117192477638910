import { useEffect, useState } from "react"
import { config, orderOption } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useMyOrderDetail = () => {
    const Img = useElement('Img')
    const {commonPageSize} = config
    const {fetchByAttr: fetchByAttrFeedback} = useService('Data', 'feedback')
    const {fetchByAttr} = useService('Data', 'serviceWork')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        toIndianCurrency,
        openModal,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {getLoggedInUserData} = useService('User')
    const {
        getOrderInvoice,
        getOrder,
    } = useService('Order')
    const [workData, setworkData] = useState([])
    const [invoiceNo, setinvoiceNo] = useState<string>('')
    const [orderData, setorderData] = useState({})
    const [addr, setaddr] = useState(null)
    const [tableContent, settableContent] = useState({})
    const activeShownTotal = commonPageSize
    const [user, setuser] = useState(getLoggedInUserData())
    const [orderContent, setorderContent] = useState(null)
    const [invoiceData, setinvoiceData] = useState([])
    const [feedbackContent, setfeedbackContent] = useState(null)
    const [serviceWorkAwaitedStatus, setserviceWorkAwaitedStatus] = useState<boolean>(false)
    const [serviceData, setserviceData] = useState(null)
    const [servicework, setservicework] = useState(null)

    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname),
        query = {
            uid: urlData[3]
        }
        setTitle('Order Details #'+urlData[3])
        getInvoice(urlData[3])
        getOrderData(query)
        getOrderFeedback(urlData[3])
    }, [])

    const getInvoice = async orderId => {
        let query = {
            orderId
        }
        const result = await getOrderInvoice(query)
        if (result.status)
            setinvoiceData(result.data)
        else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const getOrderData = async (query) => {
        const result = await getOrder(query)
        if (result.status) {
            let orderContent = result.data[0]
            setorderContent(orderContent)
        }
    }
    
    const getOrderFeedback = async orderId => {
        let query = {
            orderId
        }
        const result = await fetchByAttrFeedback(query)
        if (result.status) {
            if (result.data?.length > 0) {
                let feedbackContent = result.data[0]
                setfeedbackContent(feedbackContent)
            }
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData?.forEach(item => {
            let activeStatus = ''
            if (orderContent.orderNotes) {
                orderContent.orderNotes.forEach(e => {
                    if (e.tempUid === item.tempUid) {
                        orderOption.forEach(el => {
                            if (el.value === e.status) {
                                activeStatus = el.label
                            }
                        })
                    }
                })
            }
            let e = {
                image: <div className="product-thumbnail">
                {
                    item.type === 'servicePackage'?
                    <i className="hi-dropbox service-package-icon"></i>:
                    item.type === 'subscription'?
                    <i className="hi-block service-package-icon"></i>:
                    <Img src={item.image} alt={item.title} />
                }
                </div>,
                title: <>{item.title}
                    {
                        item.type === 'service' &&
                        <p className="active-time-slot"><b>Time Slot:</b> {(item.activeTimeSlot).slot}</p>
                    }
                </>,
                price: <span className="Price-amount amount">{toIndianCurrency(item.price)}</span>,
                purchaseQuantity: <div className="quantity">
                    {item.purchaseQuantity}
                </div>,
                subTotal: <div className="product-subtotal" data-title="Subtotal">
                    {toIndianCurrency((item.taxAmount+item.price) * item.purchaseQuantity)}
                    {
                        item.type === 'service' &&
                        <p className="status-btn" onClick={() => getCompletedWorkData(item.tempUid)}>Status</p>
                    }
                    {
                        activeStatus !== '' &&
                        <p className="status-box"><label>{activeStatus}</label></p>
                    }
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const getCompletedWorkData = async tempUid => {
		let query = {
			tempUid: String(tempUid)
		}
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length>0) {
                setserviceWorkAwaitedStatus(false)
                setservicework(result.data[result.data?.length-1])
            }
            else
                setserviceWorkAwaitedStatus(true)
        }
        openModal("car-service-status-modal")
    }
    
    useEffect(() => {
        let addr = '', orderData = null;
        if (orderContent !== null) {
            setaddr(JSON.parse(orderContent.activeAddress))
            setuser(JSON.parse(orderContent.userData))
            orderData = JSON.parse(orderContent.orderData)
            if (typeof orderData.discount !== "number")
                orderData.discount = Number(orderData.discount)
        }
        if (orderContent !== null) {
            invoiceData.forEach(e => {
                if (e.orderId === orderContent.uid)
                    setinvoiceNo(e.invoiceNo)
            })
        }
        let workData = null
        if (servicework !== null) {
            setserviceData(JSON.parse(servicework.activeCar))
            if (typeof workData !== 'undefined')
                workData =  JSON.parse(servicework.workData)
        }
        if (orderData) {
            settableContent({
                headers: [
                    {
                        template: <div className="product-thumbnail">Preview</div>,
                        key: 'preview'
                    },
                    {
                        template: <div className="product-name">Product</div>,
                        key: 'product'
                    },
                    {
                        template: <div className="product-price">Price</div>,
                        key: 'price'
                    },
                    {
                        template: <div className="product-quantity">Quantity</div>,
                        key: 'quantity'
                    },
                    {
                        template: <div className="product-subtotal">Total</div>,
                        key: 'total'
                    }
                ],
                content: getTableData(orderData.cartData),
                addonBodyContent: <>
                    <tr>
                        <td className="small-col" colSpan={4}>Total</td>
                        <td className="small-col"><b>{toIndianCurrency(orderData?.cartTotal)}</b></td>
                    </tr>
                    {
                        !isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0' ?
                        <>
                            <tr>
                                <td className="small-col" colSpan={4}>Discount</td>
                                <td className="small-col">{toIndianCurrency(orderData.discount)}</td>
                            </tr>
                            <tr>
                                <td className="small-col" colSpan={4}>Net Payable</td>
                                <td className="small-col"><b>{toIndianCurrency(orderData.cartTotal - orderData.discount)}</b></td>
                            </tr>
                        </>:<></>
                    }
                </>
            })
        }
    }, [orderContent])
    return {
        orderContent,
        invoiceNo,
        user,
        addr,
        tableContent,
        activeShownTotal,
        feedbackContent,
        serviceWorkAwaitedStatus,
        servicework,
        serviceData,
        workData,
    }
}