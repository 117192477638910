import {brandInfo} from '../../../constant'
import { useComponent, useElement, useService } from '../../../hooks'
import { useMyPackageDetail } from './hooks'

export const MyPackageDetail = () => {
    const {
        getFormattedDate,
        getDay,
        closeModal,
        getFormattedTime,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const DatePicker = useElement('DatePicker')
    const Box = useComponent('Box')
    const Table = useComponent('Table')
    const PackagesData = useComponent('PackagesData')
    const {
        upgradedData,
        chosenServiceDates,
        chosenPreventiveCheckListDates,
        getCompletedWorkData,
        activeShownTotal,
        packageTitle,
        needDate,
        setactiveServiceDate,
        tomorrow,
        activeServiceDate,
        updatePackage,
        upgraded,
        packageData,
        upgradeActivePackage,
        getPreventiveAnswers,
        preventiveCheckList,
        serviceWorkAwaitedStatus,
        carNotAvailable,
        serviceData,
        workData,
        partialPackage,
        packageWork,
    } = useMyPackageDetail()

    const renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                <Img hasZoom={true} src={item} alt={item} />
            </li>
        })
    }
    
    const renderServiceData = (packageData, todayOnly = false) => {
        let datesCollection = [],
        buildBilling = 30
        if (packageData.billing === 'quarterly') {
            buildBilling = 90
        }
        for (let i = 0; i < buildBilling; i++) {
            let newDate = new Date(packageData.startDate).getTime() + i * 1000 * 60 * 60 * 24,
            e = {
                date: getFormattedDate(newDate),
                day: new Date(newDate).getDay(),
                newUid: upgradedData !== null && upgradedData !== '' ? upgradedData.uid : undefined
            }
            datesCollection.push(e)
        }
        return datesCollection.map(( item, index ) => {
            let found = false, preventiveFound = false
            chosenServiceDates.forEach(e => {
                if (getFormattedDate(new Date(e)) === getFormattedDate(new Date(item.date)))
                found = true
            })
            chosenPreventiveCheckListDates.forEach(e => {
                if (getFormattedDate(new Date(e)) === getFormattedDate(new Date(item.date)))
                preventiveFound = true
            })
            if ((item.day !== 0 && !todayOnly) || (todayOnly && getFormattedDate(new Date()) === getFormattedDate(new Date(item.date)))) {
                return <Box key={index} title={<>{getDay(item.day)} <span className="datePreventive-checklist">({item.date})</span></>}>
                    <ul className="daily-service-data">
                        { preventiveFound && <li><span onClick={() => getPreventiveAnswers(item.date)} className="link">Preventive Checklist</span> {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        { found && <li>Car Wash {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        <li>Car Cleaning (Waterless) {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 22].includes(index) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (index === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 22].includes(index) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && packageData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                        <li>Air Pressure {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 3].includes(item.day) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (item.day === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 3].includes(item.day) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && packageData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                    </ul>
                    {!todayOnly && <p className="view-service-status" onClick={() => getCompletedWorkData(item.date)}>Status</p>}
                </Box>
            } else return true
        })
    }

    const renderPreventiveQueries = statusFiles => {
        return statusFiles.map(( item, index ) => {
            return <tr key={index} className="query-list-status">
                <th>{item.type}</th>
                <td className="query-list-icon-Box">
                    {
                        item.answers[0] === 'Perfect' ?
                        <i className="success hi-check_circle"></i>:
                        item.answers[0] === 'Not Available' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Need attention' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Available' &&
                        <i className="success hi-check_circle"></i>
                    }
                    <span className="ml10">{item.answers[0]}</span>
                </td>
            </tr>
        })
    }

    const renderPriventiveCheckList = preventiveQueries => {
        return preventiveQueries.map(( item, index ) => {
            return <div key={index}>
                <h3>{index+1}. {item.serviceTitle}</h3>
                { renderPreventiveQueriesHead(item.serviceQueries || [] )}
            </div>
        })
    }
    
    const renderPreventiveQueriesHead = queriesFiles => {
        return queriesFiles.map(( item, index ) => {
            let tableContent = {
                headers: [],
                addonBodyContent: renderPreventiveQueries(item.status || [] )
            }
            return <div key={index}>
                <p>{index+1}. {item.query}</p>
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
        })
    }
    return <>
        {
            packageData !== null &&
            <div id="content-box" className="content">
                <h1 className="flex">
                    <span>
                        <BackIcon backLink='/package/list' />
                        {packageTitle}</span>
                </h1>
                <div className="columns">
                    <div className="left">
                        { renderServiceData(packageData) }
                    </div>
                    <div className="right">
                        {
                            needDate &&
                            <Box title='Choose Car Wash Date' bodyClass="select-date-mypackages">
                                {/* <div className="body select-date-mypackages"> */}
                                    <DatePicker
                                        onChange={activeServiceDate => setactiveServiceDate(activeServiceDate)}
                                        minDate={tomorrow}
                                        startDate={tomorrow}
                                        value={activeServiceDate}
                                        maxDate={new Date(packageData.endDate)}
                                        selected={activeServiceDate}
                                    />
                                    <Button className="btn btn-primary tac" onClick={updatePackage}>Submit</Button>
                                {/* <div className="right">
                                    {
                                        needDate &&
                                        <Box title="Choose Car Wash Date" bodyClass="select-date-mypackages">
                                            <DatePicker
                                                onChange={activeServiceDate => setactiveServiceDate(activeServiceDate)}
                                                minDate={tomorrow}
                                                startDate={tomorrow}
                                                value={activeServiceDate}
                                                maxDate={new Date(packageData.endDate)}
                                                selected={activeServiceDate}
                                            />
                                        </Box>
                                    } */}
                                    {
                                        packageData !== null && !upgraded && packageData.uid !== "PACKAGE__003" && new Date(packageData.endDate).getTime() > new Date().getTime() &&
                                        <Box title="Upgrade Package" bodyClass="select-date-mypackages">
                                            <Button className="btn btn-primary tac" onClick={() => upgradeActivePackage(packageData.uid)}>Upgrade Package</Button>
                                        </Box>
                                    }
                                {/* </div> */}
                            </Box>
                        }
                        <Box title="Preventive Checklist">
                            <span onClick={() => getPreventiveAnswers(new Date().getTime(), 'view')} className="link">View Preventive Checklist</span>
                        </Box>
                    </div>
                </div>
            </div>
        }
        <div className="modal" id="preventive-checklist-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => closeModal("preventive-checklist-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    { renderPriventiveCheckList(preventiveCheckList || [] )}
                </div>
            </div>
        </div>
        <div className="modal" id="car-service-status-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content content medium">
                <Button className="close" onClick={() => closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    {
                        serviceWorkAwaitedStatus ?
                        <>
                        <div className="no-data-icon">
                            <i className="hi-hourglass_empty"></i>
                        </div>
                        <p className="awited-text">Work is still awaited for the day for this service</p></>: 
                        <div>
                            {
                                packageWork !== null &&
                                <>
                                    {
                                        carNotAvailable?
                                        <>
                                            <div className="no-data-icon">
                                                <i className="hi-directions_car"></i>
                                            </div>
                                            <p className="awited-text">Car Not Available</p>
                                        </>:
                                        <>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Worked Services</label>
                                                <div className="col-9 controls ml20">
                                                    {
                                                        typeof workData[0].workedServices !== 'undefined' && workData[0].workedServices.length !== 0?
                                                        workData[0].workedServices.map((e, index) => {
                                                            return <p key={index}>{e} <i className="checked-service hi-check_circle"></i></p>
                                                        })
                                                        :renderServiceData(packageData, true)
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Service Date</label>
                                                <div className="col-9 controls ml20">
                                                    {getFormattedDate(packageWork.serviceDate)}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Start Time</label>
                                                <div className="col-9 controls ml20">
                                                    {
                                                        workData[0].startTime?
                                                        getFormattedTime(workData[0].startTime) :
                                                        getFormattedTime(workData[0].time)
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">{brandInfo.name.capital} Id</label>
                                                <div className="col-9 controls ml20">
                                                    {packageWork.workerId}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Active Car</label>
                                                <div className="col-9 controls ml20">
                                                <li className="my-packages-assign-car">
                                                    {
                                                        serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                        <Img source='carCDN' src={(JSON.parse(packageWork.activeCar).image_path)} alt={(packageWork.activeCar).name} />
                                                    }
                                                    <div className="ml10">
                                                        <p>{serviceData.name+' '+serviceData?.Segment}</p>
                                                        {
                                                            serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null &&
                                                            <small>{serviceData.registrationNo}</small>
                                                        }
                                                    </div>
                                                </li>
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Comments</label>
                                                <div className="col-9 controls ml20">
                                                    {workData[0].comments}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Images</label>
                                                <div className="col-9 controls ml20">
                                                        <ul className="media-box">
                                                        { renderImages(workData[0].carImages||[]) }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="modal" id="package-upgrade-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("package-upgrade-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    {
                        packageData !== null &&
                        <PackagesData partialPackage={partialPackage} startDate={packageData.startDate} endDate={packageData.endDate} activeCar={packageData.activeCar} billing={packageData.billing} price={packageData.price} />
                    }
                </div>
            </div>
        </div>
    </>
}
