import Select from 'react-select'
import { userRole } from '../../../constant'
import { useAddUser } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddUser = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        title,
        addUser,
        name,
        setname,
        urlData,
        activeRole,
        setrole,
        branchOptions,
        parent,
        setparent,
        companies,
        email,
        setemail,
        gstin,
        setgstin,
        mobile,
        setmobile,
        image,
        setimage,
        role,
        supervisor,
        setsupervisor,
        supervisorOptions,
    } = useAddUser()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/user/list' />
            {title}
        </h1>
        <form className="columns" noValidate onSubmit={addUser}>
            <div className="left">
                <Box title='Name' required>
                    <Input autoFocus required name="name" value={name||''} onChange={e => setname(e.target.value)} />
                </Box>
                {
                    urlData[2] !== 'account' &&
                    <Box title='Role' required>
                        <Select
                            value={activeRole}
                            defaultValue={activeRole}
                            onChange={e => setrole(e.value)}
                            options={userRole}
                        />
                    </Box>
                }
                {['manager'].includes(role) && <Box title='Parent Branch' required>
                    <Select
                        value={parent}
                        defaultValue={parent}
                        onChange={parent => setparent(parent)}
                        options={branchOptions}
                    />
                </Box>}
                {['branch', 'representative', 'builder'].includes(role) && <Box title='Parent Company' required>
                    <Select
                        value={parent}
                        defaultValue={parent}
                        onChange={parent => setparent(parent)}
                        options={companies}
                    />
                </Box>}
                <Box title='Email' required>
                    <Input type="email" required name="email" value={email||''} onChange={e => setemail(e.target.value)} />
                </Box>
                {!['manager', 'representative'].includes(role) && <Box title='GSTIN' required={role==='company'}>
                    <Input required name="gstin" value={gstin||''} onChange={e => setgstin(e.target.value)} />
                </Box>}
                <Box title='Mobile No.' required>
                    <Input type="number" required name="mobile" value={mobile||''} onChange={e => setmobile(e.target.value)} />
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn btn-primary" type="submit" title="Click here to Add user">Submit</Button>
                </Box>
                <MediaBox image={image} setimage={image => setimage(image)} usage={['user']} />
                {role === 'branch' && <Box title='Assign Supervisor'>
                    <Select
                        value={supervisor}
                        defaultValue={supervisor}
                        onChange={e => setsupervisor(e)}
                        options={supervisorOptions}
                    />
                </Box>}
            </div>
        </form>
    </div>
}