import { BlogSideBar, Box, InputBox, CarSelector, ChatBot, FeaturedProduct, RelatedProductSlider, MapWithMarker, TrashConfirmModal, FreeInspection, GuestCarQueryModal, HomeSlider, HomeSliderProduct, KeyFeatures, Modal, OurWorkingProcess, PackagesData, PremiumServices, ProductBox, ProfileSideBar, SecureSection, Share, SubscriptionData, WelcomeTo, UserImage, TopNavbarBanner, TestimonialWeb, Table, ImportResult, MediaBox, FranchiseCTA, MobileApp, PropertyRequirements, StoreLocatorSideBar, DownloadTheApp, RecentPosts, RecentServices, SubscriptionBar, SubscriptionForm, FormSlider, DetailingServices, BookServiceForm, CartSingleItem, TimeSlots, FeaturedServices, ShowServices, StepProvide, PostMeta } from "../component"

const components: any = {
    "BlogSideBar": BlogSideBar,
    "Box": Box,
    "InputBox": InputBox,
    "CarSelector": CarSelector,
    "ChatBot": ChatBot,
    "FeaturedProduct": FeaturedProduct,
    "RelatedProductSlider": RelatedProductSlider,
    "MapWithMarker": MapWithMarker,
    "TrashConfirmModal": TrashConfirmModal,
    "FreeInspection": FreeInspection,
    "GuestCarQueryModal": GuestCarQueryModal,
    "RecentServices": RecentServices,
    "HomeSlider": HomeSlider,
    "HomeSliderProduct": HomeSliderProduct,
    "KeyFeatures": KeyFeatures,
    "RecentPosts": RecentPosts,
    "Modal": Modal,
    "DetailingServices": DetailingServices,
    "OurWorkingProcess": OurWorkingProcess,
    "PackagesData": PackagesData,
    "PremiumServices": PremiumServices,
    "ProductBox": ProductBox,
    "ProfileSideBar": ProfileSideBar,
    "SecureSection": SecureSection,
    "Share": Share,
    "SubscriptionData": SubscriptionData,
    "WelcomeTo": WelcomeTo,
    "UserImage": UserImage,
    "TopNavbarBanner": TopNavbarBanner,
    "TestimonialWeb": TestimonialWeb,
    "Table": Table,
    "ImportResult": ImportResult,
    "MediaBox": MediaBox,
    "FranchiseCTA": FranchiseCTA,
    "MobileApp": MobileApp,
    "PropertyRequirements": PropertyRequirements,
    "StoreLocatorSideBar": StoreLocatorSideBar,
    "DownloadTheApp": DownloadTheApp,
    "SubscriptionBar": SubscriptionBar,
    "SubscriptionForm": SubscriptionForm,
    "FormSlider": FormSlider,
    "BookServiceForm": BookServiceForm,
    "CartSingleItem": CartSingleItem,
    "TimeSlots": TimeSlots,
    "FeaturedServices": FeaturedServices,
    "ShowServices": ShowServices,
    "StepProvide": StepProvide,
    "PostMeta": PostMeta,
}

export const useComponent = (component: string) => {
    return components[component]
}