import {base, openigHours} from '../../constant'
import { useFranchise } from './hooks'
import { useComponent, useElement, useWidget } from '../../hooks'

export const Franchise = () => {
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const Banner = useWidget('Banner')
    const MapWithMarker = useComponent('MapWithMarker')

    const {
        image,
        title,
        companyName,
        city,
        content,
        addressDetail,
        places,
        address,
        email,
        contactPerson,
        mobile,
        socialLinks,
    } = useFranchise()

    const renderPhotoGallery = () => {
        return image.slice(1).map((e, i) => {
            return <Img src={e} key={i} hasZoom={true} />
        })
    }

    const renderOpeningHours = () => {
        return openigHours.map((e, i) => {
            return <li key={i}>
                <p className='day'>{e.label}</p>
                <p className='time'>{e.value}</p>
            </li>
        })
    }

    return <>
        <Banner title={title} image="white-car-banner.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt120 pb20">
                <div className="wrapper">
                        <div className="blog-details-wrap">
                            <div className="blog-details-items">
                                <div className="content bg-none">
                                    <h3 className="title">{companyName}</h3>
                                </div>
                                <div className="thumb mv20">
                                    <Img src={image[0]} alt={title} />
                                </div>
                                <div className='wrapper mh-20'>
                                    <div className="col-8">
                                        <h2>Car Maintenance Repair and Detailing with expert hands in <span className='link'>{city}</span></h2>
                                        <div className="blog-details-inner mv20">
                                            <Html content={content} />
                                        </div>
                                        <h2>We care for Your Car just Like You do !</h2>
                                    </div>
                                    <div className='col-4'>
                                        <div className='widget-area'>
                                            {addressDetail?.address_components && <div className="widget">
                                                <MapWithMarker
                                                    center={places[0]}
                                                    places={places}
                                                />
                                                <div className='mt10'>
                                                    <b>Address:</b> {address}
                                                </div>
                                                <div className='mt10'>
                                                    <i className='hi-user'></i> {contactPerson?.label}
                                                </div>
                                                <div className='mt10'>
                                                    <Link href={`mailto:${email}`}>
                                                        <i className='hi-email'></i> {email}
                                                    </Link>
                                                </div>
                                                <div className='mt10'>
                                                    <Link href={`tel:${mobile}`}>
                                                        <i className='hi-call'></i> {mobile}
                                                    </Link>
                                                </div>
                                            </div>}
                                            {socialLinks?.googleMap && <Link target='_blank' className='mb20 btn-primary uppercase' href={socialLinks.googleMap} title='Get Directions'>Get Directions</Link>}
                                            <div className='widget'>
                                                <h5 className="widget-title border-bottom">Photo Gallery</h5>
                                                <div className='photo-gallery'>
                                                    {renderPhotoGallery()}
                                                </div>
                                            </div>
                                            <div className='widget'>
                                                <h5 className="widget-title border-bottom">Opening Hours</h5>
                                                <ul className='opening-hours'>
                                                    {renderOpeningHours()}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </>
}
