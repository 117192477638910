import { CarService, DataService, MetaService, OrderService, ProductService, RouterService, LocationService, UserService, ValidationService, ServiceService, MicroServiceService, DashboardWidgetService, MiscService } from '../service'

const services: any = {
    'Car': CarService,
    'Data': DataService,
    'Meta': MetaService,
    'Order': OrderService,
    'Product': ProductService,
    'Router': RouterService,
    'Location': LocationService,
    'User': UserService,
    'Validation': ValidationService,
    'Service': ServiceService,
    'MicroService': MicroServiceService,
    'DashboardWidget': DashboardWidgetService,
    'Misc': MiscService,
}

export const useService = (service: string, model?: string) => {
    return new services[service](model)
}