import { PageRouteProps } from "../../types";

export const badgeRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/badge/list",
        "module": ["Ecommerce"],
        "component": "BadgeList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/badge/add",
        "module": ["Ecommerce"],
        "component": "AddBadge",
        "type": "admin",
        "mode": "static"
    }
]