import { memo } from 'react';
import { actor } from '../../constant';
interface ActorProps {
    type: string
    title?: string
    onClick?: () => void
}
export const Actor = memo(({type, title, onClick}: ActorProps) => {
    return <span className="action" onClick={onClick} title={ title ?? actor[type].label}>
        <i className={actor[type].icon}></i>
    </span>
})