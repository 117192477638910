import { useEffect, useState } from "react"

export const useProfileSideBar = () => {
    const [temporaryUser, settemporaryUser] = useState<boolean>(false)

    useEffect(() => {
        if (localStorage.getItem('tempLogin') === 'true') {
            settemporaryUser(true)
        }
    }, [])
    return {
        temporaryUser
    }
}