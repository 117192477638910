import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddApp = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {setTitle} = useService('Meta')
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'app')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getLoggedInUserData,
        getUser,
    } = useService('User')
    const {
        generateRandomString,
        generateUID,
        showAlert,
    } = useService('Misc')
    const user = getLoggedInUserData()
    const [activeBuilder, setactiveBuilder] = useState(null)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [uid, setuid] = useState(new Date().getTime())
    const [id, setid] = useState(generateRandomString(8 + Math.ceil(Math.random() * 8)))
    const [secret, setsecret] = useState(generateRandomString(24 + Math.ceil(Math.random() * 8)))
    const [allowedApis, setallowedApis] = useState([])
    const [builders, setbuilders] = useState([])
    const [status, setstatus] = useState('active')
    const [title, settitle] = useState<string>('')
    const [brandKey, setbrandKey] = useState<string>('')
    const [description, setdescription] = useState<string>('')
    let [author, setauthor] = useState(["admin", "developer"].includes(user.role) ? '' : user.uid)

    const getAppData = async (query) => {
        const result = await fetchByAttr(query)
        if(result.status && result.data?.length > 0) {
            let app = result.data[0]
            setid(app.id)
            setuid(app.uid)
            setsecret(app.secret)
            setallowedApis(app.allowedApis)
            setstatus(app.status)
            setauthor(app.author)
            settitle(app.title)
            setbrandKey(app.brandKey)
            setdescription(app.description)
            if (["admin", "developer"].includes(user.role)) {
                getBuilders(app.author)
            }
        }
    }
    
    const getBuilders = async (author = false) => {
        const result = await getUser({role: 'builder'})
        let c = []
        if (result.status) {
            result.data.forEach(e => {
                let el = {
                    label: e.name,
                    value: e.uid
                }
                c.push(el)
                if (author) {
                    if (author === e.uid) {
                        setactiveBuilder(el)
                    }
                }
            })
            setbuilders(c)
        }
    }
    
    const addApp = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = ''
        if (activeBuilder !== null) {
            author = activeBuilder.value
        }
        let appObject = {
            title,
            brandKey,
            id,
            secret,
            description,
            author,
            status,
            allowedApis: JSON.stringify(allowedApis),
            uid: mode === 'add' ? generateUID() : uid,
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (brandKey === '') {
            errorMsg = 'Please select the Brand Key'
            error = true
        } else if (author === '') {
            errorMsg = 'Please select the author'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(appObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'app added successfully!' })
                    navigateTo({route: '/admin/app/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add app!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    appData: JSON.stringify(appObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'app updated successfully!' })
                    navigateTo({route: '/admin/app/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update app!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const assignBuilder = (e) => {
        setauthor(e.value)
        setactiveBuilder(e)
    }
    
    const toggleStatus = () => {
        setstatus(status === 'active' ? 'inactive' : 'active')
    }

    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname), mode = 'add'
        if (urlData[3] === 'edit') {
            setmode('edit')
            getAppData({
                uid: urlData[4]
            })
        } else {
            if (["admin", "developer"].includes(user.role)) {
                getBuilders()
            }
        }
        setTitle(mode + ' App', 'admin')
    }, [])
    return {
        builders,
        mode,
        addApp,
        title,
        settitle,
        description,
        setdescription,
        id,
        setid,
        secret,
        setsecret,
        toggleStatus,
        user,
        status,
        brandKey,
        setbrandKey,
        activeBuilder,
        assignBuilder,
    }
}