import { useState } from "react"
import { useService } from "../../../../hooks"

export const useSubscriptionForm = () => {
    const [value, setvalue] = useState<string>('')
    const {isValidEmail} = useService('Validation')
    const {save} = useService('Data', 'franchise')
    const { parseValue, getFranchiseRequest, generateUID, showAlert } = useService('Misc')
    const subscribeForFranchise = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        franchiseObject = {
            name: parseValue(),
            pinCode: parseValue(),
            email: parseValue(value),
            mobile: parseValue(),
            message: parseValue(),
            city: parseValue(),
            uid: generateUID(),
            status: 'raised'
        }
        if (value === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        } else if (!isValidEmail(value)) {
            errorMsg = 'Please enter a valid Email Address'
            error = true
        }
        if (!error) {
            await save(franchiseObject)
            const result = await getFranchiseRequest(franchiseObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'Your franchise request has been submitted. We\'ll connect you back in 24 hours.' })
                setvalue('')
            } else showAlert({ type: 'error', msg: 'Unable to send franchise request.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        value,
        setvalue,
        subscribeForFranchise,
    }
}