import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useBlog = () => {
    const {fetchByAttr} = useService('Data', 'post')
    const commonPageSize = 5
    const {setTitle} = useService('Meta')
    const {isBottom} = useService('Misc')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const [postList, setpostList] = useState([])
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [filteredList, setfilteredList] = useState([])

    setTitle('Blog')
    useEffect(() => {
        getPostsData()
        document.addEventListener('scroll', trackScrolling)
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    } ,[])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getPostsData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            let postData = result.data.reverse()
            setpostList(postData)
            setfilteredList(postData)
        }
    }
    return {
        activeShownTotal,
        postList,
    }
}