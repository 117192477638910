import { memo, ReactNode } from 'react'
import {base} from '../../../constant'
import { useElement } from '../../../hooks'
import './style.scss'

interface BannerProps {
    buttonAlign: 'left' | 'right' | 'center'
    image?: string
    title?: string
    align: 'left' | 'right' | 'center'
    cta?: {
        onClick?: () => void,
        label: string
    }
    customRender?: string | ReactNode
}

export const Banner = memo(({buttonAlign ,image = 'header-back-car.jpg', title, align = 'left', cta, customRender}: BannerProps) => {
    const Button = useElement('Button')
    let buttonStyle = {
        ...align === 'left' ? {marginRight: 'auto'} : {marginLeft: 'auto'}
    }
    if (buttonAlign === 'center') {
        buttonStyle = {marginRight: 'auto', marginLeft: 'auto'}
    }
    return <div className={"blog-banner sub-header-banner "+ align} style={{backgroundImage: 'url("'+base.imageBaseUrl+image+'")'}}>
        <div className={cta ? 'sub-banner small' : "sub-banner"}>
            <div className="sub-header-content jumbo-container">
                <div className='banner-content'>
                    {title ? <h1 className="text-custom-white" style={{textAlign: align}}>{title}</h1>: ''}
                    {cta && <Button style={buttonStyle} className="btn btn-big btn-primary mt20" onClick={cta.onClick}>{cta.label}</Button>}
                </div>
                {customRender}
            </div>
        </div>
    </div>
})