import { useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"

export const useMySubscription = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getFormattedDate,
        toIndianCurrency,
        openModal,
    } = useService('Misc')
    const {getUserOrders} = useService('Order')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const activeShownTotal = commonPageSize
    const [tableData, settableData] = useState(null)
    
    setTitle('My Subscription')
    useEffect(() => {
        getSubscriptionData()
    }, [])

    const getSubscriptionData = async () => {
        let query = {
            uid: getLoggedInUserData().uid
        }
        const result= await getUserOrders(query)
        if (result.status) {
            if (result.data?.length === 0)
                settableData([])
            else
                settableData(result.data.reverse())
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                order: `#${item.uid}`,
                name: JSON.parse(item.userData).name,
                date: getFormattedDate(item.timing),
                subscription: item.subscriptionData,
                status: <Badge item={item.status} />,
                total: toIndianCurrency(JSON.parse(item.orderData).cartTotal),
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => openModal("get-subscription-modal")} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    useEffect(() => {

        settableContent({
            headers: [
                {
                    label: 'Order',
                    key: 'order'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Subscription',
                    key: 'subscription'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            total: tableData.length,
            content: getTableData(tableData)
        })
    }, [tableData])
    return {
        tableContent,
        activeShownTotal,
    }
}