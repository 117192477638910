import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useMyPackages = () => {
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {getFormattedDate} = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {getUserOrders} = useService('Order')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const activeShownTotal = commonPageSize
    const [tableData, settableData] = useState([])
    const [activePackage, setactivePackage] = useState<string>('')

    setTitle('My Packages List')
    useEffect(() => {
        getPackagesData()
    }, [])

    const getPackagesData = async () => {
        let query = {
            uid: getLoggedInUserData().uid
        }
        const result = await getUserOrders(query)
        if (result.status) {
            if (result.data?.length !== 0) {
                let tableData = []
                result.data.reverse().forEach((item, index) => {
                    let cartData = JSON.parse(item.orderData).cartData, unpgradedPackages = [],
                    found = false, packagesData = []
                    if (item.status === 'paid' || item.status === 'processing' || item.status === 'shipped' || item.status === 'delivered' || item.status === 'completed') {
                        cartData.forEach(e => {
                            if (e.type === 'servicePackage') {
                                found = true
                                e.orderId = item.uid
                                if (e.previousPackageId)
                                    unpgradedPackages.push(e)
                                else
                                    packagesData.push(e)
                            }
                        })
                        packagesData.forEach(el => {
                            unpgradedPackages.forEach(e => {
                                if (e.previousPackageId === String(el.tempUid))
                                    el.title = e.title
                            })
                            if (found)
                                tableData.push(el)
                        })
                    }
                })
                settableData(tableData)
            }
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            if (item.status !== "initialised" && typeof item.previousOrderId === "undefined" && typeof item.previousPackageId === "undefined") {
                let el = {
                    title: <span className="package-name">{item.title}</span>,
                    startDate: getFormattedDate(new Date(item.startDate)),
                    endDate: getFormattedDate(new Date(item.endDate)),
                    car: <li className="my-packages-assign-car">
                        <Img source='carCDN' src={item.activeCar.image_path} alt={item.activeCar.name+' '+item.activeCar?.Segment} />
                        <div className="ml10">
                            <p>{item.activeCar.name+' '+item.activeCar?.Segment}</p>
                            {
                                item.activeCar.registrationNo && item.activeCar.registrationNo !== '' && item.activeCar.registrationNo !== null &&
                                <small>{item.activeCar.registrationNo}</small>
                            }
                        </div>
                    </li>,
                    action: <div className="action-bar">
                        <Actor type='view' onClick={() => navigateTo({route: '/package/view/' + btoa(item.orderId + '/' + item.tempUid)})} />
                    </div>
                }
                data.push(el)
            }
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Start Date',
                    key: 'startDate'
                },
                {
                    label: 'End Date',
                    key: 'endDate'
                },
                {
                    label: 'Assign Car',
                    key: 'assignCar'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(tableData)
        })
    }, [tableData])
    return {
        tableContent,
        activeShownTotal,
        activePackage,
    }
}