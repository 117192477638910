import { PageRouteProps } from "../../types";

export const offerRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/offer/add",
        "module": ["Ecommerce"],
        "component": "AddOffer",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/offer/list",
        "module": ["Ecommerce"],
        "component": "OfferList",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/offer/edit",
        "module": ["Ecommerce"],
        "component": "AddOffer",
        "type": "admin",
        "mode": "dynamic",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/trash/offer/list",
        "module": ["Ecommerce"],
        "component": "OfferList",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    }
]