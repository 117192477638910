import { useEffect, useState } from "react"
import {activeDateFilter as ADF} from '../../../../constant'
import { useService } from "../../../../hooks"
export const useManageAddress = () => {
    const {
        getLoggedInUserData,
        getCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getLocations,
        deleteLocation,
    } = useService('Location')
    const {showAlert} = useService('Misc')
    const user = getLoggedInUserData()
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[0])
    const [activeBranches, setactiveBranches] = useState([])
    const [branches, setbranches] = useState([])
    const [addressList, setaddressList] = useState([])

    setTitle('Manage Address')
    useEffect(() => {
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getBranches(orgId)
        } else {
            getLocationsData()
        }
    }, [])

    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getLocationsData(activeBranchIds)
    }
    
    const getBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await getCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getLocationsData = async (branches?: string[]) => {
        let query = {
            ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
        }
        if (branches) {
            query.ownerId = {
                $in: branches
            }
        }
        const result = await getLocations(query)
        if (result.status)
            setaddressList(result.data)
    }
    
    const trashLocation = async locationData => {
        let query = {
            uid: locationData.uid
        }
        const result = await deleteLocation(query)
        if (result.status) {
            getLocationsData()
            showAlert({ type: 'success', msg: 'Location deleted successfully!' })
        } else showAlert({ type: 'error', msg: 'Unable to delete location!' })
    }
    
    const selectCompany = (e) => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }
    return {
        trashLocation,
        user,
        activeBranches,
        selectCompany,
        branches,
        addressList,
    }
}