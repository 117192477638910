import { useElement, useService } from '../../../hooks'
import { useAddAddress } from './hooks'

export const AddAddress = () => {
    const {isModuleEnabled} = useService('Misc')
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const {
        addLocation,
        mode,
        setshowSocietyListBox,
        fullAddress,
        showSocietyListBox,
        renderSocietyList,
        societyData,
        activeSociety,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setlocality,
        pinCode,
        setpinCode,
        city,
        setcity,
        state,
        setstate,
        landmark,
        setlandmark,
        alternateNo,
        setalternateNo,
    } = useAddAddress()

    return <div className="right-side-profile-content">
        <div className="profile-sec wrapper">
            <div className="form-editable-section">
                <div className="form-box">
                    <div className="account-detail">
                        <form className="col-9" noValidate onSubmit={addLocation}>
                            <h1>
                                <BackIcon backLink='/address/list' />
                                {mode} Address
                            </h1>
                            {
                                isModuleEnabled(["Society"]) && fromSociety && mode === 'add' ?
                                <div className="relative">
                                    <div className="form-group">
                                        <label className="col-3 control-label">Select your society</label>
                                        <div className="col-9 controls relative">
                                            {
                                                activeSociety === null ?
                                                <Button className="btn btn-primary" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>Show Societies List</Button>
                                                :<>
                                                    <div className="selected-society-type" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>
                                                        <p>{activeSociety.title}</p>
                                                    </div>
                                                    <small className="ashgray">({ fullAddress })</small>
                                                </>
                                            }
                                            {
                                                showSocietyListBox &&
                                                <ul className="scroll-box mt-10">
                                                    { renderSocietyList(societyData || []) }
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                    {
                                        activeSociety !== null &&
                                        <div className="filled-society-data">
                                            <div className="form-group">
                                                <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                                <div className="col-9 controls">
                                                    <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Locality (Street/Area)" name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="City/District/Town" disabled name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="col-3 control-label">State <sup>*</sup></label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="State" disabled name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-group">
                                                <label className="col-3 control-label">Alternate Phone No. </label>
                                                <div className="col-9 controls">
                                                    <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="link mt20" onClick={() => setfromSociety(!fromSociety)}>Not living in above listed society? Click Here</div>
                                </div>:
                                <>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                        <div className="col-9 controls">
                                            <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="Locality (Street/Area)" required name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="City/District/Town" required name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">State <sup>*</sup></label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="State" required name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-group">
                                        <label className="col-3 control-label">Landmark </label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="Landmark" required name="landmark" value={landmark||''} onChange={e => setlandmark(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label className="col-3 control-label">Alternate Phone No. </label>
                                        <div className="col-9 controls">
                                            <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                        </div>
                                    </div>
                                </>
                            }
                            <Button className="btn btn-primary btn-small addlocationbtn" type="submit">Submit</Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
