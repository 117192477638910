import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useChatBot = () => {
    const {
        fetchAll,
        save,
    } = useService('Data', 'chat')
    const {
        operateMessage,
        generateUID,
        showAlert,
    } = useService('Misc')
    const [chatData, setchatData] = useState([])
    const [genericMessages, setgenericMessages] = useState([])
    const [queries, setqueries] = useState([])
    const [activeQuery, setactiveQuery] = useState<string>('')

    const chatbotToggle = () => {
        document.getElementById("chatbot").classList.toggle('is-active')
    }
    
    const scrolltoBottom = () => {
        let chatBox = document.getElementsByClassName("chatbot-wrapper")[0]
        setTimeout(() => {
            chatBox.scrollTop = chatBox.scrollHeight
        }, 1000)
    }

    const getChatsData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length !== 0) {
                let chatList = [],
                queries = []
                result.data.forEach(e => {
                    if (e.type === 'generic')
                        chatList.push(e)
                    else if (e.type === 'queries')
                        queries.push(e)
                })
                setgenericMessages(chatList)
                setqueries(queries)
            }
        } else
            addMsg({ msg: 'Unable to connect to server! Please try again after some time.', msgType: 'bot' })
    }
    
    const ansQuery = q => {
        let msgFound
        genericMessages.forEach(e => {
            if (operateMessage(q) === operateMessage(e.question)) {
                if (e.answer !== '') {
                    addMsg({
                        msg: e.answer,
                        msgType: 'bot' })
                }
                msgFound = true
            }
        })
        if (!msgFound) {
            queries.forEach(e => {
                if (operateMessage(q) === operateMessage(e.question)) {
                    addMsg({ msg: e.answer, msgType: 'bot' })
                    msgFound = true
                }
            })
        }
        if (!msgFound)
            saveQuery(q)
    }
    
    const addMsg = msg => {
        chatData.push(msg)
        setchatData(chatData)
        scrolltoBottom()
    }
    
    const saveQuery = async q => {
        let chatObject = {
            question: q,
            answer: '',
            type: 'queries',
            uid: generateUID(),
        },
        error = false
        if (q === '') {
            error = true
        }
        if (!error) {
            const result = await save(chatObject)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Your query has been submitted. Will make to sure to answer the same next time!' })
                addMsg({ msg: 'Your query has been submitted. Will make to sure to answer the same next time!', msgType: 'bot' })
                getChatsData()
            }
        }
    }
    
    const askQuery = (e) => {
        e.preventDefault()
        addMsg({
            msg: activeQuery,
            msgType: 'mine'
        })
        ansQuery(activeQuery)
        setchatData(chatData)
        setactiveQuery('')
    }

    const inputQuery = e => setactiveQuery(e.target.value)

    useEffect(() => {
        getChatsData()
    }, [])

    return {
        inputQuery,
        chatbotToggle,
        chatData,
        askQuery,
        activeQuery,
    }
}