import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useBusiness = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'post')
    const {setTitle} = useService('Meta')
    const {isEmpty} = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [excerpt, setexcerpt] = useState<string>('')
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState([])
    const [tag, settag] = useState(null)

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getBusinessData({slug: urlData[2]})
    }, [slug, urlData])

    const getBusinessData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'publish')
                navigateTo({route: '/error404'})
            else {
                const businessData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === businessData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === businessData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === businessData.status))
                settag(isEmpty(businessData.tag) ? [] : JSON.parse(businessData.tag))
                setcategory(isEmpty(businessData.category) ? [] : JSON.parse(businessData.category))
                setmetaKeyword(isEmpty(businessData.metaKeyword) ? [] : JSON.parse(businessData.metaKeyword))
                setTitle(businessData.title)
                settitle(businessData.title)
                setslug(businessData.slug)
                setcontent(businessData.content)
                setexcerpt(businessData.excerpt)
                setcss(businessData.css)
                setjs(businessData.js)
                setcanonical(businessData.canonical)
                setrobots(businessData.robots)
                setmetaDescription(businessData.metaDescription)
                setauthor(businessData.author)
                setpublishDate(new Date(businessData.publishDate))
                setstatus(businessData.status)
                setuid(businessData.uid)
                setimage(businessData.image)
            }
        }
    }
    return {
        category: category.join(', '),
        title,
        publishDate,
        image,
        content,
        slug,
    }
}