import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddAddress = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getSocities,
        getLocation,
        addLocation: publishLocation,
        updateLocation,
    } = useService('Location')
    const {
        isModuleEnabled,
        generateUID,
        showAlert,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {isValidJson} = useService('Validation')
    const user = getLoggedInUserData()
    const [building, setbuilding] = useState<string>('')
    const [locality, setlocality] = useState<string>('')
    const [pinCode, setpinCode] = useState<string>('')
    const [city, setcity] = useState<string>('')
    const [state, setstate] = useState<string>('')
    const [landmark, setlandmark] = useState<string>('')
    const [alternateNo, setalternateNo] = useState<string>('')
    const [uid, setuid] = useState<string>('')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [societyData, setsocietyData] = useState([])
    const [activeSociety, setactiveSociety] = useState(null)
    const [showSocietyListBox, setshowSocietyListBox] = useState<boolean>(false)
    const [fromSociety, setfromSociety] = useState<boolean>(true)
    const [fullAddress, setfullAddress] = useState<string>('')

    setTitle('Add Address')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[2] === 'edit') {
            let query = {
                uid: urlData[3]
            }
            getAddressData(query)
        }
        if(isModuleEnabled(["Society"])) getSocietiesListData()
    }, [])

    const getSocietiesListData = async () => {
        const result = await getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            setsocietyData(societyData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No society added yet!' })
        } else showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    
    const getAddressData = async (query) => {
        const result = await getLocation(query)
        if (result.status) {
            let addressData = result.data[0]
            setmode('edit')
            setbuilding(addressData.building)
            setlocality(addressData.locality)
            setpinCode(addressData.pinCode)
            setcity(addressData.city)
            setstate(addressData.state)
            setlandmark(addressData.landmark)
            setalternateNo(addressData.alternateNo)
            setuid(addressData.uid)
            if (isValidJson(addressData.activeSociety)) setactiveSociety(JSON.parse(addressData.activeSociety))
        } else showAlert({ type: 'error', msg: 'No address added yet!' })
    }
    
    const addLocation = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo,
            activeSociety: JSON.stringify(activeSociety),
            uid: mode === 'add' ? generateUID() : uid,
            ownerId: mode === 'add' ? ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid : undefined,
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the PinCode'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await publishLocation(locationObject)
                    if (result.status) {
                        showAlert({ type: 'success', msg: 'Address added successfully!' })
                        navigateTo({route: '/address/list'})
                    } else
                        showAlert({ type: 'error', msg: 'Unable to add address!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    locationData: JSON.stringify(locationObject)
                }
                const result = await updateLocation(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Address updated successfully!' })
                    navigateTo({route: '/address/list'})
                } else showAlert({ type: 'error', msg: 'Unable to update address!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const renderSocietyList = carList => {
        return carList.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null)
                activeClass = activeSociety.title === item.title? 'active': ''
            return <li key={index} className={activeClass} onClick={() => {
                setactiveSociety(item)
                setshowSocietyListBox(false)
                let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                activeaddress.address_components?.forEach(e => {
                    if (e.types[0]==="postal_code")
                        pinCode = e.long_name
                    if (e.types[0]==="administrative_area_level_1")
                        state = e.long_name
                    if (e.types[0]==="administrative_area_level_2")
                        city = e.long_name
                    if (e.types[0]==="locality")
                        locality = e.long_name
                })
                setlocality(locality)
                setpinCode(pinCode)
                setcity(city)
                setstate(state)
                setfullAddress(activeaddress.formatted_address)
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    return {
        addLocation,
        mode,
        setshowSocietyListBox,
        fullAddress,
        showSocietyListBox,
        renderSocietyList,
        societyData,
        activeSociety,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setlocality,
        pinCode,
        setpinCode,
        city,
        setcity,
        state,
        setstate,
        landmark,
        setlandmark,
        alternateNo,
        setalternateNo,
    }
}