import { memo } from 'react'
import { useElement } from '../../../hooks'
import { useTrashConfirmModal } from './hooks'

interface TrashConfirmModalProps {
    trashCancelAction?: () => void
    showTrashConfirm?: boolean
    isMultipleDelete?: boolean
    trashOkAction?: () => void
}

export const TrashConfirmModal = memo(({
    trashCancelAction,
    showTrashConfirm,
    isMultipleDelete,
    trashOkAction,
}: TrashConfirmModalProps) => {
    const Button = useElement('Button')
    const {
        trashCancel,
    } = useTrashConfirmModal({
        trashCancelAction,
        showTrashConfirm,
    })

    return <div className="modal" id="trash-modal">
        <div className="modal-backdrop"></div>
        <div className="modal-content small">
            {trashCancelAction && <Button className="close" onClick={trashCancel}><i className="hi-close"></i></Button>}
            <div className="modal-body p20">
                <i className="hi-close"></i>
                <span className="store-name">
                    {
                        isMultipleDelete?
                        "Are you sure you want to delete selected items.":
                        "Are you sure you want to delete it."
                    }
                </span>
                <div className="flex btn-box-trash">
                    {trashOkAction && <Button className="btn btn-primary btn-box" onClick={trashOkAction}>Delete</Button>}
                    {trashCancelAction && <Button className="btn btn-box btn-outline" onClick={trashCancel}>Cancel</Button>}
                </div>
            </div>
        </div>
    </div>
})