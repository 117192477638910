import { PageRouteProps } from "../../types";

export const locationRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/pinCode/list",
        "module": ["Location"],
        "component": "AdminPinCode",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/city/list",
        "module": ["Location"],
        "component": "AdminCity",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/state/list",
        "module": ["Location"],
        "component": "AdminState",
        "type": "admin",
        "mode": "static"
    }
]