import { useElement, useService } from '../../../hooks'
import { useBlogSideBar } from './hooks'

export const BlogSideBar = () => {
    const {
        getFormattedDate,
        isModuleEnabled,
        preventFormSubmit,
        isEmpty,
    } = useService('Misc')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const Link = useElement('Link')
    const {
        searchProduct,
        searchPost,
        getPostsData,
        recentPost,
        filteredList,
        filteredPostList,
        searchPostKey,
        searchKey,
    } = useBlogSideBar()
    
    const renderRecentPostList = recentPostFiles => {
        return recentPostFiles.slice(0, 4).map(( item, index ) => {
            return <li className="single-recent-post-item" key={index}>
                <div className="thumb">
                    {
                        isEmpty(item.image)?
                        <Img src={'product-placeholder.png'} alt={item.title} />:
                        <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                    }
                </div>
                <div id="content-box" className="content">
                    <h5 className="title"><Link href={"/post/"+item.slug}>{item.title}</Link></h5>
                    <span className="time">{getFormattedDate(new Date(item.publishDate))}</span>
                </div>
            </li>
        })
    }

    const renderFilteredList = (filteredList, slug) => {
        return filteredList.map(( item, index ) => {
            let productimg = JSON.parse(item.image)
            return <li key={index} className="blog-product-search-box">
                <Link href={"/"+slug+"/" + item.slug} title={item.title} className="flex">
                    <Img
                        className="blog-product-search-img" src={
                        isEmpty(item.image)?
                        'product-placeholder.png':
                        productimg[0]} alt={item.title}
                    />
                    <p className="ml10 mb0">
                        <p className="m0">{item.title}</p>
                        {item.status && <><span className="black"><b>Item Code: </b><span className="gray">{item.sku}</span> </span> <span className="black ml10"> <b>Stock: </b> <span className="gray">{item.stock} </span></span></>}
                    </p>
                </Link>
            </li>
        })
    }

    return <div className="widget-area">
        {isModuleEnabled(["Blog"]) && <div className="widget blog-bg">
            <form className="search-form" noValidate onSubmit={preventFormSubmit}>
                <h5 className="widget-title border-bottom">Search For Posts</h5>
                <div className="form-group">
                    <Input onFocus={getPostsData} required name="searchPostKey" value={searchPostKey || ''} placeholder="Search..." onChange={searchPost} className="form-control" />
                </div>
                <div className="search-result-blog">
                    <ul>
                        {
                            searchPostKey !== ''?
                            renderFilteredList(filteredPostList || [], 'post') : null
                        }
                    </ul>
                </div>
            </form>
        </div>}

        {isModuleEnabled(["Ecommerce"]) && <div className="widget blog-bg">
            <form className="search-form" noValidate onSubmit={preventFormSubmit}>
                <h5 className="widget-title border-bottom">Search For Products</h5>
                <div className="form-group">
                    <Input required name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={searchProduct} className="form-control" />
                </div>
                <div className="search-result-blog">
                    <ul>
                        {
                            searchKey !== ''?
                            renderFilteredList(filteredList || [], 'product'): null
                        }
                    </ul>
                </div>
            </form>
        </div>}
        
        {isModuleEnabled(["Blog"]) && <div className="widget widgetrecentpost blog-bg style-01">
            <h5 className="widget-title border-bottom">Recent Posts</h5>
            <ul className="recent-post-item">
                { renderRecentPostList(recentPost || []) }
            </ul>
        </div>}
    </div>
}