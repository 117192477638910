import { useElement } from "../../../hooks"
import { useSubscriptionForm } from "./hooks"
import './style.scss'

export const SubscriptionForm = () => {
    const Input = useElement('Input')
    const Button = useElement('Button')
    const {
        value,
        setvalue,
        subscribeForFranchise,
    } = useSubscriptionForm()
    return <form className="subscription-form form-inline" onSubmit={subscribeForFranchise} noValidate>
        <Input
            placeholder="Enter your Email"
            value={value}
            onChange={e => setvalue(e.target.value)}
            required
        />
        <Button
            type="submit"
            className="btn btn-primary"
        >Become Partner today!</Button>
    </form>
}