import { useElement, useService } from '../../../hooks'
import { useHomeSliderProduct } from './hooks'

export const HomeSliderProduct = () => {
    const {isEmpty} = useService('Misc')
    const Img = useElement('Img')
    const Slider = useElement('Slider')
    const Link = useElement('Link')
    const {
        productSliderList,
    } = useHomeSliderProduct()
    const setting = {
        rows: 1,
        swipeToSlide: true,
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000
    }
    const renderServiceSliderList = productSliderFiles => {
        return productSliderFiles.map(( item, index ) => {
            return <div className="slider-items" key={index}>
                <div className="slider-items-wrap">
                    <div className="slider-inner">
                        <div className="slider-inner-box">
                            <div className="slider-content">
                                <div className="slider-content-inner">
                                    <div className="section-title ">
                                        <div className="image-cover-box mobile-image-home-banner-box">
                                            <Img src={JSON.parse(item.image)[0]} alt={item.title} className="mobile-image-home-banner" />
                                        </div>
                                    </div>
                                    <div className="slider-title">
                                        <h1 className="heading-01 wow fadeInUp" data-wow-delay="0.3s">{item.title}</h1>
                                    </div>
                                    <div className="slider-paragraph">
                                        <p dangerouslySetInnerHTML={{__html: item.excerpt}}></p>
                                    </div>
                                    <div className="main-btn-wrap">
                                        <Link href={"/product/"+item.slug} className="btn btn-primary btn-box">Programmer India</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="right-cut-box"></div>
                        </div>
                        <div className="slider-img">
                            <div className="image-cover-box">
                                {
                                    isEmpty(item.image)?
                                    <Img src={'product-placeholder.png'} alt={item.title} />:
                                    <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }

    return <Slider {...setting}>
        {renderServiceSliderList(productSliderList || [] )}
    </Slider>
}
