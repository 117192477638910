import {brandInfo, mainUrl} from '../../constant'
import { useJob } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../hooks'

export const Job = () => {
    const {getFormattedDate} = useService('Misc')
    const Html = useElement('Html')
    const Img = useElement('Img')
    const Banner = useWidget('Banner')
    const BlogSideBar = useComponent('BlogSideBar')
    const PostMeta = useComponent('PostMeta')
    const Share = useComponent('Share')
    const {
        category,
        title,
        publishDate,
        image,
        content,
        slug,
    } = useJob()
    return <>
        <Banner title={title} image="white-car-banner.jpg" />
        <div className="blog-page jumbo-container">
            <div className="blog-page-content pt120 pb100">
                <div className="wrapper">
                    <div className="col-8">
                        <div className="blog-details-wrap">
                            <div className="blog-details-items">
                                <div className="content bg-none">
                                    <h3 className="title">{title}</h3>
                                    <PostMeta categoryList={category} publishDate={publishDate} />
                                </div>
                                <div className="thumb mt20">
                                    <Img src={JSON.parse(image)[0]} alt={title} />
                                </div>
                                <div className="blog-details-inner">
                                    <Html content={content} />
                                </div>
                            </div>
                            <Share shareUrl={mainUrl+"/job/"+slug} />
                        </div>
                    </div>
                    <div className="col-4">
                        <BlogSideBar />
                    </div>
                </div>
            </div>
        </div>
    </>
}
