import Select from 'react-select'
import { useWorkListAdmin } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const WorkListAdmin = () => {
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        title,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        trashEvent,
        trashCancel,
        showTrashConfirm,
        closeWorkAssignModal,
        assignWorker,
        setassignWorker,
        assignWorkers,
        assignAWorker,
        closeWorkDoneModal,
        activeCar,
        fileArray,
        selectFiles,
        setfileArray,
        comments,
        setcomments,
        uploadFiles,
    } = useWorkListAdmin()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">{title}</h1>
        <div className="filter-box">
            <div className="search-box">
                <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                <i className="hi-close" onClick={resetSearch}></i>
            </div>
        </div>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashEvent} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal" id="work-assign-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={closeWorkAssignModal}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <h4 className="head">Assign Worker</h4>
                    <div className='body overflown'>
                        <Select
                            value={assignWorker}
                            defaultValue={assignWorker}
                            onChange={assignWorker => setassignWorker(assignWorker)}
                            options={assignWorkers}
                        />
                    </div>
                    <Button className="mb0 btn-success btn-box" onClick={assignAWorker}>Submit</Button>
                </div>
            </div>
        </div>
        <div className="modal" id="work-done-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={closeWorkDoneModal}><i className="hi-close"></i></Button>
                {activeCar && <div className="modal-body p20">
                    <h3 className='lh1'>Car Details</h3>
                    <div className='flex'>
                        {
                            activeCar?.carData?.image_path ?
                            <Img source='carCDN' src={activeCar?.carData?.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />:
                            <p>No Image</p>
                        }
                        <div className='fs12'>
                            {activeCar?.registrationNo?.toUpperCase()}<br />
                            {activeCar?.carData?.name+' '+activeCar?.carData?.Segment}
                        </div>
                    </div>
                    <h3>Post Work Car Images</h3>
                    {
                        fileArray.length < 5 &&
                        <>
                            <label htmlFor="choose-file" className="btn btn-primary btn-small">Car Images</label>
                            <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/jpg" />
                        </>
                    }
                    {
                        fileArray.length !== 0 &&
                        <div className="upload-box">
                            <ul className="media-box mb20">
                                {(fileArray || []).map((file, index ) => (
                                    <li key={index}>
                                        <Img source="upload" src={file.url} alt="..." />
                                    </li>
                                ))}
                            </ul>
                            {fileArray.length >0 && <Button className="btn btn-outline btn-small" onClick={() => setfileArray([])}>Reset</Button>}
                        </div>
                    }
                    <hr />
                    <h3 className='lh1'>Comments</h3>
                    <textarea className="comments" placeholder="Type your comments here" required name="comments" value={comments} onChange={e => setcomments(e.target.value)}></textarea>
                    <Button className="mb0 btn-success btn-box" onClick={uploadFiles}>Submit</Button>
                </div>}
            </div>
        </div>
    </div>
}
