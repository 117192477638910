import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const usePremiumServices = () => {
    const {getService} = useService('Service')
    const [serviceList, setserviceList] = useState([])

    const getServicesData = async () => {
        const result = await getService({status: 'publish', isFeatured: 'true'})
        if (result.status) {
            if (result.data?.length !== 0) {
                let serviceList = result.data
                setserviceList(serviceList.reverse())
            }
        }
    }
    
    useEffect(() => {
        getServicesData()
    }, [])
    
    return {
        serviceList,
    }
}