import { PageRouteProps } from "../../types";

export const campaignRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/campaign/add",
        "component": "AddCampaign",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/campaign/edit",
        "component": "AddCampaign",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/campaign/list",
        "component": "CampaignList",
        "type": "admin",
        "mode": "static"
    }
]