import { useAddTestimonial } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddTestimonial = () => {
    const Button = useElement('Button')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Box = useComponent('Box')
    const MediaBox = useComponent('MediaBox')
    const {
        title,
        name,
        setname,
        content,
        setcontent,
        addTestimonial,
        image,
        setimage,
    } = useAddTestimonial()
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/testimonial/list' />
            {title}
        </h1>
        <div className="columns">
            <div className="left">
                <Box title='Name' required>
                    <Input required name="name" value={name} onChange={e => setname(e.target.value)} />
                </Box>
                <Box title='Content' required>
                    <textarea className="description" placeholder="Type the testimonial here" required name="content" value={content} onChange={e => setcontent(e.target.value)}></textarea>
                </Box>
            </div>
            <div className="right">
                <form noValidate onSubmit={addTestimonial}>
                    <Box title='Meta'>
                        <Button className="btn btn-primary" type="submit" title="Click here to Add testimonial">Submit</Button>
                    </Box>
                    <MediaBox image={image} setimage={image => setimage(image)} usage={['user']} />
                </form>
            </div>
        </div>
    </div>
}
