import { PageRouteProps } from "../../types";

export const faqRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/faq/list",
        "module": ["Faq"],
        "component": "AdminFAQ",
        "type": "admin",
        "mode": "static"
    }
]