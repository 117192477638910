import { PageRouteProps } from "../../types";

export const ecommerceRoutes: PageRouteProps[] = [
    {
        "pathName": "/shop",
        "module": ["Ecommerce"],
        "redirectTo": "/accessories",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/shop",
        "module": ["Ecommerce"],
        "redirectTo": "/accessories",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/accessories",
        "module": ["Ecommerce"],
        "component": "Shop",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/accessories",
        "module": ["Ecommerce"],
        "component": "Shop",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/accessories/tag",
        "module": ["Ecommerce"],
        "component": "Shop",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/product",
        "module": ["Ecommerce"],
        "component": "Product",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/thankyou",
        "module": ["Ecommerce"],
        "component": "Thankyou",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/cart",
        "module": ["Ecommerce"],
        "component": "Cart",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/payment-done",
        "module": ["Ecommerce"],
        "component": "Payment",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/feedback",
        "module": ["Notification"],
        "component": "Feedback",
        "type": "public",
        "mode": "dynamic"
    },
    {
        "pathName": "/checkout",
        "module": ["Ecommerce"],
        "component": "Checkout",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/order/track",
        "module": ["Ecommerce"],
        "component": "OrderTracking",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/order/list",
        "module": ["Ecommerce"],
        "component": "MyOrders",
        "type": "postLogin",
        "mode": "static"
    },
    {
        "pathName": "/order/view",
        "module": ["Ecommerce"],
        "component": "MyOrderDetail",
        "type": "postLogin",
        "mode": "dynamic"
    },
    {
        "pathName": "/offer",
        "module": ["Ecommerce"],
        "component": "Offers",
        "type": "public",
        "mode": "static"
    }
]