import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useAppBanners = () => {
    const {fetchAll} = useService('Data', 'media')
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'setting')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        openModal,
        generateUID,
    } = useService('Misc')
    const {
        getLocationData,
    } = useService('Router')
    const [uid, setuid] = useState<string>('')
    const [mediaFiles, setmediaFiles] = useState([])
    const [image, setimage] = useState<string>('[]')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [activeFile, setactiveFile] = useState(null)
    
    const selectImage = file => {
        let imagesArray = []
        let images = (document.getElementById('image-input') as HTMLInputElement).value === '' ? JSON.stringify([]) : (document.getElementById('image-input') as HTMLInputElement).value
        if (!images.includes(file)) {
            imagesArray = JSON.parse(images)
            imagesArray.push(file)
        } else {
            imagesArray = JSON.parse(images)
            let imageIndex = imagesArray.indexOf(file);
            if (imageIndex > -1) {
                imagesArray.splice(imageIndex, 1);
              }
        }
        setimage(JSON.stringify(imagesArray))
    }
    const getMedias = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No media added yet!' })
            }
            let media = result.data.reverse().filter(e => e.fileName.includes('.'))
            setmediaFiles(media)
        }
    }
    
    const preview = filePath => {
        setactiveFile(filePath)
        openModal('preview-modal')
    }
    
    const trashSelectedImage = item => {
        let newImages = []
        JSON.parse(image).forEach(i => {
            if (i !== item) {
                newImages.push(i)
            }
        })
        setimage(JSON.stringify(newImages))
    }
    const getAppBanners = async () => {
        const result = await fetchByAttr({type: 'app-banner'})
        if (result.status) {
            if (result.data?.length > 0) {
                let serviceDate = result.data[0]
                setmode('edit')
                setimage(serviceDate.image)
                setuid(serviceDate.uid)
            }
        } else showAlert({ type: 'error', msg: 'No banners added yet!' })
    }
    
    const saveBanners = async () => {
        let error = false,
        errorMsg = '',
        settingObject = {
            type: 'app-banner',
            image,
            uid: mode === 'add' ? generateUID() : uid,
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(settingObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Banners added successfully!' })
                    getLocationData().reload()
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add user!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    settingData: JSON.stringify(settingObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Banners updated successfully!' })
                    getLocationData().reload()
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update user!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    setTitle('App Banners', 'admin')
    useEffect(() => {
        getAppBanners()
    }, [])

    return {
        selectImage,
        preview,
        trashSelectedImage,
        getMedias,
        setimage,
        mediaFiles,
        image,
        saveBanners,
        activeFile,
    }
}