import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddJob = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'job')
    const {getUsers} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        isEmpty,
        generateUID,
        showAlert,
        slugify,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const keywordOptions =  [],
    categoryOptions = [],
    tagOptions = []
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [renderSlug, setrenderSlug] = useState<boolean>(true)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [assignWorkers, setassignWorkers] = useState([])
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState(null)
    const [tag, settag] = useState(null)
    const urlData = urlToSplitData(getLocationData().pathname)

    setTitle('Add Job', 'admin')
    useEffect(() => {
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getJobData({uid: urlData[4]})
        }
        getUsersData()
    }, [])

    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    tableData.push({label: result.name, value: result.uid})
                })
                setassignWorkers(tableData)
            }
        }
    }
    
    const getJobData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            const jobData = result.data[0]
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === jobData.robots))
            setactiveAuthorIndex(authorOption.findIndex(e => e.value === jobData.author))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === jobData.status))
            setmetaKeyword(isEmpty(jobData.metaKeyword) ? [] : jobData.metaKeyword)
            settag(isEmpty(jobData.tag) ? [] : jobData.tag)
            setcategory(isEmpty(jobData.category) ? [] : jobData.category)
            settitle(jobData.title)
            setmode('edit')
            setslug(jobData.slug)
            setcontent(jobData.content)
            setexcerpt(jobData.excerpt)
            setcss(jobData.css)
            setjs(jobData.js)
            setcanonical(jobData.canonical)
            setrobots(jobData.robots)
            setmetaDescription(jobData.metaDescription)
            setauthor(jobData.author)
            setpublishDate(new Date(jobData.publishDate))
            setstatus(jobData.status)
            setuid(jobData.uid)
            setimage(jobData.image)
        } else showAlert({ type: 'error', msg: 'No jobs added yet!' })
    }
    
    const addJob = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        jobObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status.value,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            image,
            uid: mode === 'add' ? generateUID() : uid,
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (content === '') {
            errorMsg = 'Please enter the content'
            error = true
        } else if (excerpt === '') {
            errorMsg = 'Please enter the excerpt'
            error = true
        } else if (author === '') {
            errorMsg = 'Please select the author'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(jobObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Job added successfully!' })
                    navigateTo({route: '/admin/job/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add job!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    jobData: JSON.stringify(jobObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Job updated successfully!' })
                    navigateTo({route: '/admin/job/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to update job!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const pageTitle = mode + ' Job'
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let activeRobots = robotsOption[activeRobotsIndex],
    activeAuthor = authorOption[activeAuthorIndex],
    activeStatus = statusOption[activeStatusIndex]
    if (mode === 'edit') {
        statusOption.forEach(e => {
            if (e.value === status)
                activeStatus = e
        })
        robotsOption.forEach(e => {
            if (e.value === robots)
                activeRobots = e
        })
        assignWorkers.forEach(e => {
            if (e.value === author)
                activeAuthor = e
        })
    }

    const addTitle = (e) => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)
    return {
        pageTitle,
        addJob,
        title,
        addTitle,
        slug,
        setslug,
        content,
        setcontent,
        excerpt,
        setexcerpt,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setimage,
        image,
        tagOptions,
        settag,
        tag,
        categoryOptions,
        setcategory,
        category,
        setstatus,
        status,
        publishDate,
        setpublishDate,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        activeAuthor,
        setauthor,
        assignWorkers,
    }
}