import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useContact = () => {
    const {setTitle} = useService('Meta')
    const {
        getContactRequest,
        showAlert,
    } = useService('Misc')
    const [name, setname] = useState<string>('')
    const [email, setemail] = useState<string>('')
    const [company, setcompany] = useState<string>('')
    const [mobile, setmobile] = useState<string>('')
    const [message, setmessage] = useState<string>('')
    const [subject, setsubject] = useState<string>('')

    setTitle('Contact Us')

    const sendContactRequest = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = {
            name,
            subject,
            company,
            email,
            mobile,
            message
        }
        if (name === '') {
            errorMsg = 'Please enter your Full Name'
            error = true
        }
        if (email === '') {
            errorMsg = 'Please enter your Email Address'
            error = true
        }
        if (mobile === '') {
            errorMsg = 'Please enter your Mobile Number'
            error = true
        }
        if (subject === '') {
            errorMsg = 'Please enter your Subject'
            error = true
        }
        if (message.length < 50) {
            errorMsg = 'Message length should be greated than 50 letters.'
            error = true
        }
        if (!error) {
            const result = await getContactRequest(userObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'Your contact request has been submitted. We\'ll contact you back in 24 hours.' })
                setname('')
                setemail('')
                setmessage('')
                setmobile('')
                setsubject('')
            } else showAlert({ type: 'error', msg: 'Unable to send contact request.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        sendContactRequest,
        name,
        setcompany,
        setemail,
        setname,
        email,
        company,
        mobile,
        setmobile,
        subject,
        setsubject,
        message,
        setmessage,
    }
}