import { PageRouteProps } from "../../types";

export const eventRoutes: PageRouteProps[] = [
    {
        "pathName": "/event",
        "module": ["Event"],
        "component": "OurEvents",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/event",
        "module": ["Event"],
        "component": "Event",
        "type": "public",
        "mode": "dynamic"
    }
]