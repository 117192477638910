import { base, showServices } from "../../../constant"
import { useElement } from "../../../hooks"

export const ShowServices = () => {
    const Html = useElement('Html')
    return <ul className='show-services'>
        {
            showServices.map((e, i) => {
                return <li key={i} style={{backgroundImage: `url('${base.imageBaseUrl+e.image}')`}}>
                    <Html content={e.title} />
                </li>
            })
        }
    </ul>
}