import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useOrderDetail = () => {
    const {fetchByAttr} = useService('Data', 'feedback')
    const {save} = useService('Data', 'log')
    const {
        getLoggedInUserData,
        getUsers,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {getSocities} = useService('Location')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getOrderInvoice,
        getOrder,
        updateOrder,
        forceRegenrateInvoice,
    } = useService('Order')
    const [cartDataType, setcartDataType] = useState([])
    const [orderData, setorderData] = useState(null)
    const [addr, setaddr] = useState(null)
    const [invoiceNo, setinvoiceNo] = useState<string>('')
    const [user, setuser] = useState(getLoggedInUserData())
    const [registrationNo, setregistrationNo] = useState<string>('')
    const [orderContent, setorderContent] = useState(null)
    const [note, setnote] = useState<string>('')
    const [activeProductTempUid, setactiveProductTempUid] = useState<string>('')
    const [assignWorkers, setassignWorkers] = useState([])
    const [customersData, setcustomersData] = useState([])
    const [assignCustomers, setassignCustomers] = useState([])
    const [status, setstatus] = useState(null)
    const [invoiceData, setinvoiceData] = useState([])
    const [assignCustomer, setassignCustomer] = useState(null)
    const [assignWorker, setassignWorker] = useState(null)
    const [feedbackContent, setfeedbackContent] = useState(null)
    const [editAddress, seteditAddress] = useState<boolean>(false)
    const [datepicker, setdatepicker] = useState<boolean>(false)
    const [startDate, setstartDate] = useState(new Date())
    const [order, setorder] = useState([])
    const [building, setbuilding] = useState<string>('')
    const [locality, setlocality] = useState<string>('')
    const [pinCode, setpinCode] = useState<string>('')
    const [city, setcity] = useState<string>('')
    const [state, setstate] = useState<string>('')
    const [landmark, setlandmark] = useState<string>('')
    const [alternateNo, setalternateNo] = useState<string>('')
    const [societyData, setsocietyData] = useState([])
    const [activeSociety, setactiveSociety] = useState(null)
    const [showSocietyListBox, setshowSocietyListBox] = useState<boolean>(false)
    const [fromSociety, setfromSociety] = useState<boolean>(true)
    const [fullAddress, setfullAddress] = useState<string>('')

    setTitle('Order Details', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname),
        query = {
            uid: urlData[4]
        }
        getOrderData(query)
        getUsersData()
        getOrderFeedback(urlData[4])
        getInvoice(urlData[4])
        getSocietiesListData()
    }, [])

    const getInvoice = async orderId => {
        let query = {
            orderId
        }
        const result = await getOrderInvoice(query)
        if (result.status)
            setinvoiceData(result.data)
        else showAlert({ type: 'error', msg: 'No order placed yet!' })
    }
    
    const getUsersData = async () => {
        let tableData = [], customersData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableData.push({label: result.name, value: result.uid})
                    } else {
                        customersData.push({label: result.name, value: result.uid})
                    }
                })
                setassignWorkers(tableData)
                setassignCustomers(customersData)
                setcustomersData(result.data)
            }
        }
    }
    
    const getOrderData = async (query) => {
        const result = await getOrder(query)
        if (result.status) {
            let orderContent = result.data[0]
            seteditAddress(false)
            setorderContent(orderContent)
            if (orderContent?.assignWorker)
                setassignWorker(JSON.parse(orderContent.assignWorker))
        } else showAlert({ type: 'error', msg: 'No product added yet!' })
    }
    
    const updateOrderContent = async orderContent => {
        delete orderContent._id
        let data = {
            query: JSON.stringify({uid: orderContent.uid}),
            orderData: JSON.stringify(orderContent)
        }
        const result = await updateOrder(data)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Order data updated successfully.' })
            setTimeout(() => {
                getLocationData().reload()
            }, 2000)
        } else showAlert({ type: 'error', msg: 'Unable to update order! Please try after some time.' })
    }
    
    const assignCar = tempUid => {
        let newCartData = [],
        orderData = JSON.parse(orderContent.orderData)
        orderData.cartData?.forEach(e => {
            if (tempUid === e.tempUid) {
                e.title += ' ['+registrationNo+']'
                e.activeCar.registrationNo = registrationNo
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        updateOrderContent(orderContent)
    }
    
    const updateCar = (tempUid, activeCar) => {
        let newCartData = [],
        orderData = JSON.parse(orderContent.orderData)
        orderData.cartData?.forEach(e => {
            if (tempUid === e.tempUid) {
                activeCar.registrationNo = e.activeCar.registrationNo
                e.activeCar = activeCar
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        updateOrderContent(orderContent)
    }
    
    const addNote = (e) => {
        e.preventDefault()
        let orderNotes = typeof orderContent.orderNotes === 'undefined'? []: orderContent.orderNotes,
        activeNote = {
            status,
            note,
            timing: new Date().getTime(),
            tempUid: activeProductTempUid !== 'order' ? activeProductTempUid : undefined,
        }
        if (activeProductTempUid === 'order') {
            orderContent.status = status
        }
        orderNotes.push(activeNote)
        orderContent.orderNotes = orderNotes
        updateOrderContent(orderContent)
    }
    
    const assignAWorkers = () => {
        orderContent.assignWorker = JSON.stringify(assignWorker)
        let logData = {
            log: user.name+' assign order Id #'+orderContent.uid + ' to '+ assignWorker.label,
            timing: new Date().getTime(),
            type: 'info',
            userId: user.uid,
            uid: generateUID()
        }
        save(logData)
        updateOrderContent(orderContent)
    }
    
    const assignACustomer = () => {
        let userData = JSON.parse(orderContent.userData)
        customersData.forEach(e => {
            if (e.uid === assignCustomer.value)
                userData = e
        })
        orderContent.userData = JSON.stringify(userData)
        updateOrderContent(orderContent)
    }
    
    const generateInvoice = async () => {
        let data = {
            orderId: orderContent.uid
        }
        const result = await forceRegenrateInvoice(data)
        if (result.status)
            showAlert({ type: 'success', msg: 'Invoice generated successfully!' })
        else showAlert({ type: 'error', msg: 'Unable to generate Invoice!' })
    }
    
    const getOrderFeedback = async orderId => {
        let query = {
            orderId
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length > 0)
                setfeedbackContent(result.data[0])
        }
    }
    
    const getSocietiesListData = async () => {
        const result = await getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            setsocietyData(societyData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No society added yet!' })
        } else showAlert({ type: 'error', msg: 'No society added yet!' })
    }
    
    const addLocation = (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo,
            activeSociety: JSON.stringify(activeSociety)
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the PinCode'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            orderContent.activeAddress = JSON.stringify(locationObject)
            updateOrderContent(orderContent)
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const updateStartDate = (tempUid, date) => {
        let newCartData = [],
        orderData = JSON.parse(orderContent.orderData), totalDays = 0, endDate = date, startDate = date
        orderData.cartData?.forEach(e => {
            if (tempUid === e.tempUid) {
                e.startDate = startDate
                totalDays = e.billing === 'monthly' ? 30 : 90
                e.endDate = new Date(endDate.getTime() + (totalDays * 24 * 60 * 60 * 1000))
            }
            newCartData.push(e)
        })
        orderData.cartData = newCartData
        orderContent.orderData = JSON.stringify(orderData)
        updateOrderContent(orderContent)
    }
    
    useEffect(() => {
        let orderData = null
        if (orderContent && orderContent !== null) {
            if (orderContent.activeAddress !== "undefined" && orderContent.activeAddress)
                setaddr(JSON.parse(orderContent.activeAddress))
            setuser(JSON.parse(orderContent.userData))
            orderData = JSON.parse(orderContent.orderData)
        }
        order.push({value: 'order', label: 'Whole Order' })
        if (orderContent !== null) {
            invoiceData.forEach(e => {
                if (e.orderId === orderContent.uid)
                    setinvoiceNo(e.invoiceNo)
            })
        }
        let cartDataType = []
        if (orderData !== null) {
            orderData.cartData?.forEach(e => {
                cartDataType?.push(e.type)
                order.push({value: e.tempUid, label: e.title })
            })
        }
        setcartDataType(cartDataType)
        setorderData(orderData)
    }, [orderContent])
    return {
        setactiveSociety,
        setfullAddress,
        updateCar,
        setregistrationNo,
        registrationNo,
        assignCar,
        datepicker,
        setstartDate,
        updateStartDate,
        startDate,
        setdatepicker,
        invoiceNo,
        generateInvoice,
        user,
        editAddress,
        addLocation,
        setshowSocietyListBox,
        fullAddress,
        showSocietyListBox,
        societyData,
        activeSociety,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setlocality,
        pinCode,
        setpinCode,
        city,
        setcity,
        state,
        setstate,
        landmark,
        setlandmark,
        alternateNo,
        setalternateNo,
        seteditAddress,
        orderData,
        addr,
        assignWorker,
        setassignWorker,
        assignWorkers,
        assignAWorkers,
        cartDataType,
        assignCustomer,
        setassignCustomer,
        assignCustomers,
        assignACustomer,
        feedbackContent,
        orderContent,
        addNote,
        note,
        setnote,
        setstatus,
        setactiveProductTempUid,
        order,
    }
}