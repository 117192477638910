import { memo } from 'react'
import './style.scss'
import { useImg } from './hooks'
import { ImgType } from "./type"

export const Img = memo(({
    src,
    hasZoom,
    style,
    alt = '',
    title = '',
    className = '',
    source,
    onClick = () => {},
}: ImgType) => {
    const {
        zoom,
        setzoom,
        imageSrc,
    } = useImg({
        src,
        source,
    })
    return <>{
        hasZoom ? <>
            <picture className="picture" style={style}>
                <span className="preview-icon" onClick={() => setzoom(true)}>
                    <i className="hi-eye1"></i>
                </span>
                <img onClick={onClick} className={className} alt={alt} style={style} title={title} src={imageSrc} />
            </picture>
            {
                zoom &&
                <div className="zoomed">
                    <span className="close-icon" onClick={() => setzoom(false)}>
                        <i className="hi-close"></i>
                    </span>
                    <img className={"zoomed "+className} alt={alt} style={{...style, width: 'auto'}} title={title} src={imageSrc} />
                </div>
            }
        </>:
        <img onClick={onClick} className={className} alt={alt} style={style} title={title} src={imageSrc} />
    }</>
})