import { useComponent, useElement, useService } from '../../../hooks'
import { useQueriesChatBot } from './hooks'

export const QueriesChatBot = () => {
    const {openModal} = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const TrashConfirmModal = useComponent('TrashConfirmModal')
    const {
        trackScrolling,
        setmode,
        tableContent,
        activeShownTotal,
        trashChat,
        trashCancel,
        showTrashConfirm,
        setanswer,
        setuid,
        mode,
        addChat,
        question,
        setquestion,
        answer,
    } = useQueriesChatBot()
    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Chatbot: Queries <Button onClick={() => {
            setmode('add')
            openModal("chat-modal")
        }} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
            pageSize={activeShownTotal}
        />
        <TrashConfirmModal trashOkAction={trashChat} trashCancelAction={trashCancel} showTrashConfirm={showTrashConfirm} />
        <div className="modal chat-modal" id="chat-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => {
                    setquestion('')
                    setanswer('')
                    setuid('')
                }}>
                    <i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="chat-modal-header">
                        <div className="modal-header">
                            <div className="heading">{mode} Query</div>
                            <form noValidate onSubmit={addChat}> 
                                <div className="form-group">
                                    <Input className="input" required name="question" value={question} onChange={e => setquestion(e.target.value) } />
                                    <label>Query</label>
                                    <small className="msg text-danger">Please enter a Query</small>
                                </div>
                                <div className="form-group">
                                    <textarea placeholder="Type the notification here" required name="answer" value={answer} onChange={e => setanswer(e.target.value)}></textarea>
                                    <label>Answer</label>
                                    <small className="msg text-danger">Please enter related Answer</small>
                                </div>
                                <div className="buttons">
                                    <Button type="submit" title="Click here to Add Chat" className="btn btn-primary btn-small">Add</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
