import { useContext, useEffect } from 'react'
import { useService, useWidget } from '../../hooks';
import { NavigatorContext } from '../../context';

export const Admin = component => {
    const AdminFooter = useWidget('AdminFooter')
    const AdminHeader = useWidget('AdminHeader')
    const AdminSidebar = useWidget('AdminSidebar')
    const {isAllowedAdmin} = useService('User')
    const {navigateTo} = useContext(NavigatorContext)
    const {getThemeMode} = useService('Misc')
    const themeClass = getThemeMode()
    
    useEffect(() => {
        document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    }, [])

    document.getElementById('root').className = 'admin '+themeClass
    if (isAllowedAdmin())
        return <>
            <AdminSidebar />
            <div className="container">
                <AdminHeader />
                {component}
                <AdminFooter />
            </div>
        </>
    navigateTo({route: '/login'})
    return
}