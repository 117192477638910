import { read, utils } from "xlsx";
import { useContext, useEffect, useState } from "react";
import { config, fuelOption, createColumns } from "../../../../constant";
import { useService } from "../../../../hooks";
import { AppDataContext } from "../../../../context";

export const useImportCars = () => {
    const {fetchAll} = useService('Data', 'carModel')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        getFormattedDate,
        isBottom,
    } = useService('Misc')
    const {
        getAllCars,
        addCar: publishCar,
        updateCar,
    } = useService('Car')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [file, setfile] = useState({})
    const [data, setdata] = useState([])
    const [cols, setcols] = useState([])
    const [excelCarsList, setexcelCarsList] = useState([])
    const [fileImported, setfileImported] = useState<boolean>(false)
    const [fileSelected, setfileSelected] = useState<boolean>(false)
    const [importCarData, setimportCarData] = useState([])
    const [tableData, settableData] = useState([])
    const [showTable, setshowTable] = useState<boolean>(false)
    const [importDone, setimportDone] = useState<boolean>(false)
    const [carModels, setcarModels] = useState([])
    const [filteredList, setfilteredList] = useState([])

    setTitle('Import Cars', 'admin')
    useEffect(() => {
        getAllCarsListData()
        getCarModelListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            setfile(files[0])
            setfileSelected(true)
        }
    }
    
    const getCarModelListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            setcarModels(result.data)
        }
    }
    
    const handleFile = () => {
        showPreloader()
        setshowTable(true)
        let excelCarsList = [],
        reader = new FileReader(),
        rABS = !!reader.readAsBinaryString
        reader.onload = (e) => {
            let bstr = e.target.result,
            wb = read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true, cellDates:true }),
            wsname = wb.SheetNames[0],
            ws = wb.Sheets[wsname],
            data = utils.sheet_to_json(ws)
            setdata(data)
            setcols(createColumns(ws['!ref']))
            data.forEach(e => excelCarsList.push(e))
            setexcelCarsList(excelCarsList)
            setfileImported(true)
            hidePreloader()
        }
        if (rABS) {
            //@ts-ignore
            reader.readAsBinaryString(file)
        } else {
            //@ts-ignore
            reader.readAsArrayBuffer(file)
        }
    }
    
    const processCars = () => {
        setshowTable(false)
        let mode = 'add', activeItem = null
        excelCarsList.forEach(e => {
            tableData.forEach(item => {
                if (String(e.uid) === item.uid) {
                    mode = 'edit'
                    activeItem = item;
                }
            })
            addCar(e, mode, activeItem)
        })
    }
    
    const getAllCarsListData = async () => {
        const result = await getAllCars()
        if (result.status) {
            let tableData = result.data.reverse()
            settableData(tableData)
        }
    }
    
    const addCar = async (item, mode, oldCar = null) => {
        let error = false, carData = null, errorMsg = ''
        fuelOption.forEach(el => {
            if(el.label === item.fuelType)
                item.fuelType = el
        })
        carModels.forEach(el => {
            if(item.modelKey === el.modelKey) {
                carData = el
                item.carData = el
            }
        })
        let carObject = {
            ownerId: String(oldCar.uid),
            uid: item.uid,
            registrationNo: item.registrationNo,
            registrationDate: item.registrationDate,
            chassisNo: item.chassisNo ?? '',
            engineNo: item.engineNo ?? '',
            ownerName: item.ownerName ?? '',
            vehicleClass: item.vehicleClass ?? carData?.Segment,
            maker: item.maker ?? carData.name,
            fitness: item.fitness,
            mvTax: item.mvTax,
            insurance: item.insurance,
            pucc: item.pucc,
            fuelType: JSON.stringify(item.fuelType),
            emission: item.emission,
            rcStatus: item.rcStatus,
            financed: item.financed ?? false,
            rcImage: item.rcImage,
            status: item.status ?? 'active',
            shoot360: item.shoot360 ?? false,
            shoot360Url: item.shoot360Url ?? '',
            modelKey: item.modelKey,
            carData: JSON.stringify(item.carData)
        }
        if (!error) {
            try {
                if (mode === 'add') {
                    carObject.uid = generateUID()
                    const result = await publishCar(carObject)
                    if (result.status) {
                        showAlert({ type: 'success', msg: 'Car added successfully!' })
                        setimportCarData(result)
                    } else {
                        showAlert({ type: 'error', msg: 'Unable to add car!' })
                    }
                    if (item.registrationNo === excelCarsList[excelCarsList.length-1].registrationNo) {
                        setshowTable(false)
                        setimportDone(true)
                    }
                } else {
                    let data = {
                        query: JSON.stringify({uid: String(oldCar.uid)}),
                        carData: JSON.stringify(carObject)
                    }
                    const result = await updateCar(data)
                    if (result.status) {
                        showAlert({ type: 'success', msg: 'Car updated successfully!' })
                        setimportCarData(result)
                    } else {
                        showAlert({ type: 'error', msg: 'Unable to update car!' })
                    }
                    if (item.registrationNo === excelCarsList[excelCarsList.length-1].registrationNo) {
                        setshowTable(false)
                        setimportDone(true)
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {...item, insurance: getFormattedDate(item.insurance)}
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'ownerId',
                    key: 'ownerId'
                },
                {
                    label: 'uid',
                    key: 'uid'
                },
                {
                    label: 'registrationNo',
                    key: 'registrationNo'
                },
                {
                    label: 'registrationDate',
                    key: 'registrationDate'
                },
                {
                    label: 'chassisNo',
                    key: 'chassisNo'
                },
                {
                    label: 'engineNo',
                    key: 'engineNo'
                },
                {
                    label: 'ownerName',
                    key: 'ownerName'
                },
                {
                    label: 'vehicleClass',
                    key: 'vehicleClass'
                },
                {
                    label: 'maker',
                    key: 'maker'
                },
                {
                    label: 'fitness',
                    key: 'fitness'
                },
                {
                    label: 'mvTax',
                    key: 'mvTax'
                },
                {
                    label: 'insurance',
                    key: 'insurance'
                },
                {
                    label: 'pucc',
                    key: 'pucc'
                },
                {
                    label: 'fuelType',
                    key: 'fuelType'
                },
                {
                    label: 'emission',
                    key: 'emission'
                },
                {
                    label: 'rcStatus',
                    key: 'rcStatus'
                },
                {
                    label: 'financed',
                    key: 'financed'
                },
                {
                    label: 'rcImage',
                    key: 'rcImage'
                },
                {
                    label: 'status',
                    key: 'status'
                },
                {
                    label: 'shoot360',
                    key: 'shoot360'
                },
                {
                    label: 'shoot360Url',
                    key: 'shoot360Url'
                },
                {
                    label: 'modelKey',
                    key: 'modelKey'
                },
            ],
            content: getTableData(excelCarsList)
        })
    }, [excelCarsList])
    return {
        trackScrolling,
        fileSelected,
        fileImported,
        processCars,
        handleFile,
        handleChange,
        showTable,
        tableContent,
        activeShownTotal,
        importDone,
    }
}