import { useContext, useEffect, useState } from "react"
import { fuelOption, packagesOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddCar = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'media')
    const {getLoggedInUserData} = useService('User')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
        getBase64,
    } = useService('Misc')
    const {isValidVehicleRegistrationNumber} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getCar,
        addCar: publishCar,
        updateCar,
    } = useService('Car')
    const user = getLoggedInUserData()
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [registrationNo, setregistrationNo] = useState<string>('')
    const [registrationDate, setregistrationDate] = useState<string>('')
    const [chassisNo, setchassisNo] = useState<string>('')
    const [engineNo, setengineNo] = useState<string>('')
    const [ownerName, setownerName] = useState(['manager','representative'].includes(user.role) ? user.parent.label : user.name)
    const [vehicleClass, setvehicleClass] = useState<string>('')
    const [activeFuelType, setactiveFuelType] = useState<number>(0)
    const [fuelType, setfuelType] = useState(fuelOption[0])
    const [packageType, setpackageType] = useState(packagesOption[0])
    const [activePackageType, setactivePackageType] = useState(packagesOption[0])
    const [maker, setmaker] = useState<string>('')
    const [fitness, setfitness] = useState<string>('')
    const [mvTax, setmvTax] = useState<string>('')
    const [insurance, setinsurance] = useState<string>('')
    const [pucc, setpucc] = useState<string>('')
    const [emission, setemission] = useState<string>('')
    const [rcStatus, setrcStatus] = useState<string>('')
    const [financed, setfinanced] = useState<string>('')
    const [showForm, setshowForm] = useState<boolean>(false)
    const [additionalCarInfo, setadditionalCarInfo] = useState<boolean>(false)
    const [carData, setcarData] = useState(null)
    const [file, setfile] = useState(null)
    const [fileObj, setfileObj] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [uid, setuid] = useState<string>('')
    const [showInsurance, setshowInsurance] = useState<boolean>(true)
    const [showRc, setshowRc] = useState<boolean>(true)
    const [rcImage, setrcImage] = useState<string>('')
    const [insuranceImage, setinsuranceImage] = useState<string>('')
    const [activeImage, setactiveImage] = useState(JSON.parse(user.image)[0])

    setTitle('Add Car')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[2] === 'edit' || urlData[2] === 'view') {
            let query = {
                uid: urlData[3]
            }
            getCarData(query, urlData[2])
        }
    }, [])

    const getCarData = async (query, mode) => {
        const result = await getCar(query)
        if (result.status) {
            if (result.data?.length > 0) {
                let carData = result.data[0]
                if (typeof carData.rcImage === 'undefined' || carData.rcImage === '' ) {
                    setshowRc(false)
                    carData.rcImage = '[]'
                }
                if (typeof carData.insuranceImage === 'undefined' || carData.insuranceImage === '') {
                    setshowInsurance(false)
                    carData.insuranceImage = '[]'
                }
                setmode(mode)
                setregistrationNo(carData.registrationNo)
                setregistrationDate(carData.registrationDate)
                setchassisNo(carData.chassisNo)
                setengineNo(carData.engineNo)
                setownerName(carData.ownerName)
                setvehicleClass(carData.vehicleClass)
                setmaker(carData.maker)
                setfitness(carData.fitness)
                setmvTax(carData.mvTax)
                setinsurance(carData.insurance)
                setpucc(carData.pucc)
                setfuelType(typeof carData.fuelType === "object"? JSON.parse(carData.fuelType) : fuelOption[activeFuelType])
                setemission(carData.emission)
                setrcStatus(carData.rcStatus)
                setfinanced(carData.financed)
                setcarData(JSON.parse(carData.carData))
                setuid(carData.uid)
                setshowForm(true)
                setrcImage(carData.rcImage)
                setinsuranceImage(carData.insuranceImage)
            }
        }
    }
    
    const handleChange = fuelType => {
        setfuelType(fuelType)
    }
    
    const handleChangePackage = packageType => {
        setpackageType(packageType)
        setactivePackageType(packageType)
    }
    
    const addCar = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        carObject = {
            registrationNo: registrationNo,
            registrationDate: registrationDate,
            chassisNo: chassisNo,
            engineNo: engineNo,
            ownerName: ownerName,
            vehicleClass: vehicleClass,
            maker: maker,
            fitness: fitness,
            mvTax: mvTax,
            insurance: insurance,
            pucc: pucc,
            fuelType: JSON.stringify(fuelType),
            emission: emission,
            rcImage: rcImage,
            insuranceImage: insuranceImage,
            rcStatus: rcStatus,
            financed: financed,
            carData: JSON.stringify(carData),
            uid: mode === 'add' ? generateUID(): uid,
            ownerId: mode === 'add' ? ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid : undefined
        }
        if (registrationNo === '') {
            errorMsg = 'Please enter car Registration Number!'
            error = true
        } else if (fuelType === '') {
            errorMsg = 'Please select car fuel'
            error = true
        } else if (!isValidVehicleRegistrationNumber(registrationNo)) {
            errorMsg = 'Please enter a valid car Registration Number!'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await publishCar(carObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Car added successfully!' })
                    navigateTo({route: '/car/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to add car!' })
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    carData: JSON.stringify(carObject)
                }
                const result = await updateCar(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Car updated successfully!' })
                    localStorage.removeItem('activeCar')
                    navigateTo({route: '/car/list'})
                } else
                    showAlert({ type: 'error', msg: 'Unable to update car!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getSelectedCar = carData => {
        setvehicleClass(carData?.Segment)
        setmaker(carData.name)
        setshowForm(true)
        setcarData(carData)
    }
    
    const selectFiles = async (e) => {
        let fileArray = [], fileObj = [],
        activeUploadType = e.target.id === 'choose-rc'?'rc': 'insurance'
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            const result = await getBase64(fileObj[0][i])
            let fileData = {
                url: URL.createObjectURL(fileObj[0][i]),
                data: result,
                fileName: fileObj[0][i].name
            }
            fileArray.push(fileData)
            setfile(fileArray)
            setfileArray(fileArray)
            uploadFiles(activeUploadType)
        }
    }
    
    const uploadCall = async (file, activeUploadType) => {
        let error = false,
        errorMsg = ''
        if (!error) {
            file.uid = generateUID()
            const fileResult = await save(file)
            if (fileResult.status) {
                let carObject = {
                    [activeUploadType === 'rc' ? 'rcImage' : 'insuranceImage']: '["'+fileArray[0].fileName+'"]'
                }
                let data = {
                    query: JSON.stringify({uid: carData.uid}),
                    carData: JSON.stringify(carObject)
                }
                const result = await updateCar(data)
                if (result.status) {
                    setfileArray([])
                    setfile([])
                    if (activeUploadType === 'rc') {
                        setrcImage(carObject.rcImage)
                        setshowRc(true)
                    } else {
                        setinsuranceImage(carObject.insuranceImage)
                        setshowInsurance(true)
                    }
                } else
                    showAlert({ type: 'error', msg: 'Unable to update image!' })
            } else showAlert({ type: 'error', msg: 'Unable to update image!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const uploadFiles = activeUploadType => {
        for (let i = 0; i < file.length; i++) {
            uploadCall(file[i], activeUploadType)
        }
    }
    return {
        addCar,
        carData,
        getSelectedCar,
        showForm,
        registrationNo,
        setregistrationNo,
        ownerName,
        setownerName,
        vehicleClass,
        setvehicleClass,
        fuelType,
        handleChange,
        maker,
        setmaker,
        user,
        setadditionalCarInfo,
        additionalCarInfo,
        registrationDate,
        setregistrationDate,
        chassisNo,
        setchassisNo,
        engineNo,
        setengineNo,
        fitness,
        setfitness,
        mvTax,
        setmvTax,
        insurance,
        setinsurance,
        pucc,
        setpucc,
        emission,
        setemission,
        rcStatus,
        setrcStatus,
        financed,
        setfinanced,
        rcImage,
        setshowRc,
        showRc,
        selectFiles,
        setactiveImage,
        insuranceImage,
        setshowInsurance,
        showInsurance,
        mode,
        activeImage,
    }
}