import { memo } from "react"
import {Box} from "../Box"

interface ImportResultProps {
    resultList?: any[]
    excelList?: any[]
}

export const ImportResult = memo(({resultList = [], excelList = []}: ImportResultProps) => {
    return <>
        {
            resultList.length > 0 && resultList.length !== excelList.length && <p className='fs24'>Processing...</p>
        }
        {
            resultList.length > 0 && <Box title="Import Result" overflown={false}>
                <ul className='result-box'>
                    {
                        resultList.map((e, i) => {
                            return <li className={e.success ? 'success' : 'error'} key ={i}>
                                <i className={e.success ? 'hi-check' : 'hi-cross'}></i>
                                <span className='ml10 fs10'>{JSON.stringify(e)}</span>
                            </li>
                        })
                    }
                </ul>
            </Box>
        }
    </>
})