import { brandInfo } from "../../../constant"
import { useElement } from "../../../hooks"
import { SubscriptionForm } from "../SubscriptionForm"

export const SubscriptionBar = () => {
    const Link = useElement('Link')
    const Img = useElement('Img')
    
    return <section className="subscription-bar">
        <div className="wrapper jumbo-container">
            <Link href="/" className="logo"><Img source='internal' alt={brandInfo.name.capital} className="logo-right-banner" src={brandInfo.logo} /></Link>
            <SubscriptionForm />
        </div>
    </section>
}