import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext, NavigatorContext } from '../../../../context'

export const useOfferList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const CheckBox = useElement('CheckBox')
    const {
        save,
        fetchAll,
        remove,
        update,
    } = useService('Data', 'offer')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        toIndianCurrency,
        isBottom,
        getFormattedDate,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const filterTags = require('../filterTags.json')
    const [tableData, settableData] = useState([])
    const [tableContent, settableContent] = useState({})
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [activeItem, setactiveItem] = useState(null)
    const [checkedList, setcheckedList] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [searchKey, setsearchKey] = useState<string>('')
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    setTitle('Offer List', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getOfferListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const cloneOffer = async item => {
        let offerObject = { ...item, code: item.code+'-copy', uid: generateUID() }
        const result = await save(offerObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Offer clone successfully!' })
            getOfferListData()
        } else showAlert({ type: 'error', msg: 'Unable to clone offer!' })
    }
    
    const getOfferListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash)
                        tableData.push(e)
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1)
                    activefilterValue = e.value
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No offer added yet!' })
        } else showAlert({ type: 'error', msg: 'No offer added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectOffer = () => {
        deleteOffer(activeItem.uid)
    }
    
    const deleteOffer = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                showAlert({ type: 'success', msg: 'Offer deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getOfferListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete offer!' })
        } else {
            let offerObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    offerObject = e
                }
            })
            offerObject.trash = task === 'trash'
            delete offerObject._id
            let data = {
                query: JSON.stringify({uid}),
                offerData: JSON.stringify(offerObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Offer '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getOfferListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' offer!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteOffer(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            if (item.status) {
                let e = {
                    checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                        {
                            checkedAll || checkedList.includes(item.uid) ?
                            <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                        }
                    </span>,
                    code: item.code,
                    offerRestriction: item.offerRestriction,
                    discount: item.discountType === "fixedprice" ? toIndianCurrency(item.discount):item.discount+'%',
                    expiryDate: getFormattedDate(new Date(item.expiryDate)),
                    status: <Badge item={item.status} />,
                    action: trashAction ?
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        <Actor type='undo' onClick={() => deleteOffer(item.uid, 'restore')} />
                    </div>:
                    <div className="action-bar">
                        <Actor type='trash' onClick={() => {
                            setactiveItem(item)
                            setshowTrashConfirm(true)
                        }} />
                        <Actor type='copy' onClick={() => cloneOffer(item)} />
                        <Actor type='edit' onClick={() => navigateTo({route: '/admin/offer/edit/'+item.uid})} />
                    </div>
                }
                data.push(e)
            }
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex, tableData ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData?.forEach(e => {
                tempTitle = e.code?.toLowerCase()
                if (tempTitle?.search(searchKey?.toLowerCase()) !== -1) {
                    filteredList.push(e)
                }
            })
            setfilteredList(filteredList)
        }
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Code',
                    key: 'code'
                },
                {
                    label: 'Offer Restrictions',
                    key: 'offerRestrictions'
                },
                {
                    label: 'Discount',
                    key: 'discount'
                },
                {
                    label: 'Expiry Date',
                    key: 'expiryDate'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        tableData,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectOffer,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}