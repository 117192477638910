import { memo } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { useShare } from './hooks';
import { useLocale } from '../../../hooks';
import './style.scss'

interface ShareProps {
    shareUrl?: string
}

export const Share = memo(({shareUrl}: ShareProps) => {
    const {t} = useLocale()
    const { url } = useShare({shareUrl})
    return <div className="share">
        <span className="share-text">{t('share')}</span>
        <div className="line-shape">
            <span className="bloget"></span>
        </div>
        <div className="share-icon">
            <ul className="icon-wrap">
                <li><FacebookShareButton url={url} children={<i className="hi-facebook3"></i>} /></li>
                <li><WhatsappShareButton url={url} children={<i className="hi-whatsapp"></i>} /></li>
                <li><TwitterShareButton url={url} children={<i className="hi-twitter"></i>} /></li>
                <li><LinkedinShareButton url={url} children={<i className="hi-linkedin"></i>} /></li>
            </ul>
        </div>
    </div>
})