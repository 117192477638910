import { memo } from 'react'
import {AddressTotal} from './AddressTotal'
import {CarTotal} from './CarTotal'
import {GarageTotal} from './GarageTotal'
import {JobTotal} from './JobTotal'
import {MicroServiceTotal} from './MicroServiceTotal'
import {OrderTotal} from './OrderTotal'
import {PaytmTotal} from './PaytmTotal'
import {ProductTotal} from './ProductTotal'
import {ServiceTotal} from './ServiceTotal'
import {SocietyTotal} from './SocietyTotal'
import {UserTotal} from './UserTotal'
import {PostTotal} from './PostTotal'
import {PageTotal} from './PageTotal'
import { useComponent } from '../../../hooks'
import { DashboardWidgetProps } from './types'

export const DashboardWidget = memo(({
    data,
    action,
}: DashboardWidgetProps) => {
    const Box = useComponent('Box')
    return <Box title={data.title}>
        {
            data.type === 'order-total'?
            <OrderTotal action={action} data={data} />:
            data.type === 'user-total'?
            <UserTotal action={action} data={data} />:
            data.type === 'paytm-total'?
            <PaytmTotal action={action} data={data} />:
            data.type === 'car-total'?
            <CarTotal action={action} data={data} />:
            data.type === 'job-total'?
            <JobTotal action={action} data={data} />:
            data.type === 'garage-total'?
            <GarageTotal action={action} data={data} />:
            data.type === 'product-total'?
            <ProductTotal action={action} data={data} />:
            data.type === 'service-total'?
            <ServiceTotal action={action} data={data} />:
            data.type === 'microService-total'?
            <MicroServiceTotal action={action} data={data} />:
            data.type === 'address-total'?
            <AddressTotal action={action} data={data} />:
            data.type === 'society-total'?
            <SocietyTotal action={action} data={data} />:
            data.type === 'post-total'?
            <PostTotal action={action} data={data} />:
            data.type === 'page-total'?
            <PageTotal action={action} data={data} />:
            <></>
        }
    </Box>
})