import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useBadgeList = () => {
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const Img = useElement('Img')
    const {
        save,
        remove,
        fetchAll,
    } = useService('Data', 'badge')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        showAlert,
        isBottom,
    } = useService('Misc')
    const {commonPageSize} = config
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [tableContent, settableContent] = useState({})
    const [filteredList, setfilteredList] = useState([])
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [checkedList, setcheckedList] = useState([])
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    setTitle('Badges List', 'admin')

    useEffect(() => {
        getBadgesData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const cloneBadge = async item => {
        let badgeObject = { ...item, uid: generateUID() }
        const result = await save(badgeObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Badge clone successfully!' })
            getBadgesData()
        } else showAlert({ type: 'error', msg: 'Unable to clone badge!' })
    }
    
    const deleteSelectedBadge = () => {
        deleteBadge(activeItem.uid)
    }
    
    const deleteBadge = async (uid: string) => {
        const result = await remove({ uid })
        if (result.status) {
            getBadgesData()
            showAlert({ type: 'success', msg: 'Badge deleted successfully!' })
            setactiveItem(null)
            getBadgesData()
        } else showAlert({ type: 'error', msg: 'Unable to delete app!' })
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteBadge(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const getBadgesData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data.reverse();
            settableData(tableData)
            setfilteredList(tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No badge added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No badge added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashBadge = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getBadgesData()
            showAlert({ type: 'success', msg: 'Badge deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete badge!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all">
                    <span onClick={() => setcheckedAll(!checkedAll)}>
                        {
                        checkedAll ?
                            <i className="orange hi-check-circle"></i>:
                            <i className="hi-circle"></i>
                        }
                    </span>
                </span>,
                image: item.image?
                    <Img alt={item.title} src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>,
                title: item.title,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='copy' onClick={() => cloneBadge(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label:'Image',
                    key: 'image'
                },
                {
                    label:'Title',
                    key: 'title'
                },
                {
                    label:'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(tableData)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        tableContent,
        activeShownTotal,
        deleteChecked,
        trashBadge,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}