import { PageRouteProps } from "../../types";

export const postRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/post/list",
        "module": ["Blog"],
        "component": "PostList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/post/add",
        "module": ["Blog"],
        "component": "AddPost",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/post/edit",
        "module": ["Blog"],
        "component": "AddPost",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/post/list",
        "module": ["Blog"],
        "component": "PostList",
        "type": "admin",
        "mode": "static"
    }
]