import Select from 'react-select'
import { fuelOption } from '../../../constant'
import { useAddCar } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const AddCar = () => {
    const {
        openModal,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const CarSelector = useComponent('CarSelector')
    const {
        addCar,
        carData,
        getSelectedCar,
        showForm,
        registrationNo,
        setregistrationNo,
        ownerName,
        setownerName,
        vehicleClass,
        setvehicleClass,
        fuelType,
        handleChange,
        maker,
        setmaker,
        user,
        setadditionalCarInfo,
        additionalCarInfo,
        registrationDate,
        setregistrationDate,
        chassisNo,
        setchassisNo,
        engineNo,
        setengineNo,
        fitness,
        setfitness,
        mvTax,
        setmvTax,
        insurance,
        setinsurance,
        pucc,
        setpucc,
        emission,
        setemission,
        rcStatus,
        setrcStatus,
        financed,
        setfinanced,
        rcImage,
        setshowRc,
        showRc,
        selectFiles,
        setactiveImage,
        insuranceImage,
        setshowInsurance,
        showInsurance,
        mode,
        activeImage,
    } = useAddCar()

    return <>
        <div className="right-side-profile-content">
            <div className="profile-sec wrapper">
                <div className="form-editable-section">
                    <div className="form-box">
                        <form className="account-detail flex" noValidate onSubmit={addCar}>
                            <div className="col-9 carSelectDetails">
                                <h1 className="capitalize">
                                    <BackIcon backLink='/car/list' />
                                    {mode} Car
                                </h1>
                                <div className="form-group">
                                    <label className="col-3 control-label p0">Select Car</label>
                                    <div className="col-9 controls">
                                        {
                                            mode === 'edit' ?
                                            <>
                                                {
                                                    carData !== null &&
                                                    <CarSelector getSelectedCar={getSelectedCar} getNewCars={true} activeCar={carData} />
                                                }
                                            </>:
                                            <CarSelector getSelectedCar={getSelectedCar} getNewCars={true} activeCar={null} />
                                        }
                                    </div>
                                </div>
                                {
                                    showForm &&
                                    <>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Registration No <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <Input className="input uppercase" placeholder="registration no" required name="registrationNo" value={registrationNo || ''} onChange={e => setregistrationNo(e.target.value)} />
                                                <small className="help-text ashgray">e.g. RJ14 AB 1234 --OR-- RNX AB 1234</small>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Owner Name: </label>
                                            <div className="col-9 controls">
                                                <Input className="input" placeholder="ownerName name" required name="ownerName" value={ownerName || ''} onChange={e => setownerName(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Vehicle Class</label>
                                            <div className="col-9 controls">
                                                <Input className="input" placeholder="vehicle class" disabled name="vehicleClass" value={vehicleClass || ''} onChange={e => setvehicleClass(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Fuel <sup>*</sup></label>
                                            <div className="col-9 controls">
                                                <Select
                                                    value={fuelType}
                                                    onChange={handleChange}
                                                    options={fuelOption}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-3 control-label p0">Maker / Model</label>
                                            <div className="col-9 controls">
                                                <Input className="input" placeholder="maker / model" disabled name="maker" value={maker || ''} onChange={e => setmaker(e.target.value)} />
                                            </div>
                                        </div>
                                        <hr />
                                        { !['company', 'manager', 'branch'].includes(user.role) && <div className="show-additional-car-detail">
                                            <p onClick={() => setadditionalCarInfo(!additionalCarInfo)}>Show Additional Car Details <i className={additionalCarInfo ? "mlauto additional-detail-active hi-add-circle": "mlauto hi-add-circle"}></i></p>
                                            {
                                                additionalCarInfo &&
                                                <div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Registration Date</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="registration date" required name="registrationDate" value={registrationDate || ''} onChange={e => setregistrationDate(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Chassis No</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="chassis no" required name="chassisNo" value={chassisNo || ''} onChange={e => setchassisNo(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Engine No</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="engine no" required name="engineNo" value={engineNo || ''} onChange={e => setengineNo(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Fitness/REGN Upto</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="fitness/REGN upto" required name="fitness" value={fitness || ''} onChange={e => setfitness(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">MV Tax upto</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="MV Tax upto" required name="mvTax" value={mvTax || ''} onChange={e => setmvTax(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Insurance Upto</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="insurance Upto" required name="insurance" value={insurance || ''} onChange={e => setinsurance(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">PUCC Upto</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="PUCC upto" required name="pucc" value={pucc || ''} onChange={e => setpucc(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Emission norms</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="emission norms" required name="emission" value={emission || ''} onChange={e => setemission(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">RC Status</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="RC status" required name="rcStatus" value={rcStatus || ''} onChange={e => setrcStatus(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-3 control-label p0">Financed</label>
                                                        <div className="col-9 controls">
                                                            <Input className="input" placeholder="financed" required name="financed" value={financed || ''} onChange={e => setfinanced(e.target.value)} />
                                                        </div>
                                                    </div>
                                                    {
                                                        mode === 'edit' &&
                                                        <>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label p0">Rc</label>
                                                                <div className="col-9 controls">
                                                                    {
                                                                        !showRc?
                                                                        <label htmlFor="choose-rc" className="uploadBox pointer">
                                                                            <i className="hi-upload-cloud"></i> Upload Rc
                                                                            <Input type="file" className="hidden" onChange={selectFiles} id="choose-rc" accept="image/x-png,image/gif,image/jpeg,image/png,image/webp,image/jpg" />
                                                                        </label>:
                                                                        <div className="relative">
                                                                            <div className="uploadViewBox pointer" onClick={() => {
                                                                                setactiveImage(JSON.parse(rcImage)[0])
                                                                                openModal('preview-modal')
                                                                            }}>
                                                                                <i className="hi-eye1"></i> View
                                                                            </div>
                                                                            <i title="Change Car" className="hi-refresh refresh-icon" onClick={() => setshowRc(!showRc)}></i>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="col-3 control-label p0">Insurance</label>
                                                                <div className="col-9 controls">
                                                                    {
                                                                        !showInsurance?
                                                                        <label htmlFor="choose-insurance" className="uploadBox pointer">
                                                                            <i className="hi-upload-cloud"></i> Attach Insurance
                                                                            <Input type="file" className="hidden" onChange={selectFiles} id="choose-insurance" accept="image/x-png,image/gif,image/jpeg,image/png,image/webp,image/jpg" />
                                                                        </label>:
                                                                        <div className="relative">
                                                                            <div className="uploadViewBox pointer" onClick={() => {
                                                                                setactiveImage(JSON.parse(insuranceImage)[0])
                                                                                openModal('preview-modal')
                                                                            }}>
                                                                                <i className="hi-eye1"></i> View
                                                                            </div>
                                                                            <i title="Change Car" className="hi-refresh refresh-icon" onClick={() => setshowInsurance(!showInsurance)}></i>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                        </div>}
                                        {mode !== 'view' && <Button className="btn btn-primary btn-small addcarbtn" type="submit">Submit</Button>}
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal preview-modal" id="preview-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("preview-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <Img src={activeImage} className="mauto" alt="preview" />
                </div>
            </div>
        </div>
    </>
}
