import { memo } from 'react';
import { badge } from '../../constant';
import './style.scss';
import { useBadge } from './hooks';
import { useService } from '../../hooks';

interface BadgeProps {
    item: string
    onClick?: () => void
}

export const Badge = memo(({item, onClick}: BadgeProps) => {
    const {isEmpty} = useService('Misc')
    const {
        onBadgeClick
    } = useBadge({
        onClick
    })
    return !isEmpty(item) && <span className={"badge "+item} onClick={onBadgeClick}>
        {item !== '' && <>
            <i className={badge[item]['icon']}></i>
            <span>{badge[item]['label']}</span>
        </>}
    </span>
})