import { useContext, useEffect, useState } from "react"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useCart = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Button = useElement('Button')
    const Img = useElement('Img')
    const {
        toIndianCurrency
    } = useService('Misc')
    const {setTitle} = useService('Meta')
    const {
        getLocationData,
    } = useService('Router')
    const {
        getCartData: fetchCartData,
        removeFromCart,
        createOrderData,
        emptyCart,
    } = useService('Order')
    const [cartTotalContent, setcartTotalContent] = useState(null)
    const [cartDataContent, setcartDataContent] = useState(null)
    const [cartData, setcartData] = useState([])
    const [cartTotal, setcartTotal] = useState<number>(0)
    const [productTotal, setproductTotal] = useState<number>(0)
    const [serviceTotal, setserviceTotal] = useState<number>(0)

    setTitle('Cart')
    useEffect(() => {
        getCartData()
    }, [])

    const emptyCartData = () => {
        emptyCart()
        setcartData([])
        getLocationData().reload()
    }

    const getCartData = () => {
        let derivedCartData = fetchCartData(),
        derivedCartTotal = 0, derivedServiceTotal = 0, derivedProductTotal = 0
        derivedCartData.forEach(e => {
            derivedCartTotal += Number(e.displayPrice * e.purchaseQuantity)
            if (e.type === "service") {
                derivedServiceTotal += Number(e.displayPrice * e.purchaseQuantity)
            } else {
                derivedProductTotal += Number(e.displayPrice * e.purchaseQuantity)
            }
        })
        setcartData(derivedCartData)
        setcartTotal(derivedCartTotal)
        setserviceTotal(derivedServiceTotal)
        setproductTotal(derivedProductTotal)
    }
    
    const removeProduct = async item => {
        await removeFromCart(item)
        getCartData()
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(item => {
            let e = {
                action: <span className="product-remove" onClick={() => removeProduct(item)}>×</span>,
                image: <div className="product-thumbnail">
                    {
                        item.type === 'servicePackage'?
                        <i className="hi-dropbox service-package-icon"></i>:
                        item.type === 'subscription'?
                        <i className="hi-block service-package-icon"></i>:
                        <Img src={item.image} alt={item.title} />
                    }
                </div>,
                title: item.title,
                price: typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                    <div className="mrp">
                        <p className="black m0">{toIndianCurrency(item.price)} </p>
                        <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                    </div>:
                    <>
                        <div className="mrp">
                            <p className="black m0">{toIndianCurrency(item.price)} </p>
                            <p className="gray fs10 mt-5 mb0"> + {toIndianCurrency(item.taxAmount)} (tax)</p>
                        </div>
                        <div className="sale-price">
                            <p className="black">{toIndianCurrency(item.mrp)} </p>
                        </div>
                        <div className="per-off m0">
                            {((Number(item.mrp) - Number(item.saleprice))/Number(item.mrp) * 100).toFixed(0)} % Off
                        </div>
                    </>,
                purchaseQuantity: <div className="quantity">
                    {item.purchaseQuantity}
                </div>,
                displayPrice: toIndianCurrency(item.displayPrice * item.purchaseQuantity)
            }
            data.push(e)
        })
        return data
    }
    
    const proceedToCheckOut = () => {
        createOrderData({
            cartData,
            cartTotal,
            serviceTotal,
            productTotal
        })
        setTimeout(() => {
            navigateTo({route: '/checkout'})
        }, 1000)
    }
    
    useEffect(() => {
        setcartDataContent({
            headers: [
                {
                    template: <div className="product-remove"></div>
                },
                {
                    template: <div className="product-thumbnail">Preview</div>
                },
                {
                    template: <div className="product-name">Product</div>
                },
                {
                    template: <div className="product-price">Price</div>
                },
                {
                    template: <div className="product-quantity">Quantity</div>
                },
                {
                    template: <div className="product-subtotal">Total</div>
                }
            ],
            content: getTableData(cartData),
            addonBodyContent: <tr>
                <td colSpan={6} className="actions">
                    <Button className="btn mlauto btn-primary" onClick={emptyCartData}><i className="hi-trash"></i>Empty Cart</Button>
                </td>
            </tr>
        })
    }, [cartData])

    useEffect(() => {
        setcartTotalContent({
            content: [],
            addonBodyContent: <>
                {
                    serviceTotal > 0 &&
                    <tr>
                        <th>Service Total</th>
                        <td className="priceProductPage">{toIndianCurrency(serviceTotal)}</td>
                    </tr>
                }
                {
                    productTotal > 0 &&
                    <tr>
                        <th>Product Total</th>
                        <td className="priceProductPage">{toIndianCurrency(productTotal)}</td>
                    </tr>
                }
                <tr>
                    <th><strong>Total</strong></th>
                    <td className="priceProductPage"><strong>{toIndianCurrency(cartTotal)}</strong></td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <div className="wc-proceed-to-checkout">
                            <Button className="btn btn-block btn-primary wc-forward" onClick={proceedToCheckOut}>Proceed to checkout</Button>
                        </div>
                    </td>
                </tr>
            </>,
        })
    }, [
        serviceTotal,
        productTotal,
        cartTotal
    ])
    return {
        cartTotal,
        cartDataContent,
        cartTotalContent,
    }
}