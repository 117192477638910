import { useContext, useEffect, useState } from "react"
import { base, config, sortOption } from "../../../../constant"
import { useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useShop = () => {
    const {commonPageSize} = config
    const {setTitle} = useService('Meta')
    const {isValidJson} = useService('Validation')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        isEmpty,
        toIndianCurrency,
        showAlert,
        generateUID,
        toggleBodyClass,
        isBottom,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart: pushToCart} = useService('Order')
    const {
        getProduct,
        getProductCategory,
        getProductBrand,
    } = useService('Product')
    const [activeSubscriptionType, setactiveSubscriptionType] = useState(null)
    const [activeCarType, setactiveCarType] = useState(null)
    const [derivedCategory, setderivedCategory] = useState<string>('')
    const [derivedTag, setderivedTag] = useState<string>('')
    const [filterTags, setfilterTags] = useState([])
    const [productList, setproductList] = useState([])
    const [filteredProductList, setfilteredProductList] = useState([])
    const [selectedCategory, setselectedCategory] = useState([])
    const [selectedBrands, setselectedBrands] = useState([])
    const [filterCategoryList, setfilterCategoryList] = useState([])
    const [filterBrandList, setfilterBrandList] = useState([])
    const [maxPrice, setmaxPrice] = useState(50000)
    const [minPrice, setminPrice] = useState<number>(0)
    const [filteredPrice, setfilteredPrice] = useState(50000)
    const [loadMoreBtn, setloadMoreBtn] = useState<boolean>(true)
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [selectedFilter, setselectedFilter] = useState(sortOption[1])

    const handleChange = selectedFilter => {
        setselectedFilter(selectedFilter)
        if (selectedFilter.value === 'name')
        filteredProductList.sort( compare )
        else
        filteredProductList.sort( compareByPrice )
        setfilteredProductList(filteredProductList)
    }

    setTitle('Accessories')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname), derivedCategory = '', derivedTag = ''
        if (urlData[3])
            setderivedTag(urlData[3])
        else if (urlData[2])
            setderivedCategory(urlData[2])
        getProductCategoryData()
        getProductBrandData()
        getProductsData()
        document.addEventListener('scroll', trackScrolling)
        return () => {
            document.removeEventListener('scroll', trackScrolling)
        }
    }, [])
    
    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredProductList.length > 24 && activeShownTotal < filteredProductList.length)
                showMore()
        }
    }
    
    const getProductsData = async () => {
        const result = await getProduct({status: 'publish'})
        if (result.status) {
            if (result.data?.length > 0) {
                let newProductList = result.data.reverse(), productList = [], tableData = []
                newProductList.forEach(e => {
                    let found = false
                    if (derivedTag !== '')
                        found = e.tag.includes(derivedTag)
                    else if (derivedCategory !== '' && isValidJson(e.category)) {
                        JSON.parse(e.category).forEach(c => {
                            if (c.value === derivedCategory)
                                found = true
                        })
                    } else
                        found = true
                    if (found)
                        productList.push(e)
                })
                productList.forEach(e => {
                    if(!e.category) {
                        e.category = e.categories
                    }
                    if (typeof e.trash === "undefined")
                        tableData.push(e)
                    else if (!e.trash)
                        tableData.push(e)
                })
                handleChange(selectedFilter)
                setproductList(tableData)
                setfilteredProductList(tableData)
            }
        }
    }
    
    const getProductCategoryData = async () => {
        const result = await getProductCategory()
        if (result.data?.length !== 0)
            setfilterCategoryList(result.data)
    }
    
    const getProductBrandData = async () => {
        const result = await getProductBrand()
        if (result.data?.length !== 0) {
            setfilterBrandList(result.data)
        }
    }
    
    const productAddedTemplate = item => {
        let price = item.price
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp ) {
            price = item.saleprice
        }
        let prodcutAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Product added to cart!</p></div>'+
        '<div class="product-box">'+
        '<img src="'+base.imageBaseUrl+item.image+'" alt="'+item.title+'" class="product-image" />'+
        '<div class="product-content">'+
        '<p class="product-title">'+item.title+'</p>'+
        '<p class="product-price">'+toIndianCurrency(item.displayPrice)+'</p>'+
        '</div>'+
        '</div>'
        showAlert({ type: 'custom', template: prodcutAddedTemplate })
        setactiveCarType(null)
        setTimeout(() => {
            setactiveCarType(null)
            setactiveSubscriptionType(null)
        }, 2000)
    }
    
    const addToCart = item => {
        let price = item.price, mrp = price, saleprice = item.saleprice
        if ( item.saleprice && item.saleprice !== 0 && item.saleprice !== '' && item.saleprice !== item.mrp )
            price = saleprice
        const taxAmount = Number((price * Number(item.tax)/(100+Number(item.tax))).toFixed(2))
        let product = {
            uid: item.uid,
            title: item.title,
            image: JSON.parse(item.image)[0],
            purchaseQuantity: 1,
            type: 'product',
            tempUid: generateUID(),
            mrp: Number(mrp),
            saleprice,
            tax: item.tax,
            hsn: item.hsn,
            sku: item.sku,
            taxAmount,
            price: price - taxAmount,
            displayPrice: price,
        };
        pushToCart(product)
        productAddedTemplate(product)
    }
    
    const toggleSelectedCategory = (category, action) => {
        let newFilteredProductList = [], derivedFilterTags = filterTags, derivedSelectedCategory = selectedCategory, derivedfilteredProductList = filteredProductList
        if (action === 'push') {
            let cat = {
                label: category.catName,
                value: category.catSlug,
                type: 'category'
            }
            derivedFilterTags.push(cat)
            derivedSelectedCategory.push(cat)
        } else {
            let newSelectedCategory = [], newFilterTags = []
            selectedCategory.forEach(e => {
                if (category.catSlug) {
                    if (e.value !== category.catSlug)
                        newSelectedCategory.push(e)
                } else {
                    if (e.value !== category.value)
                        newSelectedCategory.push(e)
                }
            })
            filterTags.forEach(e => {
                if (category.catSlug) {
                    if (e.value !== category.catSlug)
                        newFilterTags.push(e)
                } else {
                    if (e.value !== category.value)
                        newFilterTags.push(e)
                }
            })
            derivedFilterTags = newFilterTags
            derivedSelectedCategory = newSelectedCategory
        }
        if (selectedCategory.length === 0) {
            derivedfilteredProductList = productList
        } else {
            productList.forEach(e => {
                let found = false
                if (derivedTag !== '')
                    found = e.tag.includes(derivedTag)
                if (isValidJson(e.category)) {
                    JSON.parse(e.category).forEach(c => {
                        selectedCategory.filter(sc => {
                            if (c.value === sc.value)
                                found = true
                        })
                    })
                }
                if (found)
                    newFilteredProductList.push(e)
            })
            derivedfilteredProductList = newFilteredProductList
        }
        setselectedCategory(derivedSelectedCategory)
        setfilteredProductList(derivedfilteredProductList)
        setfilterTags(derivedFilterTags)
    }
    
    const toggleSelectedBrands = (brands, action) => {
        let newFilteredProductList = [], derivedfilterTags = filterTags, derivedselectedBrands = selectedBrands
        if (action === 'push') {
            let brand = {
                label: brands.brandName,
                value: brands.brandSlug,
                type: 'brand'
            }
            derivedfilterTags.push(brand)
            derivedselectedBrands.push(brand)
        } else {
            let newSelectedBrands = [], newFilterTags = []
            selectedBrands.forEach(e => {
                if (brands.brandSlug) {
                    if (e.value !== brands.brandSlug)
                        newSelectedBrands.push(e)
                } else {
                    if (e.value !== brands.value)
                        newSelectedBrands.push(e)
                }
            })
            filterTags.forEach(e => {
                if (brands.brandSlug) {
                    if (e.value !== brands.brandSlug)
                        newFilterTags.push(e)
                } else {
                    if (e.value !== brands.value)
                        newFilterTags.push(e)
                }
            })
            derivedfilterTags = newFilterTags
            derivedselectedBrands = newSelectedBrands
        }
        if (selectedBrands.length === 0) {
            setfilteredProductList(productList)
        } else {
            productList.forEach(e => {
                let found = false
                if (!isEmpty(e.brand)) {
                    selectedBrands.forEach(sc => {
                        let brand = JSON.parse(e.brand)
                        if (brand !== null && brand) {
                            if (sc.value === brand.value)
                                found = true
                        }
                    })
                    if (found)
                        newFilteredProductList.push(e)
                }
            })
            setfilteredProductList(newFilteredProductList)
        }
        setselectedBrands(derivedselectedBrands)
        setfilterTags(derivedfilterTags)
    }
    
    const compare = ( a, b, key = 'title' ) => {
        if ( a[key] < b[key] )
            return -1
        if ( a[key] > b[key] )
            return 1
        return 0
    }
    
    const compareByPrice = ( a, b ) => {
        if ( Number(a.price) < Number(b.price) )
            return -1
        if ( Number(a.price) > Number(b.price) )
            return 1
        return 0
    }
    
    const toggleSideFilter = () => {
        toggleBodyClass('nav-open-filter')
    }

    const filterByPrice = price => {
        let newFilteredProductList = [], derivedfilteredProductList = filteredProductList
        setfilteredPrice(price)
        productList.forEach(e => {
            let found = false
            if (derivedTag !== '') {
                found = e.tag.includes(derivedTag)
            } else if (selectedCategory.length !== 0 && isValidJson(e.category)) {
                JSON.parse(e.category).forEach(c => {
                    selectedCategory.forEach(sc => {
                        if (c.value === sc.value) {
                            found = true
                        }
                    })
                })
            } else
                found = true
            if (Number(e.price) > Number(price))
                found = false
            if (found)
                newFilteredProductList.push(e)
            derivedfilteredProductList = newFilteredProductList
        })
        setselectedCategory(selectedCategory)
        setfilteredProductList(derivedfilteredProductList)
    }
    
    const showMore = () => {
        showPreloader()
        setloadMoreBtn(false)
        setTimeout(() => {
            hidePreloader()
            setloadMoreBtn(true)
            setactiveShownTotal(activeShownTotal + 24)
        }, 500)
    }
    let featuredProductList = []
    productList.forEach(e => {
        if (featuredProductList.length <= 10 && e.status === 'publish' && e.isFeatured)
            featuredProductList.push(e)
    })
    let pageTitle = derivedCategory ===''?'Accessories': derivedCategory.replace(/-/g, ' ')
    return {
        activeShownTotal,
        addToCart,
        selectedCategory,
        toggleSelectedCategory,
        derivedCategory,
        selectedBrands,
        toggleSelectedBrands,
        pageTitle,
        filterByPrice,
        minPrice,
        maxPrice,
        filteredPrice,
        filterCategoryList,
        filterBrandList,
        featuredProductList,
        derivedTag,
        filterTags,
        toggleSideFilter,
        selectedFilter,
        handleChange,
        filteredProductList,
    }
}