import { brandInfo } from '../../../constant'
import { useElement } from '../../../hooks'
import './style.scss'
const secureIndex = [
    {
        title: 'TRUSTPAY',
        content: '100% safe online payments.'
    },
    {
        title: 'PROFESSIONAL & TRAINED STAFF',
        content: 'In house training and certification programs.'
    },
    {
        title: '100% HAPPINESS GUARANTEE',
        content: 'Customer centricity is our core value.'
    },
    {
        title: 'HIGH QUALITY',
        content: 'We work with established branded materials.'
    },
    {
        title: 'BEST IN CLASS SERVICES / PRODUCTS',
        content: 'All services we provide are value for money.'
    }
]
export const SecureSection = () => {
    const Img = useElement('Img')
    return <section className="secure-sec mb60-mbs40">
        <div className="jumbo-container">
            <div className='wrapper'>
                <div className="main-secure">
                    <div className="why-carmitra">
                        <h2 className='heading-02'>Why to choose {brandInfo.name.capital}?</h2>
                    </div>
                    {
                        secureIndex.map((item, index) => {
                            return <div className="item flex" key={index}>
                                <div className='index-box'>0{index+1}</div>
                                <div className="main-box">
                                    <h4>{item.title}</h4>
                                    <p className='fs16'>{item.content}</p>
                                </div>
                            </div>
                        })
                    }
                </div>
                <Img src="secure-tiles.jpg" />
            </div>
        </div>
    </section>
}