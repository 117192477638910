import Select from 'react-select'
import { featuredSetting, sortOption } from '../../../constant'
import { useShop } from './hooks'
import { useElement, useService, useWidget } from '../../../hooks'
import './style.scss'
import { NavigatorContext } from '../../../context'
import { useContext } from 'react'
export const Shop = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        toIndianCurrency,
        isEmpty,
        toggleBodyClass,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const Input = useElement('Input')
    const Banner = useWidget('Banner')
    const Rating = useElement('Rating')
    const Slider = useElement('Slider')
    const {
        activeShownTotal,
        addToCart,
        selectedCategory,
        toggleSelectedCategory,
        derivedCategory,
        selectedBrands,
        toggleSelectedBrands,
        pageTitle,
        filterByPrice,
        minPrice,
        maxPrice,
        filteredPrice,
        filterCategoryList,
        filterBrandList,
        featuredProductList,
        derivedTag,
        filterTags,
        toggleSideFilter,
        selectedFilter,
        handleChange,
        filteredProductList,
    } = useShop()
    const renderFilterTags = filterTags => {
        return filterTags.map(( item, index ) => {
            return <li onClick={() => {
                if (item.type === 'brand')
                    toggleSelectedBrands(item, 'pop')
                else
                    toggleSelectedCategory(item, 'pop')
            }} key={index}>{item.label} <i className="hi-close"></i></li>
        })
    }
    const renderShopProducts = shopFiles => {
        return shopFiles.slice(0, activeShownTotal).map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            return <div className="grid-list-column-item" key={index}>
                {
                    item.isAssured &&
                    <Img className="assuredImg" src={'assured.png'} alt={item.title} />
                }
                <div className="thumb">
                    {
                        <Img src={(item.image === '' || item.image === '[]' || typeof item.image === "undefined"?'product-placeholder.png':JSON.parse(item.image)[0])} alt={item.title} />
                    }
                </div>
                <h5 className="title"><Link title={item.title} href={"/product/"+item.slug}>{item.title}</Link></h5>
                {
                    item.feedback &&
                    <div className="common-rating-style">
                        {averageRating && <Rating rating={averageRating} />}
                    </div>
                }
                {
                    typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                    <div className="mrp">
                        <span className="black">{toIndianCurrency(item.price)}</span>
                    </div>:
                    <div className="flex">
                        <div className="mrp mrauto">
                            <span className="black">{toIndianCurrency(item.saleprice)}</span>
                        </div>
                        <div className="sale-price mlauto">
                            <span className="black">{toIndianCurrency(item.price)}</span>
                        </div>
                        <div className="per-off">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % Off
                        </div>
                    </div>
                }
                <div className="main-btn-wrap pt20">
                    {
                        isEmpty(item.stock)?
                        <Button disabled className="btn btn-gray btn add-to-cart mauto" title="Out Of Stock">Out Of Stock</Button>:
                        <Button className="btn btn-primary btn-block btn-small" onClick={() => addToCart(item)}>Add To Cart<i className='hi-cart'></i></Button>
                    }
                </div>
            </div>
        })
    }
    const renderFeaturedProductList = productFiles => {
        return productFiles.map(( item, index ) => {
            let averageRating = 0
            if (item.feedback) {
                if (item.feedback.length>0) {
                    item.feedback.forEach(e => {
                        averageRating += e.rating
                    })
                    averageRating = averageRating/item.feedback.length
                }
            }
            return <li className="single-recent-post-item grid-list-column-item" key={index}>
                {
                    item.isAssured &&
                    <Img className="assuredImg" src={'assured.png'} alt={item.title} />
                }
                <div className="thumb">
                    {
                        item.image === '' || item.image === '[]' || typeof item.image === "undefined"?
                        <Img src={'product-placeholder.png'} alt={item.title} />:
                        <Img className="pointer" onClick={() => navigateTo({route: '/product/'+item.slug})} src={JSON.parse(item.image)[0]} alt={item.title} />
                    }
                </div>
                <div className="content">
                    <h5>
                        <Link href={"/product/"+item.slug}>{item.title}</Link>
                    </h5>
                    <div className="common-rating-style left">
                        {averageRating && <Rating rating={averageRating} />}
                    </div>
                    {
                        typeof item.saleprice === "undefined" || item.saleprice === 0 || item.saleprice === '' || item.saleprice === item.mrp ?
                        <div className="mrp">
                            <span className="black">{toIndianCurrency(item.price)}</span>
                        </div>:
                        <div className="flex">
                            <div className="mrp mrauto">
                                <span className="black">{toIndianCurrency(item.saleprice)}</span>
                            </div>
                            <div className="sale-price mlauto">
                                <span className="black">{toIndianCurrency(item.price)}</span>
                            </div>
                            <div className="per-off">{(( item.price - item.saleprice)/item.price * 100).toFixed(0)} % Off
                            </div>
                        </div>
                    }
                </div>
            </li>
        })
    }
    const renderSubCategories = (filterCategoryList, parentName) => {
        return filterCategoryList?.map(( item, index ) => {
            let isChecked = false
            selectedCategory.forEach(e => {
                if (e.value === item.catSlug)
                    isChecked = true
            })
            return item.parentCat === parentName && <div className="select-category-box select-subcategory-box" key={index}>
                {
                    isChecked ?
                    <i className="hi-checkbox orange" onClick={() => toggleSelectedCategory(item, 'pop')}></i>:
                    <i className="hi-checkbox-unchecked1" onClick={() => toggleSelectedCategory(item, 'push')}></i>
                }
                <span>{item.catName}</span>
            </div>
        })
    }
    
    const renderFilterCategoryList = filterCategoryList => {
        return filterCategoryList?.map(( item, index ) => {
            let isChecked = false
            selectedCategory.forEach(e => {
                if (e.value === item.catSlug)
                    isChecked = true
            })
            if (derivedCategory === '') {
                return item.parentCat === '' && <div className="select-category-box" key={index}>
                    {
                        isChecked ?
                        <i className="hi-checkbox orange" onClick={() => toggleSelectedCategory(item, 'pop')}></i>:
                        <i className="hi-checkbox-unchecked1" onClick={() => toggleSelectedCategory(item, 'push')}></i>
                    }
                    <Link href={"/accessories/"+item.catSlug}>{item.catName}</Link>
                    { renderSubCategories(filterCategoryList || [], item.catName) }
                </div>
            } else {
                return item.parentCat === '' && item.catSlug === derivedCategory && <div className="select-category-box" key={index}>
                    <i className="hi-checkbox orange"></i>
                    <span>{item.catName}</span>
                    { renderSubCategories(filterCategoryList || [], item.catName) }
                </div>
            }
        })
    }
    
    const renderFilterBrandList = filterBrandList => {
        return filterBrandList?.map(( item, index ) => {
            let isChecked = false
            selectedBrands.forEach(e => {
                if (e.value === item.brandSlug)
                    isChecked = true
            })
            return <div className="select-category-box" key={index}>
                {
                    isChecked ?
                    <i className="hi-checkbox orange" onClick={() => toggleSelectedBrands(item, 'pop')}></i>:
                    <i className="hi-checkbox-unchecked1" onClick={() => toggleSelectedBrands(item, 'push')}></i>
                }
                <span>{item.brandName}</span>
            </div>
        })
    }
    return <>
        <Banner title={pageTitle} image="accessories-6.jpg"/>
        <div onClick={() => toggleBodyClass('cart-open')} className="background-fixed-shop-filter"></div>
        <div className="product-page shop-page">
            <section className="product-page-section">
                <div className="product-page-body">
                    <div className="product-filter-wrapper pt40">
                        <Button className="btn btn-primary btn-small btn-filter-done" onClick={() => toggleBodyClass('cart-open')}>Done</Button>
                        <h5 className="filters-heading">Filters</h5>
                        <div className="widget blog-bg">
                            <h5 className="widget-title border-bottom">Filter By Price</h5>
                            <div className="price-filter">
                                <Input type="range" step={500} min={minPrice} max={maxPrice} value={filteredPrice} onChange={e => filterByPrice(e.target.value)} />
                                <div className="filterPrice">
                                    <span>{toIndianCurrency(minPrice)}</span> <span>{toIndianCurrency(maxPrice)}</span>
                                </div>
                                <div className="priceShow" style={{marginLeft: (filteredPrice*100)/maxPrice+'%' }}> {toIndianCurrency(filteredPrice)}</div>
                            </div>
                        </div>
                        <div className="widget blog-bg">
                            <h5 className="widget-title border-bottom">Filter By Category</h5>
                            <div className="price-filter-category">
                                { renderFilterCategoryList(filterCategoryList || []) }
                            </div>
                        </div>
                        <div className="widget blog-bg">
                            <h5 className="widget-title border-bottom">Filter By Brands</h5>
                            <div className="price-filter-category">
                                { renderFilterBrandList(filterBrandList || []) }
                            </div>
                        </div>
                        <div className="featured-group-sidebar">
                            <Link href="/">
                                <Img src={"iftex-clean-D-02.jpg"} />
                            </Link>
                        </div>
                    </div>
                    <div className="product-page-wrapper">
                        <div className="product-filtering-area pt40">
                            <div className="filter-left flex pointer" id="btnContainer">
                                <span onClick={() => toggleBodyClass('cart-open')} className="mr10 btn-filter-box-shop"><i className="hi-menu1 btn-menu-filter mr10"></i>Filters</span>
                                <ul className="filter-tags">
                                    {
                                        derivedTag !== '' &&
                                        <li className="tagger" onClick={() => navigateTo({route: '/accessories'})}>{derivedTag} <i className="hi-close"></i></li>
                                    }
                                    { renderFilterTags(filterTags||[]) }
                                </ul>
                            </div>
                            <div className="filter-right">
                                <span className="hi-filter filter-icon" onClick={toggleSideFilter}></span>
                                <span className="sorting-text">Sort by</span>
                                <div className="custom-select-box">
                                    <Select
                                        value={selectedFilter}
                                        onChange={handleChange}
                                        options={sortOption}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* {isModuleEnabled(["FeaturedProducts"]) && <div className="recent-products-box">
                            <h5 className="filters-heading">Featured Products</h5>
                            <ul className="recent-products">
                                <Slider {...featuredSetting}>
                                    { renderFeaturedProductList(featuredProductList || []) }
                                </Slider>
                            </ul>
                        </div>} */}
                        <div className="grid-list-wrapper pv40" id="list-wrapper">
                            { renderShopProducts(filteredProductList || []) }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}
