import { useElement } from '../../../hooks'

export const KeyFeatures = () => {
    const Img = useElement('Img')
    return <div className="key-features jumbo-container">
        <div className="items-box">
            <div className="items">
                <Img source='internal' src={require('../../../../assets/images/car-accessories.png')} className="icon-image" />
                <div className="text-part">
                    <h4>Car Accessories</h4>
                    <p>100% genuine accessories</p>
                </div>
            </div>
            <div className="items">
                <Img source='internal' src={require('../../../../assets/images/monthly-packegs.png')} className="icon-image" />
                <div className="text-part">
                    <h4>Monthly Packages</h4>
                    <p>Choose the plan that's right for you</p>
                </div>
            </div>
            <div className="items">
                <Img source='internal' src={require('../../../../assets/images/service.png')} className="icon-image" />
                <div className="text-part">
                    <h4>Services</h4>
                    <p>Best in car service</p>
                </div>
            </div>
        </div>
    </div>
}