import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useServiceCategory = () => {
    const Actor = useElement('Actor')
    const {commonPageSize} = config
    const {setTitle} = useService('Meta')
    const {
        getServiceCategory,
        deleteServiceCategory,
        addServiceCategory: publishServiceCategory,
    } = useService('Service')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        closeModal,
        showAlert,
        slugify,
        isBottom,
    } = useService('Misc')
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [categoryList, setcategoryList] = useState([])
    const [catName, setcatName] = useState<string>('')
    const [catSlug, setcatSlug] = useState<string>('')
    const [renderSlug, setrenderSlug] = useState<boolean>(true)
    const [activeItem, setactiveItem] = useState(null)
    const [parentCat, setparentCat] = useState(null)
    const [categoryOptions, setcategoryOptions] = useState([])
    const [uid, setuid] = useState<string>('')
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [filteredList, setfilteredList] = useState([])

    setTitle('Service Category', 'admin')
    useEffect(() => {
        getServiceCategoryData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getServiceCategoryData = async () => {
        const result = await getServiceCategory()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No Categorys placed yet!' })
                setcategoryList([])
                setcategoryOptions([])
            } else {
                let categoryList = [],
                categoryOptions = []
                result.data.reverse().forEach(e => {
                    categoryList.push(e)
                    categoryOptions.push({label: e.catName, value: e.catSlug})
                })
                setcategoryList(categoryList)
                setcategoryOptions(categoryOptions)
            }
        }
    }
    
    const categoryCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashCategory = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await deleteServiceCategory(query)
        if (result.status) {
            getServiceCategoryData()
            showAlert({ type: 'success', msg: 'Category deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete Category!' })
    }
    
    const addServiceCategory = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '', parentCategory = ''
        if (parentCat) {
            parentCategory = parentCat.label
        }
        let categoryObject = {
            catName: catName,
            catSlug: catSlug,
            parentCat: parentCategory,
            uid: generateUID(),
        }
        if (catName === '' || catSlug === '') {
            errorMsg = 'Please enter Category content!'
            error = true
        }
        if (!error) {
            const result = await publishServiceCategory(categoryObject)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Category added successfully!' })
                setcatName('')
                setcatSlug('')
                setparentCat('')
                setuid('')
                closeModal("category-modal")
                getServiceCategoryData()
            } else {
                showAlert({ type: 'error', msg: 'Unable to add Category!' })
                setcatName('')
                setcatSlug('')
                setparentCat('')
                setuid('')
                closeModal("category-modal")
                getServiceCategoryData()
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                catName: <p className="query mb40">{item.catName}</p>,
                catSlug:<p className="ans mb40">{item.catSlug}</p>,
                parentCat:<p className="ans mb40">{item.parentCat}</p>,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }

    const addCategoryName = (e) => {
        let tempTitle = e.target.value, tempSlug = catSlug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        setcatName(tempTitle)
        setcatSlug(tempSlug)
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Category Name',
                    key: 'categoryName'
                },
                {
                    label: 'Slug',
                    key: 'slug'
                },
                {
                    label: 'Parent Category',
                    key: 'parentCategory'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(categoryList)
        })
    }, [categoryList])
    return {
        trackScrolling,
        tableContent,
        activeShownTotal,
        trashCategory,
        categoryCancel,
        showTrashConfirm,
        setcatName,
        setuid,
        addServiceCategory,
        catName,
        addCategoryName,
        catSlug,
        setcatSlug,
        parentCat,
        setparentCat,
        categoryOptions,
    }
}