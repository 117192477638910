import { PageRouteProps } from "../../types";

export const appRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/app/list",
        "module": ["Blog"],
        "component": "AppList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/app/add",
        "module": ["Blog"],
        "component": "AddApp",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/app/edit",
        "module": ["Blog"],
        "component": "AddApp",
        "type": "admin",
        "mode": "dynamic"
    }
]