import { memo } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { base } from '../../constant'

interface PIEditorProps {
    value?: string
    initialValue?: string
    onEditorChange?: () => void
}

export const PIEditor = memo(({
    value = '',
    initialValue = '',
    onEditorChange,
}: PIEditorProps) => {
    return <Editor
        apiKey={base.tinyMceApiKey}
        value={value}
        initialValue={initialValue}
        init={base.tinyMceEditorOptions}
        onEditorChange={onEditorChange}
    />
})