import { useContext, useEffect, useState } from 'react'
import { config, searchRegex } from '../../../../constant'
import { useElement, useService } from '../../../../hooks'
import { AppDataContext, NavigatorContext } from '../../../../context'

export const useAppList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const CheckBox = useElement('CheckBox')
    const {
        fetchAll,
        remove,
        save,
    } = useService('Data', 'app')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        generateUID,
        isBottom,
    } = useService('Misc')
    const { commonPageSize } = config
    const appStatus = require('../appStatus.json')
    const [tableContent, settableContent] = useState({})
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState<string>('')
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [activeItem, setactiveItem] = useState(null)
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(appStatus[0])

    setTitle('App List', 'admin')
    useEffect(() => {
        getAppListData()
    }, [])

    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashApp = async () => {
        const result = await remove({uid: activeItem.uid})
        if (result.status) {
            getAppListData()
            showAlert({ type: 'success', msg: 'App deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete App!' })
    }
    
    const getAppListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = result.data
            settableData(tableData)
            setfilteredList(tableData)
            let activefilterValue = 'all'
            appStatus.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No apps added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No apps added yet!' })
    }
    
    const deleteSelectedApp = () => {
        deleteApp(activeItem.uid)
    }
    
    const deleteApp = async (uid: string) => {
        const result = await remove({ uid })
        if (result.status) {
            getAppListData()
            showAlert({ type: 'success', msg: 'App deleted successfully!' })
            setactiveItem(null)
            getAppListData()
        } else showAlert({ type: 'error', msg: 'Unable to delete app!' })
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteApp(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const cloneApp = async item => {
        let appObject = { ...item, uid: generateUID() }
        const result = await save(appObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'App clonsed successfully!' })
            getAppListData()
        } else showAlert({ type: 'error', msg: 'Unable to add app!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                title: item.title,
                id: item.id,
                author: item.author,
                status: <Badge item={item.status} />,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/app/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => cloneApp(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const filterItems = ( key, activeFilterIndex ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }

    useEffect(() => {
        settableContent({
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'App Id',
                    key: 'id'
                },
                {
                    label: 'Author',
                    key: 'author'
                },
                {
                    label: 'App Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        appStatus,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        trashApp,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}