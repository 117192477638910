import { useElement, useService } from '../../../hooks'
import { useSubscriptionData } from './hooks'

interface SubscriptionDataProps {
    activeSubscription: string
}

export const SubscriptionData = ({
    activeSubscription
}: SubscriptionDataProps) => {
    const {
        toIndianCurrency,
        isEmpty,
    } = useService('Misc')
    const Button = useElement('Button')
    const {
        purchaseGoldSubscription,
        purchaseSilverSubscription,
        silverInCart,
        goldInCart,
        purchase,
    } = useSubscriptionData({
        activeSubscription
    })
    return <div className="pricing-table-background">
        {
            !isEmpty(activeSubscription) &&
            <h1 className="plan-heading">Active Subscription</h1>
        }
        <div className="pricing-table-grid-box mt20 mb20">
            <div className="pricing-detail-box">
                <div className="grid-Box">
                    <div className="upper-part">
                        <h1 className="plan-heading"><span className="blank">blank</span></h1>
                    </div>
                    <div className="middle-part">
                        <div className="features"> Services <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Accessories <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Parts / Lubes <i className="hi-arrow-circle-right"></i></div>
                        <div className="features"> Labour  <i className="hi-arrow-circle-right"></i></div>
                    </div>
                </div>
            </div>
            <div className="pricing-table-grid pl0">
                {
                    isEmpty(activeSubscription) &&
                    <>
                        <div className="grid-Box pb20">
                            <div className="upper-part">
                                <h1 className="plan-heading">SILVER</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                                <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> NA </div></div>
                                <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                            </div>
                                <p className="tac">({toIndianCurrency(99)} / MONTH)</p>
                            {
                                purchaseSilverSubscription?
                                <div className="bottom-part">
                                    <Button onClick={() => purchase('Silver')} className="btn btn-primary btn-box">Buy Now</Button>
                                </div>:<>
                                    { silverInCart && <p className="tac">Already In the Cart</p>}
                                </>
                            }
                        </div>
                        <div className="grid-Box grid-box-center">
                            <div className="upper-part">
                                <h1 className="plan-heading">GOLD</h1>
                            </div>
                            <div className="middle-part">
                                <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                                <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> 5.00% </div></div>
                                <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                            </div>
                                <p className="tac"> ({toIndianCurrency(249)} / QUARTER)</p>
                            {
                                purchaseGoldSubscription?
                                <div className="bottom-part">
                                    <Button onClick={() => purchase('Gold')} className="btn btn-primary btn-box">Buy Now</Button>
                                </div>:<>
                                    {goldInCart && <p className="tac">Already In the Cart</p>}
                                </>
                            }
                            <div className="most-popular">Most Popular</div>
                        </div>
                    </>
                }
                {
                    activeSubscription === 'silver' ?
                    <div className="grid-Box pb20">
                        <div className="upper-part">
                            <h1 className="plan-heading">SILVER</h1>
                            <p>({toIndianCurrency(99)} / MONTH)</p>
                        </div>
                        <div className="middle-part">
                            <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                            <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                            <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> NA </div></div>
                            <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                        </div>
                        {
                            isEmpty(activeSubscription) &&
                            <div className="bottom-part">
                                <Button onClick={() => purchase('Silver')} className="btn btn-primary btn-box">Buy Now</Button>
                            </div>
                        }
                    </div>
                    : activeSubscription === 'gold' &&
                    <div className="grid-Box grid-box-center">
                        <div className="upper-part">
                            <h1 className="plan-heading">GOLD</h1>
                            <p>({toIndianCurrency(249)} / QUARTER)</p>
                        </div>
                        <div className="middle-part">
                            <div className="features box11"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                            <div className="features box12"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00%</div></div>
                            <div className="features box13"> <div className="feature-details"> <i className="hi-check-circle"></i> 5.00% </div></div>
                            <div className="features box14"> <div className="feature-details"> <i className="hi-check-circle"></i> 10.00% </div></div>
                        </div>
                        {
                            isEmpty(activeSubscription) &&
                            <div className="bottom-part">
                                <Button onClick={() => purchase('Gold')} className="btn btn-primary btn-box">Buy Now</Button>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    </div>
}
