import { memo } from "react"
import { useElement } from "../../../hooks"
import {Box} from "../Box"
import { useInputBox } from "./hooks"

interface InputBoxProps {
    type: string
    value: number | string
    setvalue: (e: number | string) => void
    title?: string
    key?: string
    multi?: boolean
    className: string
    placeholder?: string 
    required?: boolean
}

export const InputBox = memo(({
    type = 'text',
    value = '',
    setvalue,
    title = '',
    key = '',
    multi = false,
    className = '',
    placeholder = '',
    required = false
}: InputBoxProps) => {
    const Input = useElement('Input')
    const { setInputValue } = useInputBox({setvalue})
    return <Box title={title} required>
        {
            type === 'text' ?
                multi ?
                    <textarea className={className} placeholder={placeholder} required={required} name="css" value={value} onChange={setInputValue}></textarea>
                    :<Input required name={key} value={value} onChange={setInputValue} />
            :<></>
        }
    </Box>
})
