import { memo } from 'react'
import { useInput } from './hooks'

interface InputProps {
    className?: string
    placeholder?: string
    type?: string
    required?: boolean
    id?: string
    name?: string
    value: string
    onChange: () => void
    setValue: () => void
    accept?: string
    multiple?: boolean
    min?: number | string
    max?: number | string
}

export const Input = memo(({
    className="input",
    placeholder="",
    type="text",
    required,
    id,
    name='',
    value,
    onChange=() => {},
    setValue=() => {},
    accept,
    multiple,
    min,
    max,
}: InputProps) => {
    const {
        onChangeInput
    } = useInput({
        onChange,
        setValue
    })
    return <input
        id={id}
        min={min}
        max={max}
        className={className}
        placeholder={placeholder}
        accept={accept}
        multiple={multiple}
        type={type}
        required={required}
        name={name}
        value={value}
        onChange={onChangeInput}
    />
})