import { PageRouteProps } from "../../types";

export const projectRoutes: PageRouteProps[] = [
    {
        "pathName": "/projects",
        "module": ["Project"],
        "component": "ProjectsList",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "/project",
        "module": ["Project"],
        "component": "Project",
        "type": "public",
        "mode": "dynamic"
    }
]