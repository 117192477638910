import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useModuleList = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'setting')
    const {getLoggedInUserData} = useService('User')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        generateUID,
        isBottom,
    } = useService('Misc')
    const {commonPageSize} = config
    const user = getLoggedInUserData()
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [showModulesModal, setshowModulesModal] = useState<boolean>(false)
    const [activeModule, setactiveModule] = useState<string>('')
    const [module, setmodule] = useState([])
    const [mode, setmode] = useState('edit')
    const [filteredList, setfilteredList] = useState([])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const toggleModuleStatus = (e) => {
        let targetStatus = e.status === 'active' ? 'inactive' : 'active', moduleData = []
        module.forEach(i => {
            if(i.module === e.module) {
                i.status = targetStatus
            }
            moduleData.push(i)
        })
        setmodule(moduleData)
        saveModulesData(moduleData)
    }
    
    const deleteModule = (e) => {
        let moduleData = []
        module.forEach(i => {
            if(i.module !== e.module) {
                moduleData.push(i)
            }
        })
        setmodule(moduleData)
        saveModulesData(moduleData)
    }
    
    const fetchModules = async () => {
        let query = {
            key: 'active-modules'
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length === 0) {
                setmode('add')
            } else {
                let moduleData = JSON.parse(result.data[0].value).reverse()
                setmodule(moduleData)
            }
        }
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                label: item.module,
                status: <Badge item={item.status} onClick={() => toggleModuleStatus(item)} />,
                actions: <div className='action-bar'>
                    <Actor type='trash' onClick={() => deleteModule(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const saveModulesData = async (moduleData) => {
        let settingObject = {
            key: 'active-modules',
            userId: user.uid,
            value: JSON.stringify(moduleData),
            uid: generateUID(),
        }
        if (mode === 'add') {
            save(settingObject)
            setmode('edit')
        } else {
            let data = {
                query: JSON.stringify({
                    key: 'active-modules',
                    userId: user.uid
                }),
                settingData: JSON.stringify({value: settingObject.value})
            }
            update(data)
        }
    }
    
    const adModule = (e) => {
        e.preventDefault()
        let moduleData = [...module, {module: activeModule, status: 'active'}]
        setmodule(moduleData)
        saveModulesData(moduleData)
        setshowModulesModal(false)
        setactiveModule('')
    }
    
    const openModulesModal = () => {
        setshowModulesModal(true)
    }

    useEffect(() => {
        fetchModules()
    }, [])

    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Module',
                    key: 'module'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(module)
        })
    }, [module])
    return {
        trackScrolling,
        openModulesModal,
        tableContent,
        showModulesModal,
        adModule,
        activeModule,
        setactiveModule,
        setshowModulesModal,
    }
}