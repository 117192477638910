import { useContext, useEffect, useState } from "react"
import { statusOption, config, searchRegex } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext, NavigatorContext } from "../../../../context"

export const useFranchiseList = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const CheckBox = useElement('CheckBox')
    const filterTags = statusOption
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'franchise')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        generateUID,
        isBottom,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {commonPageSize} = config
    const [tableData, settableData] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [filteredList, setfilteredList] = useState([])
    const [tableContent, settableContent] = useState(null)
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState<string>('')
    const [activeFilterIndex, setactiveFilterIndex] = useState<number>(0)
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [activeFilter, setactiveFilter] = useState(filterTags[0])

    setTitle('Franchise List', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        settrashAction(urlData[2] === 'trash')
        getFranchiseListData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getFranchiseListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                if (!trashAction) {
                    if (!e.trash) {
                        e.addressDetail = isValidJson(e.addressDetail) ? JSON.parse(e.addressDetail) : null
                        e.owner = isValidJson(e.owner) ? JSON.parse(e.owner) : null
                        e.contactPerson = isValidJson(e.contactPerson) ? JSON.parse(e.contactPerson) : null
                        e.addressDetail?.address_components?.forEach(l => {
                            if (l.types?.includes("administrative_area_level_2")) {
                                e.addressDetail.city = l.long_name
                            }
                        })
                        tableData.push(e)
                    }
                } else {
                    if (e.trash)
                        tableData.push(e)
                }
            })
            settableData(tableData)
            let activefilterValue = 'all'
            filterTags.forEach(e => {
                if (Number(e.id) === activeFilterIndex+1) {
                    activefilterValue = e.value
                }
            })
            filterItems( activefilterValue, activeFilterIndex, tableData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No franchise added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No franchise added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectedFranchise = () => {
        deleteFranchise(activeItem.uid)
    }
    
    const deleteFranchise = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                getFranchiseListData()
                showAlert({ type: 'success', msg: 'Franchise deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getFranchiseListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete franchise!' })
        } else {
            let franchiseObject = null
            tableData.forEach(e => {
                if (e.uid === uid) {
                    franchiseObject = e
                }
            })
            franchiseObject.trash = task === 'trash'
            delete franchiseObject._id
            let data = {
                query: JSON.stringify({uid}),
                franchiseData: JSON.stringify(franchiseObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Franchise '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getFranchiseListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' franchise!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteFranchise(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const cloneFranchise = async item => {
        const franchiseObject = {
            ...item,
            slug: item.slug+'-copy',
            uid: generateUID()
        }
        const result = await save(franchiseObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'Franchise clonsed successfully!' })
            getFranchiseListData()
        } else showAlert({ type: 'error', msg: 'Unable to add franchise!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    <CheckBox isChecked={checkedAll || checkedList.includes(item.uid)} />
                </span>,
                title: item.title,
                city: item.addressDetail?.city,
                email: item.email,
                owner: item.owner?.label,
                contactPerson: item.contactPerson?.label,
                status: <Badge item={item.status} />,
                action: trashAction?
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='undo' onClick={() => deleteFranchise(item.uid, 'restore')} />
                </div>:
                <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='view' onClick={() => navigateTo({route: '/franchise/'+item.slug, newTab: true})} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/franchise/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => cloneFranchise(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const resetSearch = () => {
        setfilteredList(tableData)
        setsearchKey('')
    }

    const filterItems = ( key, activeFilterIndex, tableData = [] ) => {
        const filteredList = (key === 'all') ? tableData : tableData.filter(e => e.status === key)
        setfilteredList(filteredList)
        setactiveFilterIndex(activeFilterIndex)
    }
    
    const search = (e) => {
        if (e.target.value === '' || searchRegex.test(e.target.value)) {
            setsearchKey(e.target.value)
            let tempTitle = '', filteredList = []
            tableData.forEach(e => {
                tempTitle = e.title.toLowerCase()
                if (tempTitle.search(searchKey) !== -1)
                    filteredList.push(e)
            })
            setfilteredList(filteredList)
        }
    }
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template:<span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Title',
                    key: 'title'
                },
                {
                    label: 'City',
                    key: 'city'
                },
                {
                    label: 'Email',
                    key: 'email'
                },
                {
                    label: 'Owner',
                    key: 'owner'
                },
                {
                    label: 'Contact Person',
                    key: 'contactPerson'
                },
                {
                    label: 'Franchise Status',
                    key: 'status'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trackScrolling,
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        activeFilter,
        setactiveFilter,
        filterItems,
        filterTags,
        searchKey,
        search,
        resetSearch,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedFranchise,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}