import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useHomeSlider = () => {
    const {fetchByAttr} = useService('Data', 'setting')
    const {fetchAll} = useService('Data', 'subscription')
    const {getServices} = useService('Service')
    const {showAlert} = useService('Misc')
    const [serviceSliderList, setserviceSliderList] = useState(null)
    const [subscriptionData, setsubscriptionData] = useState(null)
    const [banners, setbanners] = useState([])
    
    const getWebBanners = async () => {
        const result = await fetchByAttr({type: 'web-banner'})
        if (result.status) {
            let serviceData = result.data[0]
            if(serviceData?.image) {
                setbanners(JSON.parse(serviceData.image))
            }
        } else showAlert({ type: 'error', msg: 'No banners added yet!' })
    }
    
    const getSubscriptionData = async () => {
        const result = await fetchAll()
        if (result.status) {
            setsubscriptionData(result.data)
        }
    }
    
    const getServicesData = async () => {
        const result = await getServices()
        if (result.status) {
            setserviceSliderList(result.data)
        }
    }

    useEffect(() => {
        getWebBanners()
        getServicesData()
        getSubscriptionData()
    }, [])

    return {
        serviceSliderList,
        subscriptionData,
        banners,
    }
}