import { PageRouteProps } from "../../types";

export const taxClassRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/taxClass/list",
        "module": ["Ecommerce"],
        "component": "TaxClassList",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/taxClass/add",
        "module": ["Ecommerce"],
        "component": "AddTaxClass",
        "type": "admin",
        "mode": "static",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    },
    {
        "pathName": "/admin/taxClass/edit",
        "module": ["Ecommerce"],
        "component": "AddTaxClass",
        "type": "admin",
        "mode": "dynamic",
        "rejectRoles": ["franchiseManager", "franchiseOwner"],
        "accessRoles": ["supervisor"]
    }
]