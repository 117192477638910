import { useComponent, useElement, useService, useWidget } from '../../../hooks'
import './style.scss'

export const About = () => {
    const Banner = useWidget('Banner')
    const Img = useElement('Img')
    const Link = useElement('Link')
    const {setTitle} = useService('Meta')
    const ShowServices = useComponent('ShowServices')
    const StepProvide = useComponent('StepProvide')
    setTitle('About Us')
    return <div className="about-page">
        <Banner title="About Us" image="about-4.jpg" />
        <div className='mt60 wrapper jumbo-container'>
            <div className='left-section'>
                <p>
                    CarMitra is a brand under the company name "Dclario Technologies
                    India Pvt. Ltd". We started this journey since May2020 during the
                    challenging time of Covid-19 pandemic. Developing 12V port operated
                    ozone devices to address the need for frequent sanitization shows
                    innovation and commitment to solving customer problems. We are
                    incessantly working towards creating a brand synonym to the premium
                    quality Car care services at affordable prices. Customer convenience &
                    trust are the core of our all-service offerings. We have been trusted
                    partner of reputed brands in this industry like CarDekho & OEM dealers
                    of Maruti, Hyundai, Tata, Mahindra, MG, Nissan, Jeep across multiple cities
                    like Jaipur, Lucknow, Agra, Mohali, Gurgaon & Delhi NCR locations.
                </p>
                <p>
                    Overall, CarMitra's journey so far reflects a blend of innovation,
                    customer-centric approach, & commitment to excellence, setting a
                    strong foundation for future growth and success in the car care industry.
                </p>
                <Link className="btn btn-big btn-primary" href="/services">Services</Link>
            </div>
            <div className='right-section'></div>
        </div>
        <div className='jumbo-container'>
            <h2 className='heading-02 mt60'>At CarMitra We Provide</h2>
            <StepProvide />
        </div>
        <section className='our-usp'>
            <div className='jumbo-container wrapper'>
                <div className='right-section'>
                    <h3 className='fs20'>USPs of Our Services</h3>
                    <p><b>The Unique Selling Proposition of our services are as follows: -</b></p>
                    <ul>
                        <li>Available in multiple cities (Customer can avail services in multiple cities)</li>
                        <li>Online booking and tracking through Mobile app & Website (E-Warranties</li>
                        <li>Trusted brand input materials</li>
                        <li>One stop shop for wide range of car care services</li>
                        <li>Professionally trained staff</li>
                    </ul>
                </div>
            </div>
        </section>
        <section className='mt60 service-type-list'>
            <div className='jumbo-container'>
                <h2 className='heading-02 tac'>The CarMitra Way: A Novel<br />Experience</h2>
                <ShowServices />
            </div>
        </section>
        <section className='join-us'>
            <div className='jumbo-container'>
                <Img src="speaker.png" />
                <h1>Join The CarMitra Family Now!</h1>
                <p>
                    Get an instant price and book your CarMitra experience now comfortably from the vicinity of your home or workspace.
                    We’re not just a car service company; we strive to create a better and smoother way of life for you and your wheels.
                </p>
                <p>
                    We are our customers first choice as we provide never seen before prices, quality of service and personal
                    assistance and guidance. The wait is over!! <b>Book your CARMITRA DEAL FOR YOUR HAPPY WHEELS.</b>
                </p>
            </div>
        </section>
    </div>
}