import { profileNav } from '../../../constant'
import { useElement, useService } from '../../../hooks'
import { useProfileSideBar } from './hooks'

export const ProfileSideBar = () => {
    const Link = useElement('Link')
    const {logout, getLoggedInUserData} = useService('User')
    const {isModuleEnabled} = useService('Misc')
    const user = getLoggedInUserData()
    const { temporaryUser } = useProfileSideBar()
    const renderProfileMenu = () => {
        return profileNav.map(( item, index ) => {
            if ((item.accessRoles?.includes(user.role) || (item.accessRoles?.includes('user') && ['admin', 'developer'].includes(user?.role))) && isModuleEnabled(item.module))
                return <li title={item.title} key={index}><Link href={item.link}><i className={item.icon}></i><span className="profile-sidebar-name">{item.title}</span></Link></li>
            else return true
        })
    }
    return <div className="left-menu-section">
        <div className="nav-list">
            <div className="menus">
                <ul>
                    { renderProfileMenu() }
                    {!temporaryUser && <li title='Logout'><i onClick={logout}><i className="hi-logout"></i><span className="profile-sidebar-name">Logout</span></i></li>}
                </ul>
            </div>
        </div>
    </div>
}