import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const usePackageDetail = () => {
    const {fetchByAttr} = useService('Data', 'packageWork')
    const {
        getUsers,
        getLoggedInUserData,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        getFormattedDate,
        openModal,
        getPreventiveCheckList,
        generateUID,
        isEmpty,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {
        getUpgradedPackage,
        getOrder,
        fetchServiceDates,
        addServiceDates,
        updateServiceDates,
    } = useService('Order')
    const [tableContent, settableContent] = useState({})
    const [orderContent, setorderContent] = useState(null)
    const [assignWorkers, setassignWorkers] = useState([])
    const [preventiveCheckList, setpreventiveCheckList] = useState([])
    const [packageId, setpackageId] = useState(null)
    const [orderDate, setorderDate] = useState<string>('' )
    const [chosenPreventiveCheckListDates, setchosenPreventiveCheckListDates] = useState([])
    const [chosenServiceDates, setchosenServiceDates] = useState([])
    const [workedPackagedata, setworkedPackagedata] = useState([])
    const [activeServiceDate, setactiveServiceDate] = useState(null)
    const [packageData, setpackageData] = useState(null)
    const [serviceWorkAwaitedStatus, setserviceWorkAwaitedStatus] = useState<boolean>(false)
    const [serviceData, setserviceData] = useState(null)
    const [packageWork, setpackageWork] = useState(null)
    const [upgradedData, setupgradedData] = useState(null)
    const [upgraded, setupgraded] = useState<boolean>(false)

    setTitle('Package Details', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname),
        orderData = urlToSplitData(atob(urlData[5])),
        query = {
            uid: orderData[0]
        }
        setpackageId(orderData[1])
        getServiceDates(orderData[1])
        getOrderData(query, orderData[1])
        checkForUpgrade({
            orderId: orderData[0],
            packageId: orderData[1]
        })
        getPreventiveChecks()
    }, [])

    const checkForUpgrade = async (query) => {
        const result = await getUpgradedPackage(query)
        if (result.status) {
            if (result.data !== '') {
                let upgradedData = result.data
                setupgradedData(upgradedData)
                setupgraded(true)
            }
        }
    }
    
    const getPackageWorkData = async (packageId, packageData) => {
        let query = {
            tempUid: String(packageId)
        }
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data?.length > 0) {
                if (result.data[0].title.toLowerCase() !== packageData.title.toLowerCase()) {
                    let query = {
                        title: packageData.title
                    }
                    let newResult = await fetchByAttr(query)
                    if (newResult.status) {
                        if (newResult.data?.length > 0) {
                            setworkedPackagedata(newResult.data)
                        }
                    }
                } else {
                    setworkedPackagedata(result.data)
                }
            }
        }
    }
    
    const getUsersData = async () => {
        let tableData = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No user added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'worker') {
                        tableData.push({label: result.name, value: result.uid})
                        setassignWorkers(tableData)
                    }
                })
            }
        }
    }
    
    const getOrderData = async (query, packageId) => {
        const result = await getOrder(query)
        if (result.status) {
            if (result.data?.length > 0) {
                const orderContent = result.data[0],
                packageData = null,
                orderData = JSON.parse(orderContent.orderData)
                setorderContent(orderContent)
                orderData.cartData.forEach(e => {
                    if (e.tempUid === Number(packageId)) {
                        setpackageData(e)
                        getPackageWorkData(packageId, e)
                    }
                })
            }
        }
    }
    
    const getServiceDates = async packageId => {
        let query = {
            tempUid: packageId
        }
        const result = await fetchServiceDates(query)
        if (result.status) {
            let resultTable = result.data.reverse()
            if (resultTable.length > 0) {
                if (typeof resultTable[0].preventiveCheckListDates !== 'undefined' && resultTable[0].preventiveCheckListDates !== 'undefined') {
                    setchosenPreventiveCheckListDates(JSON.parse(resultTable[0].preventiveCheckListDates))
                }
                if (typeof resultTable[0].serviceDate !== 'undefined' && resultTable[0].serviceDate !== 'undefined') {
                    setchosenServiceDates(JSON.parse(resultTable[0].serviceDate))
                }
            }
        }
    }
    
    const getCompletedWorkData = serviceDate => {
        let found = false
        workedPackagedata.forEach(e => {
            if (getFormattedDate(e.serviceDate) === getFormattedDate(new Date(serviceDate))) {
                found = true
                let packageWork = e
                setpackageWork(packageWork)
                setserviceWorkAwaitedStatus(false)
            }
        })
        if (!found)
            setserviceWorkAwaitedStatus(true)
        openModal("car-service-status-modal")
    }
    
    const getPreventiveChecks = async () => {
		const result = await getPreventiveCheckList()
        if (result.status) {
            let preventiveCheckList = result.data
            setpreventiveCheckList(preventiveCheckList)
        } else showAlert({ type: 'error', msg: 'Unable to get preventiveCheckList!' })
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                type: <b>{item.type}</b>,
                answers: <div className="query-list-icon-Box">
                    {
                        item.answers[0] === 'Perfect' ?
                        <i className="success hi-check_circle"></i> :
                        item.answers[0] === 'Not Available' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Need attention' ?
                        <i className="hi-cross-circle error"></i>:
                        item.answers[0] === 'Available' ?
                        <i className="success hi-check_circle"></i>:<></>
                    }
                    <span className="ml10">{item.answers[0]}</span>
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const getPreventiveAnswers = (date, mode = 'detail') => {
        let activePreventiveData = null
        workedPackagedata.forEach(e => {
            if (getFormattedDate(e.serviceDate) === getFormattedDate(new Date(date))) {
                activePreventiveData = JSON.parse(e.workData)[0].preventiveWorkData
            }
        })
        if (activePreventiveData === null && mode === 'detail') {
            showAlert({ type: 'info', msg: 'Preventive check is yet not done for this date.' })
        } else {
            preventiveCheckList.forEach(e => {
                e.serviceQueries.forEach(el => {
                    el.status.forEach(eq => {
                        if (mode === 'view') {
                            eq.answers[0] = '...'
                        } else {
                            activePreventiveData?.forEach(ed => {
                                if (ed.title === el.query && ed.query === eq.type) {
                                    eq.answers[0] = ed.status
                                }
                            })
                        }
                    })
                })
            })
            setpreventiveCheckList(preventiveCheckList)
            openModal("preventive-checklist-modal")
        }
    }
    
    const updatePackage = async () => {
        let error = false,
        errorMsg = ''
        chosenServiceDates.push(getFormattedDate(activeServiceDate))
        let serviceDateObject = {
            userId: getLoggedInUserData().uid,
            tempUid: packageId,
            preventiveCheckListDates: JSON.stringify(chosenServiceDates),
            uid: chosenServiceDates.length === 1 ? generateUID() : undefined
        }
        if (new Date(activeServiceDate).getDay() === 0) {
            error = true
            errorMsg = 'We do not serve on Sundays'
        }
        if (error) {
            showAlert({ type: 'error', msg: errorMsg })
        } else {
            if (chosenServiceDates.length === 1) {
                const result = await addServiceDates(serviceDateObject)
                if (result.status) {
                    getServiceDates(packageId)
                    showAlert({ type: 'success', msg: 'Preventive Checklist dates submitted successfully' })
                    getLocationData().reload()
                }
            } else {
                let data = {
                    query: JSON.stringify({tempUid: packageId}),
                    serviceDateData: JSON.stringify(serviceDateObject)
                }
                const result = await updateServiceDates(data)
                if (result.status) {
                    getServiceDates(packageId)
                    showAlert({ type: 'success', msg: 'Preventive Checklist dates submitted successfully' })
                    getLocationData().reload()
                }
            }
        }
    }
    let carNotAvailable = false,
    needDate = true,
    today = new Date(),
    activeMonth = 0,
    activeYear = 0,
    activeDate = 0,
    tomorrow = new Date(today)
    activeMonth = today.getMonth() + 1
    activeYear = today.getFullYear()
    activeDate = today.getDate()
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (packageData!== null) {
        if (getFormattedDate(tomorrow) < getFormattedDate(new Date(packageData.startDate))) {
            tomorrow = new Date(packageData.startDate)
        }
        if (packageData !== null) {
            if (packageData.billing === 'monthly') {
                if (chosenServiceDates.length === 0 || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 2)) {
                } else {
                    needDate = false
                }
            } else {
                if (chosenServiceDates.length === 0 || (((packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') && chosenServiceDates.length < 3)) || (packageData.uid === 'PACKAGE__003' && chosenServiceDates.length < 6)) {
                    if (packageData.uid === 'PACKAGE__001' || packageData.uid === 'PACKAGE__002') {
                        if (activeMonth === 12) {
                            activeYear++
                            setactiveServiceDate(new Date('01-01-'+activeYear))
                        } else {
                            activeMonth++
                            setactiveServiceDate(new Date(activeMonth+'-01-'+activeYear))
                        }
                    } else {
                        if (activeMonth === 12 && activeDate === 31) {
                            activeYear++
                            setactiveServiceDate(new Date('01-01-'+activeYear))
                        } else {
                            if (chosenServiceDates.length % 2 === 0) {
                                activeMonth++
                                setactiveServiceDate(new Date(activeMonth+'-01-'+activeYear))
                            } else {
                                setactiveServiceDate((new Date(chosenServiceDates[chosenServiceDates.length - 1])).setDate(activeServiceDate.getDate() + 1))
                            }
                        }
                    }
                } else {
                    needDate = false
                }
            }
        }
    }
    if (isEmpty(activeServiceDate)) {
        setactiveServiceDate(tomorrow)
    }
    let workData = null
    if (packageWork !== null) {
        setserviceData(JSON.parse(packageWork.activeCar))
        if (typeof workData !== 'undefined')
        workData =  JSON.parse(packageWork.workData)
        if (typeof workData[0].workedServices === 'undefined') {
            carNotAvailable = true
        }
    }

    return {
        chosenServiceDates,
        chosenPreventiveCheckListDates,
        getPreventiveAnswers,
        upgradedData,
        getCompletedWorkData,
        getTableData,
        packageData,
        needDate,
        setactiveServiceDate,
        activeServiceDate,
        updatePackage,
        preventiveCheckList,
        serviceWorkAwaitedStatus,
        carNotAvailable,
        packageWork,
        serviceData,
        workData,
    }
}