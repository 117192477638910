import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddCampaign = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        fetchByAttr,
        save,
        update,
    } = useService('Data', 'campaign')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [content, setcontent] = useState<string>('')
    const [uid, setuid] = useState<string>('')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [name, setname] = useState<string>('')
    const [image, setimage] = useState<string>('[]')

    setTitle('Add Campaign', 'admin')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        if (urlData[3] === 'edit') {
            getCampaignData({uid: urlData[4]})
        }
    }, [])

    const getCampaignData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            let campaignData = result.data[0]
            setname(campaignData.name)
            setmode('edit')
            setcontent(campaignData.content)
            setimage(campaignData.image)
            setuid(campaignData.uid)
        } else showAlert({ type: 'error', msg: 'No campaigns added yet!' })
    }
    
    const addCampaign = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        campaignObject = {
            content,
            name,
            image,
            uid: mode === 'add' ? generateUID() : uid
        }
        if (content === '') {
            errorMsg = 'Please enter campaign content!'
            error = true
        }
        if (name === '') {
            errorMsg = 'Please enter user name!'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await save(campaignObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Campaign added successfully!' })
                    navigateTo({route: '/admin/campaign/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add campaign!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    campaignData: JSON.stringify(campaignObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Campaign updated successfully!' })
                    navigateTo({route: '/admin/campaign/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update campaign!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const setCampaignName = e => setname(e.target.value)

    const setCampaignContent = e => setcontent(e.target.value)
    return {
        mode,
        name,
        setCampaignName,
        content,
        setCampaignContent,
        addCampaign,
    }
}