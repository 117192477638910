import { useContext, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddNotification = () => {
    const {save} = useService('Data', 'notification')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {isValidJson} = useService('Validation')
    const [body, setbody] = useState<string>('')
    const [jsonData, setjsonData] = useState<string>('')
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [image, setimage] = useState<string>('[]')
    const [title, settitle] = useState<string>('')

    setTitle('Add Notification', 'admin')

    const addNotification = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        notificationObject = {
            title,
            body: body,
            jsonData: jsonData,
            timing: new Date().getTime(),
            image: typeof image === 'undefined'? '[]' : image,
            uid: generateUID(),
        }
        if (title === '') {
            errorMsg = 'Please enter notification title!'
            error = true
        } else if (body === '') {
            errorMsg = 'Please enter notification body!'
            error = true
        } else if (jsonData !== '') {
            if (!isValidJson(jsonData)) {
                errorMsg = 'Please enter valid json Data for notification!'
                error = true
            }
        }
        if (!error) {
            const result = await save(notificationObject)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Notification added successfully!' })
                navigateTo({route: '/admin/notification/list'})
            } else showAlert({ type: 'error', msg: 'Unable to add notification!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    let pageTitle = mode + ' Notification'
    return {
        pageTitle,
        addNotification,
        title,
        settitle,
        body,
        setbody,
        jsonData,
        setjsonData,
        image,
        setimage,
    }
}