import { memo } from "react"
import { useBox } from "./hooks"

interface BoxProps {
    children?: string | JSX.Element
    title?: string | JSX.Element
    required?: boolean
    bodyClass?: string | JSX.Element
    boxClass?: string | JSX.Element
    overflown?: boolean
}

export const Box = memo(({
    children,
    title = '',
    required,
    bodyClass = '',
    boxClass = '',
    overflown = true
}: BoxProps) => {
    let boxBodyClass = "body "+bodyClass
    boxBodyClass += overflown?" overflown" : ''
    const { toggleClose } = useBox()

    return <div className={`box ${boxClass}`}>
        <h4 className="head" onClick={toggleClose}>{title}{required && <sup>*</sup>}</h4>
        <div className={boxBodyClass}>
            {children}
        </div>
    </div>
})
