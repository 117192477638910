import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useRegister = () => {
    const { navigateTo } = useService('Router')
    const { save } = useService('Data', 'log')
    const {
        addUser,
        sendOtp,
        isAdmin,
        isSuperVisor,
        getUser,
        login,
    } = useService('User')
    const { setTitle } = useService('Meta')
    const {
        generateUID,
        showAlert,
        openModal,
        proceedToCheckOut,
    } = useService('Misc')
    const { isValidMobile } = useService('Validation')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const { getCartData } = useService('Order')
    const [email, setemail] = useState<string>('')
    const [name, setname] = useState<string>('')
    const [otp, setotp] = useState<string>('')
    const [role, setrole] = useState('user')
    const [gstin, setgstin] = useState<string>('')
    const [mobile, setmobile] = useState<string>('')
    const [terms, setterms] = useState<boolean>(false)
    const [showMobileField, setshowMobileField] = useState<boolean>(false)
    const [additionalData, setadditionalData] = useState<string>('')

    const parseRegisterData = async () => {
        const urlData = urlToSplitData(getLocationData().pathname), mobile = '', additionalData = []
        if (atob(urlData[2]) === 'social-user') {
            let socialData = localStorage.getItem('socialData'),
                query = {
                    email: JSON.parse(socialData)._profile.email
                }
            const userResult = await getUser(query)
            if (userResult.status) {
                if (userResult.data.lenght === 0) {
                    setname(JSON.parse(socialData)._profile.name)
                    setemail(JSON.parse(socialData)._profile.email)
                } else {
                    let userData = userResult.data[0]
                    const userObject = await login(userData)
                    let logData = {
                        log: userObject.name + ' logged in with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: generateUID()
                    }
                    save(logData)
                    if (userObject !== null) {
                        let nextUrl = isAdmin() || isSuperVisor() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (getCartData().length !== 0)
                            proceedToCheckOut()
                        else
                            navigateTo({ route: nextUrl, reload: true })
                    }
                }
            } else showAlert({ type: 'error', msg: 'Unable to login!' })
            additionalData.push({
                label: 'socialData',
                value: socialData
            })
            setshowMobileField(true)
            setadditionalData(JSON.stringify(additionalData))
        } else {
            setmobile(atob(urlData[2]).replace('new', ''))
        }
    }

    setTitle('Register')
    useEffect(() => {
        parseRegisterData()
    }, [])

    const registerUser = async (e?: any) => {
        e.preventDefault()
        let error = false,
            errorMsg = ''
        if (!terms) {
            errorMsg = 'Please accept terms first!'
            error = true
            showAlert({ type: 'error', msg: errorMsg })
        } else {
            if (showMobileField) {
                let userObject = {
                    mobile
                }
                if (mobile === '') {
                    errorMsg = 'Please enter your Mobile Number'
                    error = true
                } else if (!isValidMobile(mobile)) {
                    errorMsg = 'Please enter a valid Mobile Number'
                    error = true
                }
                if (!error) {
                    const result = await sendOtp(userObject)
                    if (result.status) {
                        showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                        let otpData = result.data[0]
                        localStorage.setItem('otpData', JSON.stringify(otpData))
                        openModal('otp-modal')
                    } else
                        showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
                } else
                    showAlert({ type: 'error', msg: errorMsg })
            } else {
                let userObject = {
                    email,
                    name,
                    role,
                    gstin,
                    additionalData: additionalData,
                    mobile,
                    image: JSON.stringify(["no-image-found.png"]),
                    uid: generateUID()
                }
                if (email === '') {
                    errorMsg = 'Please enter user email!'
                    error = true
                } else if (name === '') {
                    errorMsg = 'Please enter the Name'
                    error = true
                }
                if (!error) {
                    const result = await addUser(userObject)
                    let logData = {
                        log: userObject.name + ' registered with website',
                        timing: new Date().getTime(),
                        type: 'success',
                        userId: userObject.uid,
                        uid: generateUID()
                    }
                    save(logData)
                    if (result.status)
                        loginUser(userObject)
                    else
                        showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
                } else
                    showAlert({ type: 'error', msg: errorMsg })
            }
        }
    }

    const resendOtp = async () => {
        let error = false,
            errorMsg = '',
            userObject = {
                mobile
            }
        if (!error) {
            const result = await sendOtp(userObject)
            if (result.status) {
                showAlert({ type: 'info', msg: 'An OTP has been sent to your mobile number!' })
                let otpData = result.data[0]
                localStorage.setItem('otpData', JSON.stringify(otpData))
            } else showAlert({ type: 'error', msg: 'Unable to send OTP, try after some time.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const checkOtp = async () => {
        let otpData = JSON.parse(localStorage.getItem('otpData')),
            derivedOtp = otpData.otp,
            derivedUid = otpData.uid,
            errorMsg = '',
            error = false,
            query = null,
            userType = otpData.userType;
        if (userType === 'old') {
            query = {
                uid: derivedUid
            }
        }
        if (otp === '') {
            errorMsg = 'Please enter OTP'
            error = true
        }
        if (Number(otp) !== derivedOtp) {
            errorMsg = 'OTP does not match'
            error = true
        }
        if (!error) {
            if (userType === 'new')
                registerUser()
            else {
                const result = await getUser(query)
                if (result.status) {
                    let userData = result.data[0]
                    const userObject = await login(userData)
                    if (userObject !== null) {
                        let nextUrl = isAdmin() || isSuperVisor() ? '/admin/dashboard' : '/user/profile'
                        localStorage.removeItem('otpData')
                        if (getCartData().length !== 0)
                            proceedToCheckOut()
                        else
                            navigateTo({ route: nextUrl })
                    }
                } else
                    showAlert({ type: 'error', msg: 'Unable to login!' })
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }

    const submitRegisterForm = () => {
        setshowMobileField(false)
        checkOtp()
    }

    const loginUser = async userObject => {
        localStorage.removeItem('otpData')
        localStorage.removeItem('socialData')
        let query = {
            uid: String(userObject.uid)
        }
        const result = await getUser(query)
        if (result.status) {
            let userData = result.data[0]
            const userObject = await login(userData)
            if (userObject !== null)
                navigateTo({ route: '/user/profile' })
        } else showAlert({ type: 'error', msg: 'Unable to login!' })
    }
    return {
        registerUser,
        name,
        setname,
        email,
        setemail,
        gstin,
        setgstin,
        showMobileField,
        setmobile,
        setterms,
        terms,
        mobile,
        submitRegisterForm,
        setotp,
        resendOtp,
    }
}