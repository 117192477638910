import { PageRouteProps } from "../../types";

export const miscRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin",
        "redirectTo": "/admin/dashboard",
        "mode": "static",
        "type": "admin",
    },
    {
        "pathName": "/admin/dashboard",
        "component": "AdminDashboard",
        "type": "admin",
        "accessRoles": ["supervisor", "worker", "franchiseOwner", "franchiseManager"],
        "mode": "static"
    },
    {
        "pathName": "/admin/report",
        "component": "Report",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/media",
        "component": "Media",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/complaint",
        "module": ["Complaint"],
        "component": "ComplaintAdmin",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/franchise-request",
        "module": ["Franchise"],
        "component": "FranchiseRequestAdmin",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/import/work",
        "module": ["Car"],
        "component": "ImportB2BWorkData",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/import/stock",
        "module": ["Car"],
        "component": "ImportB2BStockData",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/import/pincode",
        "module": ["Location"],
        "component": "ImportPinCode",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/package/work/list",
        "module": ["Ecommerce"],
        "component": "PackageWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/service/work/list",
        "module": ["Ecommerce"],
        "component": "ServiceWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/company/work/list",
        "module": ["Ecommerce"],
        "component": "CompanyWorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/work/list",
        "module": ["Ecommerce"],
        "component": "WorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/work/list",
        "module": ["Ecommerce"],
        "component": "WorkListAdmin",
        "type": "admin",
        "accessRoles": ["worker", "supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/log/list",
        "module": ["Log"],
        "component": "LogList",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/utils/sendmail",
        "component": "SendMail",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/robots",
        "component": "Robots",
        "type": "admin",
        "mode": "static"
    }
]