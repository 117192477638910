import { useState } from "react"
import {adminNav as nav} from '../../../../constant'
import { routes } from "../../../../router"
import { useService } from "../../../../hooks"

export const useAdminSidebar = () => {
    const {getLoggedInUserData} = useService('User')
    const {getPath} = useService('Misc')
    const {navigateTo} = useService('Router')
    const [navFilter, setnavFilter] = useState<string>('')
    const user = getLoggedInUserData()

    const adminNav = nav.filter((item) => {
        let allowed = false
        routes.forEach(e => {
            if (['admin', 'developer'].includes(user?.role)) {
                allowed = true
            } else if (e.type === "admin") {
                item.subNav?.forEach(s => {
                    if (s.subNav) {
                        s.subNav.forEach(c => {
                            if ('/admin'+getPath(c.url) === e.pathName) {
                                if (e.accessRoles?.includes(user?.role))
                                    allowed = true
                                if (c.accessRoles) {
                                    if (c.accessRoles.includes(user?.role))
                                        allowed = true
                                } else if (c.rejectRoles) {
                                    if (!c.rejectRoles.includes(user?.role))
                                        allowed = true
                                }
                            }
                        })
                    } else if ('/admin'+getPath(s.url) === e.pathName) {
                        if (e.accessRoles?.includes(user?.role))
                            allowed = true
                        if (s.accessRoles) {
                            if (s.accessRoles.includes(user?.role))
                                allowed = true
                        } else if (s.rejectRoles) {
                            if (!s.rejectRoles.includes(user?.role))
                                allowed = true
                        }
                    }
                })
            } else if ('/admin'+getPath(item.url) === e.pathName) {
                if (e.accessRoles?.includes(user?.role))
                    allowed = true
            }
        })
        return allowed
    })

    const toggleActiveClass = (e) => {
        e.target.parentElement.classList.toggle("active")
    }

    const filter = (e) => {
        return e.toLowerCase().includes(navFilter.toLowerCase())
    }

    const goToDashboard = () => {
        navigateTo({route: '/admin', reload: true})
    }

    return {
        toggleActiveClass,
        filter,
        user,
        adminNav,
        navFilter,
        setnavFilter,
        goToDashboard,
    }
}