import { useComponent, useElement } from '../../../hooks';
import { useModuleList } from './hooks';

export const ModuleList = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Table = useComponent('Table')
    const Modal = useComponent('Modal')
    const {
        trackScrolling,
        openModulesModal,
        tableContent,
        showModulesModal,
        adModule,
        activeModule,
        setactiveModule,
        setshowModulesModal,
    } = useModuleList()

    return <div id="content-box" className="content" onScroll={trackScrolling}>
        <h1 className="title flex">Modules <Button onClick={openModulesModal} className="btn btn-primary btn-small ml20">Add</Button></h1>
        <Table
            data={tableContent}
        />
        <Modal id="modules-modal" showCancel={false} showSuccess={false} onCancel={() => setshowModulesModal(false)} showModal={showModulesModal}>
            <h3 className="modal-header">Add Module</h3>
            <form className='p20' onSubmit={adModule}>
                <Input value={activeModule} onChange={e => setactiveModule(e.target.value)} name="module" className='mb40' />
                <div className='flex'>
                    <Button onClick={() => setshowModulesModal(false)} className="btn btn-outline">Cancel</Button>
                    <Button type="submit" className="btn btn-primary ml20">Submit</Button>
                </div>
            </form>
        </Modal>
    </div>
}
