import Select from 'react-select'
import { useCompanyManagers } from './hooks'
import { useComponent } from '../../../hooks';

export const CompanyManagers = () => {
    const Table = useComponent('Table')
    const {
        user,
        branches,
        activeBranches,
        selectCompany,
        tableContent,
        activeShownTotal,
    } = useCompanyManagers()
    return <>
        <div id="content-box" className="content">
            <div className='flex overflow-visible'>
                <h1 className="nowrap mr20">Company Managers</h1>
                {
                    ['company', 'representative'].includes(user.role) && branches.length > 0 &&
                    <Select
                        isClearable
                        closeMenuOnSelect={false}
                        placeholder='Select Company'
                        defaultValue={activeBranches}
                        onChange={selectCompany}
                        options={branches}
                        isMulti
                    />
                }
            </div>
            <div className="order-table">
                <Table
                    data={tableContent}
                    pageSize={activeShownTotal}
                />
            </div>
        </div>
    </>
}
