import { bannerText } from '../../../constant';
import { useComponent, useElement, useService } from '../../../hooks'
import { useFormSlider } from './hooks'
import './style.scss';

export const FormSlider = () => {
    const {isDesktop} = useService('Misc')
    const BookServiceForm = useComponent('BookServiceForm')
    const Slider = useElement('Slider')
    const {
        serviceSliderList,
        subscriptionData,
    } = useFormSlider()
    const setting = {
        rows: 1,
        swipeToSlide: true,
        dots: true,
        infinite: true,
        speed: 1000,
        arrows: false,
        slidesToShow: 1,
        pauseOnHover: true,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    }
    const renderBanners = () => {
        return bannerText.map(( item, index ) => {
            return <div key={index}>
                <h1>{item.title}</h1>
                <p>{item.content}</p>
            </div>
        })
    }

    return <div className="slider-items mb60-mbs40">
        <div className='wrapper jumbo-container'>
            <div className='form-slider-box'>
                <BookServiceForm />
            </div>
            {isDesktop() && <div className='slider-box'>
            {
                serviceSliderList !== null && subscriptionData !== null &&
                <Slider {...setting}>
                    { renderBanners() }
                </Slider>
            }
            </div>}
        </div>
    </div>
}
