import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useAddCarModel } from './hooks'
import { useComponent, useElement } from '../../../hooks'

export const AddCarModel = () => {
    const Img = useElement('Img')
    const BackIcon = useElement('BackIcon')
    const DatePicker = useElement('DatePicker')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const {
        setmodelKey,
        setfuel,
        setyear_of_manufacture,
        year_of_manufacture,
        setstart_year,
        start_year,
        setend_year,
        end_year,
        image_path,
        vehicleClassListoptions,
        selectFiles,
        manufactureroptions,
        fuelOption,
        activeSegment,
        addCarModelImage,
        selectCarManufacturer,
        submitForm,
        selectVehicleSegment,
        activeManufacturer,
        uploaded,
        mode,
        name,
        setname,
        modelKey,
        fuel
    } = useAddCarModel()

    let pageTitle = mode + ' Car Model'
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/carModel/list' />
            {pageTitle}
        </h1>
        <form className="columns" noValidate onSubmit={submitForm}>
            <div className="left">
                <Box title='Name' required>
                    <Input required name="name" value={name} onChange={e => setname(e.target.value)} />
                </Box>
                {mode === 'edit' && <Box title='Model Key' required>
                    <Input required name="modelKey" value={modelKey} onChange={e => setmodelKey(e.target.value)} />
                </Box>}
                <Box title='Fuel'>
                    <CreatableSelect
                        value={fuel}
                        onChange={e => setfuel(e)}
                        options={fuelOption}
                        isMulti
                    />
                </Box>
                <Box title='Year Of Manufacture'>
                    <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        onChange={year_of_manufacture => setyear_of_manufacture(year_of_manufacture)}
                        selected={year_of_manufacture}
                    />
                </Box>
                <Box title='Start Year'>
                    <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        onChange={start_year => setstart_year(start_year)}
                        selected={start_year}
                    />
                </Box>
                <Box title='End Year'>
                    <DatePicker
                        showYearPicker
                        dateFormat="yyyy"
                        onChange={end_year => setend_year(end_year)}
                        selected={end_year}
                    />
                </Box>
            </div>
            <div className="right">
                <Box title='Meta'>
                    <Button className="btn btn-primary has-icon" type="submit" title="Click here to save">Save</Button>
                </Box>
                <Box title='Brand'>
                    <Select
                        value={activeManufacturer}
                        defaultValue={activeManufacturer}
                        onChange={selectCarManufacturer}
                        options={manufactureroptions}
                    />
                </Box>
                <Box title='Vehicle Segment'>
                    <Select
                        value={activeSegment}
                        defaultValue={activeSegment}
                        onChange={selectVehicleSegment}
                        options={vehicleClassListoptions}
                    />
                </Box>
                <Box title='Image' required>
                    { image_path !== '' && <Img className='input-img' src={ uploaded ? image_path.url ? image_path.url : image_path.fileName ? image_path.fileName : image_path : image_path} />}
                    <label htmlFor="choose-file" className="btn btn-blank btn-small">Choose Files</label>
                    <div className='clearfix'></div>
                    <Input className="hidden" placeholder="Please enter image" required name="image" value={image_path} onChange={addCarModelImage} />
                    <Input type="file" className="hidden" onChange={selectFiles} multiple id="choose-file" accept="image/x-png,image/gif,image/jpeg,image/webp" />
                </Box>
            </div>
        </form>
    </div>
}
