import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption, timeSlot, base } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

interface ActivePropsProps {
    saleprice
    price
}

export const useServiceTemplate = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchAll} = useService('Data', 'subscription')
    const {getActiveSubscription} = useService('User')
    const {getService} = useService('Service')
    const {setTitle} = useService('Meta')
    const {
        getActiveCar,
        isEmpty,
        openCarSelectModal,
        generateUID,
        toIndianCurrency,
        showAlert,
        closeModal,
        openModal,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {addToCart} = useService('Order')
    const {getVehicleClassList} = useService('Car')
    const [selectPick, setselectPick] = useState<boolean>(false)
    const [serviceData, setserviceData] = useState(null)
    const [activePrice, setactivePrice] = useState<ActivePropsProps>()
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [activeTimeSlot, setactiveTimeSlot] = useState<string>(timeSlot[0])
    const [prices, setprices] = useState<string>('[]')
    const [image, setimage] = useState<string>('[]')
    const [vehicleClassList, setvehicleClassList] = useState([])
    const [uid, setuid] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [serviceTime, setserviceTime] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [excerpt, setexcerpt] = useState<string>('')
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [usernote, setusernote] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [orderDate, setorderDate] = useState<string>('')
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState(null)
    const [tag, settag] = useState(null)
    const [showDropdwon, setshowDropdwon] = useState<boolean>(false)
    const [activeCar, setactiveCar] = useState(getActiveCar())
    const [chooseServiceTime, setchooseServiceTime] = useState<boolean>(false)
    const [buyType, setbuyType] = useState<string>('')
    const [activeSubscription, setactiveSubscription] = useState(null)
    const [subscriptionData, setsubscriptionData] = useState([])

    const getVehicleClasses = async () => {
        const result = await getVehicleClassList()
        if (result.status) {
            if (result.data?.length !== 0) 
                setvehicleClassList(result.data)
        }
    }
    
    const getServiceData = async (query) => {
        const result = await getService(query)
        if (result.status) {
            const serviceData = result.data[0]
            if (result.data.length === 0)
                navigateTo({route: '/error404'})
            else {
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === serviceData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === serviceData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === serviceData.status))
                settag(isEmpty(serviceData.tag) ? [] : JSON.parse(serviceData.tag))
                setcategory(isEmpty(serviceData.category) ? [] : JSON.parse(serviceData.category))
                setmetaKeyword(isEmpty(serviceData.metaKeyword) ? [] : JSON.parse(serviceData.metaKeyword))
                setserviceData(serviceData)
                setTitle(serviceData.title)
                settitle(serviceData.title)
                setserviceTime(serviceData.serviceTime)
                setslug(serviceData.slug)
                setcontent(serviceData.content)
                setusernote(serviceData.usernote)
                setexcerpt(serviceData.excerpt)
                setcss(serviceData.css)
                setjs(serviceData.js)
                setcanonical(serviceData.canonical)
                setrobots(serviceData.robots)
                setmetaDescription(serviceData.metaDescription)
                setauthor(serviceData.author)
                setpublishDate(new Date(serviceData.publishDate))
                setstatus(serviceData.status)
                setuid(serviceData.uid)
                setimage(serviceData.image)
                setprices(JSON.parse(serviceData.prices))
                JSON.parse(JSON.parse(serviceData.prices)).forEach(c => {
                    if (!isEmpty(activeCar?.Segment)) {
                        if (activeCar?.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase())) {
                            setactivePrice(c)
                        }
                    }
                })
            }
        }
    }
    
    const getSubscriptionData = async () => {
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length !== 0)
                setsubscriptionData(result.data)
        }
    }
    

    const checkForActiveCar = () => {
        let activeCar = getActiveCar(), found,
        derivedPrices = JSON.parse(prices)
        if (activeCar === null) {
            setTimeout(() => {
                checkForActiveCar()
            }, 3000)
        } else {
            derivedPrices.forEach(c => {
                if (activeCar?.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    serviceAddedTemplate(c.price)
                }
            })
        }
    }
    
    const chooseActiveCar = () => {
        closeModal("add-to-cart-modal")
        let activeCar = getActiveCar(), found,
        derivedPrices = JSON.parse(prices)
        if (activeCar === null) {
            openCarSelectModal()
            checkForActiveCar()
        } else {
            derivedPrices.forEach(c => {
                if (activeCar?.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                    found = true
                    if (typeof c.saleprice === "undefined" || c.saleprice === 0 || c.saleprice === '') {
                        c.saleprice = c.price
                        if (getActiveSubscription() !== null) {
                            c.saleprice = c.saleprice*0.9
                        }
                    }
                    let mrp = Number(c.price)
                    let price: number | string = Number(c.saleprice)
                    // price += price*Number(activeServiceData.tax)*0.01
                    price = String(price.toFixed(2))
                    c.saleprice = Number(c.saleprice)
                    // c.saleprice = c.saleprice + c.saleprice*Number(activeServiceData.tax)*0.01
                    c.saleprice = Number(c.saleprice.toFixed(2))
                    c.price = Number(c.price)
                    // c.price = c.price + c.price*Number(activeServiceData.tax)*0.01
                    c.price = String(c.price.toFixed(2))
                    serviceAddedTemplate(price, serviceData, mrp, c.saleprice)
                }
            })
        }
    }
    
    const serviceAddedTemplate = async (derivedPrice: number | string, item?: any, mrp?: number, saleprice?: string) => {
        let price = Number(derivedPrice)
        const taxAmount = Number((price * (Number(item.tax))/100).toFixed(2))
        let service = {
            orderDate,
            uid: item.uid,
            activeTimeSlot,
            activeCar,
            title: activeCar.registrationNo ? item.title + ' ['+activeCar.registrationNo+']' : item.title,
            image: JSON.parse(item.image)[0],
            price,
            purchaseQuantity: 1,
            type: 'service',
            tempUid: generateUID(),
            tax: item.tax,
            hsn: item.hsn,
            mrp,
            saleprice,
            selectPick,
            taxAmount,
            displayPrice: (price + taxAmount).toFixed(2)
        };
        let productAddedTemplate = '<div class="alert-msg"><i class="hi-checkbox-checked"></i><p>Service added to cart!</p></div>'+
        '<div class="service-box">'+
        '<img src="'+base.imageBaseUrl+JSON.parse(item.image)[0]+'" alt="'+item.title+'" class="service-image" />'+
        '<div class="service-content">'+
        '<p class="service-title">'+item.title+'</p>'+
        '<p class="service-price">'+toIndianCurrency(service.displayPrice)+'</p>'+
        '</div>'+
        '</div>'
        await addToCart(service)
        if (serviceData.slug !== 'pick-drop' && selectPick) {
            setserviceData('pick-drop')
            chooseActiveCar()
        }
        else {
            setchooseServiceTime(false)
            setserviceData('')
            setorderDate('')
            setactiveTimeSlot(timeSlot[0])
            setactiveCar('')
            setselectPick(false)
        }
        showAlert({ type: 'custom', template: productAddedTemplate })
    }
    
    const selectSubscription = item => {
        closeModal("subscribe-modal")
        setactiveSubscription(item)
        serviceAddedTemplate(item.price)
    }
    
    const toggleSubscribeDropDown = () => {
        setshowDropdwon(!showDropdwon)
    }
    
    const subscribe = time => {
        toggleSubscribeDropDown()
    }
    
    const buyItem = buyType => {
        setbuyType(buyType)
        openModal("add-to-cart-modal")
    }
    
    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if (slug !== urlData[2]) {
            getSubscriptionData()
            getServiceData({
                slug: urlData[2],
                status: 'publish'
            })
            getVehicleClasses()
        }
    }, [slug, urlData])
    
    return {
        selectSubscription,
        setactiveSubscription,
        activeSubscription,
        activeTimeSlot,
        setactiveTimeSlot,
        title,
        image,
        serviceTime,
        activePrice,
        activeCar,
        buyItem,
        excerpt,
        usernote,
        content,
        setorderDate,
        setchooseServiceTime,
        orderDate,
        chooseServiceTime,
        buyType,
        chooseActiveCar,
        subscriptionData,
    }
}