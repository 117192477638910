import { useEffect, useState } from "react"
import { useElement, useService } from "../../../../hooks"

export const useOrderTracking = () => {
    const Img = useElement('Img')
    const {setTitle} = useService('Meta')
    const {showAlert} = useService('Misc')
    const {getOrder} = useService('Order')
    const [submitForm, setsubmitForm] = useState<boolean>(false)
    const [orderContent, setorderContent] = useState(null)
    const [orderId, setorderId] = useState<string>('')
    const [paymentFail, setpaymentFail] = useState<boolean>(false)
    const [orderStatus, setorderStatus] = useState([ "initialised", "payment-fail", "paid", "processing", "shipped", "delivered", "completed" ])

    setTitle('Track Order')

    const getOrderData = async orderId => {
        if (orderId !== '') {
            let query = {
                uid: orderId
            }
            const result = await getOrder(query)
            if (result.status) {
                if (result.data?.length !== 0) {
                    let orderContent = result.data[0]
                    setorderContent(orderContent)
                    setsubmitForm(true)
                } else showAlert({ type: 'error', msg: 'Order with given ID not found, please check the order ID.' })
            }
        }
    }

    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                name: item.name,
                email: item.email,
                mobile: item.mobile,
                image: item.image?
                    <Img src={JSON.parse(item.image)[0]} style={{maxHeight: 40, maxWidth: 40}} />:
                <p>No Image</p>
            }
            data.push(e)
        })
        return data
    }

    let orderStatusIndex = 0
    if (orderContent !== null) {
        orderStatus.forEach(( e, i ) => {
            if (e === orderContent.status) {
                orderStatusIndex =i
            }
        })
    }
    return {
        paymentFail,
        setpaymentFail,
        submitForm,
        orderId,
        setorderId,
        getOrderData,
        orderStatusIndex,
        orderContent,
        orderStatus,
    }
}