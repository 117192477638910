import { useContext, useState } from "react"
import { franchiseTiles } from "../../../../constant"
import { NavigatorContext } from "../../../../context"

export const useFranchiseCTA = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const [activeFranchise, setactiveFranchise] = useState(franchiseTiles[0])

    const navigateToStoreLocator = () => {
        navigateTo({route: '/store-locator'})
    }

    return {
        franchiseTiles,
        activeFranchise,
        setactiveFranchise,
        navigateToStoreLocator,
    }
}