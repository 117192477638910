import { memo } from "react"
import Select from "react-select"
import { userRole } from "../../../../constant"
import { useUserTotal } from "./hooks"
import { useElement } from "../../../../hooks"
import { DashboardWidgetProps } from "../types"
export const UserTotal = memo(({
    data,
    action,
}: DashboardWidgetProps) => {
    const Input = useElement('Input')
    const Button = useElement('Button')
    const {
        applyFilters,
        setWidgetTitle,
        title,
        role,
        changeRole,
        count,
    } = useUserTotal({
        data,
        action,
    })

    const renderFilters = () => {
        return <form onSubmit={applyFilters}>
            <Input onChange={setWidgetTitle} value={title} placeholder="Widget Title" />
            <Select
                defaultValue={role}
                onChange={changeRole}
                options={userRole}
                placeholder='Select Role'
            />
            <Button type="submit" className="btn btn-primary hi-check-circle"></Button>
        </form>
    }

    return <>
        { data.configure ? renderFilters():
        <>
            <i className="widget-icon hi-user"></i>
            {count}
        </>}
    </>
})