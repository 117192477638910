import { carRoutes } from './Car'
import { gameRoutes } from './Game'
import { addressRoutes } from './Address'
import { homeRoutes } from './Home'
import { companyRoutes } from './Company'
import { eventRoutes } from './Event'
import { managerRoutes } from './Manager'
import { jobRoutes } from './Job'
import { blogRoutes } from './Blog'
import { projectRoutes } from './Project'
import { subscriptionRoutes } from './Subscription'
import { userRoutes } from './User'
import { serviceRoutes } from './Service'
import { microServiceRoutes } from './MicroService'
import { packageRoutes } from './Package'
import { garageRoutes } from './Garage'
import { pageRoutes } from './Page'
import { ecommerceRoutes } from './Ecommerce'
import { franchiseRoutes } from './Franchise'
import { staticRoutes } from './Static'
import { PageRouteProps } from '../types'

export const frontRoutes: PageRouteProps[] = [
    ...franchiseRoutes,
    ...packageRoutes,
    ...garageRoutes,
    ...pageRoutes,
    ...ecommerceRoutes,
    ...serviceRoutes,
    ...microServiceRoutes,
    ...userRoutes,
    ...subscriptionRoutes,
    ...blogRoutes,
    ...projectRoutes,
    ...staticRoutes,
    ...jobRoutes,
    ...managerRoutes,
    ...homeRoutes,
    ...carRoutes,
    ...gameRoutes,
    ...addressRoutes,
    ...companyRoutes,
    ...eventRoutes
]