import { useContext, useEffect, useState } from "react"
import { base, config, activeDateFilter as ADF} from '../../../../constant'
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useMyOrders = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const Img = useElement('Img')
    const {commonPageSize} = config
    const [tableOrderContent, settableOrderContent] = useState({})
    const [tableRecommendationContent, settableRecommendationContent] = useState({})
    const activeShownTotal = commonPageSize
    const {fetchByAttr} = useService('Data', 'companyOrder')
    const {
        getLoggedInUserData,
        getCompanyBranches: fetchCompanyBranches,
        getActiveBranches,
        setGlobalActiveBranches,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        toIndianCurrency,
        openModal,
        getFormattedDate,
    } = useService('Misc')
    const {isValidJson} = useService('Validation')
    const {navigateTo} = useContext(NavigatorContext)
    const {getUserOrders} = useService('Order')
    const user = getLoggedInUserData()
    const [activeDateFilter, setactiveDateFilter] = useState(ADF[0])
    const [activeBranches, setactiveBranches] = useState([])
    const [branches, setbranches] = useState([])
    const [tableData, settableData] = useState(null)
    const [isCompanyBranch, setisCompanyBranch] = useState<boolean>(false)
    const [orderData, setorderData] = useState(null)
    const [activeRecommendation, setactiveRecommendation] = useState(null)

    setTitle('My Orders')
    useEffect(() => {
        let orgId = false
        if (user?.role === 'representative') {
            orgId = user.parent?.value
            getCompanyBranches(orgId)
        } else if (user?.role === 'company') {
            orgId = user.uid
            getCompanyBranches(orgId)
        } else {
            if (['manager', 'company', 'branch', 'representative'].includes(user?.role)) {
                setisCompanyBranch(true)
                getOrdersData(true)
            }
            else
                getOrdersData()
        }
    }, [])

    const getDashboardData = (activeDateFilter, activeBranches) => {
        let activeBranchIds = []
        activeBranches?.forEach( e => {
            activeBranchIds.push(e.value)
        })
        getOrdersData(true, activeBranchIds)
    }
    
    const getCompanyBranches = async v => {
        let query = {
            parentId: v
        }, branches = []
        const result = await fetchCompanyBranches(query)
        if (result.status) {
            result.data.forEach(e => {
                branches.push({
                    label: e.name,
                    value: e.uid
                })
            })
            let activeBranches = getActiveBranches() === null ? getActiveBranches() : branches
            setbranches(branches)
            setactiveBranches(activeBranches)
            setGlobalActiveBranches(activeBranches)
            getDashboardData(activeDateFilter, activeBranches)
        }
    }
    
    const getOrdersData = async (isCompanyBranch?: boolean, branches?: string[]) => {
        if (isCompanyBranch) {
            let query = {
                ownerId: ['manager','representative'].includes(user.role) ? user.parent?.value : user.uid
            }
            if (branches) {
                query.ownerId = {
                    $in: branches
                }
            }
            const result = await fetchByAttr(query)
            if (result.status) {
                if (result.data?.length === 0) {
                    setorderData(null)
                } else {
                    let orderData = []
                    result.data.reverse().forEach(e => {
                        if (e.carDetails && e.carDetails !== "undefined" && isValidJson(e.carDetails) && e.orderData && isValidJson(e.orderData)) {
                            e.carDetails = isValidJson(e.carDetails) ? JSON.parse(e.carDetails) : false
                            e.carImages = isValidJson(e.carImages) ? JSON.parse(e.carImages) : false
                            e.orderData = isValidJson(e.orderData) ? JSON.parse(e.orderData) : false
                            orderData.push(e)
                        }
                    })
                    setorderData(orderData)
                }
            }
        } else {
            let query = {
                uid: user.uid
            }
            const result = await getUserOrders(query)
            if (result.status) {
                if (result.data?.length === 0)
                    settableData([])
                else
                    settableData(result.data.reverse())
            }
        }
    }
    
    const getOrderTableData = filterData => {
        let data = []
        filterData?.forEach(item => {
            let e = {
                orderId: `#${item.uid}`,
                name: JSON.parse(item.userData).name,
                timing: getFormattedDate(item.timing),
                status: <Badge item={item.status} />,
                cartTotal: JSON.parse(item.orderData).discount ?
                    <div>{toIndianCurrency(JSON.parse(item.orderData).cartTotal - JSON.parse(item.orderData).discount)}</div>:
                    <div>{toIndianCurrency(JSON.parse(item.orderData).cartTotal)}</div>,
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => navigateTo({route: '/order/view/'+item.uid})} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    const browsePurchase = item => {
        if (typeof item.carDetails.carData === 'string') {
            item.carDetails.carData = JSON.parse(item.carDetails.carData)
        }
        setactiveRecommendation(item)
        openModal('recommendation-modal')
    }
    
    const getRecommendationsTableData = filterData => {
        let data = []
        filterData?.forEach(( e ) => {
            let car = e.carDetails
            let carData = typeof car.carData === 'string' ? JSON.parse(car.carData) : car.carData
            let product = e.orderData[0]
            let el = {
                orderOd: `#${e.uid}`,
                actionDate: getFormattedDate(e.actionDate),
                car: <div className='flex'>
                        {
                            carData?.image_path && <Img source='carCDN' src={carData.image_path} style={{maxHeight: 40, maxWidth: 64, marginRight: 10}} />
                        }
                        <div className='fs12'>
                            {(car.registrationNo).toUpperCase()}<br />
                            {carData.name+' '+carData?.Segment}
                        </div>
                    </div>,
                image: <div className='flex'>
                        {product.image && <Img src={JSON.parse(product.image)[0]} alt={product.title} style={{ width: 50, borderRadius: 5, marginRight: 5, marginBottom: 'auto' }} />}
                        { product.title ? product.title : product.value }
                    </div>,
                status: <Badge item={e.status} />,
                action: <div className="action-bar">
                    <Actor type='view' onClick={() => browsePurchase(e)} />
                    { e.invoiceNo && <Actor type='download' onClick={() => navigateTo({route: base.invoiceBaseUrl+"/invoice_"+e.invoiceNo+".pdf", newTab: true})} /> }
                </div>
            }
            data.push(el)
        })
        return data
    }

    const selectActiveBranches = (e) => {
        setactiveBranches(e)
        setGlobalActiveBranches(e)
        getDashboardData(activeDateFilter, e)
    }

    useEffect(() => {
        settableRecommendationContent({
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Car',
                    key: 'car'
                },
                {
                    label: 'Product / Service',
                    key: 'product_Service'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getRecommendationsTableData(orderData)
        })
    }, [orderData])

    useEffect(() => {
        settableOrderContent({
            headers: [
                {
                    label: 'Order Id',
                    key: 'orderId'
                },
                {
                    label: 'Name',
                    key: 'name'
                },
                {
                    label: 'Date',
                    key: 'date'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Total',
                    key: 'total'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getOrderTableData(tableData)
        })
    }, [tableData])
    return {
        user,
        branches,
        activeBranches,
        selectActiveBranches,
        orderData,
        tableRecommendationContent,
        tableOrderContent,
        activeShownTotal,
        activeRecommendation,
    }
}