import { useEffect, useRef, useState } from "react"

export const useRun = () => {
    const obstacle = useRef(null)

    const player = useRef(null)
    const [gameState, setgameState] = useState('stop')

    useEffect(() => {
        setgameState('play')
        runObstacles()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            checkIfAlive(gameState)
        }, 10);
    }, [gameState, player])

    const checkIfAlive = gameState => {
        let playerCoordinates
        let obstacleCoordinates
            if (gameState === 'play' && player && obstacle) {
                playerCoordinates = player.current.getBoundingClientRect()
                obstacleCoordinates = obstacle.current.getBoundingClientRect()
                    if (obstacleCoordinates.x - playerCoordinates.x + playerCoordinates.width < 2) {
                        stopObstacles()
                        setgameState('pause')
                    }
            }
    }

    const runObstacles = () => {
        if (obstacle?.current) {
            obstacle.current.className += ' run'
        }
    }
    
    const stopObstacles = () => {
        if (obstacle?.current) {
            obstacle.current.className = obstacle.current.classList.replace(' run', '')
        }
    }

    const jump = () => {
        if (!player.current.classList.includes('jump')) {
            player.current.className += ' jump'
            setTimeout(() => {
                player.current.className = player.current.classList.replace(' jump', '')
            }, 700);
        }
    }
    return {
        jump,
        player,
        obstacle,
    }
}