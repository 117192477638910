import { useContext } from 'react'
import './style.scss'
import { useElement } from '../../../hooks'
import { usePremiumServices } from './hooks'
import { base } from '../../../constant'
import { NavigatorContext } from '../../../context'

export const PremiumServices = () => {
    const Img = useElement('Img')
    const {navigateTo} = useContext(NavigatorContext)
    const {
        serviceList,
    } = usePremiumServices()
    const Slider = useElement('Slider')
    const Button = useElement('Button')
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }
    const renderServiceList = () => {
        return serviceList.map((e, i) => {
            return <div className='premium-box' key={i}>
                <div className='premium-service' style={{backgroundImage: `url('${base.imageBaseUrl}${JSON.parse(e.image)[0]}')`}}>
                    <Button onClick={() => navigateTo({route: '/service/'+e.slug})} className="btn btn-primary">Book Now<i className='hi-cart'></i></Button>
                    <h3>{e.title}</h3>
                </div>
            </div>
        })
    }
    return <section className="premium-services-section mb60-mbs40">
        <div className='jumbo-container'>
            <h2 className="heading-02">Our Premium Services</h2>
            <Slider {...settings}>
                { renderServiceList() }
            </Slider>
        </div>
    </section>
}
