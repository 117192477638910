import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useOurEvents = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'event')
    const {setTitle} = useService('Meta')
    const [eventList, seteventList] = useState([])

    setTitle('Events')
    useEffect(() => {
        getEventData()
    }, [])

    const viewEvent = (slug) => {
        navigateTo({route: `/event/${slug}`})
    }

    const getEventData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status)
            seteventList(result.data)
    }
    return {
        eventList,
        viewEvent,
    }
}