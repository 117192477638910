import { provideText } from "../../../constant"

export const StepProvide = () => {
    return <ul className='steps-provide'>
        {
            provideText.map((e, i) => {
                return <div className='step' key={i}>
                    <p>{e}</p>
                    <span>0{i+1}</span>
                    {i !== provideText.length - 1 && <i className='hi-double_arrow'></i>}
                </div>
            })
        }
    </ul>
}