import {brandInfo} from '../../../constant'
import { usePackageDetail } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const PackageDetail = () => {
    const {
        getDay,
        closeModal,
        getFormattedDate,
        getFormattedTime,
        isEmpty,
    } = useService('Misc')
    const Img = useElement('Img')
    const BackIcon = useElement('BackIcon')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Table = useComponent('Table')
    const Box = useComponent('Box')
    const {
        chosenServiceDates,
        chosenPreventiveCheckListDates,
        getPreventiveAnswers,
        upgradedData,
        getCompletedWorkData,
        getTableData,
        packageData,
        needDate,
        setactiveServiceDate,
        activeServiceDate,
        updatePackage,
        preventiveCheckList,
        serviceWorkAwaitedStatus,
        carNotAvailable,
        packageWork,
        serviceData,
        workData,
    } = usePackageDetail()

    const renderServiceData = (packageData, todayOnly = false) => {
        let datesCollection = [], buildBilling = 30
        if (packageData.billing === 'quarterly') {
            buildBilling = 90
        }
        for (let i = 0; i < buildBilling; i++) {
            let newDate = new Date(packageData.startDate).getTime() + i * 1000 * 60 * 60 * 24,
            e = {
                date: getFormattedDate(newDate),
                day: new Date(newDate).getDay(),
                newUid:  !isEmpty(upgradedData) ? upgradedData.uid : undefined,
            }
            datesCollection.push(e)
        }
        return datesCollection.map(( item, index ) => {
            let found = false, preventiveFound = false
            chosenServiceDates.forEach(e => {
                if (getFormattedDate(new Date(e)) === getFormattedDate(new Date(item.date)))
                found = true
            })
            chosenPreventiveCheckListDates.forEach(e => {
                if (getFormattedDate(new Date(e)) === getFormattedDate(new Date(item.date)))
                preventiveFound = true
            })
            if ((item.day !== 0 && !todayOnly) || (todayOnly && getFormattedDate(new Date()) === getFormattedDate(new Date(item.date)))) {
                return <Box key={index} title={<>{getDay(item.day)} <span className="datePreventive-checklist">({item.date})</span></>}>
                    <ul className="daily-service-data">
                        { preventiveFound && <li><span onClick={() => getPreventiveAnswers(item.date)} className="link">Preventive Checklist</span> {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        { found && <li>Car Wash {todayOnly && <i className="checked-service hi-check_circle"></i>}</li> }
                        <li>Car Cleaning (Waterless) {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 22].includes(index) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (index === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 22].includes(index) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 14, 22].includes(index) && packageData.uid === 'PACKAGE__003')?
                            <li>Vacuuming {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                        <li>Air Pressure {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>
                        {
                            upgradedData !== null && upgradedData !== '' ?
                            ([6, 3].includes(item.day) && upgradedData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && upgradedData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>:
                            (item.day === 6 && packageData.uid === 'PACKAGE__001') ||
                            ([6, 3].includes(item.day) && packageData.uid === 'PACKAGE__002') ||
                            ([6, 4, 2].includes(item.day) && packageData.uid === 'PACKAGE__003')?
                            <li>Interior Dusting {todayOnly && <i className="checked-service hi-check_circle"></i>}</li>:<></>
                        }
                    </ul>
                    {!todayOnly && <p className="view-service-status" onClick={() => getCompletedWorkData(item.date)}>Status</p>}
                </Box>
            } else return true
        })
    }

    const renderPriventiveCheckList = preventiveQueries => {
        return preventiveQueries.map(( item, index ) => {
            return <div key={index}>
                <h3>{index+1}. {item.serviceTitle}</h3>
                { renderPreventiveQueriesHead(item.serviceQueries || [] )}
            </div>
        })
    }
    
    const renderPreventiveQueriesHead = queriesFiles => {
        return queriesFiles.map(( item, index ) => {
            let tableContent = {
                content: getTableData(item.status)
            }
            return <div key={index}>
                <p>{index+1}. {item.query}</p>
                <Table
                    showTotal={false}
                    data={tableContent}
                />
            </div>
        })
    }

    const renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                <Img src={item} alt={item} hasZoom={true} />
            </li>
        })
    }

    return <>
        {
            packageData !== null &&
            <>
                <h1 className="flex">
                    <span>
                        <BackIcon backLink='/admin/package/order/list' />
                        {
                            packageData.title
                        }
                        {
                            upgradedData !== null && upgradedData !== ''?
                            '('+upgradedData.title+')': ''
                        }
                        Service Schedule
                    </span>
                </h1>
                <div className="columns">
                    <div className="left">
                        { renderServiceData(packageData) }
                    </div>
                    <div className="right">
                        {
                            needDate &&
                            <Box title="Choose Preventive Checklist Date" bodyClass="select-date-mypackages">
                                <DatePicker
                                    onChange={activeServiceDate => setactiveServiceDate(activeServiceDate)}
                                    maxDate={new Date(packageData.endDate)}
                                    selected={activeServiceDate}
                                />
                                <Button className="btn btn-primary tac" onClick={updatePackage}>Submit</Button>
                            </Box>
                        }
                        <Box title="Preventive Checklist">
                            <span onClick={() => {
                                getPreventiveAnswers(new Date().getTime(), 'view')
                            }} className="link">View Preventive Checklist</span>
                        </Box>
                    </div>
                </div>
            </>
        }
        <div className="modal" id="preventive-checklist-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content small">
                <Button className="close" onClick={() => closeModal("preventive-checklist-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    { renderPriventiveCheckList(preventiveCheckList || [] )}
                </div>
            </div>
        </div>
        <div className="modal" id="car-service-status-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content medium">
                <Button className="close" onClick={() => closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    {
                        serviceWorkAwaitedStatus ?
                        <>
                        <div className="no-data-icon">
                            <i className="hi-hourglass_empty"></i>
                        </div>
                        <p className="awited-text">Work is still awaited for the day for this service</p></>: 
                        <div>
                            {
                                packageWork !== null &&
                                <>
                                    {
                                        carNotAvailable?
                                        <>
                                            <div className="no-data-icon">
                                                <i className="hi-directions_car"></i>
                                            </div>
                                            <p className="awited-text">Car Not Available</p>
                                        </>:
                                        <>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Worked Services</label>
                                                <div className="col-9 controls ml20">
                                                    {
                                                        typeof workData[0].workedServices !== 'undefined' && workData[0].workedServices.length !== 0?
                                                        workData[0].workedServices.map((e, index) => {
                                                            return <p key={index}>{e} <i className="checked-service hi-check_circle"></i></p>
                                                        })
                                                        :renderServiceData(packageData, true)
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Service Date</label>
                                                <div className="col-9 controls ml20">
                                                    {getFormattedDate(packageWork.serviceDate)}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Start Time</label>
                                                <div className="col-9 controls ml20">
                                                    {
                                                        typeof workData[0].startTime !== 'undefined'?
                                                        getFormattedTime(workData[0].startTime) :
                                                        getFormattedTime(workData[0].time)
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">{brandInfo.name.capital} Id</label>
                                                <div className="col-9 controls ml20">
                                                    {packageWork.workerId}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Active Car</label>
                                                <div className="col-9 controls ml20">
                                                    <li className="my-packages-assign-car">
                                                        {
                                                            serviceData.image_path === null || typeof serviceData.image_path === 'undefined' || serviceData.image_path === ''?<></>:
                                                            <Img source='carCDN' src={(JSON.parse(packageWork.activeCar).image_path)} alt={(packageWork.activeCar).name} />
                                                        }
                                                        <div className="ml10">
                                                            <p>{serviceData.name+' '+serviceData?.Segment}</p>
                                                            {
                                                                serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null?
                                                                <small>{serviceData.registrationNo}</small>:<></>
                                                            }
                                                        </div>
                                                    </li>
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Comments</label>
                                                <div className="col-9 controls ml20">
                                                    {workData[0].comments}
                                                </div>
                                            </div>
                                            <div className="form-group flex">
                                                <label className="col-3 control-label">Images</label>
                                                <div className="col-9 controls ml20">
                                                    <ul className="media-box">
                                                        { renderImages(workData[0].carImages ?? workData[0].carImagesName ?? []) }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}
