import { useContext } from 'react'
import Select from 'react-select'
import './style.scss'
import { useCheckout } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'
import { NavigatorContext } from '../../../context'

export const Checkout = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {
        parseAddress,
        toIndianCurrency,
        isModuleEnabled,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Slider = useElement('Slider')
    const Input = useElement('Input')
    const Link = useElement('Link')
    const Banner = useWidget('Banner')
    const Modal = useComponent('Modal')
    const checkoutSettings = {
        dots: true,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000
    }
    const {
        franchises,
        setactiveFranchise,
        activeFranchise,
        showFranchiseModal,
        setshowFranchiseModal,
        franchiseCode,
        setfranchiseCode,
        placeFranchiseOrder,
        payAtFranchise,
        proceedToPay,
        orderData,
        discount,
        productDiscount,
        serviceDiscount,
        applyCouponCode,
        removeCouponCode,
        couponApplied,
        couponCode,
        activeSociety,
        setcity,
        city,
        state,
        setstate,
        landmark,
        setlandmark,
        pinCode,
        setpinCode,
        alternateNo,
        setalternateNo,
        userData,
        setactiveSociety,
        setshowSocietyListBox,
        setlocality,
        setfullAddress,
        setactiveAddress,
        activeAddress,
        navigateToLogin,
        registerNewUser,
        setregisterNewUser,
        name,
        setname,
        email,
        setemail,
        mobile,
        setmobile,
        gstin,
        setgstin,
        terms,
        setterms,
        addressList,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setcouponCode,
        showSocietyListBox,
        fullAddress,
        societyData,
    } = useCheckout()
    const renderSocietyList = societyData => {
        return societyData.map(( item, index ) => {
            let activeClass = ''
            if (activeSociety !== null)
                activeClass = activeSociety.title === item.title? 'active': ''
            return <li key={index} className={activeClass} onClick={() => {
                setactiveSociety(item)
                setshowSocietyListBox(false)
                let activeaddress = JSON.parse(item.addressDetail), pinCode ='', locality= '', city= '', state = ''
                activeaddress.address_components.forEach(e => {
                    if (e.types[0]==="postal_code")
                        pinCode = e.long_name
                    if (e.types[0]==="administrative_area_level_1")
                        state = e.long_name
                    if (e.types[0]==="administrative_area_level_2")
                        city = e.long_name
                    if (e.types[0]==="locality")
                        locality = e.long_name
                })
                setlocality(locality)
                setpinCode(pinCode)
                setcity(city)
                setstate(state)
                setfullAddress(activeaddress.formatted_address)
            }}>
                <p>{item.title}</p>
            </li>
        })
    }
    
    const renderAddressList = addressFiles => {
        return addressFiles.map(( item, index ) => {
            return <div className="p15" key={index} onClick={() => setactiveAddress(item)}>
                <div className="form-box form-select-box">
                    <div className="account-detail service-single-item locationlistdetail">
                        <div className="col-9">
                            <div className="service-content p0">
                                {
                                    activeAddress.uid === item.uid?
                                    <i className="success hi-check_circle"></i>:<i className="hi-circle ashgray"></i>
                                }
                                <div className="item-number">{index>9?(index+1): '0'+(index+1)}</div>
                            </div>
                            <div className="form-group">
                                <label className="col-3 control-label">Address</label>
                                <p>{parseAddress(item)}</p>
                            </div>
                            <div className="row">
                                <div className="form-group col">
                                    <label className="col-3 control-label">Landmark </label>
                                    <p>{item.landmark}</p>
                                </div>
                                <div className="form-group col">
                                    <label className="col-3 control-label">Alternate Phone No. </label>
                                    <p>{item.alternateNo}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    
    const renderCartData = cartData => {
        return cartData.map(( item, index ) => {
            if (!item.extraDiscount)
                item.extraDiscount = 0
            return <tr className="cart_item" key={index}>
                <td className="product-name">
                    {item.title} × {item.purchaseQuantity}
                </td>
                <td className="product-total">
                    <span className="Price-amount amount">
                        {toIndianCurrency((item.displayPrice - item.extraDiscount) * item.purchaseQuantity)}
                    </span>						
                </td>
            </tr>
        })
    }

    let netPayable = ''
    if (orderData) {
        if (orderData.cartTotal)
            netPayable = orderData.cartTotal.toFixed(2)
        else
            navigateTo({route: '/cart'})
    }

    return <>
        <Banner title='Checkout' image="blue-road-1.jpg"/>
        <div className="checkout-page pv40">
            <div className="jumbo-container">
                {
                    orderData === null || (Array.isArray(orderData) && orderData.length === 0)?
                    <div className="empty-cart">
                        <Img source='internal' src={require('../../../../assets/images/emptycart.png')} alt="empty-cart" />
                        <p className="tac">No items in the cart yet!</p>
                    </div>:
                    <div className="wrapper mh-20">
                        <div className="left-checkout">
                            <div className="billing-address">
                                <h2 className="heading-02">Delivery Details</h2>
                                {
                                    userData === null &&
                                    <>
                                        <p className="active-options" onClick={navigateToLogin}>
                                            <i className="hi-circle"></i>
                                            Already a member?
                                        </p>
                                        <p className="active-options" onClick={() => setregisterNewUser(true)}>
                                            <i className="hi-check_circle orange"></i>
                                            New User
                                        </p>
                                        <hr className="mh0" />
                                    </>
                                }
                                {
                                    userData !== null?
                                    <>
                                        <h2>User Details</h2>
                                        <div className="form-group">
                                            <label>Full Name</label>
                                            <p>{userData.name}</p>
                                        </div>
                                        <div className="form-group">
                                            <label>Email</label>
                                            <p>{userData.email}</p>
                                        </div>
                                        <div className="form-group">
                                            <label>Mobile Phone</label>
                                            <p>{userData.mobile}</p>
                                        </div>
                                    </>:
                                    registerNewUser &&
                                    <>
                                        <h2>User Details</h2>
                                        <div className="form-group">
                                            <Input className="input" placeholder="Full Name *" required name="name" value={name||''} onChange={e => setname(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <Input className="input" placeholder="Email *" type="email" required name="email" value={email||''} onChange={e => setemail(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <Input className="input" placeholder="Mobile *" type="number" required name="mobile" value={mobile||''} onChange={e => setmobile(e.target.value)} />
                                        </div>
                                        <div className="form-group">
                                            <Input className="input" placeholder="GSTIN (optional)" name="gstin" value={gstin||''} onChange={e => setgstin(e.target.value)} />
                                        </div>
                                        <div className="flex-box">
                                            <label className="switch">
                                                <Input type="checkbox" onChange={() => setterms(!terms)} checked={terms?'checked':false} />
                                                <span className="slider round"></span>
                                            </label>
                                            <span>I accept the <Link target="_blank" href="/page/terms-and-conditions" title="Terms and Conditions">Terms and Conditions</Link></span>
                                        </div>
                                    </>
                                }
                                {
                                    (userData !== null) && (addressList.length !== 0)?
                                    <>
                                        <hr className="mh0" />
                                        <h2>Delivery Address</h2>
                                        <div className="address-slider-checkout">
                                            <Slider {...checkoutSettings}>
                                                {renderAddressList(addressList || [] )}
                                            </Slider>
                                        </div>
                                    </>:
                                    (registerNewUser || addressList.length === 0) &&
                                    <>
                                        <hr className="mh0" />
                                        <h2>Delivery Address</h2>
                                        {
                                            isModuleEnabled(["Society"]) && fromSociety ?
                                            <div className="relative">
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Select your society</label>
                                                    <div className="col-9 controls relative">
                                                        {
                                                            activeSociety === null ?
                                                            <Button className="btn btn-primary" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>Show Societies List</Button>
                                                            :
                                                            <><div className="selected-society-type" onClick={() => setshowSocietyListBox(!showSocietyListBox)}>
                                                                <p>{activeSociety.title}</p>
                                                            </div>
                                                            <small className="ashgray">({ fullAddress })</small></>
                                                        }
                                                        {
                                                            showSocietyListBox &&
                                                            <ul className="scroll-box mt-10 full">
                                                                { renderSocietyList(societyData || []) }
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    activeSociety !== null &&
                                                    <div className="filled-society-data">
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" placeholder="Locality (Street/Area)" name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" placeholder="Pincode" type="number" disabled name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" placeholder="City/District/Town" disabled name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">State <sup>*</sup></label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" placeholder="State" disabled name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <hr />
                                                        <div className="form-group">
                                                            <label className="col-3 control-label">Alternate Phone No. </label>
                                                            <div className="col-9 controls">
                                                                <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="link mt20" onClick={() => setfromSociety(!fromSociety)}>Not living in above listed society? Click Here</div>
                                            </div>:
                                            <>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Building / Flat <sup>*</sup>: </label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" autoFocus placeholder="Building / Flat" required name="building" value={building||''} onChange={e => setbuilding(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Locality (Street/Area) <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="Locality (Street/Area)" required name="locality" value={locality||''} onChange={e => setlocality(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Pincode <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="Pincode" type="number" required name="pinCode" value={pinCode||''} onChange={e => setpinCode(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">City/District/Town <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="City/District/Town" required name="city" value={city||''} onChange={e => setcity(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">State <sup>*</sup></label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="State" required name="state" value={state||''} onChange={e => setstate(e.target.value)} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Landmark </label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="Landmark" required name="landmark" value={landmark||''} onChange={e => setlandmark(e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-3 control-label">Alternate Phone No. </label>
                                                    <div className="col-9 controls">
                                                        <Input className="input" placeholder="Alternate Phone No." type="number" name="alternateNo" value={alternateNo||''} onChange={e => setalternateNo(e.target.value)} />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="right-checkout">
                            <h2 className="heading-02">Your Order</h2>
                            <div id="order_review" className="checkout-review-order">
                                {/* <Table
                                    data={tableContent}
                                    pageSize={activeShownTotal}
                                /> */}
                                <table className="shop-table checkout-review-order-table">
                                    <thead>
                                        <tr>
                                            <th className="product-name">Particulars</th>
                                            <th className="product-total">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { renderCartData(orderData.cartData || []) }
                                    </tbody>
                                    <tfoot>
                                        {
                                            !isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0' ?
                                            <>
                                                <tr className="cart-subtotal">
                                                    <th>Total</th>
                                                    <td><span className="Price-amount amount">{toIndianCurrency(orderData.cartTotal)}</span></td>
                                                </tr>
                                                <tr className="cart-subtotal">
                                                    <th>Discount</th>
                                                    <td><span className="Price-amount amount">{toIndianCurrency(orderData.discount)}</span></td>
                                                </tr>
                                            </>:<></>
                                        }
                                        <tr className="order-total">
                                            <th>Coupon Apply</th>
                                            <td>
                                                <div className="coupon">
                                                    <Input name="couponCode" className="input-text" placeholder="Coupon code" value={couponCode} onChange={e => setcouponCode(e.target.value)} />
                                                    {
                                                        couponCode !== '' &&
                                                        <>
                                                            {
                                                                couponApplied?
                                                                <Button className="btn btn-primary" onClick={removeCouponCode}>Remove</Button>:
                                                                <Button className="btn btn-primary" onClick={applyCouponCode}>Apply</Button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        {
                                            (serviceDiscount > 0) &&
                                            <tr>
                                                <th>Service Discount</th>
                                                <td>{toIndianCurrency(serviceDiscount)}</td>
                                            </tr>
                                        }
                                        {
                                            (productDiscount > 0) &&
                                            <tr>
                                                <th>Product Discount</th>
                                                <td>{toIndianCurrency(productDiscount)}</td>
                                            </tr>
                                        }
                                        {
                                            discount > 0?
                                            <>
                                                <tr>
                                                    <th><strong>Overall Discount</strong></th>
                                                    <td><strong>{toIndianCurrency(discount)}</strong></td>
                                                </tr>
                                                <tr>
                                                    <th>Net Payable</th>
                                                    <td>{toIndianCurrency((orderData.cartTotal) - orderData.discount)}</td>
                                                </tr>
                                            </>:
                                            <tr className="order-total">
                                                <th>Net Payable</th>
                                                <td><strong><span className="Price-amount amount">{toIndianCurrency(netPayable)}</span></strong> </td>
                                            </tr>
                                        }
                                    </tfoot>
                                </table>
                                <Button className="btn btn-block btn-primary proccedtopay-btn mb20" onClick={proceedToPay}>Proceed to Pay</Button>
                                <Button className="btn btn-block btn-outline btn proccedtopay-btn" onClick={payAtFranchise}>Pay at Franchise</Button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        <Modal id="franchise-payment-modal" onSuccess={placeFranchiseOrder} successButtonLabel='Proceed to Order' showCancel={false} onCancel={() => setshowFranchiseModal(false)} showModal={showFranchiseModal}>
            <div className='p20'>
                <p>Select Franchise</p>
                <Select
                    value={activeFranchise}
                    defaultValue={activeFranchise}
                    onChange={e => setactiveFranchise(e)}
                    options={franchises}
                />
                <Input className="input mt20" placeholder="Enter Employee Reference Code" required name="franchiseCode" value={franchiseCode||''} onChange={e => setfranchiseCode(e.target.value)} />
            </div>
        </Modal>
    </>
}
