import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useTaxClassList = () => {
    const Actor = useElement('Actor')
    const CheckBox = useElement('CheckBox')
    const {
        fetchAll,
        remove,
        update,
        save,
    } = useService('Data', 'taxClass')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const activeShownTotal = commonPageSize
    const [activeItem, setactiveItem] = useState(null)
    const [tableData, settableData] = useState([])
    const [filteredList, setfilteredList] = useState([])
    const [checkedList, setcheckedList] = useState([])
    const [searchKey, setsearchKey] = useState<string>('')
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [trashAction, settrashAction] = useState<boolean>(false)
    const [isMultipleDelete, setisMultipleDelete] = useState<boolean>(false)
    const [checkedAll, setcheckedAll] = useState<boolean>(false)

    setTitle('TaxClasses List', 'admin')
    useEffect(() => {
        getTaxClassesListData()
    }, [])

    const getTaxClassesListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let tableData = []
            result.data.reverse().forEach(e => {
                tableData.push(e)
            })
            settableData(tableData)
            setfilteredList(tableData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No taxClass added yet!' })
        } else showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const deleteSelectedTaxClasse = () => {
        deleteTaxClass(activeItem.uid)
    }
    
    const deleteTaxClass = async (uid, task = 'trash') => {
        if (trashAction && task === 'trash') {
            const result = await remove({ uid })
            if (result.status) {
                getTaxClassesListData()
                showAlert({ type: 'success', msg: 'TaxClasse deleted successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getTaxClassesListData()
            } else showAlert({ type: 'error', msg: 'Unable to delete taxClass!' })
        } else {
            let taxClassObject = null
            tableData.forEach(e => {
                if (e.uid === uid)
                    taxClassObject = e
            })
            taxClassObject.trash = task === 'trash'
            delete taxClassObject._id
            let data = {
                query: JSON.stringify({uid}),
                taxClassData: JSON.stringify(taxClassObject)
            }
            const result = await update(data)
            if (result.status) {
                showAlert({ type: 'success', msg: 'TaxClasse '+task+'d successfully!' })
                setactiveItem(null)
                setshowTrashConfirm(false)
                getTaxClassesListData()
            } else showAlert({ type: 'error', msg: 'Unable to '+task+' taxClass!' })
        }
    }
    
    useEffect(() => {
        setcheckedAll(filteredList.length === checkedList.length)
    }, [filteredList, checkedList])

    const toggleCheck = uid => {
        if (checkedList.includes(uid)) {
            setcheckedList(checkedList.filter(e => e !== uid))
        } else {
            setcheckedList([...checkedList, uid])
        }
    }
    
    const deleteChecked = () => {
        (checkedAll ? filteredList : checkedList).forEach(e => {
            deleteTaxClass(e.uid)
        })
        setcheckedList([])
        setisMultipleDelete(false)
    }
    
    const cloneTaxClasse = async item => {
        let taxClassObject = {
            ...item,
            label: item.label+'-copy',
            uid: generateUID()
        }
        const result = await save(taxClassObject)
        if (result.status) {
            showAlert({ type: 'success', msg: 'TaxClasse clonsed successfully!' })
            getTaxClassesListData()
        } else showAlert({ type: 'error', msg: 'Unable to add taxClass!' })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.map(item => {
            let e = {
                checkAction: <span className="check-all" onClick={() => toggleCheck(item.uid)}>
                    {
                        checkedAll || checkedList.includes(item.uid) ?
                        <i className="orange hi-check-circle"></i>:<i className="hi-circle"></i>
                    }
                </span>,
                label: item.label,
                className: item.className,
                amount: item.amount,
                action: <div className="action-bar">
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                    <Actor type='edit' onClick={() => navigateTo({route: '/admin/taxClass/edit/'+item.uid})} />
                    <Actor type='copy' onClick={() => cloneTaxClasse(item)} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    template: <span className="check-all" onClick={() => {
                        let newCheckedList = []
                        if (checkedAll) {
                            setcheckedAll(!checkedAll)
                            setcheckedList([])
                        } else {
                            filteredList.forEach(e => newCheckedList.push(e.uid))
                            setcheckedAll(!checkedAll)
                            setcheckedList(newCheckedList)
                        }
                    }}>
                        <CheckBox isChecked={checkedAll || (checkedList.length === filteredList.length && filteredList.length !== 0) } />
                    </span>
                },
                {
                    label: 'Label',
                    key: 'label'
                },
                {
                    label: 'Class Name',
                    key: 'className'
                },
                {
                    label: 'Amount',
                    key: 'amount'
                },
                {
                    label: 'Actions',
                    key: 'actions'
                }
            ],
            content: getTableData(filteredList)
        })
    }, [filteredList, checkedAll, checkedList])
    return {
        trashAction,
        checkedList,
        checkedAll,
        setisMultipleDelete,
        setshowTrashConfirm,
        tableContent,
        activeShownTotal,
        deleteChecked,
        deleteSelectedTaxClasse,
        trashCancel,
        showTrashConfirm,
        isMultipleDelete,
    }
}