import { useElement } from '../../../hooks'
import { useOtp } from './hooks'

export const Otp = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const {
        changeOtp,
        login,
        resendOtp,
        mobile,
        navigateToLogin
    } = useOtp()

    return <div className="login-page">
        <div className="left-box-login left-box-login-otp"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox">
                <div className="loginIcon">
                    <i className='hi-keyboard'></i>
                </div>
                <h1 className="form-title tac">Please enter OTP received on your mobile {mobile}</h1>
                <form onSubmit={login} noValidate className="relative">
                    <div className="form-group">
                        <Input autoFocus onChange={changeOtp} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                    </div>
                    <Button type="submit" className="width-full btn-primary mt30">Proceed</Button>
                    <span className="link resend-otp" onClick={resendOtp}> Resend OTP</span>
                </form>
            </div>
            <i className="hi-arrow-circle-left footer-back-btn" onClick={navigateToLogin}></i>
        </div>
    </div>
}
