import { memo } from "react"
import { TableHeaderProps } from "../../types"

export const TableHeader = memo(({data}: TableHeaderProps) => {
    return <thead>
        <tr>{
            data?.map((e, i) => {
                return <th key={i}>
                    {e.label ?? e.template}
                </th>
            })
        }</tr>
    </thead>
})