import { PageRouteProps } from "../../types";

export const chatbotRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/chatbot/generic",
        "module": ["Chatbot"],
        "component": "GenericChatBot",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/chatbot/queries",
        "module": ["Chatbot"],
        "component": "QueriesChatBot",
        "type": "admin",
        "mode": "static"
    }
]