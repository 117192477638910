import { memo } from 'react'
import { useElement } from '../../../hooks'

interface UserImageProps {
    image?: string
    name?: string
}

export const UserImage = memo(({image, name}: UserImageProps) => {
    const Img = useElement('Img')
    return <>
        {
            image ?
            <Img src={image} title={name} />:
            <i className="hi-user1"></i>
        }
    </>
})
