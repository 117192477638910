import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useProfile = () => {
    const {save} = useService('Data', 'media')
    const {
        getLoggedInUserData,
        updateUser,
        login,
    } = useService('User')
    const {setTitle} = useService('Meta')
    const {
        getBase64,
        generateUID,
        showAlert,
        openModal,
    } = useService('Misc')
    const [userData, setuserData] = useState(getLoggedInUserData())
    const [confirmPassword, setconfirmPassword] = useState<string>('')
    const [showProfileImageOptions, setshowProfileImageOptions] = useState<boolean>(false)
    const [file, setfile] = useState(null)
    const [fileObj, setfileObj] = useState([])
    const [fileArray, setfileArray] = useState([])
    const [activeImage, setactiveImage] = useState(JSON.parse(getLoggedInUserData().image)[0])

    setTitle('My Profile')

    const selectFiles = async (e) => {
        let fileArray = [], fileObj = []
        fileObj.push(e.target.files)
        setfileObj(fileObj)
        for (let i = 0; i < fileObj[0].length; i++) {
            getBase64(fileObj[0][i]).then(result => {
                let fileData = {
                    url: URL.createObjectURL(fileObj[0][i]),
                    data: result,
                    fileName: fileObj[0][i].name
                }
                fileArray.push(fileData)
                setfile(fileArray)
                setfileArray(fileArray)
                setshowProfileImageOptions(false)
                uploadFiles()
            })
        }
    }
    
    const uploadCall = async file => {
        let error = false,
        errorMsg = ''
        if (!error) {
            file.uid = generateUID()
            const fileResult = await save(file)
            if (fileResult.status) {
                let userObject = {
                    image: '["'+fileArray[0].fileName+'"]'
                },
                data = {
                    query: JSON.stringify({uid: userData.uid}),
                    userData: JSON.stringify(userObject)
                }
                const result = await updateUser(data)
                if (result.status) {
                    login(userData).then(userObj=> {
                        if (userObj !== null) {
                            showAlert({ type: 'success', msg: 'Profile image updated successfully!' })
                            setfileArray([])
                            setfile([])
                            setactiveImage(JSON.parse(userObject.image)[0])
                            userData.image = userObject.image
                            login(userData)
                        }
                    })
                } else
                    showAlert({ type: 'error', msg: 'Unable to update profile image!' })
            } else showAlert({ type: 'error', msg: 'Unable to update profile image!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const uploadFiles = () => {
        for (let i = 0; i < file.length; i++) {
            uploadCall(file[i])
        }
    }
    
    const updateProfile = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = userData
        if (userData.email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (userData.name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        } else if (userData.mobile === '') {
            errorMsg = 'Please enter Mobile Number'
            error = true
        }
        delete userData._id
        if (!error) {
            let data = {
                query: JSON.stringify({uid: userData.uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await updateUser(data)
            if (result.status) {
                login(userData).then(userObj=> {
                    if (userObj !== null)
                        showAlert({ type: 'success', msg: 'Profile updated successfully!' })
                })
            } else showAlert({ type: 'error', msg: 'Unable to update profile!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const updatePassword = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        userObject = userData
        if (userData.password === '') {
            errorMsg = 'Please enter the Password'
            error = true
        } else if (userData.password !== confirmPassword) {
            errorMsg = 'Confirm Password should be similar to Password'
            error = true
        }
        if (!error) {
            let data = {
                query: JSON.stringify({uid: userData.uid}),
                userData: JSON.stringify(userObject)
            }
            const result = await updateUser(data)
            if (result.status) {
                login(userData).then(userObj=> {
                    if (userObj !== null)
                        showAlert({ type: 'success', msg: 'Profile updated successfully!' })
                })
            } else showAlert({ type: 'error', msg: 'Unable to update profile!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const profileImageOptions = () => {
        setshowProfileImageOptions(!showProfileImageOptions)
    }
    
    const viewPhoto = () => {
        setshowProfileImageOptions(false)
        openModal('preview-modal')
    }
    return {
        activeImage,
        profileImageOptions,
        showProfileImageOptions,
        viewPhoto,
        selectFiles,
        updateProfile,
        userData,
        setuserData,
    }
}