import { ButtonHTMLAttributes, memo } from "react"

interface ButtonProps extends ButtonHTMLAttributes<any> {
    className?: string
}

export const Button = memo(({
    type='button',
    className,
    title,
    onClick,
    id,
    children,
}: ButtonProps) => {
    return <button
        id={id}
        title={title}
        className={className}
        type={type}
        onClick={onClick}
    >{children}</button>
})