import { useComponent, useElement } from '../../../hooks'
import { useAdminHeader } from './hooks'

export const AdminHeader = () => {
    const Button = useElement('Button')
    const UserImage = useComponent('UserImage')
    const Link = useElement('Link')
    const {
        franchiseData,
        user,
        toggleUserNav,
        showUserNav,
        headerNav,
        userNav,
    } = useAdminHeader()

    const renderHeaderNav = () => {
        return headerNav.map((e, i) => {
            return <Button key={i} onClick={e.onClick} className={e.className} title={e.title}>
                <i className={e.icon}></i>
            </Button>
        })
    }

    const renderUserNav = () => {
        return <ul>
            {
                userNav.map((e, i) => {
                    return <li key={i}>
                        <Link title={e.title} href={e.link} onClick={e.onClick}>
                            <i className={e.icon}></i>
                            <span>{e.title}</span>
                        </Link>
                    </li>
                })
            }
        </ul>
    }

    return <div className="header">
        {renderHeaderNav()}
        {franchiseData?.title && <i className='franchise-title'>{franchiseData?.title}</i>}
        <nav>
            <div className="profile-box" onClick={toggleUserNav}>
                <UserImage image={JSON.parse(user?.image)[0]} name={user?.name} />
                <span title={user?.name}>{user?.name}</span>
            </div>
            {
                showUserNav && renderUserNav()
            }
        </nav>
    </div>
}