import { useContext } from 'react'
import { NavigatorContext } from '../../../context'
import { useElement } from '../../../hooks'
import { useRegister } from './hooks'

export const Register = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Link = useElement('Link')
    const {
        registerUser,
        name,
        setname,
        email,
        setemail,
        gstin,
        setgstin,
        showMobileField,
        setmobile,
        setterms,
        terms,
        mobile,
        submitRegisterForm,
        setotp,
        resendOtp,
    } = useRegister()
    return <div className="login-page">
        <div className="left-box-login left-box-login-register"></div>
        <div className="body-background flex">
            <div className="mauto p30 max-width-loginBox">
                <div className="loginIcon">
                    <i className="hi-user-plus"></i>
                </div>
                <h1 className="form-title tac">Please enter your details</h1>
                <div>
                    <form onSubmit={registerUser}>
                        <div className="form-group">
                            <Input className="input" placeholder="Full Name" autoFocus required name="name" value={name||''} onChange={e => setname(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <Input className="input" placeholder="Email" type="email" required name="email" value={email||''} onChange={e => setemail(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <Input className="input" placeholder="GSTIN" name="gstin" value={gstin||''} onChange={e => setgstin(e.target.value)} />
                        </div>
                        {
                            showMobileField &&
                            <div className="form-group">
                                <Input autoFocus onChange={e => setmobile(e.target.value)} className="input" type="number" name="mobile" placeholder="Enter Mobile Number" maxLength={10} minLength={10} max={9999999999} min={1000000000} />
                            </div>
                        }
                        <div className="flex-box">
                            <label className="switch">
                                <Input type="checkbox" onChange={() => setterms(!terms)} checked={terms?'checked':false} />
                                <span className="slider round"></span>
                            </label>
                            <span>I accept the <Link target="_blank" href="/page/terms-and-conditions" title="Terms and Conditions">Terms and Conditions</Link></span>
                        </div>
                        <Button className="btn btn-primary width-full" type="submit">Submit</Button>
                    </form>
                </div>
            </div>
            <i className="hi-arrow-circle-left footer-back-btn" onClick={() => navigateTo({route: '/login'})}></i>
            <div className="modal" id="otp-modal">
                <div className="modal-backdrop"></div>
                <div className="modal-content small">
                    <div className="modal-body p20">
                        <i className='hi-info1'></i>
                        <h1 className="form-title tac">Please enter OTP received on your mobile {mobile}</h1>
                        <form onSubmit={submitRegisterForm} noValidate className="relative">
                            <div className="form-group">
                                <Input autoFocus onChange={e => setotp(e.target.value)} className="input" type="number" name="otp" placeholder="Enter OTP" maxLength={6} minLength={6} max={100000} min={999999} />
                            </div>
                            <Button type="submit" className="width-full btn-primary mt30">Proceed</Button>
                            <span className="link resend-otp" onClick={resendOtp}> Resend OTP</span>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
