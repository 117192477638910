import {base, brandInfo} from '../../../constant'
import { useComponent, useElement, useService } from '../../../hooks'
import { useMyOrderDetail } from './hooks'

export const MyOrderDetail = () => {
    const {
        getFormattedDate,
        getFormattedTime,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Badge = useElement('Badge')
    const BackIcon = useElement('BackIcon')
    const Link = useElement('Link')
    const Box = useComponent('Box')
    const Table = useComponent('Table')
    const Rating = useElement('Rating')
    const {
       orderContent,
       invoiceNo,
       user,
       addr,
       tableContent,
       activeShownTotal,
       feedbackContent,
       serviceWorkAwaitedStatus,
       servicework,
       serviceData,
       workData,
    } = useMyOrderDetail()

    const renderImages = serviceImages => {
        return serviceImages.map(( item, index ) => {
            return <li key={index}>
                <Img src={item} alt={item} />
            </li>
        })
    }

    const renderNotes = orderNotes => {
        return orderNotes.map(( item, index ) => {
            let activeLabel = '', orderData = null
            if (item.tempUid) {
                if (orderContent !== null)
                    orderData = JSON.parse(orderContent.orderData)
                if (orderData !== null) {
                    orderData.cartData.forEach(e => {
                        if (e.tempUid === item.tempUid)
                            activeLabel = e.title
                    })
                }
            }
            return <li rel="102" className="note system-note" key={index}>
                <div className="note-content">
                    {
                        activeLabel !=='' && <label>{activeLabel}</label>
                    }
                    <p>{item.note}</p>
                </div>
                <p className="note-meta">
                    <abbr className="exact-date">{getFormattedDate(item.timing)}</abbr>
                </p>
                <p className="note-status">
                    <Badge item={item.status} />
                </p>
            </li>
        })
    }

    return <>
        {
            orderContent !== null &&
            <div id="content-box" className="content">
                <h1 className="flex">
                    <span>
                        <BackIcon backLink='/order/list' />
                        Order #{orderContent.uid} details</span>
                    {
                        (orderContent.status === 'paid' || orderContent.status === 'processing' || orderContent.status === 'shipped' || orderContent.status === 'delivered' || orderContent.status === 'completed') && invoiceNo !== '' &&
                        <Link href={base.invoiceBaseUrl+"/invoice_"+invoiceNo+".pdf"} target="_blank" rel="noopener noreferrer" className="btn btn-primary mlauto mt40"><i className="hi-download-cloud"></i>Download Invoice</Link>
                    }
                </h1>
                <div className="columns">
                    <div className="left">
                        <Box title="User Details">
                            <div className="form-group">
                                <label>Name</label>
                                <p>{user.name}</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <p>{user.email}</p>
                            </div>
                            <div className="form-group">
                                <label>Phone</label>
                                <p>{user.mobile}</p>
                            </div>
                            <div className="form-group">
                                <label>Alternate Number</label>
                                <p>{addr?.alternateNo}</p>
                            </div>
                        </Box>
                        <Box title="Delivery Address">
                            {addr?.building+', '+addr?.locality+', '+addr?.city+', '+addr?.state+' - '+addr?.pinCode}
                        </Box>
                        <Box title="Items" bodyClass='p0'>
                            <Table
                                tableClass="shop-table shop-table_responsive cart cart-form__contents mb0 br0"
                                data={tableContent}
                                pageSize={activeShownTotal}
                                showTotal={false}
                            />
                        </Box>
                    </div>
                    <div className="right">
                        <Box title="Active Status">
                            <Badge item={orderContent.status} />
                        </Box>
                        <Box title="Notes">
                            <ul className="notes">
                                { renderNotes( orderContent.orderNotes||[] ) }
                            </ul>
                        </Box>
                        {
                            orderContent.status === 'completed' &&
                            <Box title="Feedback & Review">
                                {
                                    feedbackContent === null?
                                    <Link rel="noopener noreferrer" target='_blank' href={'/feedback/'+orderContent.uid} className="btn btn-primary">Submit Feedback</Link>:
                                    <div className="rating-order-detail">
                                        {feedbackContent.rating && <Rating rating={feedbackContent.rating} />}
                                        <p>{feedbackContent.comments}</p>
                                    </div>
                                }
                            </Box>
                        }
                    </div>
                </div>
            </div>
        }
        <div className="modal" id="car-service-status-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content content medium">
                <Button className="close" onClick={() => closeModal("car-service-status-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    {
                        serviceWorkAwaitedStatus ?
                        <>
                        <div className="no-data-icon">
                            <i className="hi-hourglass_empty"></i>
                        </div>
                        <p className="awited-text">Work is still awaited for this service</p></>:
                        <div>
                            {
                                servicework !== null &&
                                <>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Title</label>
                                        <div className="col-9 controls ml20">
                                                {servicework.title}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Service Date</label>
                                        <div className="col-9 controls ml20">
                                            {getFormattedDate(new Date(servicework.serviceDate))}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Start Time</label>
                                        <div className="col-9 controls ml20">
                                            {getFormattedTime(new Date(workData[0].startTime))}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">{brandInfo.name.capital} Id</label>
                                        <div className="col-9 controls ml20">
                                            {servicework.workerId}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Active Car</label>
                                        <ul className="col-9 controls ml20">
                                            <li className="my-packages-assign-car">
                                                {
                                                    serviceData.image_path !== null && typeof serviceData.image_path !== 'undefined' && serviceData.image_path !== '' &&
                                                    <Img source='carCDN' src={(JSON.parse(servicework.activeCar).image_path)} alt={(servicework.activeCar).name} />
                                                }
                                                <div className="ml10">
                                                    <p>{serviceData.name+' '+serviceData?.Segment}</p>
                                                    {
                                                        serviceData.registrationNo && serviceData.registrationNo !== '' && serviceData.registrationNo !== null &&
                                                        <small>{serviceData.registrationNo}</small>
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Comments</label>
                                        <div className="col-9 controls ml20">
                                            {workData[0].comments}
                                        </div>
                                    </div>
                                    <div className="form-group flex">
                                        <label className="col-3 control-label">Images</label>
                                        <div className="col-9 controls ml20">
                                            <ul className="media-box">
                                                { renderImages(workData[0].carImages || []) }
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    </>
}
