import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption } from "../../../constant"
import { useService } from "../../../hooks"
import { NavigatorContext } from "../../../context"

export const useJob = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchByAttr} = useService('Data', 'job')
    const {setTitle} = useService('Meta')
    const {isEmpty} = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(0)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [image, setimage] = useState<string>('[]')
    const [uid, setuid] = useState<string>('')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState<string>('')
    const [excerpt, setexcerpt] = useState<string>('')
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState([])
    const [tag, settag] = useState(null)

    const urlData = urlToSplitData(getLocationData().pathname)
    useEffect(() => {
        if(slug !== urlData[2])
            getJobData({slug: urlData[2]})
    }, [slug, urlData])

    const getJobData = async (query) => {
        const result = await fetchByAttr(query)
        if (result.status) {
            if (result.data[0].status !== 'publish')
                navigateTo({route: '/error404'})
            else {
                const jobData = result.data[0]
                setactiveAuthorIndex(authorOption.findIndex(e => e.value === jobData.robots))
                setactiveRobotsIndex(robotsOption.findIndex(e => e.value === jobData.robots))
                setactiveStatusIndex(statusOption.findIndex(e => e.value === jobData.status))
                settag(isEmpty(jobData.tag) ? [] : JSON.parse(jobData.tag))
                setcategory(isEmpty(jobData.category) ? [] : JSON.parse(jobData.category))
                setmetaKeyword(isEmpty(jobData.metaKeyword) ? [] : JSON.parse(jobData.metaKeyword))
                setTitle(jobData.title)
                settitle(jobData.title)
                setslug(jobData.slug)
                setcontent(jobData.content)
                setexcerpt(jobData.excerpt)
                setcss(jobData.css)
                setjs(jobData.js)
                setcanonical(jobData.canonical)
                setrobots(jobData.robots)
                setmetaDescription(jobData.metaDescription)
                setauthor(jobData.author)
                setpublishDate(new Date(jobData.publishDate))
                setstatus(jobData.status)
                setuid(jobData.uid)
                setimage(jobData.image)
            }
        }
    }
    return {
        category: category.join(', '),
        title,
        publishDate,
        image,
        content,
        slug,
    }
}