import { useContext, useEffect, useState } from "react"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useFeedback = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {save} = useService('Data', 'feedback')
    const {setTitle} = useService('Meta')
    const {
        generateUID,
        showAlert,
    } = useService('Misc')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const [orderId, setorderId] = useState<string>('')
    const [rating, setrating] = useState<number>(null)
    const [comments, setcomments] = useState<string>('')

    setTitle('Give Feedback')
    useEffect(() => {
        const urlData = urlToSplitData(getLocationData().pathname)
        setorderId(urlData[2])
    }, [])

    const feedBackSubmit = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        feedbackObject = {
            rating,
            orderId,
            submitDate: new Date(),
            comments,
            uid: generateUID(),
        }
        if (rating) {
            errorMsg = 'Please give a rating'
            error = true
        }
        if (!error) {
            const result = await save(feedbackObject)
            if (result.status) {
                showAlert({ type: 'success', msg: 'Your feedback has been submited successfully.' })
                setTimeout(() => {
                    navigateTo({route: '/'})
                }, 2000)
            } else showAlert({ type: 'error', msg: 'Unable to submit your feedback' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    return {
        feedBackSubmit,
        rating,
        setrating,
        comments,
        setcomments,
    }
}