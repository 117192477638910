import {brandInfo, footerNav} from '../../../constant'
import {SubFooter} from '../SubFooter'
import {CopyrightSection} from '../CopyrightSection'
import './style.scss'
import { useFooter } from './hooks'
import { useComponent, useElement, useService } from '../../../hooks'

export const Footer = () => {
    const {
        isModuleEnabled,
        toggleActiveClass,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const ChatBot = useComponent('ChatBot')
    const SubscriptionBar = useComponent('SubscriptionBar')
    const DownloadTheApp = useComponent('DownloadTheApp')
    const {
        socialMediaAccounts,
        backToTop,
    } = useFooter()

    const renderAvailableCities = () => {
        return<></>
    }

    const renderSocialIcons = () => {
        return <ul>
            {
                socialMediaAccounts.map((e, i) => {
                    return e.link !== '' ? <li key={i}><Link className="icon" href={e.link} target="_blank" rel="noopener noreferrer"><i className={e.icon}></i></Link></li> : ''
                })
            }
        </ul>
    }

    const renderLinks = links => {
        return <ul>
            {
                links.map((e, i) => {
                    return isModuleEnabled(e.module) && <li key={i}>
                        <Link href={e.link} className={e.highlight ? "btn btn-primary btn-small": ''}>{e.label}</Link>
                    </li>
                })
            }
        </ul>
    }

    return <>
    { isModuleEnabled(['NewsLetter']) && <>
        <div className='clearfix mb40'></div>
        <SubscriptionBar />
    </>
    }
    { isModuleEnabled(['PopularSearches']) && <SubFooter />}
        { isModuleEnabled(['Chatbot']) && <ChatBot />}
        <div className="footer">
            <Button onClick={backToTop} id="back-to-top" title="Go to top">
                <Img source='internal' src={require('../../../../assets/images/car-vect.png')} alt="back-to-top" />
            </Button>
            <section className="footer-top">
                <div className="jumbo-container">
                    <div className="pt80 pb45 footer-box-wrapper">
                        <div className="col-4">
                            <div className="footer-widget widget">
                                <div className="about_us_widget">
                                    <p>{brandInfo.aboutText}</p>
                                    <div className="footer-social-icon pt10 mb10">
                                        <div className="banner-header-follow-us">
                                            <h3 className="text">Follow us on</h3>
                                            <div className="banner-header-icon">
                                                {
                                                    renderSocialIcons()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <DownloadTheApp />
                                </div>
                            </div>
                        </div>
                        <div className="col-2 m-l-8">
                            <div className="footer-widget widget widget_nav_menu" id="company-footer">
                                <h3 onClick={() => toggleActiveClass('company-footer')} className="widget-title">Company</h3>
                                {renderLinks(footerNav["company"])}
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="footer-widget widget widget_nav_menu" id="useful-links-footer">
                                <h3 onClick={() => toggleActiveClass('useful-links-footer')} className="widget-title">Useful Links</h3>
                                {renderLinks(footerNav["useful"])}
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="footer-widget widget">
                                <h3 className="widget-title">Contact Us</h3>
                                <div className="contact-area">
                                    <ul>
                                        <li><i className="icon flaticon-pin"></i>{renderAvailableCities()}</li>
                                        <li><i className="icon flaticon-email"></i><Link href={'mailto: '+brandInfo.mail}>{brandInfo.mail}</Link></li>
                                        <li><i className="icon flaticon-call-answer"></i><Link href={'tel: '+brandInfo.phone}>{brandInfo.phone}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CopyrightSection />
        </div>
    </>
}