import { useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useOffers = () => {
    const {fetchByAttr} = useService('Data', 'offer')
    const {setTitle} = useService('Meta')
    const [offerList, setofferList] = useState([])
    const [activeOffer, setactiveOffer] = useState(null)
    
    setTitle('Offers')
    useEffect(() => {
        getOffersData()
    }, [])

    const getOffersData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status)
            setofferList(result.data.reverse())
    }

    return {
        setactiveOffer,
        offerList,
        activeOffer,
    }
}