import { useCallback, useContext, useEffect, useState } from "react"
import { urlApi } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useCheckout = () => {
    const {save} = useService('Data', 'log')
    const {fetchByAttr: fetchByAttrFranchise} = useService('Data', 'franchise')
    const {fetchByAttr} = useService('Data', 'offer')
    const {
        getLoggedInUserData,
        isUserLoggedIn,
        addUser,
        getUser,
        login,
    } = useService('User')
    const {
        getLocations,
        getSocities,
        addLocation: publishLocation,
    } = useService('Location')
    const {setTitle} = useService('Meta')
    const {
        showAlert,
        generateUID,
        addDays,
    } = useService('Misc')
    const {navigateTo} = useContext(NavigatorContext)
    const {
        getOrderData,
        addOrder,
    } = useService('Order')
    const user = getLoggedInUserData()
    const [franchiseCode, setfranchiseCode] = useState<string>('')
    const [addressList, setaddressList] = useState([])
    const [userData, setuserData] = useState(null)
    const [orderData, setorderData] = useState(null)
    const [activeAddress, setactiveAddress] = useState(null)
    const [building, setbuilding] = useState<string>('')
    const [locality, setlocality] = useState<string>('')
    const [pinCode, setpinCode] = useState<string>('')
    const [city, setcity] = useState<string>('')
    const [state, setstate] = useState<string>('')
    const [landmark, setlandmark] = useState<string>('')
    const [alternateNo, setalternateNo] = useState<string>('')
    const [mobile, setmobile] = useState<string>('')
    const [couponCode, setcouponCode] = useState<string>('')
    const [email, setemail] = useState<string>('')
    const [name, setname] = useState<string>('')
    const [couponApplied, setcouponApplied] = useState<boolean>(false)
    const [role, setrole] = useState<string>('')
    const [gstin, setgstin] = useState<string>('')
    const [terms, setterms] = useState<boolean>(false)
    const [userType, setuserType] = useState('new')
    const [registerNewUser, setregisterNewUser] = useState<boolean>(true)
    const [isTermsAccepted, setisTermsAccepted] = useState<boolean>(true)
    const [societyData, setsocietyData] = useState([])
    const [activeSociety, setactiveSociety] = useState(null)
    const [offerList, setofferList] = useState(null)
    const [showSocietyListBox, setshowSocietyListBox] = useState<boolean>(false)
    const [fromSociety, setfromSociety] = useState<boolean>(true)
    const [fullAddress, setfullAddress] = useState<string>('')
    const [discount, setdiscount] = useState<number>(0)
    const [serviceTotal, setserviceTotal] = useState<number>(0)
    const [productTotal, setproductTotal] = useState<number>(0)
    const [serviceDiscount, setserviceDiscount] = useState<number>(0)
    const [productDiscount, setproductDiscount] = useState<number>(0)
    const [franchises, setfranchises] = useState([])
    const [showFranchiseModal, setshowFranchiseModal] = useState<boolean>(false)
    const [activeFranchise, setactiveFranchise] = useState(null)

    setTitle('Checkout')
    useEffect(() => {
        if (isUserLoggedIn()) {
            setuserData(user)
            setuserType('old')
            setregisterNewUser(false)
            getLocationsData()
        }
        else
            getSocietiesListData()
        getOffersData()
        let orderData = getOrderData(), productTotal = 0, serviceTotal = 0, cartTotal = 0
        if (orderData?.cartData) {
            if (orderData.cartData.length > 0) {
                orderData.cartData.forEach(e => {
                    cartTotal += Number(e.price * e.purchaseQuantity);
                    if (e.type === 'service')
                        serviceTotal += Number(e.price * e.purchaseQuantity);
                    else
                        productTotal += Number(e.price * e.purchaseQuantity);
                })
            }
            setorderData(orderData)
            setserviceTotal(serviceTotal)
            setproductTotal(productTotal)
        }
    }, [])

    const getLocationsData = async () => {
        let query = {
            ownerId: user.uid
        }
        const result = await getLocations(query)
        if (result.status) {
            if (result.data?.length === 0)
                getSocietiesListData()
            else {
                setaddressList(result.data)
                setactiveAddress(result.data[0])
            }
        }
    }
    const getSocietiesListData = async () => {
        const result = await getSocities()
        if (result.status) {
            let societyData = result.data.reverse()
            setsocietyData(societyData)
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No society added yet!' })
        } else showAlert({ type: 'error', msg: 'No society added yet!' })
    }

    const validateOrder = useCallback((activeAddress, user) => {
        let error = false,
        errorMsg = '',
        orderObject = null
        if (user !== null && activeAddress !== null) {
            error = false;
            errorMsg = ''
            orderObject = {
                activeAddress: JSON.stringify(activeAddress),
                orderData: JSON.stringify(orderData),
                userData: JSON.stringify(user),
                status: 'initialised',
                timing: new Date().getTime()
            }
        } else {
            error = true
            errorMsg = 'Please enter user and delivery address details.'
        }
        return {
            error,
            errorMsg,
            orderObject
        }
    }, [orderData, userData])

    const placeFranchiseOrder = () => {
        if (activeFranchise === null) {
            showAlert({ type: 'error', msg: 'Please select a franchise first.' })
            return
        }
        if (franchiseCode !== JSON.parse(activeFranchise.contactPerson).value) {
            showAlert({ type: 'error', msg: 'Employee Reference Code is invalid.' })
            return
        }
        proceedToPay(activeFranchise.value)
    }

    const placeOrder = async (franchiseId = false, activeAddress, userData) => {
        const {error, errorMsg, orderObject} = validateOrder(activeAddress, userData)
        if (!error) {
            orderObject.uid = generateUID()
            if (franchiseId && activeFranchise?.value) {
                orderObject.source = 'franchise'
                orderObject.franchiseId = franchiseId
                orderObject.status = 'paidAtFranchise'
            }
            const result = await addOrder(orderObject)
            let amount = '', newUrl = '', logMessage = userData.name+' initialised and order with Id #'+orderObject.uid+ ' of amount Rs.'+amount
            if (result.status) {
                if (franchiseId && activeFranchise?.value) {
                    logMessage += ` at franchise Id: ${activeFranchise.value} and Name: ${activeFranchise.label}`
                    newUrl = `/payment-done/${orderObject.uid}`
                } else {
                    if (!isNaN(orderData.discount) && orderData.discount && orderData.discount !== "" && orderData.discount !== 0 && orderData.discount !== '0')
                        amount = (orderData.cartTotal - orderData.discount).toFixed(2)
                    else
                        amount = orderData.cartTotal.toFixed(2)
                    newUrl = urlApi+'payment/save?mobile='+userData.mobile+'&email='+userData.email+'&userId='+userData.uid+'&orderId='+orderObject.uid+'&txnAmount='+amount+'&platform=WEB'
                }
                let logData = {
                    log: logMessage,
                    timing: new Date().getTime(),
                    type: 'info',
                    userId: userData.uid,
                    uid: generateUID()
                }
                save(logData)
                navigateTo({route: newUrl, differentOrigin: true})
            } else showAlert({ type: 'error', msg: 'Unable to place Order! Please try after some time.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const addLocation = async (franchiseId, user) => {
        let error = false,
        errorMsg = '',
        locationObject = {
            building,
            locality,
            pinCode,
            city,
            state,
            landmark,
            alternateNo,
            uid: generateUID(),
            ownerId: user.uid,
        }
        if (building === '') {
            errorMsg = 'Please enter Building / Flat No.'
            error = true
        } else if (locality === '') {
            errorMsg = 'Please enter the Locality'
            error = true
        } else if (pinCode === '') {
            errorMsg = 'Please select the Pin Code'
            error = true
        } else if (city === '') {
            errorMsg = 'Please select a City'
            error = true
        } else if (state === '') {
            errorMsg = 'Please select a State'
            error = true
        }
        if (!error) {
            const result = await publishLocation(locationObject)
            if (result.status) {
                setactiveAddress(locationObject)
                setTimeout(() => {
                    placeOrder(franchiseId, locationObject, user)
                }, 500);
            }
            else showAlert({ type: 'error', msg: 'Unable to add address!' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const registerUser = async (franchiseId) => {
        let error = false,
        errorMsg = '',
        userObject = {
            email,
            name,
            role,
            gstin,
            mobile,
            image: JSON.stringify(["no-image-found.png"]),
            uid: generateUID(),
        }
        if (!terms) {
            errorMsg = 'Please accept terms and conditions first!'
            error = true
        } else if (email === '') {
            errorMsg = 'Please enter user email!'
            error = true
        } else if (name === '') {
            errorMsg = 'Please enter the Name'
            error = true
        }
        if (!error) {
            const result = await addUser(userObject)
            if (result.status) {
                localStorage.removeItem('otpData')
                loginUser(userObject, franchiseId)
            } else showAlert({ type: 'error', msg: 'Mobile number already registered, please try loging in.' })
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const loginUser = async (userObject, franchiseId = false) => {
        let query = {
            uid: String(userObject.uid)
        }
        const result = await getUser(query)
            if (result.status) {
                let userData = result.data[0]
                let userObject = await login(userData)
                if (userObject !== null) {
                    setuserData(userData)
                    setregisterNewUser(false)
                    if(franchiseId) addLocation(franchiseId, userData)
                }
            } else showAlert({ type: 'error', msg: 'Unable to login!' })
    }
    
    const getOffersData = async () => {
        const result = await fetchByAttr({status: 'publish'})
        if (result.status) {
            let tempOfferList = result.data.reverse(), offerList = []
            tempOfferList.forEach(e => {
                if (typeof e.offerRestriction !== 'undefined' && e.status === 'publish' && new Date().getTime() < addDays(new Date(e.expiryDate), 1).getTime() )
                    offerList.push(e)
            })
            setofferList(offerList)
        }
    }
    
    const applyCouponCode = () => {
        let offerApplicable = true, couponFound = false, activeOffer = null, discount = 0, serviceCount = 0, otherDiscount = 0, productCount = 0, subscriptionCount = 0, subscriptionDiscount = 0, subscriptionTotal = 0, derivedServiceDiscount: number = 0, derivedProductDiscount: number = 0
        if (offerList) {
            offerList.forEach(e => {
                if (e.code.toLowerCase() === couponCode.toLowerCase()) {
                    let foundUser = false
                    if (e.offerForUser) {
                        if (e.offerForUser !== 'null' && e.offerForUser !== 'undefined' && e.offerForUser !== null) {
                            JSON.parse(e.offerForUser).forEach(e => {
                                if (e.value === user.uid)
                                    foundUser = true
                            })
                        }
                    }
                    if (
                        e.offerRestriction &&
                        (e.offerRestriction === 'public' || (e.offerRestriction === 'private' && foundUser )) &&
                        e.status !== 'expired' &&
                        new Date().getTime() < addDays(new Date(e.expiryDate), 1).getTime() &&
                        !e.trash
                    ) {
                        couponFound = true
                        activeOffer = e
                    }
                }
            })
        }
        if (couponFound) {
            if (activeOffer.discountType === "fixedprice" ) {
                if (activeOffer.discount > orderData.cartTotal) {
                    offerApplicable = false
                } else {
                    activeOffer.discount = activeOffer.discount * 100 / (orderData.cartTotal)
                    activeOffer.discountType = "percent"
                }
            } else {
                if (activeOffer.discount > 99.99) {
                    offerApplicable = false
                }
            }
            if (offerApplicable) {
                orderData.cartData.forEach(c => {
                    if (c.type === 'service' || c.type === 'servicePackage')
                        serviceCount++
                    else if (c.type === 'product')
                        productCount++
                    else if (c.type === 'subscription')
                        subscriptionCount++
                })
                // if (productCount !== 0 && serviceCount !== 0 && activeOffer.offerApplicable === 'both')
                //     otherDiscount = Number((activeOffer.discount * orderData.cartTotal/100).toFixed(2))
                // else if (productCount !== 0 && (activeOffer.offerApplicable === 'product' || activeOffer.offerApplicable === 'both'))
                //     derivedProductDiscount = Number((activeOffer.discount * productTotal/100).toFixed(2))
                // else if (serviceCount !== 0 && (activeOffer.offerApplicable === 'service' || activeOffer.offerApplicable === 'both'))
                //     derivedServiceDiscount = Number((activeOffer.discount * serviceTotal/100).toFixed(2))
                // else {
                //     otherDiscount = Number((activeOffer.discount * orderData.cartTotal/100).toFixed(2))
                //     if (subscriptionCount !== 0)
                //         subscriptionDiscount = (activeOffer.discount * subscriptionTotal/100).toFixed(2)
                //     otherDiscount -= subscriptionDiscount
                // }
                let tempCartData = []
                orderData.cartData.forEach(c => {
                    if ((activeOffer.offerApplicable === 'both' || activeOffer.offerApplicable === 'service') && (c.type === 'service' || c.type === 'servicePackage')) {
                        c.extraDiscount = Number((activeOffer.discount * (c.price + c.taxAmount)/100).toFixed(2))
                        c.tax = Number(c.tax)
                        c.taxAmount = Number((c.tax * (c.price - c.extraDiscount)/(100+c.tax)).toFixed(2))
                        c.taxableAmount = c.price - c.taxAmount
                        derivedServiceDiscount += Number(c.extraDiscount)
                    } else if ((activeOffer.offerApplicable === 'both' || activeOffer.offerApplicable === 'product') && (c.type === 'product')) {
                        c.extraDiscount = Number((activeOffer.discount * (c.price + c.taxAmount)/100).toFixed(2))
                        c.tax = Number(c.tax)
                        c.taxAmount = Number((c.tax * (c.price - c.extraDiscount)/(100+c.tax)).toFixed(2))
                        c.taxableAmount = c.price - c.taxAmount
                        derivedProductDiscount += Number(c.extraDiscount)
                    }
                    tempCartData.push(c)
                })
                orderData.cartData = tempCartData
                discount = Number(derivedServiceDiscount) + Number(derivedProductDiscount)
                if (discount > 0) {
                    setorderData(orderData)
                    setcouponApplied(true)
                    setdiscount(discount)
                    setserviceDiscount(derivedServiceDiscount)
                    setproductDiscount(derivedProductDiscount)
                    orderData.discount = discount
                    orderData.offerData = activeOffer
                    setorderData(orderData)
                    showAlert({ type: 'success', msg: 'Coupon applied successfully' })
                } else
                    showAlert({ type: 'error', msg: 'Offer cannot be applied to your cart' })
            } else showAlert({ type: 'error', msg: 'Offer cannot be applied to your cart' })
        } else showAlert({ type: 'error', msg: 'Invalid coupon code' })
    }
    
    const removeCouponCode = () => {
        let tempCartData = []
        orderData.discount = 0
        orderData.cartData.forEach(c => {
            c.tax = Number(c.tax)
            c.taxAmount = Number((c.tax * (c.price)/(100+c.tax)).toFixed(2))
            c.taxableAmount = c.price - c.taxAmount
            delete c.extraDiscount
            tempCartData.push(c)
        })
        orderData.cartData = tempCartData
        setorderData(orderData)
        setcouponApplied(false)
        setcouponCode('')
        setdiscount(0)
        setserviceDiscount(0)
        setproductDiscount(0)
    }

    const fetchFranchises = async () => {
        let result = await fetchByAttrFranchise({status: 'publish'}), franchises = []
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No active franchises are there!' })
            } else {
                result.data.forEach(e => {
                    franchises.push({...e, value: e.uid, label: e.title})
                })
                setfranchises(franchises)
            }
        }
    }

    const payAtFranchise = () => {
        fetchFranchises()
        setshowFranchiseModal(true)
    }

    const proceedToPay = (franchiseId = false) => {
        if (isTermsAccepted) {
            if (userType === 'new' && registerNewUser) {
                registerUser(franchiseId)
            } else if ( addressList.length === 0) {
                addLocation(franchiseId, user ?? userData)
            } else {
                placeOrder(franchiseId, activeAddress, user ?? userData)
            }
        } else {
            showAlert({ type: 'error', msg: 'Please accept terms and conditions first.' })
        }
    }

    const navigateToLogin = () => {
        setregisterNewUser(false)
        navigateTo({route: '/login'})
    }
    return {
        franchises,
        setactiveFranchise,
        activeFranchise,
        showFranchiseModal,
        setshowFranchiseModal,
        franchiseCode,
        setfranchiseCode,
        placeFranchiseOrder,
        payAtFranchise,
        proceedToPay,
        orderData,
        discount,
        productDiscount,
        serviceDiscount,
        applyCouponCode,
        removeCouponCode,
        couponApplied,
        couponCode,
        activeSociety,
        setcity,
        city,
        state,
        setstate,
        landmark,
        setlandmark,
        pinCode,
        setpinCode,
        alternateNo,
        setalternateNo,
        userData,
        setactiveSociety,
        setshowSocietyListBox,
        setlocality,
        setfullAddress,
        setactiveAddress,
        activeAddress,
        navigateToLogin,
        registerNewUser,
        setregisterNewUser,
        name,
        setname,
        email,
        setemail,
        mobile,
        setmobile,
        gstin,
        setgstin,
        terms,
        setterms,
        addressList,
        setfromSociety,
        fromSociety,
        building,
        setbuilding,
        locality,
        setcouponCode,
        showSocietyListBox,
        fullAddress,
        societyData,
    }
}