import { memo } from "react";
import { brandInfo } from "../../../constant";
import { useService } from "../../../hooks";

interface PostMetaProps {
    categoryList: string
    publishDate: Date
}

export const PostMeta = memo(({
    categoryList,
    publishDate,
}: PostMetaProps) => {
    const {getFormattedDate} = useService('Misc')
    return <div className="post-meta border-none pt20">
        <ul className="list-wrap">
            <li>
                <p className="author"><i className="hi-user1"></i>{brandInfo.name.capital}</p>
            </li>
            <li>
                <p className="author"><i className="hi-calendar1"></i> {getFormattedDate(publishDate)}</p>
            </li>
            <li>
                <p className="author"><i className="hi-folder1"></i>{categoryList}</p>
            </li>
        </ul>
    </div>
})