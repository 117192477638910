import { memo } from "react"
import { useElement } from "../../../hooks"
import { useMediaBox } from "./hooks"
import { Box } from "../../front"

interface MediaBoxProps {
    image: string
    setimage: (image: string) => void
    label?: string
    title?: string
    usage?: string
    id?: string
}

export const MediaBox = memo(({image, setimage, label='Add Media', title='Media', usage, id=''}: MediaBoxProps) => {
    const Button = useElement('Button')
    const Img = useElement('Img')
    const Input = useElement('Input')
    const {
        search,
        searchKey,
        mediaFiles,
        closeMediaModel,
        setImageInput,
        showImages,
        resetSearch,
        selectImage,
        trashSelectedImage,
        inputId,
    } = useMediaBox({
        id,
        usage,
        image,
        setimage,
    })

    const renderImages = () => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index} className={image.includes(item.fileName)?"selected": ''}>
                <Img alt={item.fileName} src={item.fileName} />
                <i className="hi-check-circle select" onClick={() => selectImage(item.fileName)}></i>
            </li>
        })
    }
    
    const renderUploadedImages = mediaFiles => {
        return mediaFiles.map(( item, index ) => {
            return <li key={index}>
                <Img alt={item.fileName} src={item} hasZoom={true} />
                <i className="hi-trash error" onClick={() => trashSelectedImage(item)}></i>
            </li>
        })
    }

    return <>
        <Box title={title} required overflown={false}>
            <>
                <Button className="btn btn-primary btn-small" title="Click here to show images" onClick={showImages}>{label}</Button>
                <Input className="hidden" required name="image" value={image||''} onChange={setImageInput} id={inputId} />
                <div className="upload-box mt20 mb0">
                    <ul className="media-box">
                        {
                            JSON.parse(image).length !== 0 &&
                            renderUploadedImages(JSON.parse(image)||[])
                        }
                    </ul>
                </div>
            </>
        </Box>
        <div className="modal media-modal" id={"media-modal"+id}>
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={closeMediaModel}>
                    <i className="hi-close"></i>
                </Button>
                <div className="modal-body filter-box">
                    <div className="modal-header">
                        <h2 className="heading">Select Media</h2>
                        <div className="search-box">
                            <Input name="searchKey" value={searchKey || ''} placeholder="Search..." onChange={search} />
                            <i onClick={resetSearch} className="hi-close"></i>
                        </div>
                    </div>
                    <div className="modal-box">
                        <ul className="media-box">
                            {
                                renderImages()
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </>
})