import { PageRouteProps } from "../../types";

export const gameRoutes: PageRouteProps[] = [
    {
        "pathName": "/game/run",
        "module": ["Game"],
        "component": "Run",
        "type": "game",
        "mode": "static"
    },
    {
        "pathName": "/game/ludo",
        "module": ["Game"],
        "component": "Ludo",
        "type": "game",
        "mode": "static"
    },
    {
        "pathName": "/game/snakeNLadder",
        "module": ["Game"],
        "component": "SnakeNLadder",
        "type": "game",
        "mode": "static"
    }
]