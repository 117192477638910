import {DashboardWidget} from "../../DashboardWidget"
import { dashboardWidgetOption } from "../../../../constant"
import { useAdminData } from "./hooks"
import { useElement, useService } from "../../../../hooks"

export const AdminData = () => {
    const {closeModal} = useService('Misc')
    const Actor = useElement('Actor')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const {
        addWidget,
        widget,
        configureWidget,
        deleteWidget,
        updateWidget,
        openWidgetMonitor,
    } = useAdminData()
    const renderDashboardWidgetOptions = () => {
        return <ul className='dashboard-widgets p20'>
            {
                dashboardWidgetOption.map((e, i) => {
                    return <li key={i} onClick={() => addWidget(e)}>
                        <p>{e.title}</p>
                        <Img src={e.screenshot} />
                    </li>
                })
            }
        </ul>
    }

    const renderWidgetsGrid = () => {
        return widget.map((e, i) => {
            return <div key={i} className='dash-box'>
                <div className='action-bar'>
                    <Actor type='setting' onClick={() => configureWidget(e.uid)} />
                    <Actor type='close' onClick={() => deleteWidget(e.uid)} />
                </div>
                <DashboardWidget data={e} action={{updateWidget}} />
            </div>
        })
    }

    return <>
        <Button className="btn btn-small btn-primary mb20" onClick={openWidgetMonitor}>Add New Widget</Button>
        <div className="grid-area-section">
            {renderWidgetsGrid()}
        </div>
        <div className="modal widget-modal" id="widget-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content">
                <Button className="close" onClick={() => closeModal("widget-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="modal-header">
                        <h2 className="heading">Select Widget</h2>
                    </div>
                    {renderDashboardWidgetOptions()}
                </div>
            </div>
        </div>
    </>
}