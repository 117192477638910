
import {getData,postData} from '../Ops'
import {OrderService} from '../Order'
import {RouterService} from '../Router'
const activeModules = require('../../../module/activeModules.json')

export class MiscService {
    parseValue = (value: any) => {
        return value ?? 'Value Not Available'
    }
    isDesktop = () => {
       return window.innerWidth > 991
    }
    preventFormSubmit = (e) => {
        e.preventDefault()
    }
    isBottom = (el: Element): boolean => {
        return el?.getBoundingClientRect().bottom <= window.innerHeight
    }
    isEmpty = (str: string | object | number | any[]): boolean => {
        if(Array.isArray(str)) {
            return str.length === 0
        }
        let numbercheck, isEmptyObject
        if (typeof str !== "number" && typeof str !== "object") {
            numbercheck = str?.length === 0
        }
        try {
            if (str && typeof str === 'object' && !Array.isArray(str)) {
                isEmptyObject = Object.keys(str)?.length === 0
            }
        } catch (error) {
            console.log('error', error)
        }
        return typeof str === 'undefined' || str === "\\" || str === 'undefined' || str === '""' || str === "''" || str === '' || str === null || str === 'null' || str === undefined || isEmptyObject || str === 0 || str ==='0' || numbercheck
    }
    generateRandomString = (size: number = 10): string => {
        let strSet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let dataString = '';
        for (let i = 0; i <= size; i++) {
            let randomNumber = Math.floor(Math.random() * strSet.length);
            dataString += strSet.substring(randomNumber, randomNumber +1);
        }
        return dataString
    }
    toIndianCurrency = (num: number | string): string => {
        //@ts-ignore
        if (typeof num !== Number)
            num = Number(num)
        const curr = num.toLocaleString('en-IN', {
            style: 'currency',
            currency: 'INR'
        })
        return curr.includes(`₹`) ? curr : `₹${curr}`;
    }
    getDateDifference = (date1: any, date2: any, type: string = 'day'): number => {
        const d1: any = new Date(date1);
        const d2: any = new Date(date2);
        if (this.getFormattedDate(d1) === this.getFormattedDate(d2)) {
            return 0
        } else {
            const diffTime = Math.abs(d2 - d1);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            const diffMonths = Math.floor(diffDays/30);
            const diffYears = Math.floor(diffMonths/12);
            if (type === 'time') {
                return diffTime
            } else if (type === 'day') {
                return diffDays
            } else if (type === 'month') {
                return diffMonths
            } else if (type === 'year') {
                return diffYears
            }
        }
    }
    hasImageExtension = (fileName: string): boolean => {
        let found = false, imageExtenstions = ['jpg', 'jpeg', 'png', 'webp', 'gif']
        imageExtenstions.forEach(e => {
            if(fileName?.toLowerCase().includes(e))
                found = true
        })
        return found
    }
    operateMessage = (msg: string): string => {
        return msg.toLowerCase().replace(/[^a-zA-Z ]/g, '').replace(/aa|_/g,'a').replace(/bb|_/g,'b').replace(/cc|_/g,'c').replace(/dd|_/g,'d').replace(/ee|_/g,'e').replace(/ff|_/g,'f').replace(/gg|_/g,'g').replace(/hh|_/g,'h').replace(/ii|_/g,'i').replace(/jj|_/g,'j').replace(/kk|_/g,'k').replace(/ll|_/g,'l').replace(/mm|_/g,'m').replace(/nn|_/g,'n').replace(/oo|_/g,'o').replace(/pp|_/g,'p').replace(/qq|_/g,'q').replace(/rr|_/g,'r').replace(/ss|_/g,'s').replace(/tt|_/g,'t').replace(/uu|_/g,'u').replace(/vv|_/g,'v').replace(/ww|_/g,'w').replace(/xx|_/g,'x').replace(/yy|_/g,'y').replace(/zz|_/g,'z')
    }
    sendMail = async (to: string, subject: string, content: string, selectAll: string) => {
        const formData = new FormData()
        formData.append('to', to)
        formData.append('subject', subject)
        formData.append('content', content)
        //@ts-ignore
        formData.append('userCount', selectAll?'all':1)
        return await postData('utils/sendmail', formData)
    }
    sendAppShare = async (data: any) => {
        const formData = new FormData()
        formData.append('to', data.to)
        formData.append('type', data.type)
        return await postData('utils/send-app-share', formData)
    }
    generateUID = () => {
        return String(new Date().getTime())
    }
    getContactRequest = async (data: any) => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('message', data.message)
        formData.append('mobile', data.mobile)
        formData.append('subject', data.subject)
        formData.append('company', data.company)
        return await postData('misc/get-contact-request', formData)
    }
    getFranchiseRequest = async (data: any) => {
        const formData = new FormData()
        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('message', data.message)
        formData.append('mobile', data.mobile)
        formData.append('city', data.city)
        formData.append('pinCode', data.pinCode)
        formData.append('uid', data.uid)
        return await postData('misc/get-franchise-request', formData)
    }
    getActiveLanguage = (): string => {
        return localStorage.getItem('activeLanguage')?localStorage.getItem('activeLanguage'): 'en'
    }
    getActiveLocation = (): string => {
        return localStorage.getItem('activeLocation')?localStorage.getItem('activeLocation'): 'en'
    }
    setActiveLocation = (activeLocation: any) => {
        localStorage.setItem('activeLocation', JSON.stringify(activeLocation))
    }
    getPreventiveCheckList = async () => {
        return await getData('misc/getpreventivechecklist')
    }
    openCarSelectModal = () => {
        //@ts-ignore
        document.getElementsByClassName('selectCar')[0].click()
    }
    toggleCartBox = () => {
        this.toggleBodyClass('cart-open')
    }
    toggleBodyClass = (class1: string, class2: string = class1, flushClass?: string) => {
        document.getElementsByTagName('body')[0].setAttribute('class',(document.getElementsByTagName('body')[0].className === class1)? flushClass : class2)
    }
    parseToHtml = (unparsedHtml: string) => {
        let parsedHtml = unparsedHtml.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, ' ').replace(/&amp;/g, '&').replace(/&quot;/g, '"').replace(/&apos;/g, "'").replace(/&cent;/g, '¢').replace(/&pound;/g, '£').replace(/&yen;/g, '¥').replace(/&euro;/g, '€').replace(/&copy;/g, '©').replace(/&reg;/g, '®')
        return parsedHtml
    }
    addDays = (date: any, days: number) => {
        const copy = new Date(Number(date))
        copy.setDate(date.getDate() + days)
        return copy
    }
    openModal = (modalId: string) => {
        document.getElementsByTagName('body')[0].classList.add("modal-open")
        document.getElementById(modalId).classList.add("open")
    }
    slugify = (str: string): string => {
        str = str.replace(/^\s+|\s+$/g, "")
        str = str.toLowerCase()
        var from = "åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;",
        to = "aaaaaaeeeeiiiioooouuuunc------"
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
        }
        str = str.replace(/[^a-z0-9 -]/g, "").replace(/\s+/g, "-").replace(/-+/g, "-").replace(/^-+/, "").replace(/-+$/, "")
        return str
    }
    closeModal = (modalId: string) => {
        document.getElementsByTagName('body')[0].classList.remove("modal-open")
        document.getElementById(modalId).classList.remove("open")
    }
    showAlert = (params: any) => {
        let alert = document.createElement("div"),
        alertIcon = ''
        switch (params.type) {
            case 'success':
                alertIcon = 'hi-checkbox-checked'; break
            case 'error':
                alertIcon = 'hi-bug'; break
            case 'info':
                alertIcon = 'hi-info'; break
            case 'warning':
                alertIcon = 'hi-warning-o'; break
            case 'custom':
                alertIcon = null; break
            default:
                break
        }
        alert.setAttribute('class', 'alert alert-'+params.type)
        alert.style.opacity = '1'
        alert.innerHTML = (alertIcon !== null)?'<i class="'+alertIcon+'"></i><p>'+params.msg+'</p>':params.template
        document.getElementsByClassName('alert-box')[0].appendChild(alert)
        setTimeout(() => {
            this.fadeAlert(alert)
        }, 2000)
    }
    getActiveCar = () => {
        return JSON.parse(localStorage.getItem('activeCar'))
    }
    setActiveCar = (activeCar: any) => {
        localStorage.setItem('activeCar', JSON.stringify(activeCar))
    }
    getFranchiseData = () => {
        let franchiseData = localStorage.getItem('franchiseData')
        if(!this.isEmpty(franchiseData)) {
            franchiseData = JSON.parse(franchiseData)
        }
        return franchiseData
    }
    setFranchiseData = (franchiseData: any) => {
        localStorage.setItem('franchiseData', JSON.stringify(franchiseData))
    }
    getThemeMode = () => {
        return localStorage.getItem('themeMode') ?? "theme-light"
    }
    setThemeMode = (themeMode = "theme-light") => {
        document.getElementById('root').className = ' ' + themeMode
        localStorage.setItem('themeMode', themeMode)
    }
    fadeAlert = (alert: any) => {
        if (alert.style.opacity > 0) {
            setTimeout(() => {
                alert.style.opacity -= 0.1
                this.fadeAlert(alert)
            }, 50)
        } else alert.remove()
    }
    blobToFile = (theBlob: any, fileName: string) => {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date()
        theBlob.name = fileName
        return theBlob
    }
    getMonthName = (m: number): string => {
        const month = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
        return month[m]
    }
    getBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }
    getFormattedDate = (date: string | Date | number): string => {
        if (typeof date !== "number")
            date = Number(date)
        date = new Date(date)
        let d = date.getDate()>9?date.getDate(): '0'+date.getDate(),
        y = date.getFullYear()
        return String(d+', '+this.getMonthName(date.getMonth())+', '+y)
    }
    getFormattedTime = (date: string | Date | number): string => {
        if (typeof date !== "number")
            date = Number(date)
        date = new Date(date)
        date = new Date(date)
        var hours = date.getHours()
        var minutes = date.getMinutes()
        var seconds = date.getSeconds()
        let amPm = hours >= 12 ? 'PM': 'AM',
        timeString = this.pad(hours, 'hour')+":"+this.pad(minutes)+":"+this.pad(seconds)+' '+ amPm
        return timeString
    }
    checkIfEmpty = (value: any) => {
        let newValue = value
        if (
            value === undefined ||
            value === 'undefined' ||
            typeof value === 'undefined' ||
            value === 'null'||
            value === null
        )
        newValue = ''
        return newValue
    }
    isSameDate = (first: string | Date, second: string | Date): boolean => {
        first = new Date(first)
        second = new Date(second)
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate()
    }
    pad = (num: number, type: string = ''): string => {
        if (type === 'hour') {
            num = num > 12?num-12: num
        }
        return ("0"+num).slice(-2)
    }
    getPreviousDate = (prevDays: number = 1): number => {
        let d = new Date()
        return d.setDate(d.getDate() - prevDays);
    }
    getLast7Days = () => {
        return '0123456'.split('').map(function(n: any) {
            var d = new Date();
            d.setDate(d.getDate() - n);
            return (function(day, month, year) {
                return [day<10 ? '0'+day : day, month<10 ? '0'+month : month, year].join('/');
            })(d.getMonth()+1, d.getDate(), d.getFullYear());
        }).reverse();
    }
    proceedToCheckOut = () => {
        const {navigateTo} = new RouterService()
        const {
            createOrderData,
            getCartData,
        } = new OrderService()
        let cartData = getCartData(),
        cartTotal = 0
        cartData.forEach((e: any) => {
            cartTotal += Number(e.displayPrice * e.purchaseQuantity)
        })
        createOrderData({
            cartData: getCartData(),
            cartTotal,
            couponCode: '',
            discount: 0
        })
        setTimeout(() => {
            navigateTo({route: '/checkout'})
        }, 500)
    }
    getPath = (path: string): string => {
        if (path)
            return path.includes('#') ? path.substr(0, path.indexOf('#')): path;
        return ''
    }
    toggleActiveClass = (id: string) => {
        document.getElementById(id).classList.toggle("active")
    }
    getDay = (num: number): string => {
        let daysList = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
        return daysList[num]
    }
    parseAddress = (addressObject: any): string => {
        if (addressObject !== "undefined" && addressObject) {
            let activeSocietyTitle = '', activeSociety: any = ''
            if (addressObject.activeSociety !== null && addressObject.activeSociety && addressObject.activeSociety !== "undefined" && addressObject.activeSociety !== "[object Object]" && addressObject.activeSociety !== "null" && addressObject.activeSociety !== null) {
                activeSociety = JSON.parse(addressObject.activeSociety)
                if (activeSociety !== null)
                    activeSocietyTitle = activeSociety.title
            }
            let parsedAddress = addressObject.building+', '+activeSocietyTitle+', '+addressObject.locality+', '+addressObject.city+', '+addressObject.state+' - '+addressObject.pinCode
            return parsedAddress
        }
        return ''
    }
    catchError = (e: any) => {
        console.error('e', e)
        this.showAlert({
            type: 'error',
            msg: 'Unable to connect to server! Please try again after some time.'
        })
    }
    setActiveLanguage = (language: string) => {
        localStorage.setItem('activeLanguage', language)
    }
    isAdminUrl = (activeRoute: string): boolean => {
        return activeRoute.indexOf("admin") !== -1
    }
    isModuleEnabled = (modules: string[]): boolean => {
        if(modules && modules[0]) {
            let allMoudules = 0
            modules?.forEach(m => {
                if(activeModules.includes(m))
                    allMoudules++
            })
            return (allMoudules === modules.length)
        }
        else return true
    }
}