import '../../../../assets/scss/styles.scss'
import './style.scss';
import { useUnderDevelopment } from './hooks';
import { useElement } from '../../../hooks';
export const UnderDevelopment = () => {
    const Button = useElement('Button')
    const Input = useElement('Input')
    const {
        authenticate
    } = useUnderDevelopment()
    return <div className="body-background">
        <div className="error-page auth-page">
            <h2 className='fs32 white'>Under Development</h2>
            <p>Please enter auth key to visit the page.</p>
            <div className="form-group">
                <Input id="authKey" className="input" style={{height: '40px', padding: '10px 20px'}} />
            </div>
            <Button className="btn btn-primary" onClick={authenticate}>Enter</Button>
        </div>
    </div>
}