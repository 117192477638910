import { PageRouteProps } from "../../types";

export const homeRoutes: PageRouteProps[] = [
    {
        "pathName": "/home",
        "redirectTo": "/",
        "type": "public",
        "mode": "static"
    },
    {
        "pathName": "",
        "component": "Home",
        "type": "public",
        "mode": "static"
    }
]