import { useCallback, useEffect, useState } from "react"
import { useService } from "../../../../hooks"

export const useTrashConfirmModal = ({
    trashCancelAction,
    showTrashConfirm
}) => {
    const [showTrashConfirmModal, setshowTrashConfirmModal] = useState<boolean>(false)
    const {
        openModal,
        closeModal,
    } = useService('Misc')

    useEffect(() => {
        if (showTrashConfirm !== showTrashConfirmModal) {
            setshowTrashConfirmModal(showTrashConfirm)
            if (showTrashConfirm)
                openModal('trash-modal')
            else
                trashCancel()
        }
    }, [showTrashConfirm])
    
    const trashCancel = useCallback(() => {
        closeModal("trash-modal")
        trashCancelAction()
    }, [])

    return {
        trashCancel,
    }
}