import { base } from '../../../constant'
import { useServices } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'

export const Services = () => {
    const TimeSlots = useComponent('TimeSlots')
    const {
        isUserLoggedIn,
        getActiveSubscription,
    } = useService('User')
    const {
        isEmpty,
        toIndianCurrency,
        openCarSelectModal,
        isModuleEnabled,
        openModal,
        closeModal,
    } = useService('Misc')
    const FeaturedServices = useComponent('FeaturedServices')
    const Button = useElement('Button')
    const Link = useElement('Link')
    const DatePicker = useElement('DatePicker')
    const Html = useElement('Html')
    const Banner = useWidget('Banner')
    const SubscriptionData = useComponent('SubscriptionData')
    const {
        activeCar,
        activeService,
        user,
        buyItem,
        setactiveTimeSlot,
        activeTimeSlot,
        pageTitle,
        activeBanner,
        serviceList,
        setorderDate,
        setchooseServiceTime,
        today,
        orderDate,
        chooseServiceTime,
        showPick,
        setselectPick,
        selectPick,
        chooseActiveCar,
    } = useServices()

    const renderServiceList = () => {
        let activePrice = null, found = false
        return serviceList.map(( item, index ) => {
            activePrice = 0
            found = false
            item.prices.forEach(c => {
                if (activeCar !== null && !isEmpty(activeCar?.Segment)) {
                    if (activeCar?.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                        found = true
                        activePrice = c
                    }
                }
            })
            return <div key={index} className={activeService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} id={item.slug}>
                <div className="service-content flex">
                    <div className="image" title={item.title} style={{backgroundImage: `url("${base.imageBaseUrl}${JSON.parse(item.image)[0]}")`}}></div>
                    <div className="title-box">
                        <div className="title flex">
                            <div className="title-item"><h1>{item.title}</h1></div>
                            {item.serviceTime && <div className="hrsTaken"><i className="hi-clock1"></i>Service Time<br />{item.serviceTime} Hrs</div>}
                        </div>
                        <div className="about">
                            <Html content={item.userNote} />
                        </div>
                        <Html className="points" content={item.excerpt} />
                        <div className="addToCartbtn-service-page">
                            {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <Button className="btn btn-primary mr20" onClick= {() => buyItem('add-to-cart', item)}>Add to Cart<i className="hi-cart"></i></Button>}
                            <Link href={"/service/"+item.slug} className="mrauto mt5">View more</Link>
                            {
                                activePrice !== 0?
                                <>
                                    {
                                        typeof activePrice.saleprice === "undefined" || activePrice.saleprice === 0 || activePrice.saleprice === '' || activePrice.saleprice === activePrice.price ?
                                        <div className="mrp">
                                            <span className="black">{toIndianCurrency(activePrice.price)} <small>+18% GST</small></span>
                                        </div>:
                                        <div className="flex">
                                            <div className="mrp mauto">
                                                <span className="black">{toIndianCurrency(activePrice.saleprice)}</span>
                                            </div>
                                            <div className="sale-price mlauto">
                                                <span className="black">{toIndianCurrency(activePrice.price)}</span>
                                            </div>
                                            <div className="per-off">{((activePrice.price - activePrice.saleprice)/activePrice.price * 100).toFixed(0)} % Off
                                            </div>
                                        </div>
                                    }
                                </>:<>
                                {
                                    activeCar === null &&
                                    <span className="get-price" onClick={openCarSelectModal}><i className="hi-directions_car"></i> Get Price</span>
                                }
                                </>
                            }
                            {
                                isModuleEnabled(['Subscription']) && getActiveSubscription() === null &&
                                <p className="get-off" onClick={() => openModal("get-subscription-modal")}>Get 10% off <i className="hi-info"></i> </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    
    return <>
        <Banner title={pageTitle} image={activeBanner} />
        <div className='jumbo-container'>
            {isModuleEnabled(['FeaturedServices']) && <FeaturedServices />}
        </div>
        <div className="jumbo-container mt40">
            {renderServiceList()}
        </div>
        <div className="modal" id="get-subscription-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <SubscriptionData /> 
                </div>
            </div>
        </div>
        <div className="modal add-to-cart-modal" id="add-to-cart-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content overflown small">
                <Button className="close" onClick={() => closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="add-to-cart-modal-header">
                        <div className="modal-header">
                            <div className="heading">Select Service Date</div>
                        </div>
                    </div>
                    <div className="select-date">
                        <div className="datebox">
                            <label className="date-label">Select Service Date</label>
                            <DatePicker
                                onChange={orderDate => {
                                    setorderDate(orderDate)
                                    setchooseServiceTime(true)
                                }}
                                minDate={today}
                                startDate={today}
                                selected={orderDate}
                            />
                        </div>
                        {
                            chooseServiceTime &&
                            <div className="datebox">
                                <label className="date-label">Select ServiceTime Slot</label>
                                <TimeSlots activeTimeSlot={activeTimeSlot} setactiveTimeSlot={setactiveTimeSlot} />
                                {
                                    showPick && isModuleEnabled(['Pick&Drop']) &&
                                    <div className="pick-and-drop" onClick={() => setselectPick(!selectPick)}>
                                        {
                                            selectPick ?
                                            <i className="success hi-check_circle"></i>:
                                            <i className="hi-circle ashgray"></i>
                                        }
                                        <span>Pick & Drop</span>
                                    </div>
                                }
                                <Button className="btn btn-primary mt20" onClick={chooseActiveCar}>Proceed</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
