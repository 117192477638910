import {brandInfo} from '../../../constant'
import { useElement } from '../../../hooks'

export const WelcomeTo = () => {
    const Img = useElement('Img')
    const Link = useElement('Link')
    return <section className="how-we-are mb60-mbs40">
        <div id="service_info_item" className="side-form-icons">
            <Link href={brandInfo.addressLink}> <i className="icon flaticon-placeholder" id="open-location-popup"></i></Link>
            <Link href={'mailto: '+brandInfo.mail}><i className="icon flaticon-email" id="open-message-popup"></i></Link>
            <Link href={'tel: '+brandInfo.phone}><i className="icon flaticon-phone-call" id="open-contact-popup"></i></Link>
        </div>
        <div className="jumbo-container ph5">
            <div className="wrapper">
                <div className="col-6">
                    <div className="common-section-content">
                        <div className="section-title">
                            <h2 className="heading-02"> Welcome to {brandInfo.name.capital} </h2>
                        </div>
                        <div className="paragraph pt20 pb25">
                            <p>
                                {brandInfo.name.capital} is India’s leading network of crafty mechanics and workshops that comes together to provide car service at your doorstep platform, developed & operated by {brandInfo.companyName}. We at {brandInfo.name.capital} go by the three A’s of automobiles - Affordable, Accessible and Authentic.
                            </p>
                            <p>
                                We promise to deliver affordable, easily accessible and efficient on-demand car service
                                anytime, anywhere, be it at your home or workspace. We ensure to go above and beyond to
                                keep you and your wheels happy by providing high-quality car washes, active repairs, top-
                                notch spares and personal visits and inspections carried out by our team of expert mechanics.
                                {brandInfo.name.capital} is an online portal that proves to be your all in one pitch stop for your four-wheeler
                                needs and requirements to be fulfilled at your doorstep.
                            </p>
                            <p>
                                We are known for our excellent customer service that encompasses a wide range of car cosmetic facilities from interior detailing to exterior enrichment, rodent treatment, protective coating like ceramic and teflon coating and Paint Protection Films.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="section-border-image margin-left white-gray">
                        <div className="border-design">
                            <div className="border-bg full-bg"></div>
                            <div className="we-are-img">
                                <Img source='internal' src={require('../../../../assets/images/dpm.png')} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}