import { useState } from "react"
import { PreLoader } from "../../element"
import { createContext } from "react";

export const AppDataContext = createContext(null)
export const AppData = ({ children }) => {
    const [loading, setloading] = useState<boolean>(false)
    
    const showPreloader = () => {
        setloading(true)
    }
    const hidePreloader = () => {
        setloading(false)
    }
    const appData = {
        showPreloader,
        hidePreloader
    }

    return <AppDataContext.Provider value={appData}>
       {loading && <PreLoader />}
        {children}
    </AppDataContext.Provider>
}

