import { useComponent, useElement } from '../../../hooks'
import { useAddCampaign } from './hooks'

export const AddCampaign = () => {
    const BackIcon = useElement('BackIcon')
    const Input = useElement('Input')
    const Button = useElement('Button')
    const Box = useComponent('Box')
    const {
        mode,
        name,
        setCampaignName,
        content,
        setCampaignContent,
        addCampaign,
    } = useAddCampaign()
    const title = mode + ' Campaign'
    return <div id="content-box" className="content">
        <h1>
            <BackIcon backLink='/admin/campaign/list' />
            {title}
        </h1>
        <div className="columns">
            <div className="left">
                <Box title="Name" required>
                    <Input required name="name" value={name} onChange={setCampaignName} />
                </Box>
                <Box title="Content" required>
                    <textarea className="description" placeholder="Type the campaign here" required name="content" value={content} onChange={setCampaignContent}></textarea>
                </Box>
            </div>
            <div className="right">
                <form noValidate onSubmit={addCampaign}>
                    <Box title="Meta">
                        <Button className="btn btn-primary" type="submit" title="Click here to Add campaign">Publish</Button>
                    </Box>
                </form>
            </div>
        </div>
    </div>
}
