import { base } from '../../../constant'
import { useElement, useService, useWidget } from '../../../hooks'
import { useOurEvents } from './hooks'
import './style.scss'

export const OurEvents = () => {
    const {closeModal} = useService('Misc')
    const Banner = useWidget('Banner')
    const Slider = useElement('Slider')
    const Button = useElement('Button')
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 560,
                settings: {
                    slidesToShow: 1
                }
            },
        ]
    }
    const {
        eventList,
        viewEvent,
    } = useOurEvents()

    const renderEventList = () => {
        return eventList.map((e, i) => {
            return <div className='event-box' key={i}>
                <div className='event-service' style={{backgroundImage: `url('${base.imageBaseUrl}${JSON.parse(e.image)?.[0]}')`}}>
                    <Button className="btn btn-primary" onClick={() => viewEvent(e.slug)}>View Event</Button>
                    <h3 className='tac'>{e.title}</h3>
                </div>
                <h3 className='tac'>{e.title}</h3>
            </div>
        })
    }

    return <>
        <Banner align="right" title="A SNEAK AT OUR WORK" image="event-banner.jpg" />
        <div className='jumbo-container mt60 mb40'>
            <h2 className="heading-02">Recent Events</h2>
            <Slider {...settings}>
                { renderEventList() }
            </Slider>
            {/* <h2 className="heading-02">Image Gallery</h2>
            <h2 className="heading-02">Video Gallery</h2> */}
        </div>
    </>
}
