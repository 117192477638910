import {brandInfo} from '../../../constant'
import { useElement, useService } from '../../../hooks'
import { useMobileApp } from './hooks'
import { DownloadTheApp } from '../DownloadTheApp'
import './style.scss'
export const MobileApp = () => {
    const {isModuleEnabled} = useService('Misc')
    const Button = useElement('Button')
    const Input = useElement('Input')
    const Link = useElement('Link')
    const {
        selectSharingMedium,
        activeDownloadState,
        receiver,
        setReceiverData,
        shareApp,
    } = useMobileApp()
    return <section className="mb60-mbs40 flex block-m">
        <div className="jumbo-container wrapper">
            {isModuleEnabled(['PackagePurchase']) && <Link className="choose-package p20" href="/packages">
                <h2 className="heading-02 border0 monthly-heading heading-02 tac pr20">Monthly Package</h2>
            </Link>}
            <div className="getApp-box">
                <div className="flex p2x0 mtauo pb0">
                    <div className="right-side-app-detail mb50">
                        <h2 className="heading-02 white mt20">Get the {brandInfo.name.capital} App</h2>
                        <p className="subtitle fs18">We will you send you a link, open it on your phone to download the app</p>
                        <div className="mail-number-section flex">
                            <div className="mobileDownload pointer" onClick={() => selectSharingMedium('mobile')}>
                                <i className={activeDownloadState === 'mobile' ? "hi-check-circle" : "hi-circle"}></i>
                                <span>Mobile</span>
                            </div>
                            <div className="emailDownload pointer ml20" onClick={() => selectSharingMedium('email')}>
                                <i className={activeDownloadState === 'email' ? "hi-check-circle" : "hi-circle"}></i>
                                <span>Email</span>
                            </div>
                        </div>
                        <div className="inputLink flex">
                            <Input value={receiver} onChange={setReceiverData} className="input" name="receiver" placeholder={ activeDownloadState === 'mobile' ? 'Enter Mobile Number' : 'Enter Email' } />
                            <Button className="bttn" onClick={shareApp}>Share App Link</Button>
                        </div>
                        <DownloadTheApp />
                    </div>
                </div>
            </div>
        </div>
    </section>
}
