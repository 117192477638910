export const useInput = ({
    onChange,
    setValue,
}) => {
    const onChangeInput = (e) => {
        setValue(e.target.value)
        onChange(e)
    }
    return {
        onChangeInput
    }
}