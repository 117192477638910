import { useContext, useEffect, useState } from "react"
import { authorOption, robotsOption, statusOption, mainUrl } from "../../../../constant"
import { useService } from "../../../../hooks"
import { NavigatorContext } from "../../../../context"

export const useAddService = () => {
    const {navigateTo} = useContext(NavigatorContext)
    const {fetchAll} = useService('Data', 'taxClass')
    const {
        getService,
        addService: publishService,
        updateService,
        getServiceCategory,
    } = useService('Service')
    const {getUsers} = useService('User')
    const {
        showAlert,
        isEmpty,
        generateUID,
        slugify,
    } = useService('Misc')
    const {setTitle} = useService('Meta')
    const {
        urlToSplitData,
        getLocationData,
    } = useService('Router')
    const {getVehicleClassList} = useService('Car')
    const keywordOptions =  [],
    tagOptions = []
    const [company, setcompany] = useState(null)
    const [companies, setcompanies] = useState([])
    const [activeCar, setactiveCar] = useState(null)
    const [activePrice, setactivePrice] = useState<string>('')
    const [saleprice, setsaleprice] = useState<string>('')
    const [featuredVideo, setfeaturedVideo] = useState<string>('')
    const [activeRobotsIndex, setactiveRobotsIndex] = useState<number>(0)
    const [activeStatusIndex, setactiveStatusIndex] = useState<number>(2)
    const [activeAuthorIndex, setactiveAuthorIndex] = useState<number>(0)
    const [searchKey, setsearchKey] = useState<string>('')
    const [tableData, settableData] = useState([])
    const [prices, setprices] = useState<string>('[]')
    const [image, setimage] = useState<string>('[]')
    const [icon, seticon] = useState<string>('[]')
    const [categoryOptions, setcategoryOptions] = useState([])
    const [showCarListBox, setshowCarListBox] = useState<boolean>(false)
    const [vehicleClassList, setvehicleClassList] = useState([])
    const [uid, setuid] = useState<string>('')
    const [renderSlug, setrenderSlug] = useState<boolean>(true)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [title, settitle] = useState<string>('')
    const [slug, setslug] = useState<string>('')
    const [content, setcontent] = useState("")
    const [excerpt, setexcerpt] = useState("")
    const [css, setcss] = useState<string>('')
    const [js, setjs] = useState<string>('')
    const [canonical, setcanonical] = useState<string>('')
    const [robots, setrobots] = useState<string>('')
    const [metaDescription, setmetaDescription] = useState<string>('')
    const [metaKeyword, setmetaKeyword] = useState(null)
    const [author, setauthor] = useState<string>('')
    const [publishDate, setpublishDate] = useState(new Date())
    const [status, setstatus] = useState(null)
    const [category, setcategory] = useState(null)
    const [sku, setsku] = useState<string>('')
    const [hsn, sethsn] = useState<string>('')
    const [tag, settag] = useState(null)
    const [tax, settax] = useState('18')
    const [userNote, setuserNote] = useState<string>('')
    const [serviceTime, setserviceTime] = useState<string>('')
    const [mediaFiles, setmediaFiles] = useState([])
    const [isFeatured, setisFeatured] = useState<boolean>(false)
    const [isAutoApproved, setisAutoApproved] = useState<boolean>(false)
    const [inclusiveTax, setinclusiveTax] = useState<boolean>(false)
    const [taxClassData, settaxClassData] = useState([])
    
    const getTaxClassesListData = async () => {
        const result = await fetchAll()
        if (result.status) {
            let taxClassData = []
            result.data.forEach(e => {
                taxClassData.push({
                    label: e.label,
                    value: e.amount
                })
            })
            settaxClassData(taxClassData)
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No taxClass added yet!' })
            }
        } else showAlert({ type: 'error', msg: 'No taxClass added yet!' })
    }
    
    const getVehicleClasses = async () => {
        const result = await getVehicleClassList()
        if (result.status) {
            if (result.data?.length === 0)
                showAlert({ type: 'error', msg: 'No car manufacturers added yet!' })
            else
                setvehicleClassList(result.data)
        }
    }
    
    const getUsersData = async () => {
        let companies = []
        const result = await getUsers()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No companies added yet!' })
            } else {
                result.data.forEach(result => {
                    if (result.role === 'company')
                        companies.push({ label: result.name, value: result.uid })
                })
                setcompanies(companies)
            }
        }
    }
    
    const getServiceData = async (query) => {
        const result = await getService(query)
        if (result.status) {
            const serviceData = result.data[0]
            setactiveAuthorIndex(authorOption.findIndex(e => e.value === serviceData.robots))
            setactiveRobotsIndex(robotsOption.findIndex(e => e.value === serviceData.robots))
            setactiveStatusIndex(statusOption.findIndex(e => e.value === serviceData.status))
            settag(isEmpty(serviceData.tag) ? [] : JSON.parse(serviceData.tag))
            setcategory(isEmpty(serviceData.category) ? [] : JSON.parse(serviceData.category))
            setmetaKeyword(isEmpty(serviceData.metaKeyword) ? [] : JSON.parse(serviceData.metaKeyword))
            settitle(serviceData.title)
            setmode('edit')
            setslug(serviceData.slug)
            setcontent(serviceData.content)
            setexcerpt(serviceData.excerpt)
            setcss(serviceData.css)
            setjs(serviceData.js)
            setcanonical(serviceData.canonical)
            setrobots(serviceData.robots)
            setmetaDescription(serviceData.metaDescription)
            setauthor(serviceData.author)
            setpublishDate(new Date(serviceData.publishDate))
            setstatus(serviceData.status)
            setserviceTime(serviceData.serviceTime)
            setsku(serviceData.sku ?? '')
            sethsn(serviceData.hsn ?? '')
            setfeaturedVideo(serviceData.featuredVideo ?? '')
            settax(serviceData.tax ?? '')
            setisFeatured(serviceData.isFeatured)
            setisAutoApproved(serviceData.isAutoApproved ?? false)
            setinclusiveTax(serviceData.inclusiveTax)
            setuid(serviceData.uid)
            setuserNote(serviceData.userNote)
            setimage(serviceData.image)
            seticon(serviceData.icon ?? '[]')
            setprices(JSON.parse(serviceData.prices))
            if (serviceData.status === 'private' && serviceData.company)
                setcompany(serviceData.company)
        } else showAlert({ type: 'error', msg: 'No service added yet!' })
    }
    
    const addService = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        serviceObject = {
            title,
            slug,
            content,
            excerpt,
            css,
            sku,
            hsn,
            featuredVideo,
            tax,
            userNote,
            serviceTime,
            isFeatured,
            isAutoApproved,
            inclusiveTax,
            js,
            canonical,
            robots,
            metaDescription,
            metaKeyword: JSON.stringify(metaKeyword),
            author,
            publishDate,
            status: status,
            category: JSON.stringify(category),
            tag: JSON.stringify(tag),
            prices: JSON.stringify(prices),
            image,
            icon,
            company: status.value === 'private' && company ? company : undefined,
            uid: mode === 'add' ? generateUID() : uid
        }
        if (title === '') {
            errorMsg = 'Please enter the title'
            error = true
        } else if (slug === '') {
            errorMsg = 'Please enter the slug'
            error = true
        } else if (image === '') {
            errorMsg = 'Please select image'
            error = true
        } else if (icon === '') {
            errorMsg = 'Please select icon'
            error = true
        } else if (tax === '') {
            errorMsg = 'Please enter the tax'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                const result = await publishService(serviceObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Service added successfully!' })
                    navigateTo({route: '/admin/service/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add service!' })
                }
            } else {
                let data = {
                    query: JSON.stringify({uid}),
                    serviceData: JSON.stringify(serviceObject)
                }
                const result = await updateService(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'Service updated successfully!' })
                    navigateTo({route: '/admin/service/list'})
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update service!' })
                }
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    const removePrice = item => {
        let newPrices = [],
        derivedPrices = JSON.parse(prices)
        derivedPrices.forEach(e => {
            if (e.uid !== item.uid)
                newPrices.push(e)
        })
        setprices(JSON.stringify(newPrices))
    }
    
    const getServiceCategoryData = async () => {
        let tableData = []
        const result = await getServiceCategory()
        if (result.data?.length !== 0) {
            result.data.forEach(e => {
                tableData.push({label: e.catName, value: e.catSlug})
            })
            setcategoryOptions(tableData)
        }
    }


    const showCarList = () => {
        setshowCarListBox(!showCarListBox)
    }

    const urlData = urlToSplitData(getLocationData().pathname)
    setTitle('Add Service', 'admin')
    useEffect(() => {
        getServiceCategoryData()
        getTaxClassesListData()
        setstatus(statusOption[activeStatusIndex].value)
        if (urlData[3] === 'edit') {
            getServiceData({uid: urlData[4]})
        }
        getVehicleClasses()
        getUsersData()
    }, [])

    const addTitle = (e) => {
        let tempTitle = e.target.value, tempSlug = slug
        if (renderSlug) {
            tempSlug = slugify(tempTitle)
        }
        settitle(tempTitle)
        setslug(tempSlug)
    }

    const toggleRenderSlug = () => setrenderSlug(!renderSlug)

    const serviceTitle = mode + ' Service'
    let derivedPrices = JSON.parse(prices)
    let activeRobots = robotsOption[activeRobotsIndex],
    activeStatus = statusOption[activeStatusIndex]
    statusOption.forEach(e => {
        if (e.value === status) {
            activeStatus = e
        }
    })
    robotsOption.forEach(e => {
        if (e.value === robots) {
            activeRobots = e
        }
    })
    if (canonical === '') {
        setcanonical(mainUrl+ urlData[2]+'/'+slug)
    }
    let selectedTaxClass = null
    if (taxClassData.length>0 && tax === '') {
        selectedTaxClass = taxClassData[0]
    }
    taxClassData.forEach(e => {
        if (e.value === tax) {
            selectedTaxClass = e
        }
    })
    return {
        setshowCarListBox,
        removePrice,
        serviceTitle,
        addService,
        title,
        addTitle,
        slug,
        setslug,
        sku,
        setsku,
        featuredVideo,
        setfeaturedVideo,
        hsn,
        sethsn,
        content,
        setcontent,
        userNote,
        setuserNote,
        excerpt,
        setexcerpt,
        showCarList,
        showCarListBox,
        vehicleClassList,
        activeCar,
        activePrice,
        saleprice,
        setactivePrice,
        setsaleprice,
        setactiveCar,
        derivedPrices,
        prices,
        setprices,
        css,
        setcss,
        js,
        setjs,
        canonical,
        setcanonical,
        activeRobots,
        setrobots,
        metaDescription,
        setmetaDescription,
        setmetaKeyword,
        keywordOptions,
        metaKeyword,
        renderSlug,
        toggleRenderSlug,
        selectedTaxClass,
        settax,
        taxClassData,
        serviceTime,
        setserviceTime,
        setpublishDate,
        publishDate,
        setinclusiveTax,
        inclusiveTax,
        setisFeatured,
        isFeatured,
        setisAutoApproved,
        isAutoApproved,
        activeStatus,
        setstatus,
        status,
        company,
        setcompany,
        companies,
        category,
        setcategory,
        categoryOptions,
        tag,
        settag,
        tagOptions,
        image,
        setimage,
        icon,
        seticon,
    }
}