import { memo } from 'react'
import { timeSlot } from '../../../constant'
import './style.scss'

interface Timeslot {
    id: string
    slot: string
}

interface TimeSlotsProps {
    activeTimeSlot: Timeslot
    setactiveTimeSlot: (timeslot: Timeslot) => void
}

export const TimeSlots = memo(({
    activeTimeSlot,
    setactiveTimeSlot,
}: TimeSlotsProps) => {
    const renderTimeSlots = () => {
        return timeSlot.map(( item, index ) => {
            return <li className={ activeTimeSlot.slot === item.slot ? 'time-slot-single active': 'time-slot-single'} onClick={() => setactiveTimeSlot(item)} key={index}>
                <i className={
                    activeTimeSlot.slot === item.slot?
                    "success hi-check_circle" : "hi-circle ashgray"
                }></i>
                {item.slot}
            </li>
        })
    }

    return <div className="time-slots">
        { renderTimeSlots() }
    </div>
})