import { memo } from "react"
import { useService } from "../../../../../hooks"
import { TableContentProps } from "../../types"

export const TableContent = memo(({data, pageSize}: TableContentProps) => {
    const {checkIfEmpty} = useService('Misc')
    return <tbody>
        {
            data.content?.map((e, i) => {
                if ((pageSize && i < pageSize) || !pageSize)
                return <tr key={i}>
                    {
                        Object.entries(e).map(([key, value]) => {
                            return <td key={key+'_'+i}>
                                {checkIfEmpty(value)}
                            </td>
                        })
                    }
                </tr>
            })
        }
        {data.addonBodyContent ?? ''}
    </tbody>
})