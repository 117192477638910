import { useElement } from '../../../hooks'

export const FreeInspection = () => {
    const Img = useElement('Img')
    return <section className="free-inspection-section">
        <div className="banner-auto-car style-02 pt120 pb120">
            <div className="wrapper">
                <div className="banner-right-content">
                <div className="m-auto">
                    <div className="section-title tac">
                        <h2 className="heading-02">Car Sanitization</h2>
                    </div>
                </div>
                </div>
            </div>
            <div className="service-box-banner">
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/bacteria.png')} alt="Bacteria" />
                        <p>Bacteria</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/virus.png')} alt="Virus" />
                        <p>Virus</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/gas.png')} alt="Gases" />
                        <p>Gases</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/odour.png')} alt="Odour" />
                        <p>Odour</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/formal.png')} alt="Formaldyhde" />
                        <p>Formaldyhde</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/smoke.png')} alt="Smoke" />
                        <p>Smoke</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/pm2.png')} alt="PM 2.5" />
                        <p>PM 2.5</p>
                    </div>
                </div>
                <div className="col-3">
                    <div className="data">
                        <Img source='internal' src={require('../../../../assets/images/petOdour.png')} alt="Pet Odour" />
                        <p>Pet Odour</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}