import { memo } from 'react'
import { useBackIcon } from './hooks'

interface BackIconProps {
    backLink: string
}

export const BackIcon = memo(({backLink}: BackIconProps) => {
    const {
        navigateToBack
    } = useBackIcon({backLink})
    return <span className="back-icon" onClick={navigateToBack}><i className='hi-arrow-circle-left'></i></span> 
})