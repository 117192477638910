import { PageRouteProps } from "../../types";

export const userRoutes: PageRouteProps[] = [
    {
        "pathName": "/admin/user/list",
        "module": ["User"],
        "component": "Users",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/user/list",
        "module": ["User"],
        "component": "Users",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/user/add",
        "module": ["User"],
        "component": "AddUser",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/user/edit",
        "module": ["User"],
        "component": "AddUser",
        "type": "admin",
        "mode": "dynamic"
    },
    {
        "pathName": "/admin/trash/user/list",
        "module": ["User"],
        "component": "Users",
        "type": "admin",
        "mode": "static"
    },
    {
        "pathName": "/admin/company/list",
        "module": ["User"],
        "component": "Users",
        "type": "admin",
        "accessRoles": ["supervisor"],
        "mode": "static"
    },
    {
        "pathName": "/admin/account",
        "module": ["User"],
        "component": "AddUser",
        "type": "admin",
        "accessRoles": ["supervisor", "worker"],
        "mode": "static"
    }
]