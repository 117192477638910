import { useContext, useEffect, useState } from "react"
import { config } from "../../../../constant"
import { useElement, useService } from "../../../../hooks"
import { AppDataContext } from "../../../../context"

export const useAdminPinCode = () => {
    const Actor = useElement('Actor')
    const Badge = useElement('Badge')
    const {
        fetchAll,
        remove,
        save,
        update,
    } = useService('Data', 'pinCode')
    const {setTitle} = useService('Meta')
    const {
        showPreloader,
        hidePreloader,
    } = useContext(AppDataContext)
    const {
        showAlert,
        generateUID,
        closeModal,
        openModal,
        isBottom,
    } = useService('Misc')
    const {commonPageSize} = config
    const [tableContent, settableContent] = useState({})
    const [activeShownTotal, setactiveShownTotal] = useState<number>(commonPageSize)
    const [pinCodeList, setpinCodeList] = useState([])
    const [activeItem, setactiveItem] = useState(null)
    const [mode, setmode] = useState<'add' | 'edit' | 'view'>('add')
    const [showTrashConfirm, setshowTrashConfirm] = useState<boolean>(false)
    const [filteredList, setfilteredList] = useState([])

    setTitle('Pin Code', 'admin')
    useEffect(() => {
        getPinCodesData()
    }, [])

    const trackScrolling = () => {
        const wrappedElement = document.getElementById('list-wrapper')
        if (isBottom(wrappedElement)) {
            if (filteredList.length > commonPageSize && activeShownTotal < filteredList.length)
                showMore()
        }
    }
    
    const showMore = () => {
        showPreloader()
        setTimeout(() => {
            hidePreloader()
            setactiveShownTotal(activeShownTotal + commonPageSize)
        }, 300)
    }
    
    const getPinCodesData = async () => {
        let pinCodeList = []
        const result = await fetchAll()
        if (result.status) {
            if (result.data?.length === 0) {
                showAlert({ type: 'error', msg: 'No Pin Code added yet!' })
            } else {
                result.data.forEach(e => {
                    pinCodeList.push(e)
                })
            }
            setpinCodeList(pinCodeList)
        }
    }
    
    const trashCancel = () => {
        setactiveItem(null)
        setshowTrashConfirm(false)
    }
    
    const trashPinCode = async () => {
        let query = {
            uid: activeItem.uid
        }
        const result = await remove(query)
        if (result.status) {
            getPinCodesData()
            showAlert({ type: 'success', msg: 'PinCode deleted successfully!' })
            setactiveItem(null)
            setshowTrashConfirm(false)
        } else showAlert({ type: 'error', msg: 'Unable to delete pinCode!' })
    }
    
    const addPinCode = async (e) => {
        e.preventDefault()
        let error = false,
        errorMsg = '',
        pinCodeObject = activeItem
        if (pinCodeObject.district === '' ) {
            errorMsg = 'Please enter district!'
            error = true
        } else if (pinCodeObject.code === '') {
            errorMsg = 'Please enter Pin Code'
            error = true
        } else if (pinCodeObject.state === '') {
            errorMsg = 'Please enter State'
            error = true
        } else if (pinCodeObject.area === '') {
            errorMsg = 'Please enter Area Name'
            error = true
        }
        if (!error) {
            if (mode === 'add') {
                pinCodeObject.uid = generateUID()
                const result = await save(pinCodeObject)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'PinCode added successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to add pinCode!' })
                }
                setactiveItem(null)
                closeModal("pinCode-modal")
                getPinCodesData()
            } else {
                delete pinCodeObject._id
                let data = {
                    query: JSON.stringify({uid: pinCodeObject.uid}),
                    pinCodeData: JSON.stringify(pinCodeObject)
                }
                const result = await update(data)
                if (result.status) {
                    showAlert({ type: 'success', msg: 'PinCode updated successfully!' })
                } else {
                    showAlert({ type: 'error', msg: 'Unable to update pinCode!' })
                }
                setactiveItem(null)
                closeModal("pinCode-modal")
                getPinCodesData()
            }
        } else showAlert({ type: 'error', msg: errorMsg })
    }
    
    const getTableData = filterData => {
        let data = []
        filterData.forEach(item => {
            let e = {
                area: item.area,
                code: item.code,
                district: item.district,
                state: item.state,
                status: <Badge item={item.status ? 'active' : 'inactive'} />,
                action: <div className="action-bar" style={{bottom: 10}}>
                    <Actor type='edit' onClick={() => {
                        setmode('edit')
                        setactiveItem(item)
                        openModal("pinCode-modal")
                    }} />
                    <Actor type='trash' onClick={() => {
                        setactiveItem(item)
                        setshowTrashConfirm(true)
                    }} />
                </div>
            }
            data.push(e)
        })
        return data
    }
    
    useEffect(() => {
        settableContent({
            headers: [
                {
                    label: 'Area',
                    key: 'area'
                },
                {
                    label: 'Pin Code',
                    key: 'code'
                },
                {
                    label: 'District',
                    key: 'district'
                },
                {
                    label: 'State',
                    key: 'state'
                },
                {
                    label: 'Status',
                    key: 'status'
                },
                {
                    label: 'Action',
                    key: 'action'
                }
            ],
            content: getTableData(pinCodeList)
        })
    }, [pinCodeList])

    const openAddModal = () => {
        setmode('add')
        setactiveItem({
            city: '',
            code: '',
            status: false,
            state: '',
            district: '',
            area: ''
        })
        openModal("pinCode-modal")
    }
    return {
        trackScrolling,
        openAddModal,
        tableContent,
        activeShownTotal,
        trashPinCode,
        trashCancel,
        showTrashConfirm,
        mode,
        addPinCode,
        activeItem,
        setactiveItem,
    }
}