import { PageRouteProps } from "../../types";

export const jobRoutes: PageRouteProps[] = [
    {
        "pathName": "/job",
        "module": ["Job"],
        "component": "Job",
        "type": "public",
        "mode": "dynamic"
    }
]