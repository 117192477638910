import { projectRoutes } from './Project'
import { offerRoutes } from './Offer'
import { chatbotRoutes } from './Chatbot'
import { bannerRoutes } from './Banner'
import { societyRoutes } from './Society'
import { productRoutes } from './Product'
import { orderRoutes } from './Order'
import { microServiceRoutes } from './MicroService'
import { serviceRoutes } from './Service'
import { campaignRoutes } from './Campaign'
import { carRoutes } from './Car'
import { garageRoutes } from './Garage'
import { taxClassRoutes } from './TaxClass'
import { userRoutes } from './User'
import { testimonialRoutes } from './Testimonial'
import { pageRoutes } from './Page'
import { postRoutes } from './Post'
import { eventRoutes } from './Event'
import { notificationRoutes } from './Notification'
import { appRoutes } from './App'
import { settingRoutes } from './Setting'
import { badgeRoutes } from './Badge'
import { franchiseRoutes } from './Franchise'
import { locationRoutes } from './Location'
import { faqRoutes } from './Faq'
import { miscRoutes } from './Misc'
import { jobRoutes } from './Job'
import { PageRouteProps } from '../types'

export const adminRoutes: PageRouteProps[] = [
    ...jobRoutes,
    ...eventRoutes,
    ...franchiseRoutes,
    ...badgeRoutes,
    ...settingRoutes,
    ...appRoutes,
    ...serviceRoutes,
    ...microServiceRoutes,
    ...projectRoutes,
    ...offerRoutes,
    ...chatbotRoutes,
    ...bannerRoutes,
    ...societyRoutes,
    ...productRoutes,
    ...orderRoutes,
    ...campaignRoutes,
    ...carRoutes,
    ...garageRoutes,
    ...taxClassRoutes,
    ...userRoutes,
    ...testimonialRoutes,
    ...pageRoutes,
    ...postRoutes,
    ...notificationRoutes,
    ...locationRoutes,
    ...faqRoutes,
    ...miscRoutes,
]