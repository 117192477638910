import { useMicroServices } from './hooks'
import { useComponent, useElement, useService, useWidget } from '../../../hooks'

export const MicroServices = () => {
    const {
        isUserLoggedIn,
        getActiveSubscription,
    } = useService('User')
    const TimeSlots = useComponent('TimeSlots')
    const {
        isEmpty,
        toIndianCurrency,
        openCarSelectModal,
        openModal,
        isModuleEnabled,
        closeModal,
    } = useService('Misc')
    const Img = useElement('Img')
    const Button = useElement('Button')
    const DatePicker = useElement('DatePicker')
    const Html = useElement('Html')
    const Banner = useWidget('Banner')
    const SubscriptionData = useComponent('SubscriptionData')
    const {
        activeCar,
        activeMicroService,
        user,
        buyItem,
        setactiveTimeSlot,
        activeTimeSlot,
        microServiceList,
        setorderDate,
        setchooseServiceTime,
        today,
        orderDate,
        chooseServiceTime,
        showPick,
        setselectPick,
        selectPick,
        chooseActiveCar,
    } = useMicroServices()

    const renderMicroServiceList = serviceFiles => {
        let activePrice = null, found = false
        return serviceFiles.map(( item, index ) => {
            activePrice = 0
            found = false
            JSON.parse(item.prices).forEach(c => {
                if (activeCar !== null && !isEmpty(activeCar?.Segment)) {
                    if (activeCar?.Segment?.toLowerCase().includes(c.priceGroup.toLowerCase()) && !found) {
                        found = true
                        activePrice = c
                    }
                }
            })
            return item.status === 'publish' && <div className={activeMicroService===item.slug?'service-box-new mb30 active': 'service-box-new mb30'} key={index} id={item.slug}>
                <div className="service-content flex">
                    <div className="image">
                        <Img src={JSON.parse(item.image)[0]} alt={item.title} />
                    </div>
                    <div className="title-box">
                        <div className="title flex">
                            <div className="title-item"><h1>{item.title}</h1></div>
                            {item.serviceTime && <div className="hrsTaken"><i className="hi-clock1"></i> Service Time<br />{item.serviceTime} Hrs</div>}
                        </div>
                        <div className="about">
                            <Html content={item.userNote} />
                        </div>
                        <Html className="points" content={item.excerpt} />
                        <div className="addToCartbtn-service-page">
                            {(!isUserLoggedIn() || (isUserLoggedIn() && user?.role && !['manager', 'company', 'branch', 'representative'].includes(user?.role))) && <Button className="btn btn-primary mrauto" onClick= {() => buyItem('add-to-cart', item)}><i className="hi-cart"></i> Add to Cart</Button>}
                            {
                                activePrice !== 0?
                                <>
                                    {
                                        typeof activePrice.saleprice === "undefined" || activePrice.saleprice === 0 || activePrice.saleprice === '' || activePrice.saleprice === activePrice.price ?
                                        <div className="mrp">
                                            <span className="black">{toIndianCurrency(activePrice.price)}</span>
                                        </div>:
                                        <div className="flex">
                                            <div className="mrp mrauto">
                                                <span className="black">{toIndianCurrency(activePrice.saleprice)}</span>
                                            </div>
                                            <div className="sale-price mlauto">
                                                <span className="black">{toIndianCurrency(activePrice.price)}</span>
                                            </div>
                                            <div className="per-off">{((activePrice.price - activePrice.saleprice)/activePrice.price * 100).toFixed(0)} % Off
                                            </div>
                                        </div>
                                    }
                                </>:<>
                                    {
                                        isEmpty(activeCar) && <span className="get-price" onClick={openCarSelectModal}><i className="hi-directions_car"></i> Get Price</span>
                                    }
                                </>
                            }
                            {
                                isModuleEnabled(['Subscription']) && isEmpty(getActiveSubscription()) &&
                                <p className="get-off" onClick={() => openModal("get-subscription-modal")}>Get 10% off <i className="hi-info"></i> </p>
                            }
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    
    return <>
        <Banner title="Micro Services" image="yellow-back-1.jpg" />
        <div className="jumbo-container">
            <section className="service-offer-section pt30">
                <div className="wrapper">
                    <div className="middle-setup">
                        <div className="section-title-wrap tac">
                            <div className="section-title pb30">
                                <h2 className="heading-02 pb10">Best Micro Services</h2>
                                <p className="mb0">WE PROMISE TO PROVIDE REALS FOR REAL WHEELS. Our one of a kind service include</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div className="jumbo-container">
            {renderMicroServiceList(microServiceList || [] )}
        </div>
        <div className="modal" id="get-subscription-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content big">
                <Button className="close" onClick={() => closeModal("get-subscription-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body p20">
                    <SubscriptionData /> 
                </div>
            </div>
        </div>
        <div className="modal add-to-cart-modal" id="add-to-cart-modal">
            <div className="modal-backdrop"></div>
            <div className="modal-content overflown small">
                <Button className="close" onClick={() => closeModal("add-to-cart-modal")}><i className="hi-close"></i></Button>
                <div className="modal-body">
                    <div className="add-to-cart-modal-header">
                        <div className="modal-header">
                            <div className="heading">Select Service Date</div>
                        </div>
                    </div>
                    <div className="select-date">
                        <div className="datebox">
                            <label className="date-label">Select Service Date</label>
                            <DatePicker
                                onChange={orderDate => {
                                    setorderDate(orderDate)
                                    setchooseServiceTime(true)
                                }}
                                minDate={today}
                                startDate={today}
                                selected={orderDate}
                            />
                        </div>
                        {
                            chooseServiceTime &&
                            <div className="datebox">
                                <label className="date-label">Select ServiceTime Slot</label>
                                <TimeSlots activeTimeSlot={activeTimeSlot} setactiveTimeSlot={setactiveTimeSlot} />
                                {
                                    showPick && isModuleEnabled(['Pick&Drop']) &&
                                    <div className="pick-and-drop" onClick={() => setselectPick(!selectPick)}>
                                        {
                                            selectPick ?
                                            <i className="success hi-check_circle"></i>:
                                            <i className="hi-circle ashgray"></i>
                                        }
                                        <span>Pick & Drop</span>
                                    </div>
                                }
                                <Button className="btn btn-primary mt20" onClick={() => {
                                    closeModal("add-to-cart-modal")
                                    chooseActiveCar()
                                }}>Proceed</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
