import { PageRouteProps } from "../../types";

export const garageRoutes: PageRouteProps[] = [
    {
        "pathName": "/garage",
        "module": ["Garage"],
        "component": "Garage",
        "type": "public",
        "mode": "dynamic"
    }
]