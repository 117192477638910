import { useEffect, useState } from "react"
import { useService } from "../../../../../hooks"

export const useCarTotal = ({
    data,
    action,
}) => {
    const {fetch} = useService('DashboardWidget')
    const {getFormattedDate} = useService('Misc')
    const defaultQuery = {
        table: 'car',
        filter: {
            date: {
                start: getFormattedDate(new Date('01-01-1990')),
                end: getFormattedDate(new Date())
            }
        },
        attr: {}
    },
    [count, setcount] = useState<number>(0),
    [title, settitle] = useState(data.title),
    [query, setquery] = useState(Object.keys(data.setting).length ? data.setting : defaultQuery)

    const fetchCount = async () => {
        let result = await fetch('count', query)
        if (result.status)
            setcount(result.data)
    }
    
    const applyFilters = async (e) => {
        e.preventDefault()
        let queryData = query
        await setquery(queryData)
        fetchCount()
        action.updateWidget({...data, title, configure: false, setting: queryData})
    }
    
    const setWidgetTitle = e => settitle(e.target.value)
    
    useEffect(() => {
        fetchCount()
    }, [])
    return {
        applyFilters,
        setWidgetTitle,
        title,
        count,
    }
}